import React from "react";

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  // static getDerivedStateFromError(error) {
  //   // Update state so the next render will show the fallback UI.
  //   console.error(error);
  //   return { hasError: true };
  // }

  // componentDidCatch(error, errorInfo) {
  //   // You can also log the error to an error reporting service
  //   console.error(error, errorInfo);
  //   this.props.onError();
  // }

  resolveError() {
    this.setState({
      hasError: false,
    });
  }

  render() {
    return this.props.children(this.state.hasError, this.resolveError.bind(this));
  }
}
