import React from "react";
import ContentPasteRoundedIcon from "@mui/icons-material/ContentPasteRounded";

export default function CreateAvodFields({ updateField, errors, playlistName = "" }) {
  const adUrlRef = React.useRef();

  React.useEffect(() => {
    if (playlistName) {
      updateField("playlist_name", playlistName);
    }
  }, [playlistName, updateField]);

  async function pasteUrlFromClipboard() {
    const pastedText = await navigator.clipboard.readText();
    adUrlRef.current.value = pastedText;
    updateField("ad_decision_url", pastedText);
  }

  return (
    <div className="create-playlist-form">
      <div className="gw">
        <div className="g g--1-of-3">
          <div className="form-field">
            <label htmlFor="playlist_name">Source Name</label>
            {playlistName ? (
              <input type="text" name="playlist_name" id="playlist_name" value={playlistName} disabled />
            ) : (
              <input
                type="text"
                name="playlist_name"
                id="playlist_name"
                placeholder="Set a playlist name..."
                onChange={(e) => updateField("playlist_name", e.target.value)}
              />
            )}

            {errors.hasOwnProperty("playlist_name") ? (
              <div className="form-field__error">{errors.playlist_name}</div>
            ) : null}
          </div>
        </div>
        <div className="g g--2-of-3">
          <div className="form-field">
            <label
              htmlFor="ad_decision_url"
              aria-label="Use the exported SpringServe Media Tailor tag"
              data-balloon-pos="right"
            >
              Ad Decision Server URL
            </label>
            <div className="avod-playlist__meta__input-group avod-playlist__meta__input-group--with-button">
              <input
                type="text"
                name="ad_decision_url"
                id="ad_decision_url"
                placeholder="Set the ad decision server url..."
                onChange={(e) => updateField("ad_decision_url", e.target.value)}
                ref={adUrlRef}
              />
              <button
                className="btn btn--icon"
                onClick={() => pasteUrlFromClipboard()}
                aria-label="Paste from Clipboard"
                data-balloon-pos="left"
              >
                <ContentPasteRoundedIcon />
              </button>
            </div>

            {errors.hasOwnProperty("ad_decision_url") ? (
              <div className="form-field__error">{errors.ad_decision_url}</div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}
