import React from "react";

function usePlyrId(plyr) {
  const [id, setId] = React.useState();
  const interval = React.useRef(null);

  // periodically check for the latest player instance id
  React.useEffect(() => {
    interval.current = setInterval(() => {
      if (plyr.current?.plyr?.id !== id) {
        setId(plyr.current?.plyr?.id);
      }
    }, 1000);

    return () => {
      clearInterval(interval.current);
    };
  }, [id, plyr]);

  return id;
}

export default usePlyrId;
