import { addMinutes } from "date-fns";
import { v4 } from "uuid";

const MODELS_WITH_CUEPOINTS = ["feature", "episode"];

function fromUtcDate(utcDate) {
  let offsetDate = new Date(utcDate);
  const modifier = offsetDate < 0 ? -1 : 1;
  offsetDate = addMinutes(offsetDate, offsetDate.getTimezoneOffset() * modifier);
  return offsetDate;
}

export function fromApiToTemplateItem(item) {
  let type = "feature";

  if (item.link.feature_id) {
    type = "feature";
  } else if (item.link.episode_id) {
    type = "episode";
  } else if (item.link.promo_id) {
    type = "promo";
  } else if (item.link.series_id) {
    type = "series";
  }

  const adBreaks = MODELS_WITH_CUEPOINTS.includes(type)
    ? item.link.cuepoints.map((contentCuepoint) => ({
        ad_break: contentCuepoint.ad_break,
        cuepoint: contentCuepoint.cuepoint,
      }))
    : [];

  return {
    template_day_item_id: item.template_day_item_id,
    template_day_id: item.template_day_id,

    drag_id: v4(),
    id: item.link[`${type}_guid`],
    title: item.link.formatted_name || item.link.series_name,
    total_duration_seconds: item.duration,
    original_total_duration_seconds: item.link.total_duration,
    type: item.link_type,
    __gstvMeta: {
      ad_breaks: adBreaks,
      duration_seconds: Math.floor(item.link.trimmed_duration ?? item.duration),
      total_duration_seconds: item.duration,
      original_total_duration_seconds: item.link.total_duration,
    },

    since: fromUtcDate(item.starts_at),
    till: fromUtcDate(item.ends_at),
  };
}

export function fromContentListToTemplateItem(contentItem) {
  let type = "feature";

  if (contentItem.feature_id) {
    type = "feature";
  } else if (contentItem.episode_id) {
    type = "episode";
  } else if (contentItem.promo_id) {
    type = "promo";
  } else if (contentItem.series_id) {
    type = "series";
  }

  const adBreaks = MODELS_WITH_CUEPOINTS.includes(type)
    ? contentItem.cuepoints.map((contentCuepoint) => ({
        ad_break: contentCuepoint.ad_break,
        cuepoint: contentCuepoint.cuepoint,
      }))
    : [];

  const item = {
    drag_id: v4(),
    id: contentItem[`${type}_guid`],
    title: contentItem.formatted_name || contentItem.series_name,
    total_duration_seconds: contentItem.total_duration,
    original_total_duration_seconds: contentItem.total_duration,
    type: type,
    __gstvMeta: {
      ad_breaks: adBreaks,
      duration_seconds: Math.floor(contentItem.trimmed_duration ?? contentItem.duration),
      total_duration_seconds: contentItem.total_duration,
      original_total_duration_seconds: contentItem.total_duration,
    },

    since: null,
    till: null,
  };

  return item;
}
