import React from "react";
import Loader from "../../../common/loader";
import ImageWithFallback from "../../../components/image-with-fallback";

function ContentPosterSelector({ posters, form, updateForm, openLightbox }) {
  return (
    <div className="gstv-ag-grid-posters">
      {posters ? (
        posters.map((asset, index) => (
          <div
            className="gstv-ag-grid-posters__poster gstv-ag-grid-posters__poster--two-columns"
            key={`ag-sidebar-poster-${index}`}
          >
            <div className="gstv-ag-grid-posters__poster__image" onClick={() => openLightbox(index)}>
              <ImageWithFallback url={asset.url} />
            </div>
            <div className="form-field">
              <div className="radio-group-container radio-group-container--vertical">
                <div className="radio-group">
                  <input
                    className="form-radio"
                    type="radio"
                    name="large-poster"
                    id={`ag-sidebar-poster-${index}-lg`}
                    onChange={() => updateForm({ large_asset_id: asset.id })}
                    checked={form?.large_asset_id === asset.id}
                  />
                  <label htmlFor={`ag-sidebar-poster-${index}-lg`}>1920 x 1080</label>
                </div>
                <div className="radio-group">
                  <input
                    className="form-radio"
                    type="radio"
                    name="medium-poster"
                    id={`ag-sidebar-poster-${index}-md`}
                    onChange={() => updateForm({ medium_asset_id: asset.id })}
                    checked={form?.medium_asset_id === asset.id}
                  />
                  <label htmlFor={`ag-sidebar-poster-${index}-md`}>1280 x 720</label>
                </div>
                <div className="radio-group">
                  <input
                    className="form-radio"
                    type="radio"
                    name="vertical-poster"
                    id={`ag-sidebar-poster-${index}-v`}
                    onChange={() => updateForm({ vertical_asset_id: asset.id })}
                    checked={form?.vertical_asset_id === asset.id}
                  />
                  <label htmlFor={`ag-sidebar-poster-${index}-v`}>720 x 1280</label>
                </div>
                <div className="radio-group">
                  <input
                    className="form-radio"
                    type="radio"
                    name="thumbnail-poster"
                    id={`ag-sidebar-poster-${index}-sm`}
                    onChange={() => updateForm({ thumbnail_asset_id: asset.id })}
                    checked={form?.thumbnail_asset_id === asset.id}
                  />
                  <label htmlFor={`ag-sidebar-poster-${index}-sm`}>100 x 100</label>
                </div>
              </div>
            </div>
          </div>
        ))
      ) : (
        <Loader />
      )}
    </div>
  );
}

export default ContentPosterSelector;
