let timeoutLocal = {
  track: undefined,
};
export default function debounce(func, wait, immediate, timeoutRef = null) {
  const context = this;
  const args = arguments;
  let timeout = timeoutRef || timeoutLocal;

  const later = function () {
    timeout.track = null;
    if (!immediate) {
      func.apply(context, args);
    }
  };
  const callNow = immediate && !timeout.track;
  clearTimeout(timeout.track);
  timeout.track = setTimeout(later, wait);
  if (callNow) {
    func.apply(context, args);
  }
}
