import React from "react";
import { addProvider, updateProvider } from "../../../requests/providers";
import jwt_decode from "jwt-decode";
import getToken from "../../../functions/getToken.js";
import { createDistribution, deleteDistribution } from "../../../requests/api-requests.js";
import { toast } from "react-toastify";
import Loader from "../../../common/loader.jsx";

function ProviderForm({ action, onComplete, submitting = false, provider = {} }) {
  const [form, setForm] = React.useState({});
  const [errors, setErrors] = React.useState({});
  const [hasDistribution, setDistribution] = React.useState(provider?.distribution ? true : false);
  const [isLoading, setIsLoading] = React.useState(false);

  const verifyForm = React.useCallback(() => {
    let formErrors = {};

    if (!form.provider_name) {
      formErrors = { ...formErrors, provider_name: "Provider name is required." };
    }

    if (!form.bucket) {
      formErrors = { ...formErrors, bucket: "Bucket is required." };
    }

    return formErrors;
  }, [form.bucket, form.provider_name]);

  const addDistribution = React.useCallback((providerId) => {
    setIsLoading(true);
    createDistribution(providerId)
      .then(() => {
        setDistribution(true);
        toast.success("Distribution created");
      })
      .catch((error) => {
        console.error(error);
        toast.error("There was an error creating a distribution for this provider");
      })
      .finally(() => setIsLoading(false));
  }, []);

  const removeDistribution = React.useCallback((providerId) => {
    setIsLoading(true);
    deleteDistribution(providerId)
      .then(() => {
        setDistribution(false);
        toast.success("Distribution removed");
      })
      .catch((error) => {
        console.error(error);
        toast.error("There was an error deleting this provider's distribution");
      })
      .finally(() => setIsLoading(false));
  }, []);

  React.useEffect(() => {
    if (action === "edit") {
      setForm({
        provider_name: provider.provider_name || "",
        bucket: provider.bucket || "",
      });
    }
  }, [provider, action]);

  React.useEffect(() => {
    if (submitting) {
      function onSubmit() {
        setErrors({});
        const formErrors = verifyForm();

        if (Object.keys(formErrors).length) {
          setErrors(formErrors);
          onComplete(false);
        } else if (action === "create") {
          addProvider(form)
            .then(() => {
              onComplete(true);
            })
            .catch((error) => {
              console.error(error);
              if (error.status === 422) {
                formatApiErrors(error.data.errors);
              } else if (error.status === 500 || error.status === 404) {
                setErrors({ api: "There was an error creating this provider. Please contact support." });
              }
              onComplete(false);
            });
        } else if (action === "edit") {
          updateProvider(provider.provider_guid, form)
            .then(() => {
              onComplete(true);
            })
            .catch((error) => {
              console.error(error);
              if (error.status === 422) {
                formatApiErrors(error.data.errors);
              } else if (error.status === 500 || error.status === 404) {
                setErrors({ api: "There was an error editing this provider. Please contact support." });
              }
              onComplete(false);
            });
        }
      }

      onSubmit();
    }
  }, [submitting, action, form, onComplete, provider.provider_guid, verifyForm]);

  React.useEffect(() => {
    // when creating a new provider, link it to user's organisation
    if (action === "create" && getToken()) {
      const token = getToken();
      setForm((prev) => ({
        ...prev,
        organisation_id: [jwt_decode(token).organisation.id],
      }));
    }
  }, [action]);

  function formatApiErrors(errorObj) {
    let apiErrors = {};

    for (const key in errorObj) {
      if (errorObj.hasOwnProperty(key)) {
        apiErrors[key] = errorObj[key].join(". ");
      }
    }

    setErrors(apiErrors);
  }

  function handleChange(key, e) {
    setForm((prev) => ({
      ...prev,
      [key]: e.target.value,
    }));
  }

  return (
    <form>
      <div className="form-field">
        <label>Provider Name</label>
        <input
          type="text"
          name="provider_name"
          onChange={handleChange.bind(null, "provider_name")}
          defaultValue={action === "edit" ? form.provider_name : ""}
        />
        {errors.hasOwnProperty("provider_name") ? (
          <div className="form-field__error">{errors.provider_name}</div>
        ) : null}
      </div>

      <div className="form-field">
        <label>Bucket</label>
        <input
          type="text"
          name="bucket"
          onChange={handleChange.bind(null, "bucket")}
          defaultValue={action === "edit" ? form.bucket : ""}
        />
        {errors.hasOwnProperty("bucket") ? <div className="form-field__error">{errors.bucket}</div> : null}
      </div>

      {Object.keys(provider).length ? (
        isLoading ? (
          <Loader />
        ) : (
          <button
            className="btn--primary"
            onClick={(e) => {
              e.preventDefault();
              hasDistribution ? removeDistribution(provider.provider_id) : addDistribution(provider.provider_id);
            }}
            disabled={isLoading}
          >
            {hasDistribution ? "Delete CDN Distribution" : "Add CDN Distribution"}
          </button>
        )
      ) : null}

      {errors.hasOwnProperty("api") ? <p className="u-fc--warning">{errors.api}</p> : null}
    </form>
  );
}

export default ProviderForm;
