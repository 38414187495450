import React from "react";
import ReactModal from "react-modal";

/**
 * @param fields [{ label, type, name, defaultValue }]
 */
function DialogForm({ isOpen, onClose, onCancel, onSubmit, title, fields }) {
  const formStructure = fields.reduce(
    (prev, curr) => ({
      ...prev,
      [curr.name]: curr.defaultValue ?? "",
    }),
    {},
  );

  const [form, setForm] = React.useState(formStructure);

  function handleChange(key, e) {
    setForm((prev) => ({
      ...prev,
      [key]: e.target.value,
    }));
  }

  function handleSubmit() {
    onSubmit(form);
    onClose();
  }

  return (
    <ReactModal isOpen={isOpen} ariaHideApp={false}>
      <div className="v-react-modal__inner modal-width">
        <div className="v-react-modal__content">
          <div className="v-react-modal__heading">{title}</div>
          <div className="v-react-modal__body">
            {fields.map((field) => (
              <div className="form-field" key={field.name}>
                <label htmlFor={field.name}>{field.label}</label>
                <input
                  type={field.type}
                  defaultValue={field.defaultValue}
                  name={field.name}
                  onChange={handleChange.bind(null, field.name)}
                />
              </div>
            ))}
          </div>
        </div>
        <div className="v-react-modal__footer">
          <button className="btn btn--primary" onClick={handleSubmit} key="add-genre-dialog-submit">
            Add
          </button>
          <button className="btn btn--inverse-primary" onClick={onCancel} key="add-genre-dialog-cancel">
            Cancel
          </button>
        </div>
      </div>
    </ReactModal>
  );
}

export default DialogForm;
