import React from "react";

export default function CustomGenreComponent({ onInteract, value, data }) {
  return (
    <div onClick={() => onInteract(data)} className="u-display--flex-apart">
      {value.length ? (
        <React.Fragment>
          <div className="u-overflow-text">{value.join(", ")}</div>
          <div>({value.length})</div>
        </React.Fragment>
      ) : (
        <div>No genres selected</div>
      )}
    </div>
  );
}
