import {
  createBrowserRouter,
  RouterProvider,
  Outlet,
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from "react-router-dom";
import DefaultLayout from "./src/layout/default-layout.jsx";
import React from "react";
import { ToastContainer } from "react-toastify";
import User from "./src/pages/user.jsx";
import ManageUsersPage from "./src/pages/manage-users-page.jsx";
import ManageProvidersPage from "./src/pages/manage-providers-page.jsx";
import ManageOrganisationsPage from "./src/pages/manage-organisations-page.jsx";
import ManageChannels from "./src/pages/manage-channels-page.jsx";
import Login from "./src/pages/login.jsx";
import HomePage from "./src/pages/home-page.jsx";
import PlayoutsPage from "./src/pages/playout.jsx";
import SchedulerPage from "./src/pages/scheduler-page.jsx";
import Library from "./src/pages/library-page.jsx";
import SchedulerChannelListPage from "./src/pages/scheduler-channel-list-page.jsx";
import LoginLayout from "./src/layout/login-layout.jsx";
import AuthProvider from "./src/providers/auth-provider.jsx";
import AnalyticsPage from "./src/pages/analytics-page.jsx";
import IngestFeedListPage from "./src/pages/ingest-feed-list-page.jsx";
import IngestFeedResultPage from "./src/pages/ingest-feed-result-page.jsx";
import DashQcPage from "./src/pages/dash-qc-page.jsx";
import * as Sentry from "@sentry/react";
import IngestFeedPage from "./src/pages/ingest-feed-page.jsx";
import QualityControlPage from "./src/pages/quality-control-page.jsx";
import PlaylistListPage from "./src/pages/playlist-list-page.jsx";
import PlaylistVersionPage from "./src/pages/playlist-version-page.jsx";
import PlaylistVersionListPage from "./src/pages/playlist-version-list-page.jsx";
import ManageChannelPage from "./src/pages/manage-channel-page.jsx";
import ContentDashboardPage from "./src/pages/content-dashboard-page.jsx";
import ImportDashboard from "./src/pages/dashboards/import-dashboard.jsx";
import AdminDashboardPage from "./src/pages/admin-dashboard-page.jsx";
import PlaylistDashboardPage from "./src/pages/playlist-dashboard-page.jsx";
import EpisodePage from "./src/pages/library/episode-page.jsx";
import FeaturePage from "./src/pages/library/feature-page.jsx";
import SeriesPage from "./src/pages/library/series-page.jsx";
import PromoPage from "./src/pages/library/promo-page.jsx";
import ReportingPage from "./src/pages/reporting-page.jsx";
import SchedulerWeeklyPage from "./src/pages/scheduler-weekly-page.jsx";
import TemplateListPage from "./src/pages/template-list-page.jsx";
import "react-virtualized/styles.css";
import TemplatePage from "./src/pages/template-page.jsx";

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  tracesSampleRate: 0,

  replaysOnErrorSampleRate: 1.0,
  beforeSend: (event, hint) => {
    // check if the originalException is your object and add the data as extras to the Sentry Event
    if (
      typeof hint.originalException === "object" &&
      hint.originalException != null &&
      "key" in hint.originalException
    ) {
      event.extra = {
        ...event.extra,
        ...hint.originalException,
      };
    }
    return event;
  },
});

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const router = sentryCreateBrowserRouter([
  {
    path: "/login",
    element: (
      <LoginLayout title="GoSeeTV" sidebarId="login">
        <Login />
      </LoginLayout>
    ),
  },
  {
    path: "/",
    element: (
      <DefaultLayout title="GoSeeTV - Home" sidebarId="home">
        <HomePage />
      </DefaultLayout>
    ),
  },
  {
    path: "/scheduler",
    element: <Outlet />,
    children: [
      {
        index: true,
        element: (
          <DefaultLayout title="GoSeeTV - Scheduler" sidebarId="scheduler">
            <SchedulerChannelListPage />
          </DefaultLayout>
        ),
      },
      {
        path: ":channelGuid",
        element: <Outlet />,
        children: [
          {
            index: true,
            element: (
              <DefaultLayout title="GoSeeTV - Channel Scheduler" sidebarId="scheduler">
                <SchedulerWeeklyPage />
              </DefaultLayout>
            ),
          },
          {
            path: ":planDate",
            element: (
              <DefaultLayout title="GoSeeTV - Channel Scheduler" sidebarId="scheduler">
                <SchedulerPage />
              </DefaultLayout>
            ),
          },
        ],
      },
    ],
  },
  {
    path: "/templates",
    element: <Outlet />,
    children: [
      {
        index: true,
        element: (
          <DefaultLayout title="GoSeeTV - Channel Templates" sidebarId="templates">
            <TemplateListPage />
          </DefaultLayout>
        ),
      },
      {
        path: ":template_id",
        element: (
          <DefaultLayout title="GoSeeTV - Add Template" sidebarId="templates">
            <TemplatePage />
          </DefaultLayout>
        ),
      },
    ],
  },
  {
    path: "/analytics/dashboards",
    element: (
      <DefaultLayout title="GoSeeTV - Analytics" sidebarId="analytics-dashboars">
        <AnalyticsPage />
      </DefaultLayout>
    ),
  },
  {
    path: "/analytics/reports",
    element: (
      <DefaultLayout title="GoSeeTV - Analytics" sidebarId="analytics-reports">
        <ReportingPage />
      </DefaultLayout>
    ),
  },
  {
    path: "/playouts",
    element: (
      <DefaultLayout title="GoSeeTV - Playouts" sidebarId="playouts">
        <PlayoutsPage />
      </DefaultLayout>
    ),
  },
  {
    path: "/avod",
    element: <Outlet />,
    children: [
      {
        index: true,
        element: (
          <DefaultLayout title="GoSeeTV - AVOD Dashboard" sidebarId="playlists">
            <PlaylistDashboardPage />
          </DefaultLayout>
        ),
      },
      {
        path: "playlists",
        element: <Outlet />,
        children: [
          {
            index: true,
            element: (
              <DefaultLayout title="GoSeeTV - Playlists" sidebarId="playlists">
                <PlaylistListPage />
              </DefaultLayout>
            ),
          },
          {
            path: "create",
            element: (
              <DefaultLayout title="GoSeeTV - Create Playlist" sidebarId="playlists">
                <PlaylistVersionPage create={true} />
              </DefaultLayout>
            ),
          },
          {
            path: ":id/versions",
            element: <Outlet />,
            children: [
              {
                index: true,
                element: (
                  <DefaultLayout title="GoSeeTV - Playlist Versions" sidebarId="playlists">
                    <PlaylistVersionListPage />
                  </DefaultLayout>
                ),
              },
              {
                path: "create",
                element: (
                  <DefaultLayout title="GoSeeTV - Create Playlist Version" sidebarId="playlists">
                    <PlaylistVersionPage create={true} />
                  </DefaultLayout>
                ),
              },
              {
                path: ":version",
                element: (
                  <DefaultLayout title="GoSeeTV - Playlist" sidebarId="playlists">
                    <PlaylistVersionPage create={false} />
                  </DefaultLayout>
                ),
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: "/content",
    element: <Outlet />,
    children: [
      {
        index: true,
        element: (
          <DefaultLayout title="GoSeeTV - Content" sidebarId="content">
            <ContentDashboardPage />
          </DefaultLayout>
        ),
      },
      {
        path: "quality-control",
        element: (
          <DefaultLayout title="GoSeeTV - Quality Control" sidebarId="quality-control" hideSidebarMargin={true}>
            <QualityControlPage />
          </DefaultLayout>
        ),
      },
      {
        path: "import",
        element: <Outlet />,
        children: [
          {
            index: true,
            element: (
              <DefaultLayout title="GoSeeTv - Import" sidebarId="import">
                <ImportDashboard />
              </DefaultLayout>
            ),
          },

          {
            path: "feeds",
            element: <Outlet />,
            children: [
              {
                index: true,
                element: (
                  <DefaultLayout title="GoSeeTV - Import" sidebarId="import">
                    <IngestFeedListPage />
                  </DefaultLayout>
                ),
              },
              {
                path: ":feed_id",
                element: <Outlet />,
                children: [
                  {
                    index: true,
                    element: (
                      <DefaultLayout title="GoSeeTv - Import Feed" sidebarId="import">
                        <IngestFeedPage />
                      </DefaultLayout>
                    ),
                  },
                  {
                    path: "ingestion/:ingest_id",
                    element: (
                      <DefaultLayout title="GoSeeTv - Import Feed" sidebarId="import">
                        <IngestFeedResultPage />
                      </DefaultLayout>
                    ),
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: "library",
        element: <Outlet />,
        children: [
          {
            index: true,
            element: (
              <DefaultLayout title="GoSeeTV - Content" sidebarId="library">
                <Library />
              </DefaultLayout>
            ),
          },
          {
            path: "feature/:feature_guid",
            element: (
              <DefaultLayout title="GoSeeTV - Content" sidebarId="library">
                <FeaturePage />
              </DefaultLayout>
            ),
          },
          {
            path: "promo/:promo_guid",
            element: (
              <DefaultLayout title="GoSeeTV - Content" sidebarId="library">
                <PromoPage />
              </DefaultLayout>
            ),
          },
          {
            path: "series/:series_guid",
            element: <Outlet />,
            children: [
              {
                index: true,
                element: (
                  <DefaultLayout title="GoSeeTv - Content" sidebarId="library">
                    <SeriesPage />
                  </DefaultLayout>
                ),
              },
              {
                path: "episode/:episode_guid",
                element: (
                  <DefaultLayout title="GoSeeTV - Title" sidebarId="library">
                    <EpisodePage />
                  </DefaultLayout>
                ),
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: "/admin",
    element: <Outlet />,
    children: [
      {
        index: true,
        element: (
          <DefaultLayout title="GoSeeTV - Admin" sidebarId="admin">
            <AdminDashboardPage />
          </DefaultLayout>
        ),
      },
      {
        path: "organisations",
        element: (
          <DefaultLayout title="GoSeeTV - Manage Organisations" sidebarId="admin-organisations">
            <ManageOrganisationsPage />
          </DefaultLayout>
        ),
      },
      {
        path: "providers",
        element: (
          <DefaultLayout title="GoSeeTV - Manage Providers" sidebarId="admin-providers">
            <ManageProvidersPage />
          </DefaultLayout>
        ),
      },
      {
        path: "users",
        element: (
          <DefaultLayout title="GoSeeTV - Manage Users" sidebarId="admin-users">
            <ManageUsersPage />
          </DefaultLayout>
        ),
      },
      {
        path: "channels",
        element: <Outlet />,
        children: [
          {
            index: true,
            element: (
              <DefaultLayout title="GoSeeTV - Manage Channels" sidebarId="admin-channels">
                <ManageChannels />
              </DefaultLayout>
            ),
          },
          {
            path: "create",
            element: (
              <DefaultLayout title="GoSeeTV - Create Channel" sidebarId="admin-channels">
                <ManageChannelPage create={true} />
              </DefaultLayout>
            ),
          },
          {
            path: ":channelGuid",
            element: (
              <DefaultLayout title="GoSeeTV - Manage Channel" sidebarId="admin-channels">
                <ManageChannelPage create={false} />
              </DefaultLayout>
            ),
          },
        ],
      },
      {
        path: "dash",
        element: (
          <DefaultLayout title="GoSeeTV - Dash QC" sidebarId="admin-dash-qc">
            <DashQcPage />
          </DefaultLayout>
        ),
      },
    ],
  },
  {
    path: "/user",
    element: (
      <DefaultLayout title="GoSeeTV - User" sidebarId="user">
        <User />
      </DefaultLayout>
    ),
  },
]);

function App() {
  return (
    <AuthProvider>
      <ToastContainer position={"top-center"} autoClose={3000} />
      <RouterProvider router={router}></RouterProvider>
    </AuthProvider>
  );
}

export default App;
