import { getSecondsFromHis } from "./create-ad-breaks";

import { toast } from "react-toastify";

const MIN_TIME_BETWEEN_CUEPOINTS_SECONDS = 300;
const MIN_TIME_BETWEEN_CUEPOINTS_TEXT = "five minutes";

export function areProgramCuepointsSpaced(program) {
  // early exit if less than 2 cuepoints
  if (program.cuepoints < 2) {
    return true;
  }

  const breaksInSeconds = program.cuepoints.map((adBreak) => getSecondsFromHis(adBreak.cuepoint)).sort((a, b) => a - b);

  let spacingOkay = true;
  for (let i = 0; i < breaksInSeconds.length - 1; i++) {
    if (breaksInSeconds[i + 1] - breaksInSeconds[i] < MIN_TIME_BETWEEN_CUEPOINTS_SECONDS) {
      spacingOkay = false;
      toast.error(`At least one cuepoint for this program is within ${MIN_TIME_BETWEEN_CUEPOINTS_TEXT} of another`);
      break;
    }
  }
  return spacingOkay;
}

export function areEpgProgramCuepointsSpaced(program, timezoneDateFormatter) {
  // if not epg program, return true
  if (!program?.__gstvMeta?.ad_breaks) {
    return true;
  }

  // early exit if less than 2 cuepoints
  if (program.__gstvMeta.ad_breaks.length < 2) {
    return true;
  }

  const breaksInSeconds = program.__gstvMeta.ad_breaks
    .map((adBreak) => getSecondsFromHis(adBreak.cuepoint))
    .sort((a, b) => a - b);

  let spacingOkay = true;
  for (let i = 0; i < breaksInSeconds.length - 1; i++) {
    if (breaksInSeconds[i + 1] - breaksInSeconds[i] < MIN_TIME_BETWEEN_CUEPOINTS_SECONDS) {
      spacingOkay = false;
      toast.error(
        `Cuepoints for ${program.title} at ${timezoneDateFormatter(program.since)} must be at least ${MIN_TIME_BETWEEN_CUEPOINTS_TEXT} from each other`,
      );
      break;
    }
  }
  return spacingOkay;
}

export function areAdBreaksSpaced(adBreaks, program, timezoneDateFormatter) {
  const breaksInSeconds = adBreaks.map((adBreak) => getSecondsFromHis(adBreak.cuepoint)).sort((a, b) => a - b);

  if (breaksInSeconds.length <= 1) {
    return true;
  }

  let spacingOkay = true;
  for (let i = 0; i < breaksInSeconds.length - 1; i++) {
    if (breaksInSeconds[i + 1] - breaksInSeconds[i] < MIN_TIME_BETWEEN_CUEPOINTS_SECONDS) {
      spacingOkay = false;
      toast.warning(
        `Cannot update cuepoints for ${program.title} at ${timezoneDateFormatter(program.since)}. Cuepoints must be at least ${MIN_TIME_BETWEEN_CUEPOINTS_TEXT} from each other`,
      );
      break;
    }
  }

  return spacingOkay;
}
