import React from "react";
import { getTypeForContent } from "../epg-editor/utils/accessors.js";
import { Draggable } from "react-beautiful-dnd";
import { format, formatDuration } from "date-fns";
import intervalToDuration from "date-fns/intervalToDuration";
import { getDurationInSecondsWithAdBreaks } from "../epg-editor/utils/create-ad-breaks.js";
import TranscodingStatusText from "../components/transcoding-status-text.jsx";
import { ContentItemEpisodeAction } from "../../components/content-list/content-item.jsx";
import AddBoxRoundedIcon from "@mui/icons-material/AddBoxRounded";
import CheckBoxRoundedIcon from "@mui/icons-material/CheckBoxRounded";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import getClassList from "../../functions/get-class-list.js";

function AddToPlaylist({ clicked, onClick, program }) {
  return (
    <button
      className="btn--text-primary"
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        onClick(program);
        return true;
      }}
    >
      {clicked ? <CheckBoxRoundedIcon /> : <AddBoxRoundedIcon />}
    </button>
  );
}

function RemoveFromPlaylist({ onClick, program }) {
  return (
    <button
      className="btn--text-primary"
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        onClick(program);
        return true;
      }}
    >
      <CancelRoundedIcon />
    </button>
  );
}

export default function DraggableAvodItem({
  program,
  index,
  addProgram = null,
  removeProgram = null,
  selectProgram,
  onPlay,
  draggableId,
  onActions = {},
  transcodingTypes = [],
  editingDisabled = false,
}) {
  const [isAdded, setAdded] = React.useState(false);

  function onProgramClick() {
    selectProgram(program);
    onPlay(program.video?.asset_id);
  }

  function addProgramWrapper(...params) {
    if (addProgram) {
      setAdded(true);
      addProgram(...params);
    }
  }

  function removeProgramWrapper(...params) {
    if (removeProgram) {
      removeProgram(...params);
    }
  }

  const type = getTypeForContent(program);
  const guid = program[`${type}_guid`];
  const hasPrevScheduledTime = !!program.latest_channel_plan_program?.program_start;
  const cuepoints = program?.cuepoints
    ? program.cuepoints.map((contentCuepoint) => ({
        ad_break: contentCuepoint.ad_break,
        cuepoint: contentCuepoint.cuepoint,
      }))
    : [];

  function hasError(item) {
    return !item.asset_duration && !item.duration;
  }

  function getItemClass(item) {
    return getClassList({
      "content-item": true,
      "content-item--warning": hasError(item),
    });
  }

  return (
    <Draggable key={guid} draggableId={draggableId} index={index} isDragDisabled={hasError(program) || editingDisabled}>
      {(provided) => (
        <li
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={{
            ...provided.draggableProps.style,
          }}
          onClick={onProgramClick}
        >
          <span
            className={getItemClass(program)}
            {...(hasError(program)
              ? {
                  ["aria-label"]: "No video found for this content",
                  ["data-balloon-pos"]: index === 0 ? "down" : "up",
                }
              : {})}
          >
            {!editingDisabled ? (
              <span className="content-item__control">
                <MenuRoundedIcon />
              </span>
            ) : null}
            <span className="content-item__description">
              <span className="content-item__heading">{program.formatted_name}</span>
              <span className="content-item__sub-heading">
                <span>
                  {`${formatDuration(
                    intervalToDuration({
                      start: 0,
                      end: (program.trimmed_duration ?? program.duration) * 1000,
                    }),
                  )} (${formatDuration(
                    intervalToDuration({
                      start: 0,
                      end:
                        getDurationInSecondsWithAdBreaks(program.trimmed_duration ?? program.duration, cuepoints) *
                        1000,
                    }),
                  )})`}
                </span>
              </span>
              {hasPrevScheduledTime ? (
                <span
                  className="content-item__sub-heading content-item__sub-heading--hoverable"
                  aria-label={`Last Scheduled at ${program.latest_channel_plan_program?.program_start}`}
                  data-balloon-pos="right"
                  data-balloon-length="medium"
                >
                  {format(new Date(program.latest_channel_plan_program.program_start), "y-MM-dd")}
                </span>
              ) : null}

              <span className="content-item__transcoding-status">
                {transcodingTypes.includes("hls") ? (
                  <TranscodingStatusText
                    status={program?.content_video?.hls_status || program?.content_video?.dash_status}
                    popupPosition="left"
                    type={"hls"}
                  />
                ) : null}
                {transcodingTypes.includes("dash") ? (
                  <TranscodingStatusText
                    status={program?.content_video?.dash_status || program?.content_video?.dash_status}
                    popupPosition="left"
                    type={"dash"}
                  />
                ) : null}
              </span>
            </span>
            <div className="content-item__col">
              <span className="content-item__actions">
                <span className={`content-item__action ${hasError(program) ? "content-item__action--disabled" : ""}`}>
                  <ContentItemEpisodeAction program={program} type={type} onClick={onActions.getEpisodesForSeries} />
                </span>
                {addProgram && !editingDisabled ? (
                  <span className={`content-item__action ${hasError(program) ? "content-item__action--disabled" : ""}`}>
                    <AddToPlaylist clicked={isAdded} onClick={addProgramWrapper} program={program} />
                  </span>
                ) : null}
                {removeProgram && !editingDisabled ? (
                  <span className={`content-item__action ${hasError(program) ? "content-item__action--disabled" : ""}`}>
                    <RemoveFromPlaylist onClick={removeProgramWrapper} program={program} />
                  </span>
                ) : null}
              </span>
              <span className="content-item__rating">{program.rating ? program.rating.rating : "-"}</span>
            </div>
          </span>
        </li>
      )}
    </Draggable>
  );
}
