import React from "react";
import Select from "../../common/select";

function PlayoutChannelFilter({ playoutChannels, onChange }) {
  return (
    <Select
      placeholder={"Select a channel"}
      name={"playout_channel"}
      options={playoutChannels.map((item) => ({
        label: `${item.channel?.display_name ?? item.channel?.name} (${item.destination_reference})`,
        value: {
          playoutId: item.channel_playout_id,
          status: item.status,
          destination_output: item.destination_output,
        },
      }))}
      onChange={(input) => onChange(input.value)}
      classNamePrefix="react-select"
      containerClass="react-select-container--small react-select-container--light"
    />
  );
}

export default PlayoutChannelFilter;
