import React from "react";
import BasePage from "./base-page.jsx";

const LoginLayout = ({ children, title }) => {
  React.useEffect(() => {
    document.title = title;
  }, [title]);

  return <BasePage>{children}</BasePage>;
};

export default LoginLayout;
