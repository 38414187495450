import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from "../providers/auth-provider.jsx";

function Login() {
  const [form, setForm] = React.useState({});
  const [errors, setErrors] = React.useState({});
  const { attemptLogin, isAuthenticated } = useAuth();
  const location = useLocation();

  if (isAuthenticated()) {
    return <Navigate to={location.state?.from ?? "/"} />;
  }

  function onSubmit(e) {
    e.preventDefault();

    setErrors([]);
    const formErrors = verifyForm();

    if (Object.keys(formErrors).length) {
      setErrors(formErrors);
    } else {
      attemptLogin(form.email, form.password)
        .then(() => {})
        .catch((error) => {
          console.error(error);
          if (error.status === 422) {
            error.data.errors
              ? formatApiErrors(error.data.errors)
              : setErrors({ api: "Provided login credentials were not valid." });
          } else if (error.status === 500 || error.status === 404) {
            setErrors({ api: "There was an error logging in. Please contact support." });
          }
        });
    }
  }

  function formatApiErrors(errorObj) {
    let apiErrors = {};

    for (const key in errorObj) {
      if (errorObj.hasOwnProperty(key)) {
        apiErrors[key] = errorObj[key].join(". ");
      }
    }

    setErrors(apiErrors);
  }

  function verifyForm() {
    let formErrors = {};

    if (!form.email) {
      formErrors = { ...formErrors, email: "Please enter your email." };
    }

    if (!form.password) {
      formErrors = { ...formErrors, password: "Please enter your password." };
    }

    return formErrors;
  }

  function handleChange(key, e) {
    setForm((prev) => ({
      ...prev,
      [key]: e.target.value,
    }));
  }

  return (
    <div className="login">
      <div className="login__hero">
        <div className="login__logo">
          <img src="/images/ghero.jpg" alt="" />
        </div>
        <div className="login__heading">
          <h1>CREATE. MANAGE. SYNDICATE. MONETIZE.</h1>
        </div>
      </div>
      <div className="login__content">
        <div className="login__content__inner">
          <h2 className="login__content__heading">Welcome</h2>
          <p className="login__content__message">Please enter your details</p>
          <form className="login__form">
            <div className="form-field form-field--vertical">
              <label htmlFor="email">Email</label>
              <input type="text" name="email" id="email" onChange={handleChange.bind(null, "email")} />
              {errors.hasOwnProperty("email") ? <div className="form-field__error">{errors.email}</div> : null}
            </div>
            <div className="form-field form-field--vertical">
              <label htmlFor="password">Password</label>
              <input type="password" name="password" id="password" onChange={handleChange.bind(null, "password")} />
              {errors.hasOwnProperty("password") ? <div className="form-field__error">{errors.password}</div> : null}
            </div>
            <button className="login__form__submit" onClick={onSubmit}>
              Login
            </button>
          </form>
          {errors.hasOwnProperty("api") ? <div className="form-field__error">{errors.api}</div> : null}
        </div>
      </div>
    </div>
  );
}

export default Login;
