import React from "react";
import ReactModal from "react-modal";
import { toast } from "react-toastify";

export default function ShareAvodPlaylistDialog({ isOpen, onClose, url, wordpressExportDownloadUrl }) {
  function onCopy() {
    navigator.clipboard
      .writeText(url)
      .then(() => {
        toast.success("Copied playlist url to clipboard!");
      })
      .catch(() => {
        toast.error("Could not copy playlist url to clipboard!");
      })
      .finally(() => {
        onClose();
      });
  }

  return (
    <ReactModal isOpen={isOpen} ariaHideApp={false}>
      <div className="v-react-modal__inner modal-width">
        <div className="v-react-modal__heading">Share Playlist</div>
        <div className="v-react-modal__body modal-body">
          <a href={url}>{url}</a>
        </div>
        <div className="v-react-modal__footer">
          <React.Fragment>
            <button className="btn btn--inverse-primary" onClick={onClose}>
              Close
            </button>
            <button className="btn btn--primary" onClick={() => onCopy()}>
              Copy to Clipboard
            </button>
            <a
              className="btn btn--inverse-primary"
              onClick={onClose}
              href={wordpressExportDownloadUrl}
              download={true}
              target="_blank"
              rel="noreferrer"
            >
              Download Wordpress Export
            </a>
          </React.Fragment>
        </div>
      </div>
    </ReactModal>
  );
}
