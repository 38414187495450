import React from "react";
import composeRefs from "@seznam/compose-react-refs";
import { Draggable } from "react-beautiful-dnd";
import { formatSeconds } from "../epg-editor/utils/format-seconds";
import { getSecondsFromHis } from "../epg-editor/utils/create-ad-breaks";
import { useSchedulerContext } from "../../providers/scheduler-context";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import StraightIcon from "@mui/icons-material/Straight";
import ReactInputMask from "react-input-mask";

function DraggableTemplateItem({
  item,
  droppableId,
  segment,
  updateDuration,
  // onItemClick,
  onItemDoubleClick,
  removeItem,
  secondsPerSection,
  pixelsPerSection,
  index,
  getHeightFromSeconds,
  smallDuration,
  smallOffset,
  prependMargin = 0,
}) {
  const [isResizing, setIsResizing] = React.useState(false);
  const [showResizeInput, setResizeInput] = React.useState(false);
  const [itemResizedDuration, setItemResizedDuration] = React.useState(
    formatSeconds(item.__gstvMeta.total_duration_seconds),
  );
  const resizingDuration = React.useRef();
  const resizingTill = React.useRef();
  const itemRef = React.useRef();
  const resizeInputRef = React.useRef();
  const { formatTimeForTimezone } = useSchedulerContext();

  // ensure programResizedDuration is always up to date
  React.useEffect(() => {
    setItemResizedDuration(formatSeconds(item.__gstvMeta.total_duration_seconds));
  }, [item.__gstvMeta.total_duration_seconds]);

  function getHeightStyleFromSeconds(seconds) {
    return `${getHeightFromSeconds(seconds)}px`;
  }

  function getSecondsFromHeight(heightPix) {
    const seconds = Math.ceil((heightPix / pixelsPerSection) * secondsPerSection);
    return seconds;
  }

  function programExceedsSegmentLength(segmentEnd, itemEnd) {
    return itemEnd > segmentEnd;
  }

  // function itemInactive(item) {
  // return !item.isActive;
  //   return false; // @TODO check this or remove it
  // }

  function onResizeInputKeyPress(e) {
    if (e.key === "Enter") {
      commitResize(getSecondsFromHis(resizeInputRef.current.value));
    }

    if (e.key === "Escape") {
      cancelResize();
    }
  }

  function onResizeClick(e) {
    e.preventDefault();

    setResizeInput(true);
    setIsResizing(true);

    resizeInputRef.current.focus();
    resizeInputRef.current.addEventListener("keydown", onResizeInputKeyPress);
    window.addEventListener("keypress", onResizeInputKeyPress);
    window.addEventListener("mouseup", cancelResize);
  }

  function cancelResize() {
    setResizeInput(false);
    setIsResizing(false);
  }

  function updateProgramResizedDuration(duration) {
    // resize duration cannot be longer than program, and cannot be less han 0
    if (
      getSecondsFromHis(duration) > item.__gstvMeta.original_total_duration_seconds ||
      getSecondsFromHis(duration) < 0
    ) {
      setItemResizedDuration(formatSeconds(item.__gstvMeta.original_total_duration_seconds));
      return;
    }

    setItemResizedDuration(duration);
  }

  function commitResize(commitValue = null) {
    const nextDuration = commitValue !== null ? commitValue : getSecondsFromHis(itemResizedDuration);
    updateDuration(item.drag_id, nextDuration);
    closeResizeInput();
  }

  function closeResizeInput() {
    resizeInputRef.current.removeEventListener("keypress", onResizeInputKeyPress);
    window.removeEventListener("keypress", onResizeInputKeyPress);
    window.removeEventListener("mouseup", cancelResize);
    setResizeInput(false);
    setIsResizing(false);
  }

  function resizeHandler(item, mouseDownEvent) {
    mouseDownEvent.preventDefault();
    mouseDownEvent.stopPropagation();

    setIsResizing(true);

    const initialHeight = getHeightFromSeconds(item.__gstvMeta.original_total_duration_seconds);
    const initialMouseY = mouseDownEvent.pageY;

    function onMouseMove(mouseMoveEvent) {
      const currentY = mouseMoveEvent.pageY;
      let nextHeight = currentY - itemRef.current.getBoundingClientRect().top;

      if (currentY <= itemRef.current.getBoundingClientRect().top) {
        nextHeight = initialHeight / 4; // minimum height
      } else if (currentY > itemRef.current.getBoundingClientRect().top + initialHeight) {
        nextHeight = initialHeight;
      }

      window.requestAnimationFrame(() => {
        if (resizingDuration.current) {
          resizingDuration.current.innerHTML = formatSeconds(getSecondsFromHeight(nextHeight));
        }

        if (resizingTill.current) {
          resizingTill.current.innerHTML = formatTimeForTimezone(
            new Date(item.since.getTime() + getSecondsFromHeight(nextHeight) * 1000),
            "HH:mm:ss",
          );
        }
        itemRef.current.style.height = `${nextHeight}px`;
      });
    }

    function onMouseUp(mouseUpEvent) {
      // ignore clicks for this process
      if (mouseUpEvent.pageY !== initialMouseY) {
        const nextDuration = getSecondsFromHeight(itemRef.current.getBoundingClientRect().height);
        updateDuration(item.drag_id, nextDuration);
      }

      setIsResizing(false);
      document.body.removeEventListener("mousemove", onMouseMove);
      document.body.removeEventListener("mouseup", onMouseUp);
    }

    document.body.addEventListener("mousemove", onMouseMove);
    document.body.addEventListener("mouseup", onMouseUp, { once: true });
  }

  const isSmall = getHeightFromSeconds(item.__gstvMeta.total_duration_seconds) < smallDuration;
  const isTrimmed =
    Math.round(item.__gstvMeta.total_duration_seconds) < Math.round(item.__gstvMeta.original_total_duration_seconds);
  let transformStyle = {};

  if (isSmall) {
    const offsets = [-150, -50, 50];
    transformStyle = {
      transform: `translateX(${offsets[smallOffset[0] + 1]}%) translateY(${-50 + 100 * smallOffset[1]}%)`,
    };
  }

  return (
    <div className="vertical-scheduler__program">
      <Draggable
        key={item.drag_id}
        draggableId={`${droppableId}-segment-${index}-${item.drag_id}`}
        index={index}
        isDragDisabled={isResizing}
      >
        {(provided) => {
          // programRef.current = provided.innerRef.current;
          return (
            <div
              ref={composeRefs(itemRef, provided.innerRef)}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              style={{
                height: getHeightStyleFromSeconds(item.__gstvMeta.total_duration_seconds),
                marginTop: `${prependMargin}px`,
                ...provided.draggableProps.style,
              }}
              onDoubleClick={() => onItemDoubleClick(item)}
              className={`vertical-scheduler-program ${isSmall ? "vertical-scheduler-program--small" : ""} ${
                programExceedsSegmentLength(segment.end, item.till) ? "vertical-scheduler-program--exceeded" : ""
              }`}
              // ${itemInactive(item) ? "vertical-scheduler-program--inactive" : ""}`}
              // {...(itemInactive(item)
              //   ? {
              //       "aria-label": "Program is inactive and must be removed.",
              //       "data-balloon-pos": "up",
              //     }
              //   : {
              //       onClick: onItemClick.bind(null, item),
              //     })}
            >
              {isSmall ? (
                <div
                  className={`vertical-scheduler-program__inner vertical-scheduler-program__inner--small ${item.type !== "promo" ? "vertical-scheduler-program__inner--primary" : ""}`}
                  style={transformStyle}
                >
                  <div className="vertical-scheduler-program__content">
                    <span>{formatTimeForTimezone(item.till)}</span>
                    <div className="vertical-scheduler-program__actions">
                      <button
                        className="btn--text-primary vertical-scheduler__remove-button"
                        onClick={() => {
                          removeItem(item);
                        }}
                      >
                        <CancelRoundedIcon />
                      </button>
                    </div>
                  </div>
                </div>
              ) : (
                <React.Fragment>
                  <div className="vertical-scheduler-program__inner">
                    <div className="vertical-scheduler-program__overflow-container">
                      <div className="vertical-scheduler-program__content">
                        <span>{`${item.title} (${formatTimeForTimezone(item.since)} to `}</span>
                        {isResizing ? (
                          <span ref={resizingTill}>{formatTimeForTimezone(item.till)}</span>
                        ) : (
                          <span>{formatTimeForTimezone(item.till)}</span>
                        )}
                        {")"}
                      </div>
                      <div className="vertical-scheduler-program__actions">
                        <button
                          className="btn--text-primary vertical-scheduler__remove-button"
                          onClick={() => {
                            removeItem(item);
                          }}
                        >
                          <CancelRoundedIcon />
                        </button>
                      </div>
                    </div>
                  </div>
                  {item.Type !== "promo" ? (
                    <div
                      className={`vertical-scheduler-program__bottom ${isResizing ? "vertical-scheduler-program__left--active" : ""} ${isTrimmed ? "vertical-scheduler-program__bottom--trimmed" : ""}`}
                    >
                      <div className="vertical-scheduler-program__bottom__left">
                        <button
                          className="btn btn--reset vertical-scheduler-program__bottom__btn"
                          onMouseDown={resizeHandler.bind(null, item)}
                          onClick={(e) => onResizeClick(e)}
                        >
                          <span className="vertical-scheduler-program__info">
                            {isResizing ? (
                              <span
                                className={`vertical-scheduler-program__info__text vertical-scheduler-program__info__text--${isResizing ? "visible" : "hidden"}`}
                                ref={resizingDuration}
                              >
                                {formatTimeForTimezone(item.till)}
                              </span>
                            ) : null}
                            <span
                              className={`vertical-scheduler-program__info__text vertical-scheduler-program__info__text--${isResizing ? "hidden" : "visible"}`}
                            >
                              {formatTimeForTimezone(item.till)}
                            </span>
                          </span>
                          <span
                            className={`vertical-scheduler-program__resize ${isTrimmed ? "vertical-scheduler-program__resize--trimmed" : ""}`}
                          >
                            <StraightIcon />
                          </span>
                        </button>
                        <div
                          className={`vertical-scheduler-program__resize-input ${
                            showResizeInput ? "vertical-scheduler-program__resize-input--active" : ""
                          }`}
                        >
                          <ReactInputMask
                            mask={"99:99:99"}
                            value={itemResizedDuration}
                            onChange={(e) => {
                              updateProgramResizedDuration(e.target.value);
                            }}
                            ref={resizeInputRef}
                          />
                        </div>
                      </div>
                    </div>
                  ) : null}
                </React.Fragment>
              )}
            </div>
          );
        }}
      </Draggable>
    </div>
  );
}

export default DraggableTemplateItem;
