import React from "react";
import { useSchedulerContext } from "../../../providers/scheduler-context";
import { differenceInSeconds, endOfDay, startOfDay } from "date-fns";
import { dateToHourMinutes, dateToHourMinutesSeconds } from "../../../common/duration-formatting";
import { Droppable } from "react-beautiful-dnd";
import dateIsBetween from "../../../common/date-is-between";
import { relativeSecondsToHourMinutes } from "../../../components/vertical-scheduler/helpers/time-helpers";
import CurrentTimeMarker from "./current-time-marker";
import DraggableTimelineProgram from "./draggable-timeline-program";

function SchedulerTimeline({
  epg,
  date,
  setActiveProgram = () => null,
  removeProgram,
  onPlay,
  customRange = [],
  clearRange,
  planBreaks,
  dropsDisabled,
  onProgramDrag,
  updateProgramDuration,
}) {
  const timelineWidth = 5400;

  const [segments, setSegments] = React.useState([]);
  const [pixelsPerSecond, setPixelsPerSecond] = React.useState(timelineWidth / 24 / 3600);
  const { toOffsetDate } = useSchedulerContext();
  const timelineContentRef = React.useRef();

  const buildSegmentsFromEpg = React.useCallback(
    (list, start, end, pps) => {
      // new segment structure with empty program lists
      let newSegments = planBreaks.map((pb) => {
        pb.items = [];
        pb.label = relativeSecondsToHourMinutes(date, pb.start, 0);
        pb.width = getWidthForSegment(pb, start, end, pps);
        return pb;
      });

      // attach programs in epg to correct segment
      for (let i = 0; i < list.length; i++) {
        let segmentIndex = newSegments.findIndex((s) => {
          return dateIsBetween(list[i].since, s.start, s.end, "[)");
        });

        if (segmentIndex === -1) {
          // segment is out of bounds, for now attach to last segment
          segmentIndex = newSegments.length - 1;
        }

        newSegments[segmentIndex].items.push({ ...list[i] });
      }

      newSegments.forEach((segment) => {
        // draggable index uses index of program in segment
        // but it assumes all the programs of the segment are present so we can't filter out any programs at the segment level
        segment.items = segment.items.map((epgProgram) => {
          // mark programs that are completely outside the boundaries not to be rendered
          if (epgProgram.till < start || epgProgram.since > end) {
            epgProgram.till = epgProgram.since;
            epgProgram.outOfBounds = true;
            return epgProgram;
          }

          // trim programs that are sitting on the boundaries
          if (epgProgram.since < start) {
            epgProgram.originalSince = new Date(epgProgram.since);
            epgProgram.since = new Date(start);
          }

          if (epgProgram.till > end) {
            epgProgram.originalTill = new Date(epgProgram.till);
            epgProgram.till = new Date(end);
          }

          return epgProgram;
        });
      });

      setSegments(newSegments);
    },
    [date, planBreaks],
  );

  React.useEffect(() => {
    if (planBreaks.length !== 0) {
      if (customRange.length) {
        setPixelsPerSecond(timelineWidth / differenceInSeconds(customRange[1], customRange[0]));
        buildSegmentsFromEpg(
          epg,
          customRange[0],
          customRange[1],
          timelineWidth / differenceInSeconds(customRange[1], customRange[0]),
        );
      } else {
        setPixelsPerSecond(timelineWidth / 24 / 3600);
        buildSegmentsFromEpg(epg, startOfDay(date), endOfDay(date), timelineWidth / 24 / 3600);
      }
    }
  }, [planBreaks, epg.length, date, buildSegmentsFromEpg, customRange, epg]);

  // early exit if we don't have all the information
  if (planBreaks.length === 0) {
    return null;
  }

  //#region Timeline Labels
  let timelineLabels = [];
  let baseDate = startOfDay(date);
  let endDate = endOfDay(date);
  endDate.setSeconds(60); // push end to 24:00:00

  if (customRange.length) {
    baseDate = customRange[0];
    endDate = customRange[1];
  }

  const timeRangeInSeconds = differenceInSeconds(endDate, baseDate);

  let markersTotal = 2;
  const validMarkers = [48, 30].filter((option) => timeRangeInSeconds % option === 0);
  if (validMarkers.length) {
    markersTotal = validMarkers[0];
  }
  const secondsPerDivision = timeRangeInSeconds / markersTotal;

  let iteratingDate = new Date(baseDate);
  // push first marker
  timelineLabels.push(
    customRange.length ? dateToHourMinutesSeconds(toOffsetDate(baseDate)) : dateToHourMinutes(toOffsetDate(baseDate)),
  );
  iteratingDate.setSeconds(iteratingDate.getSeconds() + secondsPerDivision);

  // populate rest of the markers
  let counter = 0;
  while (counter < markersTotal) {
    timelineLabels.push(
      markersTotal === 48
        ? dateToHourMinutes(toOffsetDate(iteratingDate))
        : dateToHourMinutesSeconds(toOffsetDate(iteratingDate)),
    );
    iteratingDate.setSeconds(iteratingDate.getSeconds() + secondsPerDivision);
    counter++;
  }
  //#endregion

  function getWidthForSegment(segment, start, end, pps) {
    // handle segments outside range
    if (segment.end < start || segment.start > end) {
      return "0px";
    }

    let segmentDuration = segment.duration;
    // trim top end
    if (segment.start < start) {
      segmentDuration -= differenceInSeconds(start, segment.start);
    }

    if (segment.end > end) {
      segmentDuration -= differenceInSeconds(segment.end, end);
    }

    return segmentDuration * pps + "px";
  }

  function onProgramClick(program) {
    setActiveProgram(program);
    onPlay(program.__gstvMeta.video_asset.asset_id);
  }

  return (
    <div className="scheduler-timeline">
      <div className="scheduler-timeline__wrapper">
        <div className="scheduler-timeline__scrollbox">
          <div className="scheduler-timeline__range" style={{ width: `${timelineWidth}px` }}>
            {timelineLabels.map((marker, index) => (
              <div
                className="scheduler-timeline__range__marker"
                style={{ left: `${index * pixelsPerSecond * secondsPerDivision}px` }}
                key={index}
              >
                <span className="scheduler-timeline__range__marker__label">{marker}</span>
              </div>
            ))}
          </div>

          <div className="scheduler-timeline__content" style={{ width: `${timelineWidth}px` }} ref={timelineContentRef}>
            <CurrentTimeMarker timeRange={[baseDate, endDate]} pixelsPerSecond={pixelsPerSecond} date={date} />
            {segments.map((seg, index) => {
              if (dateIsBetween(seg.end, baseDate, endDate)) {
                return (
                  <div
                    className="scheduler-timeline__content__break"
                    style={{ left: `${differenceInSeconds(seg.end, baseDate) * pixelsPerSecond}px` }}
                    key={index}
                  >
                    {dateToHourMinutes(toOffsetDate(seg.end))}
                  </div>
                );
              }
            })}
            {segments.map((seg) => (
              <Droppable
                droppableId={`epg-horizontal-timeline-${seg.label}`}
                direction="horizontal"
                ignoreContainerClipping={true}
                isDropDisabled={dropsDisabled || endOfDay(date) < new Date()}
                key={`epg-horizontal-timeline-${seg.label}`}
              >
                {(provided) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    className="scheduler-timeline__content__segment"
                    style={{ width: seg.width }}
                  >
                    {seg.items.map((program, index) => {
                      // don't render out-of-bounds programs while maintaining indices
                      if (program.outOfBounds) {
                        return null;
                      }

                      return (
                        <DraggableTimelineProgram
                          program={program}
                          segment={seg}
                          onProgramClick={onProgramClick}
                          removeProgram={removeProgram}
                          pixelsPerSecond={pixelsPerSecond}
                          index={index}
                          onProgramDrag={onProgramDrag}
                          updateDuration={updateProgramDuration}
                          isZoomed={customRange.length}
                          key={`h-timeline-${program.id}`}
                          timelineContentRef={timelineContentRef}
                        />
                      );
                    })}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            ))}
          </div>

          {customRange.length ? (
            <div className="scheduler-timeline__controls">
              <button className=" scheduler-timeline__controls__clear-button" onClick={clearRange}>
                Reset Zoom
              </button>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default SchedulerTimeline;
