import React from "react";
import SidebarContentGenres from "./sidebar-content-genres.jsx";
import SidebarContentImages from "./sidebar-content-images.jsx";
import { v4 as uuid } from "uuid";

function RenderCustomElement({ element, ...props }) {
  switch (element) {
    case "sidebar-content-genres":
      return <SidebarContentGenres {...props} key={uuid()} />;
    case "sidebar-content-images":
      return <SidebarContentImages {...props} key={uuid()} />;
    default:
      return null;
  }
}

function SidebarElements({ element, elementTitle, ...props }) {
  return (
    <div className="gstv-ag-grid__sidebar-component">
      <div className="gstv-ag-grid__sidebar-component__heading">{elementTitle}</div>
      <div className="gstv-ag-grid__sidebar-component__body">
        <RenderCustomElement element={element} {...props} />
      </div>
    </div>
  );
}

export default SidebarElements;
