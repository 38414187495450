function formatApiErrors(apiErrorList, rawMessages = false) {
  return Object.keys(apiErrorList).reduce((prev, apiErrorKey) => {
    const errorValue = formatErrorValue(apiErrorList[apiErrorKey], rawMessages);
    if (apiErrorKey.includes(".")) {
      // this is a nested object
      const nestedObject = nestedObjectFromString(apiErrorKey, errorValue);
      return mergeNestedObject(prev, nestedObject);
    }

    return {
      ...prev,
      [apiErrorKey]: errorValue,
    };
  }, {});
}

function nestedObjectFromString(stringKey, value) {
  if (stringKey.includes(".")) {
    // this is either an array or it is a nested object
    const keys = stringKey.split(".");

    if (Number.isNaN(parseInt(keys[0], 10))) {
      return {
        [keys[0]]: nestedObjectFromString(keys.slice(1).join("."), value),
      };
    } else {
      return [parseInt(keys[0], 10), nestedObjectFromString(keys.slice(1).join("."), value)];
    }
  }

  return { [stringKey]: value };
}

function mergeNestedObject(initial, applied) {
  for (const key in applied) {
    if (applied.hasOwnProperty(key)) {
      if (typeof applied[key] === "object" && applied[key] !== null) {
        if (Array.isArray(applied[key])) {
          initial[key] = initial[key] || [];
          initial[key][applied[key][0]] = {
            ...(initial[key][applied[key][0]] ? initial[key][applied[key][0]] : {}),
            ...applied[key][1],
          };
        } else {
          initial[key] = mergeNestedObject(initial[key] || {}, applied[key]);
        }
      } else {
        initial[key] = applied[key];
      }
    }
  }

  return initial;
}

function formatErrorValue(values, asRaw = false) {
  const capitalize = (s) => s.charAt(0).toUpperCase() + s.slice(1);

  const processErrorItem = (item) => {
    if (asRaw) {
      return item;
    }

    const field = item.split(" ")[1].split(".").slice(-1)[0].replace("_", " ");
    return `${capitalize(field)} field is required`;
  };

  return values.map(processErrorItem);
}

export default formatApiErrors;
