import axios from "../../requests/axios.js";

function getQcRows(limit, offset) {
  return new Promise((resolve, reject) => {
    axios
      .get(`/api/quality-control?limit=${limit}&offset=${offset}`)
      .then((result) => {
        resolve(result.data);
      })
      .catch((err) => reject(err));
  });
}

/**
 *
 * @throws
 * @returns {{next: (function(): Promise<unknown[]>), hasNext(): boolean}}
 */
export default function getAllQcRows() {
  let requestLimit = 1000;
  let runningOffset = 0;
  let total = 1001;

  return {
    next: async () => {
      let response = await getQcRows(requestLimit, runningOffset);
      total = response.total;
      const currentOffset = runningOffset;
      runningOffset += response.limit;
      return response.results.map((row, index) => {
        row.id = index + currentOffset;
        return row;
      });
    },
    hasNext() {
      return runningOffset < total;
    },
  };
}
