import React from "react";
import { secondsToHourMinutesSeconds } from "../../../../common/duration-formatting";
import PlannerMetaCuepoints from "../planner-meta-cuepoints";
import { formatSeconds } from "../../utils/format-seconds";

const PromoInformation = ({ promo, programInfo, saveAdBreaks, formatTimeForTimezone }) => (
  <div className="program-information__fields">
    {programInfo.__gstvMeta ? (
      <div className="program-field">
        <div className="program-field__label">Runtime</div>
        <div className="program-field__value">
          {formatTimeForTimezone(programInfo.since)} to {formatTimeForTimezone(programInfo.till)} (
          {secondsToHourMinutesSeconds(programInfo.__gstvMeta.total_duration_seconds)})
        </div>
      </div>
    ) : null}
    {programInfo.__gstvMeta &&
    Math.round(programInfo.__gstvMeta.total_duration_seconds) <
      Math.round(programInfo.__gstvMeta.original_total_duration_seconds) ? (
      <div className="program-field">
        <div className="program-field__label">Original Duration</div>
        <div className="program-field__value">
          {formatSeconds(programInfo.__gstvMeta.original_total_duration_seconds)}
        </div>
      </div>
    ) : null}
    <div className="program-field">
      <div className="program-field__label">Provider</div>
      <div className="program-field__value">{promo.provider.provider_name}</div>
    </div>
    <div className="program-field">
      <div className="program-field__label">Duration</div>
      <div className="program-field__value">{secondsToHourMinutesSeconds(promo.duration)}</div>
    </div>
    {programInfo.__gstvMeta
      ? ((
          <div className="program-field">
            <div className="program-field__label">Ad Duration</div>
            <div className="program-field__value">
              {secondsToHourMinutesSeconds(
                programInfo.__gstvMeta.total_duration_seconds - programInfo.__gstvMeta.duration_seconds,
              )}
            </div>
          </div>
        ),
        (
          <div className="program-field">
            <PlannerMetaCuepoints program={programInfo} saveAdBreaks={saveAdBreaks} />
          </div>
        ))
      : null}
  </div>
);

export default PromoInformation;
