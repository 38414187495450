import React from "react";
import format from "date-fns/format";
import TrashPlanDialog from "../dialogs/trash-plan-dialog";
import CopyPlanDialog from "../dialogs/copy-plan-dialog";
import ProcessPlanDialog from "../dialogs/process-plan-dialog";
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";
import LoopRoundedIcon from "@mui/icons-material/LoopRounded";
import SimCardDownloadRoundedIcon from "@mui/icons-material/SimCardDownloadRounded";
import DeleteForeverRoundedIcon from "@mui/icons-material/DeleteForeverRounded";
import SaveRoundedIcon from "@mui/icons-material/SaveRounded";
import { toast } from "react-toastify";
import { BASE_URL } from "../../../../requests/api-requests";
import Loader from "../../../../common/loader";
import { v4 as uuid } from "uuid";
import ContentPasteRoundedIcon from "@mui/icons-material/ContentPasteRounded";
import CreateTemplateDialog from "../dialogs/create-template-dialog.jsx";

function ChannelHeadingRightActions({
  channel,
  planDate,
  planId,
  updatedAt,
  saveChannelPlan,
  isSaving,
  clearPlan,
  changePlanDate,
}) {
  const [showTrashDialog, setTrashDialog] = React.useState(false);
  const [showProcessDialog, setProcessDialog] = React.useState(false);
  const [showCopyDialog, setCopyDialog] = React.useState(false);
  const [showCreateTemplateDialog, setCreateTemplateDialog] = React.useState(false);

  function confirmTrash() {
    // we are working with local time here so no need for utc conversion
    if (planDate < new Date()) {
      toast.error("Cannot clear plans that started in the past.");
      return;
    }
    setTrashDialog(true);
  }

  function closeTrash() {
    setTrashDialog(false);
  }

  function openProcessDialog() {
    setProcessDialog(true);
  }

  function closeProcessDialog() {
    setProcessDialog(false);
  }

  function openCopyDialog() {
    setCopyDialog(true);
  }

  function openCreateTemplateDialog() {
    setCreateTemplateDialog(true);
  }

  function closeCopyDialog() {
    setCopyDialog(false);
  }

  function closeCreateTemplateDialog() {
    setCreateTemplateDialog(false);
  }

  return (
    <React.Fragment>
      <div className="channel-heading__actions">
        <div className="channel-heading__last-updated">
          {updatedAt ? `Last saved: ${updatedAt.toLocaleDateString()} ${updatedAt.toLocaleTimeString()}` : ""}
        </div>
        <button
          className="btn btn--icon"
          onClick={openCreateTemplateDialog}
          aria-label="Copy to Template"
          data-balloon-pos="down"
        >
          <ContentPasteRoundedIcon />
        </button>
        <button className="btn btn--icon" onClick={openCopyDialog} aria-label="Copy Plan" data-balloon-pos="down">
          <ContentCopyRoundedIcon />
        </button>
        {channel.freeview_service_id ? (
          <a
            className="btn btn--icon"
            href={`${BASE_URL}/feeds/epgs/everyonetv?channel=${channel.name}&date=${format(planDate, "y-MM-dd")}&days=0`}
            download
            aria-label="Download Freeview EPG"
            data-balloon-pos="down"
          >
            <SimCardDownloadRoundedIcon />
          </a>
        ) : null}
        <button className="btn btn--icon" onClick={openProcessDialog} aria-label="Process Plan" data-balloon-pos="down">
          <LoopRoundedIcon />
        </button>
        <button className="btn btn--icon" onClick={confirmTrash} aria-label="Clear Plan" data-balloon-pos="down">
          <DeleteForeverRoundedIcon />
        </button>
        {isSaving ? (
          <Loader width={25} height={25} />
        ) : (
          <button className="btn btn--icon" onClick={saveChannelPlan} aria-label="Save Plan" data-balloon-pos="down">
            <SaveRoundedIcon />
          </button>
        )}
      </div>
      <TrashPlanDialog
        planDate={planDate}
        isOpen={showTrashDialog}
        onClose={closeTrash}
        onSuccess={clearPlan}
        channelId={channel.channel_id}
        breakSource={channel.channel_breaks}
        updateActiveDate={changePlanDate}
        key={`trash_dialog-${uuid()}`}
      />
      <ProcessPlanDialog
        planId={planId}
        isOpen={showProcessDialog}
        channelId={channel.channel_id}
        onClose={closeProcessDialog}
        key={`process_dialog-${uuid()}`}
      />
      <CopyPlanDialog
        planDate={planDate}
        isOpen={showCopyDialog}
        onClose={closeCopyDialog}
        channelId={channel.channel_id}
        updateActiveDate={changePlanDate}
        key={`copy_plan_dialog-${uuid()}`}
      />
      <CreateTemplateDialog
        planId={planId}
        isOpen={showCreateTemplateDialog}
        onClose={closeCreateTemplateDialog}
        channelId={channel.channel_id}
        key={`create_template_dialog-${uuid()}`}
      />
    </React.Fragment>
  );
}

export default ChannelHeadingRightActions;
