import React from "react";
import ReactModal from "react-modal";

export default function UnsavedChangesDialog({ isOpen, onClose, updatePlanDate, message, action, newDate = null }) {
  function onSubmit() {
    if (action === "changeDate" && newDate) {
      updatePlanDate(newDate);
    }

    onClose();
  }

  return (
    <ReactModal isOpen={isOpen} ariaHideApp={false}>
      <div className="v-react-modal__inner modal-width">
        <div className="v-react-modal__heading">Unsaved Changes</div>
        <div className="v-react-modal__body">
          <p>{message}</p>
        </div>
      </div>
      <div className="v-react-modal__footer">
        {action === "changeDate" ? (
          <React.Fragment>
            <button className="btn btn--primary" onClick={onClose} key="btn-back">
              Cancel
            </button>
            <button className="btn btn--inverse-primary" onClick={onSubmit} key="btn-yes">
              Confirm
            </button>
          </React.Fragment>
        ) : (
          <button className="btn btn--primary" onClick={onClose} key="btn-back">
            Close
          </button>
        )}
      </div>
    </ReactModal>
  );
}
