import React from "react";
import Select from "../../../common/select.jsx";

function ContentTypeFilter({ contentTypes, contentType, onChange }) {
  return (
    <Select
      value={contentType}
      placeholder={"Type"}
      name={"provider"}
      options={contentTypes}
      onChange={(input) => onChange(input)}
      containerClass="react-select-container--light react-select-container--small"
    />
  );
}

export default ContentTypeFilter;
