import React from "react";
import ReactModal from "react-modal";
import { toast } from "react-toastify";
import getToken from "../../../functions/getToken.js";

function ExportContentDialog({ isOpen, onClose, url }) {
  const [form, setForm] = React.useState({ type: "csv" });
  const [downloadUrl, setDownloadUrl] = React.useState();

  React.useEffect(() => {
    if (url) {
      const [baseUrl, parameters] = url.split("?");
      const parameterParts = parameters
        .split("&")
        .filter((parameter) => !parameter.split("=")[0].includes(["cursor", "page_size"]))
        .concat(`export_type=${form.type}`)
        .concat(`token=${getToken()}`)
        .join("&");

      setDownloadUrl(`${baseUrl}/export?${parameterParts}`);
    } else {
      setDownloadUrl("");
    }
  }, [url, form.type]);

  function handleChange(key, e) {
    if (e.target.checked) {
      setForm((prev) => ({
        ...prev,
        type: key,
      }));
    }
  }

  function downloadStarted() {
    onClose();
    toast.info("Content list download has started...");
  }

  return (
    <ReactModal isOpen={isOpen} ariaHideApp={false}>
      <div className="v-react-modal__inner modal-width">
        <div className="v-react-modal__heading">Export Content</div>
        <div className="v-react-modal__body">
          <div className="form-field">
            <label htmlFor="">Export content matching with your selected filters?</label>
            <div className="radio-group-container radio-group-container--two-columns">
              <div className="radio-group">
                <input
                  className="form-radio"
                  type="radio"
                  name="export_type"
                  id="csv"
                  value="csv"
                  onChange={handleChange.bind(null, "csv")}
                  defaultChecked
                />
                <label htmlFor="csv">CSV</label>
              </div>
            </div>
          </div>
          <div className="v-react-modal__footer">
            <button className="btn btn--inverse-primary" onClick={onClose}>
              Cancel
            </button>
            <a
              className="btn btn--primary"
              href={downloadUrl}
              target={"_blank"}
              onClick={downloadStarted}
              rel="noreferrer"
            >
              Export
            </a>
          </div>
        </div>
      </div>
    </ReactModal>
  );
}

export default ExportContentDialog;
