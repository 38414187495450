import React from "react";

function ImageWithFallback({ url }) {
  const fallback = "/images/no-image-placeholder.png";
  return <img src={url || fallback} alt="" />;
}

function MissingProgram() {
  return (
    <div className="epg-program-overview">
      <div className="missing-content">
        <p className="missing-content__text">Select a program or channel to see more information</p>
      </div>
    </div>
  );
}

function EpgChannelInfo({ channel }) {
  if (!channel) {
    return <MissingProgram />;
  }

  return (
    <div className="epg-program-overview">
      <div className="epg-program-overview__inner">
        <div className="program-information">
          <h3 className="u-fs--h2 u-margin--none">{channel.display_name}</h3>
          <div className="program-information__fields">
            <div className="gw">
              <div className="g g--1-of-1">
                <div className="home-page-image">
                  <div className="poster__image">
                    <ImageWithFallback url={channel.art?.landscape ?? channel.art?.portrait ?? channel.art?.square} />
                  </div>
                </div>
              </div>
              <div className="g g--1-of-2">
                <div className="program-field" style={{ maxHeight: "400px" }}>
                  <div className="program-field__label">Description</div>
                  <div className="program-field__value">{channel?.description ? channel.description : "None"}</div>
                </div>
              </div>
              <div className="g g--1-of-2">
                <div className="program-field">
                  <div className="program-field__label">Language</div>
                  <div className="program-field__value">{channel.language ?? "None Provided"}</div>
                </div>
                <div className="program-field">
                  <div className="program-field__label">Genre</div>
                  <div className="program-field__value">{channel.genre ?? "None Provided"}</div>
                </div>
                <div className="program-field">
                  <div className="program-field__label">Rating</div>
                  <div className="program-field__value">{channel.rating ?? "None Provided"}</div>
                </div>
                <div className="program-field">
                  <div className="program-field__label">Regions</div>
                  <div className="program-field__value">{channel.regions ?? "None Provided"}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EpgChannelInfo;
