import { addMinutes, startOfDay } from "date-fns";

export default function clockChangeSafeTime(date) {
  const startDayOffset = startOfDay(date).getTimezoneOffset();
  const currentOffset = date.getTimezoneOffset();

  let timezoneOffset = currentOffset;
  if (startDayOffset !== currentOffset) {
    // there is a change, modifier changes and we use the difference between start and end
    timezoneOffset = startDayOffset - currentOffset;
  }

  const modifier = timezoneOffset > 0 ? 1 : -1;
  return addMinutes(date, timezoneOffset * modifier);
}

export function timezoneHasChanged(date1, date2) {
  const diff = getTimezoneDifference(date1, date2);
  return diff !== 0;
}

export function getTimezoneDifference(date1, date2) {
  const date1Offset = startOfDay(date1).getTimezoneOffset();
  const date2Offset = date2.getTimezoneOffset();

  return date1Offset - date2Offset;
}
