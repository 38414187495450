import React from "react";

export default function useEpgEditorDroppableControls(verticalListRef, horizontalListRef) {
  const [verticalDroppable, setVerticalDroppable] = React.useState(true);
  const [horizontalDroppable, setHorizontalDroppable] = React.useState(true);

  const onMouseOverVertical = React.useCallback(() => {
    requestAnimationFrame(() => {
      setHorizontalDroppable(false);
      setVerticalDroppable(true);
    });
  }, []);

  const onMouseOverHorizontal = React.useCallback(() => {
    requestAnimationFrame(() => {
      setHorizontalDroppable(true);
      setVerticalDroppable(false);
    });
  }, []);

  const reset = React.useCallback(() => {
    setHorizontalDroppable(true);
    setVerticalDroppable(true);
    document.body.removeEventListener("mouseup", reset); // somehow, this works
    horizontalListRef.current.removeEventListener("mouseover", onMouseOverHorizontal);
    verticalListRef.current.removeEventListener("mouseover", onMouseOverVertical);
  }, [onMouseOverHorizontal, onMouseOverVertical, horizontalListRef, verticalListRef]);

  const onEpgDragEvent = React.useCallback(
    (event) => {
      switch (event) {
        case "on-horizontal-drag-start":
          setVerticalDroppable(false);
          setHorizontalDroppable(true);
          document.body.addEventListener("mouseup", reset);
          break;
        case "on-vertical-drag-start":
          setHorizontalDroppable(false);
          setVerticalDroppable(true);
          document.body.addEventListener("mouseup", reset);
          break;
        case "on-content-list-drag-start":
          verticalListRef.current.addEventListener("mouseover", onMouseOverVertical);
          horizontalListRef.current.addEventListener("mouseover", onMouseOverHorizontal);
          document.body.addEventListener("mouseup", reset);
          break;
        default:
          break;
      }
    },
    [horizontalListRef, verticalListRef, onMouseOverVertical, onMouseOverHorizontal, reset],
  );

  return {
    verticalDroppable,
    horizontalDroppable,
    onEpgDragEvent,
  };
}
