import React from "react";
import { secondsToHourMinutesSeconds, secondsToHourMinutes } from "../../../../common/duration-formatting.js";
import PlannerMetaCuepoints from "../planner-meta-cuepoints.jsx";
import fetcher from "../../../../common/fetcher.js";
import { BASE_URL } from "../../../../requests/api-requests.js";
import { toast } from "react-toastify";
import Loader from "../../../../common/loader.jsx";
import { formatSeconds } from "../../utils/format-seconds.js";

async function fetchEpisodes(series_guid, season_guid) {
  const data = await fetcher(`${BASE_URL}/api/series/${series_guid}/seasons/${season_guid}/episodes`);
  return data;
}

const EpisodeInformation = ({ episode, programInfo, saveAdBreaks, formatTimeForTimezone }) => {
  const [episodesLoading, setEpisodesLoading] = React.useState(false);
  const [episodes, setEpisodes] = React.useState([]);
  React.useEffect(() => {
    setEpisodesLoading(true);
    fetchEpisodes(episode.season.series_guid, episode.season.season_guid)
      .then((data) => {
        setEpisodes(data);
      })
      .catch((error) => {
        console.error(error);
        toast.error("Unable to fetch episodes for season");
      })
      .finally(() => {
        setEpisodesLoading(false);
      });
  }, [episode.episode_guid, episode.season.season_guid, episode.season.series_guid]);

  return (
    <div className="program-information__fields">
      {programInfo.__gstvMeta ? (
        <div className="program-field">
          <div className="program-field__label">Runtime</div>
          <div className="program-field__value">
            {formatTimeForTimezone(programInfo.since)} to {formatTimeForTimezone(programInfo.till)} (
            {secondsToHourMinutesSeconds(programInfo.__gstvMeta.total_duration_seconds)})
          </div>
        </div>
      ) : null}
      {programInfo.__gstvMeta &&
      Math.round(programInfo.__gstvMeta.total_duration_seconds) <
        Math.round(programInfo.__gstvMeta.original_total_duration_seconds) ? (
        <div className="program-field">
          <div className="program-field__label">Original Duration</div>
          <div className="program-field__value">
            {formatSeconds(programInfo.__gstvMeta.original_total_duration_seconds)}
          </div>
        </div>
      ) : null}
      <div className="program-field">
        <div className="program-field__label">Ratings</div>
        <div className="program-field__value-list">
          <div className="program-field__value">
            <b>BBFC</b> - {episode.rating?.bbfc_rating ? episode.rating.bbfc_rating : ""}
          </div>
          <div className="program-field__value">
            <b>MPAA</b> - {episode.rating?.mpaa_rating ? episode.rating.mpaa_rating : ""}
          </div>
          <div className="program-field__value">
            <b>US-TV</b> - {episode.rating?.us_tv_rating ? episode.rating.us_tv_rating : ""}
          </div>
        </div>
      </div>
      <div className="program-field">
        <div className="program-field__label">Duration</div>
        <div className="program-field__value">{secondsToHourMinutes(episode.duration)}</div>
      </div>
      <div className="program-field">
        <div className="program-field__label">Genres</div>
        <div className="program-field__value">
          {episode.genres ? (
            episode.genres.map((genre, idx) => (
              <div className="program-field__value" key={idx}>
                {genre.imdb_genre ? `${genre.imdb_genre.label} (IMDb)` : ""}
                {genre.freeview_genre ? `${genre.freeview_genre.label} (Freeview)` : ""}
              </div>
            ))
          ) : (
            <div className="program-field__value">-</div>
          )}
        </div>
      </div>
      <div className="program-field">
        <div className="program-field__label">Season {episode.season.season_number}</div>
        <div className="program-field__list">
          {episodesLoading ? (
            <Loader width={20} height={20} />
          ) : (
            episodes.map((ep) => (
              <div className="program-field__list__inner" key={ep.episode_guid}>
                <div className="program-field__label">
                  {ep.episode_number} ({ep.episode_name})
                </div>
                <div className="program-field__value">{secondsToHourMinutes(ep.duration)}</div>
              </div>
            ))
          )}
        </div>
      </div>
      {programInfo.__gstvMeta
        ? ((
            <div className="program-field">
              <div className="program-field__label">Ad Duration</div>
              <div className="program-field__value">
                {secondsToHourMinutesSeconds(
                  programInfo.__gstvMeta.total_duration_seconds - programInfo.__gstvMeta.duration_seconds,
                )}
              </div>
            </div>
          ),
          (
            <div className="program-field">
              <PlannerMetaCuepoints program={programInfo} saveAdBreaks={saveAdBreaks} />
            </div>
          ))
        : null}
    </div>
  );
};

export default EpisodeInformation;
