import React from "react";
import ReactModal from "react-modal";
import DatePicker from "react-datepicker";
import { createPortal } from "react-dom";
import Loader from "../../../common/loader";
import axios from "../../../requests/axios";
import { addDays, format } from "date-fns";
import { toast } from "react-toastify";

export default function PublishTemplateDialog({
  isOpen,
  onClose,
  channelId,
  channelName,
  templateId,
  daysCount,
  hasUnsavedChanges,
}) {
  const [targetDate, setDate] = React.useState(new Date());
  const [isLoading, setIsLoading] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");

  function onSubmit() {
    setIsLoading(true);
    setErrorMessage("");

    const payload = {
      start_date: format(targetDate, "yyyy-MM-dd"),
      channel_id: channelId,
    };

    axios
      .post(`api/templates/${templateId}/schedule`, payload)
      .then((resp) => {
        toast.success("Published template to schedule!");
        window.open(`/scheduler/${resp.data.channel_id}/${resp.data.plan_date}`, "_blank");
      })
      .catch((e) => {
        console.error(e);
        if (e.data?.hasOwnProperty("api")) {
          setErrorMessage(e.data.api);
        } else {
          setErrorMessage("There was an error publishing your template, please contact support.");
        }
      })
      .finally(() => setIsLoading(false));
  }

  function getHighlightedDates() {
    return Array.from(Array(daysCount)).map((_, i) => {
      if (i > 0) {
        return addDays(new Date(targetDate), i);
      }
    });
  }

  return (
    <ReactModal isOpen={isOpen} ariaHideApp={false}>
      <div className="v-react-modal__inner modal-width">
        <div className="v-react-modal__content">
          <div className="v-react-modal__heading">Schedule Template for {channelName}</div>
          <div className="v-react-modal__body modal-body">
            <div className="modal-copy__dropdown-group">
              <div className="modal-copy__body__text">Starting day</div>
              <div className="modal-copy__dropdown">
                <DatePicker
                  selected={new Date(targetDate)}
                  onChange={(date) => setDate(new Date(date))}
                  popperContainer={({ children }) => createPortal(children, document.body)}
                  dateFormat="yyyy-MM-dd"
                  shouldCloseOnSelect={false}
                  highlightDates={getHighlightedDates()}
                />
              </div>
            </div>
            If successful, the schedule will be opened in a new tab if pop-ups are enabled for this site.
            <br />
            <b>
              Note that publishing a template will overwrite {daysCount} days of content, including the starting date.
            </b>
          </div>

          {hasUnsavedChanges ? (
            <p className="modal-copy__body__subtext u-fc--warning">
              <b>This template has unsaved changes. Please save them before publishing.</b>
            </p>
          ) : null}
          {errorMessage ? <p className="modal-copy__body__subtext u-fc--warning">{errorMessage}</p> : null}
        </div>
        <div className="v-react-modal__footer">
          {isLoading ? (
            <React.Fragment>
              <Loader width={45} height={45} />
            </React.Fragment>
          ) : (
            <React.Fragment>
              <button className="btn btn--inverse-primary" onClick={onClose}>
                Cancel
              </button>
              <button className="btn btn--primary" onClick={() => onSubmit()}>
                Publish
              </button>
            </React.Fragment>
          )}
        </div>
      </div>
    </ReactModal>
  );
}
