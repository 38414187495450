import React from "react";
import { useParams, Link } from "react-router-dom";
import useApiRequest from "../hooks/use-api-request";
import ContentCard from "././library/components/content-card";
import DataTable from "../components/data-table/data-table";
import format from "date-fns/format";
import ArrowCircleRightRoundedIcon from "@mui/icons-material/ArrowCircleRightRounded";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import { AppHeading } from "../layout/parts/app-heading.jsx";
import AppBody from "../layout/parts/app-body.jsx";

function PlaylistVersionListPage() {
  const columns = React.useMemo(
    () => [
      {
        label: "Version",
        value: "version",
        key: "version",
        width: 10,
        sortable: true,
      },
      {
        label: "Status",
        value: "status",
        key: "status",
        sortable: true,
        width: 10,
      },
      {
        label: "Created At",
        value: "created_at",
        key: "created_at",
        sortable: true,
        width: 25,
        formatValue: (value) => {
          if (!value) {
            return "-";
          }

          return format(new Date(value), "y-MM-dd");
        },
      },
      {
        label: "Last Updated",
        value: "updated_at",
        key: "updated_at",
        sortable: true,
        width: 25,
        formatValue: (value) => {
          if (!value) {
            return "-";
          }

          return format(new Date(value), "y-MM-dd");
        },
      },
      {
        label: "Published At",
        value: "published_at",
        key: "published_at",
        sortable: true,
        width: 25,
        formatValue: (value) => {
          if (!value || value === "-") {
            return "-";
          }

          return format(new Date(value), "y-MM-dd");
        },
      },
      {
        label: "",
        value: "",
        key: "0",
        width: 5,
        renderValue: (row) => (
          <span className="data-table__action-cell">
            <span className="data-table__action-cell__item data-table__icon">
              <Link to={`/avod/playlists/${row.avod_playlist_guid}/versions/${row.version}`}>
                <ArrowCircleRightRoundedIcon />
              </Link>
            </span>
          </span>
        ),
      },
    ],
    [],
  );

  const [title, setTitle] = React.useState();
  const { id } = useParams();
  const url = `api/avod-playlists/${id}/versions`;
  const { response } = useApiRequest(url);

  React.useEffect(() => {
    if (response.data) {
      setTitle(response.data?.data[0]?.playlist_name ? `${response.data.data[0].playlist_name} - Versions` : "");
    }
  }, [response]);

  return (
    <React.Fragment>
      <AppHeading
        breadcrumbs={[
          {
            link: "/avod",
            title: "AVOD",
          },
          {
            link: "/avod/playlists",
            title: "Playlists",
          },
          {
            link: `/avod/playlists/${id}/versions`,
            title: title,
          },
        ]}
        rightActions={[
          {
            icon: AddCircleRoundedIcon,
            href: `/avod/playlists/${id}/versions/create`,
            type: "link",
            state: { playlist_name: response.data?.data[0]?.playlist_name },
            balloonLabel: "Add New Version",
          },
        ]}
      />
      <AppBody>
        <div className="gw">
          <div className="g g--1-of-1">
            <ContentCard>
              <DataTable url={url} columns={columns} />
            </ContentCard>
          </div>
        </div>
      </AppBody>
    </React.Fragment>
  );
}

export default PlaylistVersionListPage;
