export default function getToken(doRedirect = true) {
  const bearerToken = window.localStorage.getItem("__bearer")?.split(" ");

  if (!bearerToken || !bearerToken.length) {
    if (doRedirect && History.navigate) {
      History.navigate("/login", { state: { from: window.location.pathname } });
    }

    return null;
  }

  return bearerToken[1];
}
