import React from "react";
import ContentCard from "././library/components/content-card";
import { useParams } from "react-router-dom";
import { BASE_URL, getIngestForFeed } from "../requests/api-requests";
import { format } from "date-fns";
import { toast } from "react-toastify";
import getToken from "../functions/getToken.js";
import SimCardDownloadRoundedIcon from "@mui/icons-material/SimCardDownloadRounded";
import { AppHeading } from "../layout/parts/app-heading.jsx";
import AppBody from "../layout/parts/app-body.jsx";
import DataTable from "../components/data-table/data-table.jsx";
import { AssetWorkflowStatuses } from "./library/components/asset-workflow-statuses.jsx";
import ExpandCircleDownRoundedIcon from "@mui/icons-material/ExpandCircleDown";

function IngestFeedResultPage() {
  const [ingest, setIngest] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(true);
  const { feed_id, ingest_id } = useParams();
  const [expandedRowIndex, setExpandedRowIndex] = React.useState();

  React.useEffect(() => {
    setIsLoading(true);

    getIngestForFeed(feed_id, ingest_id)
      .then((response) => {
        setIngest(response.data);
      })
      .catch((error) => {
        console.error(error);
        toast.error("There was an error fetching this Ingestion result");
      })
      .finally(() => setIsLoading(false));
  }, [feed_id, ingest_id]);

  // function capitaliseStart(string) {
  //   return string.charAt(0).toUpperCase() + string.slice(1);
  // }

  const token = getToken();

  const resultsDownloadUrl = `${BASE_URL}/api/feeds/${feed_id}/ingests/${ingest_id}/results/download?token=${token}`;
  const patchDownloadUrl = `${BASE_URL}/api/feeds/${feed_id}/ingests/${ingest_id}/patch-file/download?token=${token}`;

  const dataTableColumns = React.useMemo(
    () => [
      {
        label: "Status",
        width: 7,
        key: "status",
        value: "status",
      },
      {
        label: "Type",
        width: 8,
        key: "type",
        value: "type",
      },
      {
        label: "Mode",
        width: 7,
        key: "mode",
        value: "mode",
      },
      {
        label: "Label",
        width: 28,
        key: "label",
        value: "label",
      },
      {
        label: "Workflow",
        width: 30,
        key: "workflow",
        renderValue: (row) => <AssetWorkflowStatuses asset={row.asset} />,
      },
      {
        label: "Message",
        width: 15,
        key: "message",
        value: "message",
      },
      {
        label: "",
        align: "right",
        width: 5,
        value: "",
        key: "actions",
        renderValue: (row, index, __, active) =>
          row.has_children ? (
            <span>
              <button
                className={`btn btn--text-primary data-table__icon ${!active ? "data-table__icon--rotate-270" : ""}`}
                onClick={() => {
                  setExpandedRowIndex((current) => (current === index ? null : index));
                }}
              >
                <ExpandCircleDownRoundedIcon />
              </button>
            </span>
          ) : null,
      },
    ],
    [],
  );

  return (
    <React.Fragment>
      <AppHeading
        breadcrumbs={[
          {
            link: "/content",
            title: "Content",
          },
          {
            title: "Import",
            link: "/content/import",
          },
          {
            title: "Feeds",
            link: "/content/import/feeds",
          },
          {
            title: ingest?.feed?.display_name ?? "...",
            link: `/content/import/feeds/${feed_id}`,
          },
          {
            title: "Results",
            link: `/content/import/feeds/${feed_id}/ingestion/${ingest_id}`,
          },
        ]}
        rightActions={[
          {
            type: "anchor",
            href: resultsDownloadUrl,
            icon: SimCardDownloadRoundedIcon,
            balloonLabel: "Download Raw Results",
            when: !!ingest.result_location,
          },
          {
            type: "anchor",
            href: patchDownloadUrl,
            icon: SimCardDownloadRoundedIcon,
            balloonLabel: "Download Patch File",
            when: !!ingest.error_location,
          },
        ]}
      />
      <AppBody loading={isLoading}>
        <div className="u-margin--block-end">
          <div className="gw">
            <div className="g g--1-of-4">
              <ContentCard>
                <div className="stat-block">
                  <div className="stat-block__label">Status:</div>
                  <div className="stat-block__value">
                    {ingest?.status ? ingest.status.charAt(0).toUpperCase() + ingest.status.slice(1) : ""}
                  </div>
                </div>
              </ContentCard>
            </div>
            <div className="g g--1-of-4">
              <ContentCard>
                <div className="stat-block">
                  <div className="stat-block__label">Triggered by:</div>
                  <div className="stat-block__value">{ingest.user || "System"}</div>
                </div>
              </ContentCard>
            </div>
            <div className="g g--1-of-4">
              <ContentCard>
                <div className="stat-block">
                  <div className="stat-block__label">Entry Count:</div>
                  <div className="stat-block__value">{ingest.parent_result_count ?? 0}</div>
                </div>
              </ContentCard>
            </div>
            <div className="g g--1-of-4">
              <ContentCard>
                <div className="stat-block">
                  <div className="stat-block__label">Created at:</div>
                  <div className="stat-block__value">
                    {ingest?.created_at ? format(new Date(ingest.created_at), "Y-MM-dd HH:mm:ss") : ""}
                  </div>
                </div>
              </ContentCard>
            </div>
          </div>
        </div>
        <div className="screen__vertical-fill-container">
          <ContentCard noPadding>
            <DataTable
              columns={dataTableColumns}
              url={`/api/feeds/${feed_id}/ingests/${ingest_id}/results`}
              pagination={false}
              expandedRowIndex={expandedRowIndex}
              expandedRowRender={(row) => (
                <DataTable
                  withHeadings={false}
                  withBackground={false}
                  pagination={false}
                  columns={dataTableColumns}
                  url={`/api/feeds/${feed_id}/ingests/${ingest_id}/results/${row.ingest_result_id}`}
                />
              )}
            />
          </ContentCard>
        </div>
      </AppBody>
    </React.Fragment>
  );
}

export default IngestFeedResultPage;
