import axios from "../requests/axios.js";
import React from "react";

const CACHE = [];

const INVALIDATIONS = [];

function buildUrlWithParams(url, pagination) {
  let urlWithParams = url;

  if (pagination.pageSize) {
    if (url.includes("page_size=")) {
      urlWithParams = url.replace(/page_size=\d+/, `page_size=${pagination.pageSize}`);
    } else {
      urlWithParams = `${urlWithParams}${url.includes("?") ? "&" : "?"}page_size=${pagination.pageSize}`;
    }
  }

  return urlWithParams;
}

export default function useApiRequest(
  requestUrl,
  options = {
    cache: false,
  },
) {
  const [url, setUrl] = React.useState(requestUrl);

  const [isLoading, setLoading] = React.useState(!!url || !!options.cache);

  const [response, setResponse] = React.useState({});
  const [error, setError] = React.useState();
  const [pagination, setPagination] = React.useState({});

  const paginationControls = {
    changePageSize(pageSize) {
      setPagination((prev) => ({ ...prev, pageSize }));
      setUrl(requestUrl);
    },
    navigate(paginationUrl) {
      setUrl(paginationUrl);
    },
  };

  React.useEffect(() => {
    setUrl(requestUrl);
  }, [requestUrl]);

  React.useEffect(() => {
    if (url) {
      let urlWithParams = buildUrlWithParams(url, pagination);

      if (options.cache && (CACHE[urlWithParams] ?? false) && !INVALIDATIONS[urlWithParams]) {
        setResponse(CACHE[urlWithParams]);
        setLoading(false);
        return;
      }

      setLoading(true);
      axios
        .get(urlWithParams)
        .then((resp) => {
          setResponse(resp);

          if (options.cache) {
            CACHE[urlWithParams] = resp;
          }

          if (INVALIDATIONS[urlWithParams]) {
            INVALIDATIONS[urlWithParams] = false;
          }

          if (resp.per_page) {
            setPagination({
              pageSize: resp.per_page,
            });
          }
        })
        .catch((error) => {
          setError(error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [url, options.cache, pagination]);

  const refreshRequest = React.useCallback(() => {
    const urlWithParams = buildUrlWithParams(url, pagination);
    setLoading(true);
    axios
      .get(urlWithParams)
      .then((resp) => {
        setResponse(resp);

        if (options.cache) {
          CACHE[urlWithParams] = resp;
        }

        if (INVALIDATIONS[urlWithParams]) {
          INVALIDATIONS[urlWithParams] = false;
        }

        if (resp.per_page) {
          setPagination({
            pageSize: resp.per_page,
          });
        }
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [url, pagination, options.cache]);

  const invalidateCache = React.useCallback(() => {
    const urlWithParams = buildUrlWithParams(url, pagination);
    INVALIDATIONS[urlWithParams] = true;
  }, [url, pagination]);

  return {
    isLoading,
    response,
    error,
    paginationControls,
    refreshRequest,
    invalidateCache,
  };
}
