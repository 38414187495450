import React from "react";
import ExpandCircleDownRoundedIcon from "@mui/icons-material/ExpandCircleDown";
import ContentCard from "../library/components/content-card";
import { format } from "date-fns";
import { Link } from "react-router-dom";
import ArrowCircleRightRoundedIcon from "@mui/icons-material/ArrowCircleRightRounded";

function IngestFeedCollapsableTable({ data, hideTableRef }) {
  const [revealed, setRevealed] = React.useState(false);

  React.useEffect(() => {
    if (hideTableRef?.current) {
      function outsideClickListener(event) {
        if (event.target === hideTableRef.current) {
          setRevealed(false);
        }
      }

      const ref = hideTableRef.current;

      if (revealed && ref) {
        ref.classList.add("trigger-area--active");
        document.addEventListener("click", outsideClickListener);
      }

      return () => {
        if (revealed && ref) {
          ref.classList.remove("trigger-area--active");
          document.removeEventListener("click", outsideClickListener);
        }
      };
    }
  }, [revealed, hideTableRef]);

  if (!data) {
    return null;
  }

  return (
    <React.Fragment>
      <button
        className="btn btn--text-primary btn--text-and-icon"
        onClick={() => setRevealed(true)}
        disabled={!data?.latest_ingestions?.length}
      >
        {data.runs_count ?? 0}
        <span
          className={`data-table__icon ${revealed ? "data-table__icon--flipped" : ""} ${!data?.latest_ingestions?.length ? "data-table__icon--disabled" : ""}`}
        >
          <ExpandCircleDownRoundedIcon />
        </span>
      </button>
      {revealed ? (
        <div className="floating-subtable">
          <div className="floating-subtable__container">
            <ContentCard>
              <div className="gw">
                <div className="g g--3-of-6">
                  <div className="data-table__heading__cell">
                    <span className="data-table__heading__cell__value">Ran at</span>
                  </div>
                  {data?.latest_ingestions.map((row, key) => (
                    <div className="data-table__cell" key={key}>
                      <span className="data-table__cell__value">
                        {format(new Date(row.created_at), "Y-MM-dd HH:mm:ss")}
                      </span>
                    </div>
                  ))}
                </div>
                <div className="g g--2-of-6">
                  <div className="data-table__heading__cell">
                    <span className="data-table__heading__cell__value">Status</span>
                  </div>
                  {data?.latest_ingestions.map((row, key) => (
                    <div className="data-table__cell" key={key}>
                      <span className="data-table__cell__value">
                        {row.status.charAt(0).toUpperCase() + row.status.slice(1)}
                      </span>
                    </div>
                  ))}
                </div>
                <div className="g g--1-of-6">
                  <div className="data-table__heading__cell">
                    <span className="data-table__heading__cell__value"></span>
                  </div>
                  {data?.latest_ingestions.map((row, key) => (
                    <div className="data-table__cell" key={key}>
                      <span className="data-table__action-cell">
                        <span className="data-table__action-cell__item data-table__icon">
                          <Link to={`/content/import/${row.ingest_feed_id}/ingestion/${row.ingest_guid}`}>
                            <ArrowCircleRightRoundedIcon />
                          </Link>
                        </span>
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            </ContentCard>
          </div>
        </div>
      ) : null}
    </React.Fragment>
  );
}

export default IngestFeedCollapsableTable;
