import React from "react";
import { getTypeForContent } from "../utils/accessors.js";
import Loader from "../../../common/loader.jsx";
import ContentPlayer from "../../../components/player/content-player.jsx";
import { formatCuepoints } from "../../../common/cuepoints.js";

function MissingProgram() {
  return (
    <div className="epg-program-overview">
      <div className="missing-content">
        <p className="missing-content__text">Select a program to start playback</p>
      </div>
    </div>
  );
}

function ContentLoader({ program, programEpg, isLoading, addCuepoint, trimVideo, removeNearestCuepoint }) {
  const playerOptions = React.useMemo(() => {
    if (isLoading) {
      return {};
    }

    if (!programEpg.__gstvMeta?.duration_seconds) {
      return {
        cuepoints: program.cuepoints ? formatCuepoints(program.cuepoints) : [],
        trimPoint: program.trimmed_duration,
        addCuepoint: addCuepoint,
        removeNearestCuepoint: removeNearestCuepoint,
        trimVideo: trimVideo,
        controls: {
          editCuepoints: false,
          playoutTrimming: false,
          changeSource: false,
          selectSource: false,
          timers: true,
        },

        availableSources: {
          source: !!program.source_video?.asset_id,
          normalised: !!program.content_video?.asset_id,
          hls: !!program.content_video?.hls_path,
          dash: !!program.content_video?.dash_path,
        },
      };
    }

    return {
      cuepoints: programEpg.__gstvMeta?.ad_breaks?.length ? formatCuepoints(programEpg.__gstvMeta.ad_breaks) : [],
      trimPoint:
        programEpg.__gstvMeta?.duration_seconds === programEpg.__gstvMeta?.original_duration_seconds
          ? null
          : programEpg.__gstvMeta?.duration_seconds,
      addCuepoint: addCuepoint,
      removeNearestCuepoint: removeNearestCuepoint,
      trimVideo: trimVideo,
      controls: {
        editCuepoints: programEpg.__gstvMeta?.link_type !== "promo",
        playoutTrimming: programEpg.__gstvMeta?.link_type !== "promo",
        changeSource: false,
        selectSource: true,
        timers: true,
      },

      availableSources: {
        source: !!program.source_video?.asset_id,
        normalised: !!program.content_video?.asset_id,
        hls: !!program.content_video?.hls_path,
        dash: !!program.content_video?.dash_path,
      },
    };
  }, [
    program?.trimmed_duration,
    program?.cuepoints,
    program?.source_video?.asset_id,
    program?.content_video?.asset_id,
    program?.content_video?.hls_path,
    program?.content_video?.dash_path,
    programEpg?.__gstvMeta?.ad_breaks,
    programEpg?.__gstvMeta?.duration_seconds,
    programEpg?.__gstvMeta?.original_duration_seconds,
    programEpg?.__gstvMeta?.link_type,
    addCuepoint,
    removeNearestCuepoint,
    trimVideo,
    isLoading,
  ]);

  if (isLoading) {
    return <Loader />;
  }

  return <ContentPlayer program={program} playerOptions={playerOptions} />;
}

function EpgProgramPlayer({
  program,
  programEpg,
  isLoading,
  onPlay,
  onRemoveProgram,
  saveAdBreaks,
  viewContentListProgram,
  transcodingTypes,
  addCuepoint,
  removeNearestCuepoint,
  trimVideo,
}) {
  let type = null;
  let guid = null;
  if (program && program?.__gstvMeta) {
    // this is an epg program
    type = program.__gstvMeta.link_type;
    guid = program.__gstvMeta.link_guid;
  } else if (program) {
    // this is a content program
    type = getTypeForContent(program);
    guid = program[`${type}_guid`];
  }

  if (!isLoading && (!program || !type || !guid)) {
    return <MissingProgram />;
  }

  return (
    <ContentLoader
      type={type}
      guid={guid}
      key={guid}
      onPlay={onPlay}
      program={program}
      programEpg={programEpg}
      isLoading={isLoading}
      onRemoveProgram={onRemoveProgram}
      saveAdBreaks={saveAdBreaks}
      selectProgram={viewContentListProgram}
      transcodingTypes={transcodingTypes}
      addCuepoint={addCuepoint}
      removeNearestCuepoint={removeNearestCuepoint}
      trimVideo={trimVideo}
    />
  );
}

export default EpgProgramPlayer;
