import React from "react";
import AutoAwesomeMotionRoundedIcon from "@mui/icons-material/AutoAwesomeMotionRounded";
import Dashboard from "./dashboards/dashboard";
import LoginRoundedIcon from "@mui/icons-material/LoginRounded";
import { Link } from "react-router-dom";
import { format } from "date-fns";
import AddRoundedIcon from "@mui/icons-material/AddRounded";

function PlaylistDashboardPage() {
  return (
    <Dashboard
      links={[
        {
          label: "AVOD Playlists",
          icon: AutoAwesomeMotionRoundedIcon,
          url: "/avod/playlists",
        },
      ]}
      breadcrumbs={[
        {
          link: "/avod",
          title: "AVOD",
        },
      ]}
      statistics={{
        actions: [
          {
            key: "create",
            render: () => (
              <div className="g g--1-of-3">
                <Link to={"/avod/playlists/create"} className="info-block info-block--interactable">
                  <span className="info-block__inner u-bgc--danger-dk">
                    <span className="info-block__label u-height-100">
                      <span className="u-display--flex-vh-center u-flex--columns u-height-100">
                        <span className="u-svg--kilo">
                          <AddRoundedIcon />
                        </span>{" "}
                        <span className="u-fs--h2">CREATE</span>
                      </span>
                    </span>
                  </span>
                </Link>
              </div>
            ),
          },
        ],
        endpoint: "/api/dashboards/avod",
      }}
      recentTable={{
        columns: [
          {
            label: "Last Updated",
            key: "updated_at",
            value: "updated_at",
            width: 25,
            formatValue: (value) => {
              if (!value) {
                return "-";
              }

              return format(new Date(value), "Y-MM-dd HH:mm:ss");
            },
          },
          {
            label: "Playlist Name",
            key: "playlist_name",
            value: "playlist_name",
            width: 30,
          },
          {
            label: "Status",
            key: "status",
            value: "status",
            width: 20,
            renderValue: (row) => (
              <span className="data-table__cell__value">
                {row.status.charAt(0).toUpperCase() + row.status.slice(1)}
              </span>
            ),
          },
          {
            label: "Version",
            key: "version",
            value: "version",
            width: 15,
          },
          {
            label: "",
            key: "",
            value: "",
            align: "right",
            width: 10,
            renderValue: (playlist) => (
              <span className="data-table__action-cell__item data-table__icon">
                <Link to={`/avod/playlists/${playlist.playlist_guid}/versions/${playlist.version}`}>
                  <LoginRoundedIcon />
                </Link>
              </span>
            ),
          },
        ],
        url: "/api/dashboards/avod/recent",
      }}
    />
  );
}

export default PlaylistDashboardPage;
