import { getCuepointLocation } from "../pages/epg-editor/utils/create-ad-breaks.js";

export function formatCuepoints(cuepoints) {
  if (!cuepoints || !cuepoints.length) {
    return [];
  }

  return cuepoints.map((adBreak, index) => ({
    time: getCuepointLocation(adBreak),
    label: `Cuepoint #${index + 1}`,
    type: "cuepoint",
  }));
}
