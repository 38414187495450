import React from "react";
import axios from "../requests/axios.js";
import AppBody from "../layout/parts/app-body.jsx";
import { AppHeading } from "../layout/parts/app-heading.jsx";
import { Link, useNavigate } from "react-router-dom";
import { format, addDays } from "date-fns";
import ImageWithFallback from "../components/image-with-fallback.jsx";
import { toast } from "react-toastify";

function getPlanFullness(channel, planIndex) {
  if (!channel.plans[planIndex].status) {
    return { value: "unstarted", label: "Not started" };
  }

  switch (channel.plans[planIndex].status) {
    case "DRAFT":
      return { value: "unstarted", label: "Not started" };

    case "PLANNING":
      return { value: "started", label: "Started" };

    case "PLANNED":
    case "PUBLISHED":
      return { value: "complete", label: "Complete" };

    case "ERROR":
      return { value: "error", label: "Error" };

    default:
      return { value: "unstarted", label: "Not started" };
  }
}

function SchedulerChannelListPage() {
  const [isPageLoading, setPageLoading] = React.useState(true);
  const [channels, setChannels] = React.useState(null);
  const navigate = useNavigate();

  React.useEffect(() => {
    axios
      .get(`api/channels/upcoming`)
      .then(({ data }) => {
        setPageLoading(false);
        setChannels(data);
      })
      .catch((e) => {
        console.error(e);
        toast.error("There was an error loading channel data. Please contact support");
      });
  }, []);

  return (
    <React.Fragment>
      <AppHeading
        breadcrumbs={[
          {
            link: "/scheduler",
            title: "Channels",
          },
        ]}
      />
      <AppBody loading={isPageLoading && channels !== null && channels.length}>
        <div>
          <div className="gw">
            {channels?.map((channel, index) => (
              <div className="g g--tall g--1-of-3" key={index}>
                <div className="link-preview-box-container">
                  <Link to={`/scheduler/${channel.channel_id}`} className="link-preview-box">
                    <span
                      className={`link-preview-box__image ${!channel.poster ? "link-preview-box__image--missing-image" : ""}`}
                    >
                      <ImageWithFallback url={channel.poster} />
                    </span>
                    <span className="link-preview-box__footer">
                      <span className="u-display--flex-apart u-width-100 u-flex--rows--wrap">
                        <span>{channel.display_name}</span>
                        <span>
                          {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((idx) => {
                            const status = getPlanFullness(channel, idx);
                            return (
                              <span
                                className={`forecast-dot forecast-dot--${status.value}`}
                                aria-label={`${format(addDays(new Date(), idx), "MMM do")} - ${status.label}`}
                                data-balloon-pos="up-right"
                                onClick={(e) => {
                                  e.preventDefault();
                                  navigate(`/scheduler/${channel.channel_id}/${channel.plans[idx].plan_date}`);
                                }}
                                key={idx}
                              ></span>
                            );
                          })}
                        </span>
                      </span>
                    </span>
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </div>
      </AppBody>
    </React.Fragment>
  );
}

export default SchedulerChannelListPage;
