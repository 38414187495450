import React from "react";
import { useParams } from "react-router-dom";
import { findIngestFeed } from "../requests/api-requests";
import { toast } from "react-toastify";
import ContentCard from "././library/components/content-card";
import IngestFeedDetails from "./ingest-feeds/ingest-feed-details";
import IngestFeedRunIngestDialog from "./ingest-feeds/ingest-feed-run-ingest-dialog";
import PlayArrowRoundedIcon from "@mui/icons-material/PlayArrowRounded";
import ArrowCircleRightRoundedIcon from "@mui/icons-material/ArrowCircleRightRounded";
import { AppHeading } from "../layout/parts/app-heading.jsx";
import AppBody from "../layout/parts/app-body.jsx";
import DataTable from "../components/data-table/data-table.jsx";
import { format } from "date-fns";
import { Link } from "react-router-dom";

function NoSchedules() {
  return (
    <div className="missing-content missing-content--half-size">
      <p className="missing-content__text">No Schedules configured for this feed</p>
    </div>
  );
}

function IngestFeedPage() {
  const [feed, setFeed] = React.useState({});
  const [feedLoading, setFeedLoading] = React.useState(true);
  const [isOpen, setOpen] = React.useState(false);
  const { feed_id } = useParams();

  const scheduleTableColumns = React.useMemo(
    () => [
      {
        label: "Started At",
        key: "starting_at",
        value: "starting_at",
        width: 33.3,
        renderValue: (row) => (
          <span className="data-table__cell__value">{format(new Date(row.starting_at), "Y-MM-dd HH:mm:ss")}</span>
        ),
      },
      {
        label: "Frequency",
        key: "frequency",
        value: "frequency",
        width: 33.3,
      },
      {
        label: "Next Scheduled Run",
        key: "next_run",
        value: "next_run",
        width: 33.3,
        renderValue: (row) => (
          <span className="data-table__cell__value">{format(new Date(row.next_run), "Y-MM-dd HH:mm:ss")}</span>
        ),
      },
    ],
    [],
  );

  const feedTableColumns = React.useMemo(
    () => [
      {
        label: "Ran At",
        key: "created_at",
        value: "created_at",
        width: 75,
        renderValue: (row) => (
          <span className="data-table__cell__value">{format(new Date(row.created_at), "Y-MM-dd HH:mm:ss")}</span>
        ),
      },
      {
        label: "Status",
        key: "status",
        value: "status",
        width: 20,
        renderValue: (row) => (
          <span className="data-table__cell__value">{row.status.charAt(0).toUpperCase() + row.status.slice(1)}</span>
        ),
      },
      {
        label: "",
        value: "",
        key: "0",
        width: 5,
        renderValue: (row) => (
          <span className="data-table__action-cell">
            <span className="data-table__action-cell__item data-table__icon">
              <Link to={`ingestion/${row.ingest_guid}`}>
                <ArrowCircleRightRoundedIcon />
              </Link>
            </span>
          </span>
        ),
      },
    ],
    [],
  );

  React.useEffect(() => {
    setFeedLoading(true);

    findIngestFeed(feed_id)
      .then((response) => {
        setFeed(response.data);
      })
      .catch((error) => {
        console.error(error);
        toast.error("There was an error fetching details for this Feed");
      })
      .finally(() => setFeedLoading(false));
  }, [feed_id]);

  function toggleOpen() {
    setOpen(!isOpen);
  }

  return (
    <React.Fragment>
      <AppHeading
        breadcrumbs={[
          {
            title: "Content",
            link: "/content",
          },
          {
            title: "Import",
            link: "/content/import",
          },
          {
            title: "Feeds",
            link: "/content/import/feeds",
          },
          {
            title: feed?.display_name ?? "...",
            link: `/content/import/feeds/${feed_id}`,
          },
        ]}
        rightActions={[
          {
            onClick: toggleOpen,
            icon: PlayArrowRoundedIcon,
            balloonLabel: "Trigger Ingestion",
          },
        ]}
      />
      <AppBody loading={feedLoading}>
        <div className="u-margin--block-end">
          <div className="gw">
            <div className="g g--1-of-2">
              <ContentCard>
                <IngestFeedDetails feed={feed} />
              </ContentCard>
            </div>
            <div className="g g--1-of-2">
              <ContentCard noPadding hasOverlay>
                {!feed?.schedules?.length ? <NoSchedules /> : null}
                <DataTable
                  url={`/api/feeds/${feed_id}/schedules`}
                  columns={scheduleTableColumns}
                  pagination={false}
                  noDataComponent={<></>}
                />
              </ContentCard>
            </div>
          </div>
        </div>
        <div className="screen__vertical-fill-container">
          <ContentCard noPadding>
            <DataTable url={`/api/feeds/${feed_id}/ingests`} columns={feedTableColumns} />
          </ContentCard>
        </div>
        <IngestFeedRunIngestDialog isOpen={isOpen} feedId={feed?.ingest_feed_id} onDone={toggleOpen} />
      </AppBody>
    </React.Fragment>
  );
}

export default IngestFeedPage;
