import React from "react";
import { getGenres } from "../../../requests/api-requests.js";
import Select from "../../../common/select.jsx";

let __GENRE_CACHE__ = [];

function GenreFilter({ onChange, selectedGenres }) {
  const [genres, setGenres] = React.useState(__GENRE_CACHE__);
  React.useEffect(() => {
    if (!genres.length) {
      getGenres()
        .then((data) => {
          __GENRE_CACHE__ = data.imdb.map((item) => ({
            label: item.label,
            value: item.value,
            subgenres: item.subgenres.map((subgenre) => ({
              label: subgenre.label,
              value: subgenre.value,
            })),
          }));
          setGenres(__GENRE_CACHE__);
        })
        .catch((e) => {
          console.error(e);
        });
    }
  }, [genres]);

  return (
    <Select
      placeholder={"Genres"}
      name={"genre"}
      isSearchable={true}
      onChange={onChange}
      options={genres}
      containerClass="react-select-container--light react-select-container--small"
      value={selectedGenres.length ? selectedGenres[0] : null}
    />
  );
}

export function SubgenreFilter({ onChange, selectedGenres, selectedSubgenres }) {
  const [genres, setGenres] = React.useState(__GENRE_CACHE__);
  React.useEffect(() => {
    if (!genres.length) {
      getGenres()
        .then((data) => {
          __GENRE_CACHE__ = data.imdb.map((item) => ({
            label: item.label,
            value: item.value,
            subgenres: item.subgenres.map((subgenre) => ({
              label: subgenre.label,
              value: subgenre.value,
            })),
          }));
          setGenres(__GENRE_CACHE__);
        })
        .catch((e) => {
          console.error(e);
        });
    }
  }, [genres]);

  const validGenreValues = selectedGenres.map((sg) => sg.value);
  const options = genres
    .filter((genre) => validGenreValues.includes(genre.value))
    .map((genre) => genre.subgenres)
    .flat();
  return (
    <Select
      placeholder={"Subgenres"}
      name={"subgenre"}
      isSearchable={true}
      onChange={onChange}
      options={options}
      containerClass="react-select-container--light react-select-container--small"
      value={selectedSubgenres.length ? selectedSubgenres[0] : null}
    />
  );
}

export default GenreFilter;
