import React from "react";
import ReactModal from "react-modal";
import { toast } from "react-toastify";
import axios from "../../../../requests/axios.js";
import Loader from "../../../../common/loader.jsx";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from "react-router-dom";

function CreateTemplateDialog({ isOpen, onClose, channelId, planId }) {
  const [isLoading, setIsLoading] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [loadMessage, setLoadMessage] = React.useState("");
  const [templateName, setTemplateName] = React.useState("");
  const navigate = useNavigate();

  function onSubmit() {
    setIsLoading(true);
    setLoadMessage("Creating template...");

    const payload = {
      template_name: templateName,
    };

    axios
      .post(`api/channels/${channelId}/plans/${planId}/template`, payload)
      .then((data) => {
        toast.success("Template craeted successfully");
        navigate(`/templates/${data.data.template_id}`);
      })
      .catch((e) => {
        console.error(e);
        if (e.data?.hasOwnProperty("api")) {
          setErrorMessage(e.data.api);
        } else {
          setErrorMessage("There was an error creating a template from your plan, please contact support.");
        }
      })
      .finally(() => setIsLoading(false));
  }

  return (
    <ReactModal isOpen={isOpen} ariaHideApp={false}>
      <div className="v-react-modal__inner modal-copy">
        <div className="v-react-modal__heading">Create Template from Plan</div>
        <div className="v-react-modal__body modal-copy__body">
          <div className="modal-copy__dropdown-group">
            <div className="modal-copy__body__text">Template Name:</div>
            <div className="modal-copy__dropdown">
              <input type="text" name="template_name" onChange={(e) => setTemplateName(e.target.value)} />
            </div>
          </div>
          {errorMessage ? <p className="modal-copy__body__subtext u-fc--warning">{errorMessage}</p> : null}
        </div>
        <div className="v-react-modal__footer" style={{ marginTop: "4px" }}>
          {isLoading ? (
            <React.Fragment>
              <div className="modal-copy__load-text">{loadMessage}</div>
              <Loader width={45} height={45} />
            </React.Fragment>
          ) : (
            <React.Fragment>
              <button className="btn btn--inverse-primary" onClick={onClose} key="copy-plan-dialog-cancel">
                Cancel
              </button>
              <button className="btn btn--primary" onClick={onSubmit} key="copy-plan-dialog-submit">
                Create Template
              </button>
            </React.Fragment>
          )}
        </div>
      </div>
    </ReactModal>
  );
}

export default CreateTemplateDialog;
