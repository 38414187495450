import React from "react";
import { format, formatDuration } from "date-fns";
import intervalToDuration from "date-fns/intervalToDuration";
import { getTypeForContent } from "../../pages/epg-editor/utils/accessors.js";
import AutoAwesomeMotionRoundedIcon from "@mui/icons-material/AutoAwesomeMotionRounded";

export function ContentItemEpisodeAction({ program, type, onClick }) {
  return type === "episode" ? (
    <button
      className="btn--text-primary"
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        onClick({ value: program.season.series.series_guid, label: program.season.series.series_name });
      }}
    >
      <AutoAwesomeMotionRoundedIcon />
    </button>
  ) : null;
}

function ContentItem({ program, onActions, actions, controlComponent }) {
  const type = getTypeForContent(program);
  const hasPrevScheduledTime = !!program.latest_channel_plan_program?.program_start;

  return (
    <span className="content-item">
      {typeof controlComponent === "function" ? controlComponent() : null}
      <span className="content-item__description">
        <span className="content-item__heading">{program.formatted_name}</span>
        <span className="content-item__sub-heading">
          <span>{formatDuration(intervalToDuration({ start: 0, end: program.duration * 1000 }))}</span>
        </span>
        <br />
        {hasPrevScheduledTime ? (
          <span
            className="content-item__sub-heading content-item__sub-heading--hoverable"
            aria-label={`Last Scheduled at ${program.latest_channel_plan_program?.program_start}`}
            data-balloon-pos="right"
            data-balloon-length="medium"
          >
            {format(new Date(program.latest_channel_plan_program.program_start), "y-MM-dd")}
          </span>
        ) : null}
      </span>
      <span className="content-item__actions">
        {actions.map((action, key) => (
          <span className="content-item__action" key={key}>
            {action}
          </span>
        ))}
        <span className="content-item__action">
          <ContentItemEpisodeAction program={program} type={type} onClick={onActions.getEpisodesForSeries} />
        </span>
      </span>
      <span className="content-item__rating">{program.rating ? program.rating.rating : "-"}</span>
    </span>
  );
}

export default ContentItem;
