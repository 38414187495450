import React from "react";
import ReactModal from "react-modal";
import axios from "../../../requests/axios.js";
import Loader from "../../../common/loader.jsx";
import { toast } from "react-toastify";

export default function TrashTemplateDialog({ isOpen, onClose, onSuccess, template }) {
  const [errorMessage, setErrorMessage] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);

  function onSubmit() {
    setIsLoading(true);

    axios
      .delete(`/api/templates/${template?.id}`)
      .then(() => {
        onClose();
        onSuccess();
        toast.success("Template deleted successfully");
      })
      .catch((e) => {
        console.error(e);
        setErrorMessage("There was an error clearing the plan, please contact support.");
      })
      .finally(() => setIsLoading(false));
  }

  return (
    <ReactModal isOpen={isOpen} ariaHideApp={false}>
      <div className="v-react-modal__inner modal-width">
        <div className="v-react-modal__content">
          <div className="v-react-modal__heading">Delete Template {template?.name} Confirmation</div>
          <div className="v-react-modal__body modal-body">
            Are you sure you want to delete this template?
            <br />
            It will not be possible for you to reverse this action.
            {errorMessage ? <p className="u-fc--warning">{errorMessage}</p> : null}
          </div>
        </div>
        <div className="v-react-modal__footer">
          {isLoading ? (
            <Loader width={45} height={45} />
          ) : (
            <React.Fragment>
              <button className="btn btn--primary" onClick={onClose}>
                Nevermind
              </button>
              <button className="btn btn--inverse-primary" onClick={onSubmit}>
                Yes I am sure
              </button>
            </React.Fragment>
          )}
        </div>
      </div>
    </ReactModal>
  );
}
