import format from "date-fns/format";
import React from "react";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import PendingRoundedIcon from "@mui/icons-material/PendingRounded";
import CircleRoundedIcon from "@mui/icons-material/CircleRounded";

export function AssetWorkflowStatuses({ asset }) {
  if (!asset?.workflow_status?.processes) {
    return null;
  }

  function getStatusIcon(status) {
    switch (status) {
      case "done":
        return <CheckCircleRoundedIcon />;
      case "failed":
        return <CancelRoundedIcon />;
      case "starting":
        return <PendingRoundedIcon />;
      case "not_started":
      default:
        return <CircleRoundedIcon />;
    }
  }

  function getLabelForProcessStatus(process, status, started_at, ended_at, failed_at) {
    const statusTitle = process
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");

    let relevantDate = "";
    if (ended_at || started_at || failed_at) {
      relevantDate = format(new Date(ended_at || started_at || failed_at), "Y-MM-dd H:mm:ss");
    }

    switch (status) {
      case "done":
        return `${statusTitle} was completed${relevantDate ? ` at ${relevantDate}` : ""}.`;
      case "failed":
        return `${statusTitle} failed${relevantDate ? ` at ${relevantDate}` : ""}.`;
      case "starting":
        return `${statusTitle} is being processed${relevantDate ? ` since ${relevantDate}` : ""}.`;
      case "not_started":
      default:
        return `${statusTitle} has not been started.`;
    }
  }

  const statuses = Object.keys(asset.workflow_status.processes).map((key) => {
    const item = asset.workflow_status.processes[key];
    return {
      key,
      label: getLabelForProcessStatus(key, item.status, item.started_at, item.ended_at, item.failed_at),
      icon: getStatusIcon(item.status),
      status: item.status,
    };
  });

  return (
    <span className="asset-workflow-statuses">
      {statuses.map((value) => (
        <span
          key={value.key}
          aria-label={value.label}
          data-balloon-pos="up"
          className={`asset-workflow-statuses__item ${value.status ? `asset-workflow-statuses__item--${value.status}` : ""}`}
        >
          {value.icon}
        </span>
      ))}
    </span>
  );
}
