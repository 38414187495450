import React from "react";
import Dashboard from "./dashboard.jsx";
import LoginRoundedIcon from "@mui/icons-material/LoginRounded";
import { Link } from "react-router-dom";
import { format } from "date-fns";

export default function ImportDashboard() {
  return (
    <Dashboard
      links={[
        {
          label: "Import Feeds",
          icon: LoginRoundedIcon,
          url: "/content/import/feeds",
        },
      ]}
      breadcrumbs={[
        {
          link: "/content",
          title: "Content",
        },
        {
          link: "/content/import",
          title: "Import",
        },
      ]}
      statistics={{
        endpoint: "/api/dashboards/ingestion",
      }}
      recentTable={{
        columns: [
          {
            label: "Date Ingested",
            key: "created_at",
            value: "created_at",
            width: 30,
            formatValue: (value) => {
              if (!value) {
                return "-";
              }

              return format(new Date(value), "Y-MM-dd HH:mm:ss");
            },
          },
          {
            label: "Title",
            key: "title",
            value: "title",
            width: 26,
          },
          {
            label: "Provider",
            key: "provider",
            value: "provider",
            width: 20,
          },
          {
            label: "Status",
            key: "status",
            value: "status",
            width: 14,
            renderValue: (row) => (
              <span className="data-table__cell__value">
                {row.status.charAt(0).toUpperCase() + row.status.slice(1)}
              </span>
            ),
          },
          {
            label: "",
            key: "",
            value: "",
            align: "right",
            width: 10,
            renderValue: (value) => (
              <span className="data-table__action-cell__item data-table__icon">
                <Link to={`/content/import/feeds/${value.feed_guid}/ingestion/${value.ingest_guid}`}>
                  <LoginRoundedIcon />
                </Link>
              </span>
            ),
          },
        ],
        url: "/api/dashboards/ingestion/recent",
      }}
    />
  );
}
