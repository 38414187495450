export function getKeyForContent(content, added = "") {
  if (content === null) {
    return;
  }
  return `${content.slice(0, content.length - 1)}_${added}`;
}

export function getValueForKeyFromContent(content, key) {
  return content[getKeyForUnknownContent(content, key)];
}

export function getKeyForUnknownContent(obj, label) {
  switch (true) {
    case obj.hasOwnProperty("feature_id"):
      return `feature_${label}`;
    case obj.hasOwnProperty("promo_id"):
      return `promo_${label}`;
    case obj.hasOwnProperty("episode_id"):
      return `episode_${label}`;
    default:
      return "";
  }
}

export function getTypeForContent(obj) {
  switch (true) {
    case obj.hasOwnProperty("feature_id"):
      return "feature";
    case obj.hasOwnProperty("promo_id"):
      return "promo";
    case obj.hasOwnProperty("episode_id"):
      return "episode";
    case obj.hasOwnProperty("series_id"):
      return "series";
    default:
      return "";
  }
}

export function getUrlForContent(link, link_id) {
  switch (link) {
    case "feature":
    case "episode":
      return `/${link}.php?${link.charAt(0)}=${link_id}`;
    case "promo":
      return `/${link}.php?t=${link_id}`;
    default:
      return "/";
  }
}
