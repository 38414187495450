import React from "react";
import Select from "../../../common/select.jsx";
import DeleteForeverRoundedIcon from "@mui/icons-material/DeleteForeverRounded";

export default function ChannelFormDistribution({
  distribution,
  errors,
  index,
  changeDistribution,
  distributionTypes,
  action,
  playoutIndex,
  removeDistribution,
  ssaiTypes,
}) {
  function handleChange(key) {
    return (event) => {
      changeDistribution(playoutIndex, index, key, event.target.value);
    };
  }

  function handleSelectChange(key, value) {
    changeDistribution(playoutIndex, index, key, value);
  }

  function getSelected(name) {
    if (name === "distributionType" && distribution.external_distribution_type) {
      return distributionTypes.find((dt) => dt.value === distribution.external_distribution_type);
    } else if (name === "ssaiType" && distribution.ssai_type) {
      return ssaiTypes.find((st) => st.value === distribution.ssai_type);
    }

    return [];
  }

  return (
    <div className="form-block">
      <div className="form-section">
        <div className="form-section__label">
          <button className="btn btn--icon" onClick={() => removeDistribution(index)} type="button">
            <DeleteForeverRoundedIcon />
          </button>
          {distribution.stream_distribution_guid
            ? `${distribution.external_distribution_type} ${distribution.external_distribution_label}`
            : "New Distribution"}
        </div>
        <div className="gw">
          <div className="g g--1-of-3">
            <div className="form-field">
              <label>Platform</label>
              <input
                type="text"
                name="name"
                onChange={handleChange("platform")}
                defaultValue={action === "edit" ? distribution.platform : ""}
              />
              {errors.hasOwnProperty("name") ? <div className="form-field__error">{errors.platform}</div> : null}
            </div>
          </div>
          <div className="g g--2-of-3">
            <div className="form-field">
              <label>Platform EPG Url</label>
              <input
                type="text"
                name="name"
                onChange={handleChange("platform_epg_url")}
                defaultValue={action === "edit" ? distribution.platform_epg_url : ""}
              />
              {errors.hasOwnProperty("name") ? (
                <div className="form-field__error">{errors.platform_epg_url}</div>
              ) : null}
            </div>
          </div>
          <div className="g g--1-of-3">
            <div className="form-field">
              <label>Distribution Type</label>
              <Select
                placeholder="Select Distribution Type"
                name="external_distribution_type"
                options={distributionTypes}
                value={getSelected("distributionType")}
                onChange={(input) => handleSelectChange("external_distribution_type", input.value)}
                classNamePrefix="react-select"
                containerClass="react-select-container--small react-select-container--light"
              />
              {errors.hasOwnProperty("name") ? (
                <div className="form-field__error">{errors.external_distribution_type}</div>
              ) : null}
            </div>
          </div>
          {getSelected("distributionType")?.value === "hbbtv" ? (
            <div className="g g--2-of-3">
              <div className="form-field">
                <label>Distribution Base Url</label>
                <input
                  type="text"
                  name="name"
                  onChange={handleChange("external_distribution_base_url")}
                  value={distribution.external_distribution_base_url ?? ""}
                  disabled={getSelected("distributionType")?.value !== "hbbtv"}
                />
                {errors.hasOwnProperty("name") ? (
                  <div className="form-field__error">{errors.external_distribution_base_url}</div>
                ) : null}
              </div>
            </div>
          ) : (
            <React.Fragment>
              <div className="g g--1-of-3">
                <div className="form-field">
                  <label>Distribution Label</label>
                  <input
                    type="text"
                    name="name"
                    onChange={handleChange("external_distribution_label")}
                    value={distribution.external_distribution_label ?? ""}
                    disabled={getSelected("distributionType")?.value === "hbbtv"}
                  />
                  {errors.hasOwnProperty("name") ? (
                    <div className="form-field__error">{errors.external_distribution_label}</div>
                  ) : null}
                </div>
              </div>
              <div className="g g--1-of-3">
                <div className="form-field">
                  <label>Distribution ID</label>
                  <input
                    type="text"
                    name="name"
                    onChange={handleChange("external_distribution_id")}
                    value={distribution.external_distribution_id}
                    disabled={getSelected("distributionType")?.value === "hbbtv"}
                  />
                  {errors.hasOwnProperty("name") ? (
                    <div className="form-field__error">{errors.external_distribution_id}</div>
                  ) : null}
                </div>
              </div>
            </React.Fragment>
          )}
          <div className="g g--1-of-3">
            <div className="form-field">
              <label>SSAI Type</label>
              <Select
                placeholder="Select SSAI Type"
                name="external_ssai_type"
                options={ssaiTypes}
                value={getSelected("ssaiType")}
                onChange={(input) => handleSelectChange("ssai_type", input.value)}
                classNamePrefix="react-select"
                containerClass="react-select-container--small react-select-container--light"
              />
              {errors.hasOwnProperty("name") ? <div className="form-field__error">{errors.ssai_type}</div> : null}
            </div>
          </div>
          <div className="g g--1-of-3">
            <div className="form-field">
              <label>SSAI Label</label>
              <input
                type="text"
                name="name"
                value={distribution.ssai_label}
                onChange={handleChange("ssai_label")}
                disabled={getSelected("ssaiType")?.value === "client-side"}
              />
              {errors.hasOwnProperty("name") ? <div className="form-field__error">{errors.ssai_label}</div> : null}
            </div>
          </div>
          <div className="g g--1-of-3">
            <div className="form-field">
              <label>SSAI ID</label>
              <input
                type="text"
                name="ssai_id"
                value={distribution.ssai_id}
                onChange={handleChange("ssai_id")}
                disabled={getSelected("ssaiType")?.value === "client-side"}
              />
              {errors.hasOwnProperty("name") ? <div className="form-field__error">{errors.ssai_id}</div> : null}
            </div>
          </div>
          <div className="g g--1-of-2">
            <div className="form-field">
              <label>Springserve Router ID</label>
              <input
                type="text"
                name="name"
                onChange={handleChange("springserve_router_id")}
                defaultValue={action === "edit" ? distribution.springserve_router_id : ""}
              />
              {errors.hasOwnProperty("name") ? (
                <div className="form-field__error">{errors.springserve_router_id}</div>
              ) : null}
            </div>
          </div>
          <div className="g g--1-of-2">
            <div className="form-field">
              <label>Springserve Router Label</label>
              <input
                type="text"
                name="name"
                onChange={handleChange("springserve_router_label")}
                defaultValue={action === "edit" ? distribution.springserve_router_label : ""}
              />
              {errors.hasOwnProperty("name") ? (
                <div className="form-field__error">{errors.springserve_router_label}</div>
              ) : null}
            </div>
          </div>
          <div className="g g--1-of-1">
            <div className="form-field">
              <label>Store ID</label>
              <input
                type="text"
                name="store_id"
                onChange={handleChange("store_id")}
                defaultValue={action === "edit" ? distribution.store_id : ""}
              />
              {errors.hasOwnProperty("store_id") ? <div className="form-field__error">{errors.store_id}</div> : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
