import React from "react";
import SortRoundedIcon from "@mui/icons-material/SortRounded";
import getClassList from "../../functions/get-class-list.js";

export function DataTableHeading({ columns, onColumnClick, sortOrder, hasActions, withHeadings }) {
  const isSorted = (col) => sortOrder.some((so) => so.key === col.key);
  const getSortDirection = (col) => {
    const item = sortOrder.find((so) => so.key === col.key);
    if (!item) {
      return "";
    }

    return item.direction;
  };

  const cellClasses = (col) =>
    getClassList({
      "data-table__heading__cell": true,
      "data-table__heading__cell--custom": !!col.render,
      "data-table__heading__cell--right": col.align === "right",
    });

  if (!withHeadings) {
    return null;
  }

  return (
    <div className={`data-table__heading ${!hasActions ? "data-table__heading--no-actions" : ""}`}>
      {columns.map((column, index) => (
        <div
          className={cellClasses(column)}
          key={`${index}-${column.key}`}
          style={{
            ...(column.width ? { width: `${column.width}%` } : {}),
          }}
        >
          {column.render ? (
            column.render()
          ) : (
            <button
              type="button"
              onClick={() => onColumnClick(column)}
              disabled={!column.sortable}
              className={`data-table__heading__cell__interactable ${column?.align && column.align === "center" ? " data-table__cell--centered" : ""}`}
            >
              {column.sortable ? (
                <span
                  className={`data-table__heading__sort ${isSorted(column) ? "data-table__heading__sort--active" : ""} data-table__icon ${getSortDirection(column) !== "desc" ? "data-table__icon--flipped" : ""}`}
                >
                  <SortRoundedIcon />
                </span>
              ) : null}
              <span className="data-table__heading__cell__value">{column.label}</span>
            </button>
          )}
        </div>
      ))}
    </div>
  );
}
