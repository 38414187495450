import React from "react";

function IngestFeedDetails({ feed }) {
  return (
    <div className="program-field__metadata">
      <div className="program-field">
        <div className="program-field__label">Source</div>
        <div className="program-field__value">{feed?.source_name}</div>
      </div>

      <div className="program-field">
        <div className="program-field__label">Type</div>
        <div className="program-field__value">
          {feed?.feed_type} ({feed?.feed_spec})
        </div>
      </div>

      <div className="program-field">
        <div className="program-field__label">Provider</div>
        <div className="program-field__value">{feed?.provider_id ? feed.provider.provider_name : "-"}</div>
      </div>

      <div className="program-field">
        <div className="program-field__label">Feed URL</div>
        <div className="program-field__value">{feed?.feed_url}</div>
      </div>

      <div className="program-field">
        <div className="program-field__label">Status</div>
        <div className="program-field__value">
          {feed?.status ? feed.status.charAt(0).toUpperCase() + feed.status.slice(1) : "-"}
        </div>
      </div>
    </div>
  );
}

export default IngestFeedDetails;
