import React from "react";
import { intervalToDuration } from "date-fns";
import formatDuration from "date-fns/formatDuration";
import { getDurationInSecondsWithAdBreaks } from "../../epg-editor/utils/create-ad-breaks";
import TranscodingStatusText from "../../components/transcoding-status-text";
import getClassList from "../../../functions/get-class-list";
import { Draggable } from "react-beautiful-dnd";
import AddBoxRoundedIcon from "@mui/icons-material/AddBoxRounded";
import CheckBoxRoundedIcon from "@mui/icons-material/CheckBoxRounded";

const MODELS_WITH_CUEPOINTS = ["feature", "episode"];

export default function TemplateContentItem({ item, index, addItem }) {
  const [isAdded, setAdded] = React.useState(false);

  function addItemWrapper(...params) {
    setAdded(true);
    addItem(...params);
  }

  function hasError(item) {
    if (item.series_guid) {
      return false;
    }

    return !item.asset_duration && !item.duration;
  }

  function getItemClass(item) {
    return getClassList({
      "library-list__item": true,
      "library-list__item--warning": hasError(item),
    });
  }

  let type = "feature";

  if (item.feature_id) {
    type = "feature";
  } else if (item.episode_id) {
    type = "episode";
  } else if (item.promo_id) {
    type = "promo";
  } else if (item.series_id) {
    type = "series";
  }

  return (
    <Draggable draggableId={`template-content-list-item-${index}`} index={index}>
      {(provided) => (
        <li
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className={getItemClass(item)}
          style={{
            ...provided.draggableProps.style,
          }}
          {...(hasError(item)
            ? {
                ["aria-label"]: "No video found for this content",
                ["data-balloon-pos"]: "up",
              }
            : {})}
          // onDoubleClick
        >
          <span className="content-item__description">
            <span className="content-item__heading">{item.formatted_name}</span>
            <span className="content-item__sub-heading">
              <span>{`${formatDuration(
                intervalToDuration({
                  start: 0,
                  end: (item.trimmed_duration ?? item.duration) * 1000,
                }),
              )} (${formatDuration(
                intervalToDuration({
                  start: 0,
                  end: MODELS_WITH_CUEPOINTS.includes(type)
                    ? getDurationInSecondsWithAdBreaks(item.trimmed_duration ?? item.duration, item.cuepoints) * 1000
                    : item.trimmed_duration ?? item.duration * 1000,
                }),
              )})`}</span>
            </span>
            <span className="content-item__transcoding-status">
              <TranscodingStatusText status={item?.content_video?.hls_status} popupPosition="left" type={"hls"} />
              <TranscodingStatusText status={item.content_video?.dash_status} popupPosition="left" type={"dash"} />
            </span>
          </span>
          <div className="content-item__col">
            <span className="content-item__actions">
              <span className={`content-item__action ${!item.duration ? "content-item__action--disabled" : ""}`}>
                <button
                  className="btn--text-primary"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    addItemWrapper(item);
                    return true;
                  }}
                >
                  {isAdded ? <CheckBoxRoundedIcon /> : <AddBoxRoundedIcon />}
                </button>
              </span>
            </span>
          </div>
        </li>
      )}
    </Draggable>
  );
}
