import { filterAdBreaksAfter, getAdBreakDuration, getSecondsFromHis } from "./create-ad-breaks.js";
import { formatSeconds } from "./format-seconds.js";
import { differenceInSeconds } from "date-fns";

export function trimProgramDurationToSeconds(
  program,
  durationSeconds,
  adBreaks = null,
  treatDurationAsMaximum = false,
) {
  // cut excess ad breaks based on duration
  let filteredAdBreaks = adBreaks ? adBreaks : filterAdBreaksAfter(program.__gstvMeta.ad_breaks, durationSeconds);
  let lastAdBreak = filteredAdBreaks[filteredAdBreaks.length - 1] ?? null;
  let finalDuration = durationSeconds;
  if (lastAdBreak) {
    let lastBreakInSeconds = getSecondsFromHis(lastAdBreak.ad_break) + getSecondsFromHis(lastAdBreak.cuepoint);
    finalDuration = durationSeconds > lastBreakInSeconds ? durationSeconds : lastBreakInSeconds;

    if (treatDurationAsMaximum && finalDuration > durationSeconds) {
      // patch it back to previous adBreak
      if (filteredAdBreaks[filteredAdBreaks.length - 2]) {
        const previousAdBreak = filteredAdBreaks[filteredAdBreaks.length - 2];
        lastBreakInSeconds = getSecondsFromHis(previousAdBreak.ad_break) + getSecondsFromHis(previousAdBreak.cuepoint);
        finalDuration = durationSeconds > lastBreakInSeconds ? durationSeconds : lastBreakInSeconds;
        filteredAdBreaks.pop();
      } else {
        // there must only be one ad break, so we need to set ad breaks to empty and keep duration
        filteredAdBreaks = [];
      }
    }
  }

  return {
    ...program,
    Runtime: formatSeconds(finalDuration),
    __gstvMeta: {
      ...program.__gstvMeta,
      ad_breaks: filteredAdBreaks,
      duration_seconds: finalDuration - getAdBreakDuration(filteredAdBreaks),
      total_duration_seconds: finalDuration, //update total duration
    },
  };
}

export function trimProgramDurationToEndTime(program, endTime, adBreaks) {
  return trimProgramDurationToSeconds(program, differenceInSeconds(endTime, program.since), adBreaks, true);
}
