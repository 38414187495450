import React from "react";

export default function usePlayerControlListeners({
  playerId,
  onAddCuepoint,
  onRemoveCuepoint,
  onTrimVideo,
  onChangeSource,
  toggleSourceDropdown,
}) {
  const initControlListeners = React.useCallback(() => {
    const addCuepointQuery = document.querySelector("[data-plyr='add-cuepoint']");
    const removeCuepointQuery = document.querySelector("[data-plyr='remove-cuepoint']");
    const trimQuery = document.querySelector("[data-plyr='trim-video']");
    const changeSourceQuery = document.querySelector("[data-plyr='change-source']");
    const selectSourceQuery = document.querySelector("[data-plyr='select-source']");

    if (addCuepointQuery) {
      addCuepointQuery.addEventListener("click", onAddCuepoint);
    }

    if (removeCuepointQuery) {
      removeCuepointQuery.addEventListener("click", onRemoveCuepoint);
    }

    if (trimQuery) {
      trimQuery.addEventListener("click", onTrimVideo);
    }

    if (changeSourceQuery) {
      changeSourceQuery.addEventListener("click", onChangeSource);
    }

    if (selectSourceQuery) {
      selectSourceQuery.addEventListener("click", toggleSourceDropdown);
    }
  }, [onAddCuepoint, onChangeSource, onRemoveCuepoint, onTrimVideo, toggleSourceDropdown]);

  const destroyControlListeners = React.useCallback(() => {
    const addCuepointQuery = document.querySelector("[data-plyr='add-cuepoint']");
    const removeCuepointQuery = document.querySelector("[data-plyr='remove-cuepoint']");
    const trimQuery = document.querySelector("[data-plyr='trim-video']");
    const changeSourceQuery = document.querySelector("[data-plyr='change-source']");
    const selectSourceQuery = document.querySelector("[data-plyr='select-source']");

    if (addCuepointQuery) {
      addCuepointQuery.removeEventListener("click", onAddCuepoint);
    }

    if (removeCuepointQuery) {
      removeCuepointQuery.removeEventListener("click", onRemoveCuepoint);
    }

    if (trimQuery) {
      trimQuery.removeEventListener("click", onTrimVideo);
    }

    if (changeSourceQuery) {
      changeSourceQuery.removeEventListener("click", onChangeSource);
    }

    if (selectSourceQuery) {
      selectSourceQuery.removeEventListener("click", toggleSourceDropdown);
    }
  }, [onAddCuepoint, onChangeSource, onRemoveCuepoint, onTrimVideo, toggleSourceDropdown]);

  React.useEffect(() => {
    initControlListeners();

    return () => {
      destroyControlListeners();
    };
  }, [playerId, destroyControlListeners, initControlListeners]);
}
