import React from "react";
import ContentCard from "././library/components/content-card.jsx";
import { Link } from "react-router-dom";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import CircleRoundedIcon from "@mui/icons-material/CircleRounded";
import { AppHeading } from "../layout/parts/app-heading.jsx";
import AppBody from "../layout/parts/app-body.jsx";
import DataTable from "../components/data-table/data-table.jsx";

function ManageChannelsPage() {
  const columns = React.useMemo(
    () => [
      {
        label: "Display Name",
        key: "display_name",
        value: "display_name",
        sortable: true,
        width: 15,
      },
      {
        label: "Channel URL",
        key: "url",
        value: "url",
        width: 18,
        renderValue: (row) => (
          <span className="data-table__cell__value">
            <a href={row.url}>{row.url}</a>
          </span>
        ),
      },
      {
        label: "Available Providers",
        key: "providers_count",
        value: "providers.length",
        width: 13,
      },
      {
        label: "Rating",
        key: "rating",
        value: "rating",
        sortable: true,
        width: 10,
      },
      {
        label: "Genre",
        key: "genre",
        value: "genre",
        sortable: true,
        width: 15,
      },
      {
        label: "Landscape",
        key: "landscape",
        value: "",
        width: 8,
        align: "center",
        renderValue: (row) => (
          <span
            className={`data-table__cell__value data-table__icon data-table__icon--${row.art?.landscape ? "active" : "inactive"}`}
          >
            <CircleRoundedIcon />
          </span>
        ),
      },
      {
        label: "Portrait",
        key: "portrait",
        value: "",
        width: 8,
        align: "center",
        renderValue: (row) => (
          <span
            className={`data-table__cell__value data-table__icon data-table__icon--${row.art?.portrait ? "active" : "inactive"}`}
          >
            <CircleRoundedIcon />
          </span>
        ),
      },
      {
        label: "Square",
        key: "square",
        value: "",
        width: 8,
        align: "center",
        renderValue: (row) => (
          <span
            className={`data-table__cell__value data-table__icon data-table__icon--${row.art?.square ? "active" : "inactive"}`}
          >
            <CircleRoundedIcon />
          </span>
        ),
      },
      {
        label: "",
        value: "",
        key: "0",
        width: 5,
        renderValue: (row) => (
          <span className="data-table__action-cell">
            <span className="data-table__action-cell__item data-table__icon">
              <Link to={`/admin/channels/${row.channel_id}`}>
                <EditRoundedIcon />
              </Link>
            </span>
          </span>
        ),
      },
    ],
    [],
  );

  return (
    <React.Fragment>
      <AppHeading
        breadcrumbs={[
          {
            link: "/admin/",
            title: "Admin",
          },
          {
            link: "/admin/channels",
            title: "Channels",
          },
        ]}
        rightActions={[
          {
            balloonLabel: "Add Channel",
            icon: AddCircleRoundedIcon,
            type: "link",
            href: "create",
          },
        ]}
      />
      <AppBody>
        <div className="gw gw--full-height">
          <div className="g g--full-height g--1-of-1">
            <ContentCard noPadding>
              <DataTable url="/api/channels" columns={columns} />
            </ContentCard>
          </div>
        </div>
      </AppBody>
    </React.Fragment>
  );
}

export default ManageChannelsPage;
