import React from "react";
import { getAllProviders } from "../../../requests/providers";
import { addOrganisation, updateOrganisation } from "../../../requests/organisations";

function OrganisationForm({ action, onComplete, submitting = false, organisation = {} }) {
  const [providers, setProviders] = React.useState([]);
  const [form, setForm] = React.useState({});
  const [errors, setErrors] = React.useState({});

  const verifyForm = React.useCallback(() => {
    let formErrors = {};

    if (!form.name) {
      formErrors = { ...formErrors, name: "Name is required." };
    }

    if (!form.display_name) {
      formErrors = { ...formErrors, display_name: "Display Name is required." };
    }

    return formErrors;
  }, [form.display_name, form.name]);

  const onSubmit = React.useCallback(() => {
    setErrors({});

    const formErrors = verifyForm();

    if (Object.keys(formErrors).length) {
      setErrors(formErrors);
      onComplete(false);
    } else if (action === "create") {
      addOrganisation(form)
        .then(() => {
          onComplete(true);
        })
        .catch((error) => {
          console.error(error);
          if (error.status === 422) {
            formatApiErrors(error.data.errors);
          } else if (error.status === 500 || error.status === 404) {
            setErrors({ api: "There was an error creating this organisation. Please contact support." });
          }
          onComplete(false);
        });
    } else if (action === "edit") {
      updateOrganisation(organisation.id, form)
        .then(() => {
          onComplete(true);
        })
        .catch((error) => {
          console.error(error);
          if (error.status === 422) {
            formatApiErrors(error.data.errors);
          } else if (error.status === 500 || error.status === 404) {
            setErrors({ api: "There was an error editing this organisation. Please contact support." });
          }
          onComplete(false);
        });
    }
  }, [action, form, onComplete, organisation.id, verifyForm]);

  React.useEffect(() => {
    setErrors({});
    getAllProviders()
      .then((response) => {
        const newProviders = response.data.map((provider) => {
          if (provider.provider_active === 0) {
            provider.provider_name = provider.provider_name + " (Inactive)";
          }
          return provider;
        });

        setProviders(newProviders);
      })
      .catch((e) => {
        console.error(e);
        formatApiErrors(e.data.errors);
      });
  }, []);

  React.useEffect(() => {
    if (action === "edit") {
      setForm({
        name: organisation.name,
        display_name: organisation.display_name,
        provider_ids: organisation?.providers.map((provider) => provider.provider_guid),
      });
    }
  }, [organisation, action]);

  React.useEffect(() => {
    if (submitting) {
      onSubmit();
    }
  }, [submitting, onSubmit]);

  function formatApiErrors(errorObj) {
    let apiErrors = {};

    for (const key in errorObj) {
      if (errorObj.hasOwnProperty(key)) {
        apiErrors[key] = errorObj[key].join(". ");
      }
    }

    setErrors(apiErrors);
  }

  function handleChange(key, e) {
    setForm((prev) => ({
      ...prev,
      [key]: e.target.value,
    }));
  }

  function handleProviderChange(e) {
    setForm((prev) => ({
      ...prev,
      provider_ids: e.target.checked
        ? form.provider_ids
          ? [...form.provider_ids].concat([e.target.id])
          : [e.target.id]
        : [...form.provider_ids].filter((id) => id !== e.target.id),
    }));
  }

  return (
    <form>
      <div className="form-field">
        <label>Name</label>
        <input
          type="text"
          name="name"
          onChange={handleChange.bind(null, "name")}
          defaultValue={action === "edit" ? form.name : ""}
        />
        {errors.hasOwnProperty("name") ? <div className="form-field__error">{errors.name}</div> : null}
      </div>

      <div className="form-field">
        <label>Display Name</label>
        <input
          type="text"
          name="display_name"
          onChange={handleChange.bind(null, "display_name")}
          defaultValue={action === "edit" ? form.display_name : ""}
        />
        {errors.hasOwnProperty("display_name") ? <div className="form-field__error">{errors.display_name}</div> : null}
      </div>

      <div className="form-field">
        <label>Providers</label>
      </div>

      <div className="form-field form-field--rows">
        {providers &&
          providers.map((provider, key) => (
            <div className="provider-checkbox" key={key}>
              <input
                className="form-checkbox"
                type="checkbox"
                name={`providerId_${provider.provider_guid}`}
                id={`${provider.provider_guid}`}
                onChange={handleProviderChange}
                defaultChecked={action === "edit" && form.provider_ids.indexOf(provider.provider_guid) >= 0}
              />
              <label htmlFor={`providerId_${provider.provider_guid}`}>{provider.provider_name}</label>
            </div>
          ))}
      </div>
      {errors.hasOwnProperty("api") ? <p className="u-fc--warning">{errors.api}</p> : null}
    </form>
  );
}

export default OrganisationForm;
