import React from "react";
import ReactModal from "react-modal";
import Loader from "../../common/loader.jsx";
import { clearPlayoutFromProgram } from "../../requests/api-requests.js";
import { toast } from "react-toastify";

function PlayoutDeleteDialog({ program, onClose, onSuccess, isOpen }) {
  const [isLoading, setIsLoading] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState({});

  function onSubmit() {
    setIsLoading(true);

    clearPlayoutFromProgram(program.channel_playout_id, program.channel_playout_program_id)
      .then(() => {
        toast.success("Programs cleared. Refresh page if necessary.");
        onClose();
        onSuccess();
      })
      .catch((error) => {
        console.error(error);
        if (error.status === 422) {
          formatApiErrors(error.data.errors);
        } else if (error.status === 500 || error.status === 404) {
          setErrorMessage({ api: "There was an error clearing programs. Please contact support." });
        }
      })
      .finally(() => setIsLoading(false));
  }

  function formatApiErrors(errorObj) {
    let apiErrors = {};

    for (const key in errorObj) {
      if (errorObj.hasOwnProperty(key)) {
        apiErrors[key] = errorObj[key].join(". ");
      }
    }

    setErrorMessage(apiErrors);
  }

  return (
    <ReactModal isOpen={isOpen} ariaHideApp={false}>
      <div className="v-react-modal__inner modal-width">
        <div className="v-react-modal__heading">Delete Programs</div>
        <div className="v-react-modal__body">
          Are you sure you wish to clear all scheduled programs from {`"`}
          {program?.plan_program.program.title}
          {`"`} onwards?
          {errorMessage.api ? <p className="u-fc--warning">{errorMessage.api}</p> : null}
        </div>
        <div className="v-react-modal__footer">
          {isLoading ? (
            <Loader width={45} height={45} />
          ) : (
            <React.Fragment>
              <button className="btn btn--inverse-primary" onClick={onClose}>
                Cancel
              </button>
              <button className="btn btn--primary" onClick={onSubmit}>
                Delete
              </button>
            </React.Fragment>
          )}
        </div>
      </div>
    </ReactModal>
  );
}

export default PlayoutDeleteDialog;
