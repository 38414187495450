import React from "react";
import { createContext } from "react";
import { getTimezoneOffset } from "date-fns-tz";
import { addMinutes, format, startOfDay } from "date-fns";
import clockChangeSafeTime from "../common/clock-change-safe-time.js";

const SchedulerContext = createContext({
  timezone: "",
  toUtcDate: (t) => t,
  toOffsetDate: (t) => t,
  formatTimeForTimezone: (t) => t,
  fromLocalTime: (t) => t,
  toOffsetDateWithClockChangeSafety: (t) => t,
});

function useSchedulerProvider(currentTimezone) {
  const [state, setState] = React.useState({
    timezone: currentTimezone ?? "UTC",
  });

  const toOffsetDate = React.useCallback(
    (dateTime) => {
      if (state.timezone === "Europe/London") {
        return fromUtcToTimezoneDate(dateTime);
      }

      return dateTime;
    },
    [state.timezone],
  );

  const toOffsetDateWithClockChangeSafety = React.useCallback(
    (date) => {
      const startDayOffset = startOfDay(date).getTimezoneOffset();
      const currentOffset = date.getTimezoneOffset();

      // nothing to do here
      if (currentOffset === startDayOffset) {
        return toOffsetDate(date);
      }

      return clockChangeSafeTime(date);
    },
    [toOffsetDate],
  );

  const formatTimeForTimezone = React.useCallback(
    (dateTime) => {
      return format(toOffsetDateWithClockChangeSafety(dateTime), "HH:mm:ss");
    },
    [toOffsetDateWithClockChangeSafety],
  );

  function toUtcDate(date) {
    return fromTimezoneToUtcDate(date);
  }

  const fromLocalTime = React.useCallback(
    (localTime) => {
      if (state.timezone === "UTC") {
        return fromTimezoneToUtcDate(localTime);
      } else if (state.timezone === "Europe/London") {
        return fromTimezoneToTimezone(localTime, state.timezone);
      }

      return localTime;
    },
    [state.timezone],
  );

  React.useEffect(() => {
    setState({
      timezone: currentTimezone,
    });
  }, [currentTimezone]);

  return {
    ...state,
    toUtcDate,
    toOffsetDate,
    formatTimeForTimezone,
    fromLocalTime,
    toOffsetDateWithClockChangeSafety,
  };
}

function fromUtcToTimezoneDate(utcDate) {
  let offsetDate = new Date(utcDate);
  const modifier = offsetDate < 0 ? -1 : 1;
  offsetDate = addMinutes(offsetDate, (getTimezoneOffset("Europe/London", utcDate) / 60000) * modifier);
  return offsetDate;
}

function fromTimezoneToUtcDate(timezoneDate) {
  let offsetDate = new Date(timezoneDate);
  const modifier = offsetDate.getTimezoneOffset() < 0 ? 1 : -1;
  offsetDate = addMinutes(offsetDate, timezoneDate.getTimezoneOffset() * modifier);
  return offsetDate;
}

function fromTimezoneToTimezone(timezoneDate, targetTimezone = "Europe/London") {
  let offsetDate = new Date(timezoneDate);
  const localOffset = timezoneDate.getTimezoneOffset() * -1; // make consistent with getTimezoneOffset result
  const targetOffset = getTimezoneOffset(targetTimezone, timezoneDate) / 60000;
  const finalOffset = targetOffset - localOffset;

  const modifier = offsetDate.getTimezoneOffset() < 0 ? 1 : -1;

  offsetDate = addMinutes(offsetDate, finalOffset * modifier);
  return offsetDate;
}

/**
 * Handles the cases where the clock timezone might change during the day, this is especially useful when we want to think
 * that we are working with UTC dates, but they are actually BST --> GMT dates
 * @param date
 * @returns {Date}
 */
export function useSchedulerContext() {
  return React.useContext(SchedulerContext);
}

export default function SchedulerProvider({ children, timezone }) {
  const context = useSchedulerProvider(timezone);
  return <SchedulerContext.Provider value={context}>{children}</SchedulerContext.Provider>;
}
