import React from "react";
import SlidingPane from "react-sliding-pane";
import useApiRequest from "../../hooks/use-api-request.js";
import Select from "../../common/select.jsx";
import Loader from "../../common/loader.jsx";
import ReactDatePicker from "react-datepicker";
import axios from "../../requests/axios.js";
import formatApiErrors from "../../functions/format-api-errors.js";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";

function CreateIngestFeedScheduledComponent({ schedules, frequencies, handleChange, errors, removeSchedule }) {
  function onHandleChange(key, index, value, schedule) {
    const payload = {
      ...schedule,
      [key]: value,
    };
    handleChange("schedules", index, payload);
  }

  return schedules.map((schedule, index) => (
    <React.Fragment key={index}>
      <div className="form-field">
        <label>Frequency</label>
        <Select
          placeholder="Select a Frequency"
          name="frequency"
          options={frequencies}
          onChange={(input) => onHandleChange("frequency", index, input.value, schedule)}
          classNamePrefix="react-select"
        />
        {errors.schedules && errors.schedules[index] && errors.schedules[index].frequency ? (
          <div className="form-field__error">{errors.schedules[index].frequency}</div>
        ) : null}
      </div>
      <div className="form-field">
        <label>Schedule Starts On</label>
        <ReactDatePicker
          selected={schedule.start_at ? new Date(schedule.start_at) : new Date()}
          onChange={(next) => onHandleChange("start_at", index, next, schedule)}
          clearIcon={null}
          dateFormat={"yyyy-MM-dd"}
        />
        {errors.schedules && errors.schedules[index] && errors.schedules[index].start_at ? (
          <div className="form-field__error">{errors.schedules[index].start_at}</div>
        ) : null}
      </div>
      <div className="form-field">
        <button className="btn btn--inverse-primary" type="button" onClick={() => removeSchedule(index)}>
          Remove this schedule
        </button>
      </div>
    </React.Fragment>
  ));
}

const defaultScheduleShape = {
  frequency: "",
  start_at: "",
  enabled: true,
};

const defaultFormShape = {
  display_name: "",
  source_name: "",
  feed_type: "",
  feed_spec: "",
  feed_url: "",
  provider_id: null,
  schedules: [],
  file: "",
  // internal
  _use_schedule: false,
  _use_file: false,
};

export default function CreateIngestFeedWindow({ isOpen, onDone, onSuccess, action = "create", feed = null }) {
  const [form, setForm] = React.useState(defaultFormShape);
  const [errors, setErrors] = React.useState({});
  const [submitLoading, setSubmitLoading] = React.useState(false);
  const { isLoading, response } = useApiRequest("/api/feeds/create");

  const getSpecs = React.useCallback(
    (type) => {
      if (response.data?.specs) {
        return Object.keys(response.data.specs[type]).map((spec) => ({
          label: response.data.specs[type][spec],
          value: spec,
        }));
      }
    },
    [response.data?.specs],
  );

  React.useEffect(() => {
    if (!isOpen) {
      setForm(defaultFormShape);
      setErrors({});
    }
  }, [isOpen]);

  React.useEffect(() => {
    if (action === "edit" && feed) {
      setForm({
        source_name: feed.source_name,
        display_name: feed.display_name,
        feed_type: feed.feed_type,
        feed_spec: feed.feed_spec,
        provider_id: feed.provider_id,
        schedules: feed.schedules ?? [],
      });
    }
  }, [feed, action]);

  React.useEffect(() => {
    if (form._use_schedule) {
      setForm((prev) => ({
        ...prev,
        schedules: feed?.schedules ?? [defaultScheduleShape],
      }));
    }
  }, [form._use_schedule, feed?.schedules]);

  React.useEffect(() => {
    if (form.feed_type && form.feed_spec) {
      const specOptions = getSpecs(form.feed_type).map((e) => e.value);
      if (!specOptions.includes(form.feed_spec)) {
        setForm((prev) => ({
          ...prev,
          feed_spec: "",
        }));
      }
    }
  }, [form.feed_type, form.feed_spec, getSpecs]);

  function onFailed(error) {
    if (error.status === 422) {
      setErrors(formatApiErrors(error.data.errors));
    } else if (error.status === 500 || error.status === 404) {
      setErrors({
        api: `There was an error creating or editing, received code ${error.status}. Please contact support.`,
      });
    } else if (error.status === 403) {
      setErrors({ api: "Insufficient permissions to create an ingestion feed." });
    }
  }

  function submit() {
    const formErrors = validate();

    if (Object.keys(formErrors).length) {
      setErrors(formErrors);
      return;
    }

    const payload = {
      ...form,
    };

    if (payload._use_schedule === false) {
      // unset schedules to indicate that this a request to immediately trigger the ingestion
      delete payload.schedules;
    }
    delete payload._use_schedule;

    if (payload._use_file === false) {
      delete payload.file;
    } else {
      delete payload.feed_url;
    }
    delete payload._use_file;

    if (!payload.provider_id) {
      delete payload.provider_id;
    }

    setSubmitLoading(true);

    // use FormData for this request so that we can handle Files
    const formData = new FormData();
    Object.keys(payload).forEach((key) => {
      if (key === "schedules") {
        formData.append("schedules", JSON.stringify(payload.schedules));
      } else {
        formData.append(key, payload[key]);
      }

      if (key === "file") {
        formData.append("file_name", payload[key].name);
      }
    });

    if (action === "edit") {
      // call edit
      axios
        .post(`/api/feeds/${feed.ingestion_feed_id}`, formData, {})
        .then(() => {
          onSuccess();
          onDone();
        })
        .catch(onFailed)
        .finally(() => setSubmitLoading(false));
    } else {
      // call create
      axios
        .post(`/api/feeds`, formData, { headers: { "Content-Type": "multipart/form-data" } })
        .then(() => {
          onSuccess();
          onDone();
        })
        .catch(onFailed)
        .finally(() => setSubmitLoading(false));
    }
  }

  function validate() {
    let formErrors = {};

    if (!form.source_name) {
      formErrors = { ...formErrors, source_name: "Source name is required." };
    }

    if (!form.display_name) {
      formErrors = { ...formErrors, display_name: "Display Name is required." };
    }

    if (!form.feed_type) {
      formErrors = { ...formErrors, feed_type: "Feed type is required." };
    }

    if (!form.feed_spec) {
      formErrors = { ...formErrors, feed_spec: "Feed spec is required." };
    }

    if (!form._use_file) {
      if (!form.feed_url) {
        formErrors = { ...formErrors, feed_url: "Feed URL is required." };
      } else if (!(form.feed_url.startsWith("https://") || form.feed_url.startsWith("s3://"))) {
        formErrors = { ...formErrors, feed_url: "Feed url must start with https:// or s3://" };
      }
    }

    if (form._use_file && !form.file) {
      formErrors = { ...formErrors, file: "File is required." };
    }

    if (Object.keys(formErrors).length) {
      formErrors = { ...formErrors, api: "Errors in fields above. Please fix and retry." };
    }

    return formErrors;
  }

  function handleChange(key, _index_or_value, _value = false) {
    let value = _value;
    let index = _index_or_value;
    if (_value === false) {
      value = _index_or_value;
      index = null;
    }

    if (key === "file") {
      value = _index_or_value.target.files[0];
    }

    setForm((_form) => ({
      ..._form,
      [key]: index === null ? value : [..._form[key].slice(0, index), value, ..._form[key].slice(index + 1)],
    }));
  }

  function getTypes() {
    return Object.keys(response.data.types).map((type) => ({
      label: response.data.types[type],
      value: type,
    }));
  }

  function getSelectedType(type) {
    if (!type) return { value: "", label: "" };

    return getTypes().filter((o) => o.value === form.feed_type)[0] ?? { value: "", label: "" };
  }

  function getSelectedSpec(spec) {
    if (!spec || !form.feed_type) return { value: "", label: "" };

    return getSpecs(form.feed_type).filter((o) => o.value === form.feed_spec)[0] ?? { value: "", label: "" };
  }

  function getFrequencies() {
    return Object.keys(response.data.frequencies).map((freq) => ({
      label: response.data.frequencies[freq],
      value: freq,
    }));
  }

  function getProviders(type) {
    if (type === "csv") {
      return [
        {
          label: "All providers",
          value: "",
        },
      ];
    }

    return response.data.providers;
  }

  function getSelectedProvider(type, provider_id) {
    let providers = [
      {
        label: "All providers",
        value: "",
      },
    ];
    if (type === "csv" || !type || !provider_id) {
      return providers[0];
    }

    return getProviders().filter((p) => p.value === provider_id)[0] ?? providers[0];
  }

  function addSchedule() {
    setForm((prev) => ({
      ...prev,
      schedules: prev.schedules.concat([defaultScheduleShape]),
    }));
  }

  function removeSchedule(index) {
    setForm((prev) => ({
      ...prev,
      schedules: [...prev.schedules.slice(0, index), ...prev.schedules.slice(index + 1)],
    }));
  }

  return (
    <SlidingPane
      closeIcon={<CancelRoundedIcon />}
      overlayClassName="react-sliding-pane--show"
      isOpen={isOpen}
      title={"Create new Feed"}
      from="right"
      onRequestClose={() => onDone()}
      width={"40%"}
      key="create-ingest-feed-pane"
    >
      {isLoading ? (
        <Loader />
      ) : (
        <form>
          <div className="form-field">
            <label>Source Name</label>
            <input
              type="source_name"
              name="source_name"
              onChange={(e) => handleChange("source_name", e.target.value)}
              defaultValue={action === "edit" ? form.name : ""}
            />
            {errors.hasOwnProperty("source_name") ? (
              <div className="form-field__error">{errors.source_name}</div>
            ) : null}
          </div>
          <div className="form-field">
            <label>Display Name</label>
            <input
              type="display_name"
              name="display_name"
              onChange={(e) => handleChange("display_name", e.target.value)}
              defaultValue={action === "edit" ? form.display_name : ""}
            />
            {errors.hasOwnProperty("display_name") ? (
              <div className="form-field__error">{errors.display_name}</div>
            ) : null}
          </div>
          <div className="form-field">
            <label>Type</label>
            <Select
              placeholder="Select a Type"
              name="feed_type"
              options={getTypes()}
              value={getSelectedType(form.feed_type)}
              onChange={(input) => handleChange("feed_type", input.value)}
              classNamePrefix="react-select"
            />
            {errors.hasOwnProperty("feed_type") ? <div className="form-field__error">{errors.feed_type}</div> : null}
          </div>
          <div className="form-field">
            <label>Specification</label>
            <Select
              placeholder="Select a Specification"
              name="feed_spec"
              options={form.feed_type ? getSpecs(form.feed_type) : {}}
              value={getSelectedSpec(form.feed_spec)}
              onChange={(input) => handleChange("feed_spec", input.value)}
              classNamePrefix="react-select"
              isDisabled={!form.feed_type}
            />
            {errors.hasOwnProperty("feed_spec") ? <div className="form-field__error">{errors.feed_spec}</div> : null}
          </div>
          <div className="form-field">
            <label>For Provider</label>
            <Select
              placeholder="Select a Provider"
              name="provider"
              options={getProviders(form.feed_type)}
              value={getSelectedProvider(form.feed_type, form.provider_id)}
              onChange={(input) => handleChange("provider_id", input.value)}
              classNamePrefix="react-select"
              isDisabled={!form.feed_spec || form.feed_type === "csv"}
            />
            {errors.hasOwnProperty("feed_spec") ? <div className="form-field__error">{errors.feed_spec}</div> : null}
          </div>
          <div className="form-field">
            <label htmlFor="">Feed source</label>
            <div className="radio-group-container">
              <div className="radio-group">
                <input
                  className="form-radio"
                  type="radio"
                  name="use-file"
                  id="use-file-no"
                  onChange={() => handleChange("_use_file", false)}
                  checked={!form._use_file}
                  value="no"
                />
                <label htmlFor="use-file-no">Specify url (https:// or s3://)</label>
              </div>
              <div className="radio-group">
                <input
                  className="form-radio"
                  type="radio"
                  name="use-file"
                  id="use-file-yes"
                  onChange={() => handleChange("_use_file", true)}
                  checked={form._use_file === true}
                  value="yes"
                />
                <label htmlFor="use-file-yes">Upload a file</label>
              </div>
            </div>
          </div>
          {!form._use_file ? (
            <div className="form-field">
              <label>Feed Url</label>
              <input
                type="text"
                name="feed_url"
                onChange={(e) => handleChange("feed_url", e.target.value)}
                defaultValue={action === "edit" ? form.feed_url : ""}
              />
              {errors.hasOwnProperty("feed_url") ? <div className="form-field__error">{errors.feed_url}</div> : null}
            </div>
          ) : (
            <div className="form-image-container">
              <input
                type="file"
                accept=".csv, application/vnd.ms-excel, text/csv"
                onChange={(e) => handleChange("file", e)}
              />
              {errors.hasOwnProperty("file") ? <div className="form-field__error">{errors.file}</div> : null}
            </div>
          )}
          <div className="form-field">
            <label htmlFor="">Scheduled Ingestion</label>
            <div className="radio-group-container">
              <div className="radio-group">
                <input
                  className="form-radio"
                  type="radio"
                  name="use-schedule"
                  id="use-schedule-no"
                  onChange={() => handleChange("_use_schedule", false)}
                  checked={!form._use_schedule}
                  value="no"
                />
                <label htmlFor="use-schedule-no">Once off and Immediate</label>
              </div>
              <div className="radio-group">
                <input
                  className="form-radio"
                  type="radio"
                  name="use-schedule"
                  id="use-schedule-yes"
                  onChange={() => handleChange("_use_schedule", true)}
                  checked={form._use_schedule === true}
                  value="yes"
                />
                <label htmlFor="use-schedule-yes">With Schedule</label>
              </div>
            </div>
            {form._use_schedule ? (
              <React.Fragment>
                <CreateIngestFeedScheduledComponent
                  removeSchedule={removeSchedule}
                  schedules={form.schedules}
                  handleChange={handleChange}
                  frequencies={getFrequencies()}
                  errors={errors}
                />
                <button className="btn btn--inverse-primary" type="button" onClick={addSchedule}>
                  Add another schedule
                </button>
              </React.Fragment>
            ) : null}
          </div>
          {errors.hasOwnProperty("api") ? <p className="u-fc--warning">{errors.api}</p> : null}
        </form>
      )}
      <div className="slide-pane__footer">
        {submitLoading ? (
          <Loader width={45} height={45} />
        ) : (
          <React.Fragment>
            <button className="btn btn--inverse-primary" onClick={() => onDone()}>
              Cancel
            </button>
            <button
              className="btn btn--primary"
              onClick={(e) => {
                e.preventDefault();
                submit();
              }}
            >
              {feed ? "Update" : "Create"} Feed
            </button>
          </React.Fragment>
        )}
      </div>
    </SlidingPane>
  );
}
