import React from "react";
import { DragDropContext } from "react-beautiful-dnd";
import DraggableContentList from "./components/draggable-content-list.jsx";
import { contentToEpg, planToEpg } from "./utils/epg-transformers.js";
import EpgDndHelper from "./utils/dnd-helpers.js";
import VerticalScheduler from "../../components/vertical-scheduler/vertical-scheduler.jsx";
import { addHours, addMinutes, compareAsc, isBefore, setHours, setMinutes, setSeconds, startOfDay } from "date-fns";
import dateIsBetween from "../../common/date-is-between.js";
import differenceInSeconds from "date-fns/differenceInSeconds";
import EpgProgramOverview from "./components/epg-program-overview.jsx";
import PlannerMeta from "./components/planner-meta.jsx";
import { filterAdBreaksAfter, getDurationInSecondsWithAdBreaks, getSecondsFromHis } from "./utils/create-ad-breaks.js";
import ErrorBoundary from "../../components/error-boundary.jsx";
import { toast } from "react-toastify";
import useContentList from "../../components/content-list/use-content-list.js";
import { secondsToHourMinutesSeconds } from "../../common/duration-formatting.js";
import { isUtcPast } from "../../common/is-utc-past.js";
import { HlsOrDashPlayer } from "../../components/player/player.jsx";
import SchedulerTimeline from "./components/scheduler-timeline.jsx";
import { SCHEDULER_TIMELINE_MARKER_CLASS } from "../../components/vertical-scheduler/use-timeline-zoom.jsx";
import useEpgEditorDroppableControls from "./hooks/use-epg-editor-droppable-controls.jsx";
import * as Sentry from "@sentry/react";
import { trimProgramDurationToSeconds } from "./utils/trim-program-duration.js";
import { useSchedulerContext } from "../../providers/scheduler-context.jsx";
import ResizableLayout from "./components/resizable-layout.jsx";
import { areEpgProgramCuepointsSpaced } from "./utils/cuepoint-spacing-validator.js";
import { getContentByType } from "../../requests/api-requests.js";
import EpgProgramPlayer from "./components/epg-program-player.jsx";

const EpgEditor = React.forwardRef(
  (
    {
      plan,
      channel,
      activeDate,
      addPlanBreak,
      deletePlanBreak,
      hasError,
      resolveError,
      notifyUpdated,
      clearPlanNotifier,
      resetClearNotifier,
      playbackData,
    },
    ref,
  ) => {
    // imperative stuff
    const layoutRef = React.useRef();

    React.useImperativeHandle(ref, () => ({
      resetLayout: () => layoutRef.current.resetLayout(),
    }));

    // refs
    const schedulerRef = React.useRef(null);
    const timelineRef = React.useRef(null);
    const containerRef = React.useRef();

    // state
    // const [activeProgram, setActiveProgram] = React.useState({ summary: null, loading: false, details: null });
    const [selectedProgram, setSelectedProgram] = React.useState({ program: null, loading: false, summary: null });
    const [activeProgram, setActiveProgram] = React.useState(null);
    const [timeRange, setTimeRange] = React.useState([]);
    const [planBreaks, setPlanBreaks] = React.useState([]);

    // epg state
    const { epg: sourceEpg } = planToEpg(channel, plan);
    const [epg, setEpg] = React.useState(sourceEpg);
    const [prevEpg, setPrevEpg] = React.useState(epg);

    // custom hooks
    const { formatTimeForTimezone, timezone } = useSchedulerContext();

    const {
      programs,
      isLoading: contentListLoading,
      getPrograms,
    } = useContentList({ planDate: plan.plan_date, channelId: plan.channel_id });

    const { horizontalDroppable, verticalDroppable, onEpgDragEvent } = useEpgEditorDroppableControls(
      schedulerRef,
      timelineRef,
    );

    const dndHelper = React.useMemo(() => {
      return EpgDndHelper(channel.channel_id, activeDate, planBreaks);
    }, [channel.channel_id, activeDate, planBreaks]);

    const resetEpg = React.useCallback(() => {
      const resetDnd = EpgDndHelper(channel.channel_id, activeDate, planBreaks);
      setEpg(resetDnd.recalculate(prevEpg));
    }, [activeDate, channel.channel_id, planBreaks, prevEpg]);

    const safeEpgUpdate = React.useCallback(
      (next) => {
        setPrevEpg(JSON.parse(JSON.stringify(epg)));
        notifyUpdated();
        setEpg(next);
      },
      [epg, notifyUpdated],
    );

    // @todo use this standardized structure everywhere instead of the current mixed-structured activeProgram
    const populateSelectedProgram = React.useCallback((active) => {
      if (!active) {
        return;
      }

      let summary = null;
      let program = null;

      if (active.id) {
        summary = active;
      } else {
        program = active;
      }

      setSelectedProgram({
        summary,
        program,
        loading: !program,
      });

      if (!program && summary) {
        const type = summary.__gstvMeta.link_type;
        const guid = summary.__gstvMeta.link_guid;

        getContentByType(type, guid) // @todo load program
          .then((program) => {
            setSelectedProgram((prev) => ({
              summary: prev.summary,
              program,
              loading: false,
            }));
          })
          .catch((e) => console.error(e));
      }
    }, []);

    const selectProgram = React.useCallback(
      (program) => {
        // @todo standardize program structure, at the moment it can be either an epg program or a api program
        setActiveProgram((prev) => {
          if (!(prev?.id && prev?.id === program.id)) {
            populateSelectedProgram(program);
          }
          return program;
        });
      },
      [populateSelectedProgram],
    );

    const saveAdBreaks = React.useCallback(
      (programId, adBreaks, callback = null) => {
        safeEpgUpdate((prevEpg) => {
          const result = prevEpg.map((program) => {
            if (program.id === programId) {
              // if we were given a callback instead of a ad break array, use that instead
              let cuepoints = [];
              if (callback) {
                // get new ad breaks callback
                cuepoints = callback(program.__gstvMeta.ad_breaks);
              } else {
                cuepoints = adBreaks;
              }

              const validAdBreaks = filterAdBreaksAfter(cuepoints, program.__gstvMeta.duration_seconds);
              const modifiedProgram = {
                ...program,
                __gstvMeta: {
                  ...program.__gstvMeta,
                  ad_breaks: validAdBreaks,
                  total_duration_seconds: getDurationInSecondsWithAdBreaks(
                    program.__gstvMeta.duration_seconds,
                    validAdBreaks,
                  ),
                },
              };

              selectProgram(modifiedProgram);
              return modifiedProgram;
            }
            return program;
          });

          return dndHelper.recalculate(result);
        });
        toast.success("Cuepoints updated successfully");
      },
      [dndHelper, safeEpgUpdate, selectProgram],
    );

    React.useEffect(() => {
      setPrevEpg(epg);
    }, [channel, plan, epg]);

    React.useEffect(() => {
      if (hasError) {
        toast.error("Something went wrong, please contact support.");
        resetEpg();
        resolveError();
      }
    }, [hasError, resetEpg, resolveError]);

    React.useEffect(() => {
      if (clearPlanNotifier > 0) {
        const resetDnd = EpgDndHelper(channel.channel_id, activeDate, planBreaks);
        setEpg(resetDnd.recalculate([]));
        resetClearNotifier();
      }
    }, [clearPlanNotifier, activeDate, channel.channel_id, planBreaks, dndHelper, resetClearNotifier]);

    function clearTimeRange() {
      setTimeRange([]);
      const highlightedMarkers = document.body.getElementsByClassName(`${SCHEDULER_TIMELINE_MARKER_CLASS}--selected`);
      if (highlightedMarkers.length) {
        // HTMLCollection to Array
        Array.from(highlightedMarkers).forEach((element) => {
          element.classList.remove(`${SCHEDULER_TIMELINE_MARKER_CLASS}--selected`);
          element.classList.remove(`${SCHEDULER_TIMELINE_MARKER_CLASS}--selected-first`);
          element.classList.remove(`${SCHEDULER_TIMELINE_MARKER_CLASS}--selected-last`);
        });
      }
    }

    // Plan Breaks
    React.useEffect(() => {
      const referenceDate = startOfDay(activeDate);
      const combinedChannelPlanBreak = plan.plan_breaks
        .map((planBreak) => {
          const [hours, minutes] = planBreak.start.split(":");
          const [endHours, endMinutes] = planBreak.end.split(":");
          let start = addHours(addMinutes(referenceDate, minutes), hours);
          let end = addHours(addMinutes(referenceDate, endMinutes), endHours);

          return {
            ...planBreak,
            start,
            end,
            duration: differenceInSeconds(end, start),
          };
        })
        .sort((a, b) => {
          return compareAsc(a.start, b.start);
        });
      setPlanBreaks(combinedChannelPlanBreak);
    }, [plan.plan_breaks, activeDate, timezone]);

    React.useEffect(() => {
      if (planBreaks.length) {
        setEpg((prev) => dndHelper.recalculate(prev));
      }
    }, [planBreaks, dndHelper]);

    function getSegmentIndexWithinTime(time) {
      return planBreaks.findIndex((s) => dateIsBetween(time, s.start, s.end, "[)"));
    }

    function getIndexOfProgramStartingAfterBreak(breakTime) {
      let programIndex = epg.findIndex((s) => s.since.toString() === breakTime.toString());

      if (programIndex === -1) {
        // no programs start on the provided break, return the index of the program before the break
        programIndex = epg.findLastIndex((s) => isBefore(s.since, breakTime)) + 1;
      }
      return programIndex < 0 ? epg.length : programIndex;
    }

    function onDragEnd(planDate, { destination, source }) {
      try {
        if (!destination || destination.droppableId === "content-list") {
          return;
        }

        let currentBreakStartTime = null;
        let currentBreakEndTime = null;
        let sourceSegment = null;
        // destination id might include a break
        if (destination.droppableId.includes(":")) {
          const breakTime = destination.droppableId.split("-").slice(-1)[0];
          const [hours, minutes] = breakTime.split(":");
          currentBreakStartTime = setHours(setMinutes(setSeconds(new Date(planDate), 0), minutes), hours);
        }

        // source might also include a break
        if (source.droppableId.includes(":")) {
          const breakTime = source.droppableId.split("-").slice(-1)[0];
          const [hours, minutes] = breakTime.split(":");
          sourceSegment = setHours(setMinutes(setSeconds(new Date(planDate), 0), minutes), hours);
        }

        let isInsert = source.droppableId === "content-list";
        let list = JSON.parse(JSON.stringify(epg)).map((item) => ({
          ...item,
          since: new Date(item.since),
          till: new Date(item.till),
        }));

        let absoluteDestinationIndex = destination.index;
        let absoluteSourceIndex = source.index;

        if (currentBreakStartTime) {
          // indices are relative to the segment they are in, we need the absolute index from here on
          const indexedCounter = [];
          for (let i = 0; i < list.length; i++) {
            const currIndex = getSegmentIndexWithinTime(list[i].since);
            if (indexedCounter[currIndex]) {
              indexedCounter[currIndex] += 1;
            } else {
              indexedCounter[currIndex] = 1;
            }
          }
          const toSegmentIndex = getSegmentIndexWithinTime(currentBreakStartTime);
          const fromSegmentIndex = sourceSegment ? getSegmentIndexWithinTime(sourceSegment) : null;
          absoluteDestinationIndex += indexedCounter.slice(0, toSegmentIndex).reduce((prev, curr) => prev + curr, 0);
          if (fromSegmentIndex !== null && fromSegmentIndex < toSegmentIndex) {
            // we have for sure already counted this element
            absoluteDestinationIndex--;
          }

          currentBreakEndTime = planBreaks[toSegmentIndex].end;

          if (!isInsert) {
            const fromSegmentIndex = getSegmentIndexWithinTime(sourceSegment);
            absoluteSourceIndex += indexedCounter.slice(0, fromSegmentIndex).reduce((prev, curr) => prev + curr, 0);
          }
        }

        if (isInsert) {
          let program = contentToEpg(programs.data[absoluteSourceIndex]);
          // check if this is a safe operation
          list = dndHelper.insert(list, program, absoluteDestinationIndex, currentBreakStartTime, currentBreakEndTime);
        } else {
          list = dndHelper.move(
            list,
            absoluteSourceIndex,
            absoluteDestinationIndex,
            currentBreakStartTime,
            currentBreakEndTime,
          );
        }

        if (isUtcPast(list[absoluteDestinationIndex].since)) {
          // reject the update
          toast.error("Cannot drop a program in the past");
          return;
        }

        safeEpgUpdate(list);
      } catch (err) {
        Sentry.captureException(err);
      }
    }

    function addProgram(program) {
      let epgProgram = contentToEpg(program);

      if (
        (epg[epg.length - 1]?.since && isUtcPast(epg[epg.length - 1].since)) ||
        new Date(plan.plan_date) < new Date()
      ) {
        // reject the update
        toast.error("Cannot add a program in the past");
        safeEpgUpdate(epg);
        return;
      }

      const list = dndHelper.insert(epg, epgProgram, epg.length, null);
      safeEpgUpdate(list);
    }

    const updateProgramDuration = React.useCallback(
      (programId, updatedDuration, adBreaks = null) => {
        safeEpgUpdate((prevEpg) => {
          const result = prevEpg.map((program) => {
            if (program.id === programId) {
              const updatedProgram = trimProgramDurationToSeconds(program, updatedDuration, adBreaks);
              selectProgram(updatedProgram);
              return updatedProgram;
            }
            return program;
          });

          return dndHelper.recalculate(result);
        });
      },
      [dndHelper, safeEpgUpdate, selectProgram],
    );

    const removeProgram = React.useCallback(
      (program) => {
        if (isUtcPast(program.since)) {
          toast.error("Cannot remove programs from the past.");
          return;
        }
        safeEpgUpdate((prev) => {
          return dndHelper.remove(prev, program);
        });
      },
      [dndHelper, safeEpgUpdate],
    );

    function overwriteSegmentWith(epgPrograms, breakTime, deleteCount) {
      const insertIndex = getIndexOfProgramStartingAfterBreak(breakTime);
      const list = dndHelper.overwriteSegmentWith(epg, epgPrograms, insertIndex, breakTime, deleteCount);
      safeEpgUpdate(list);
    }

    const addCuepoint = React.useCallback(
      (_atSeconds) => {
        if (!activeProgram) {
          return;
        }

        if (!areEpgProgramCuepointsSpaced(activeProgram, formatTimeForTimezone)) {
          return;
        }

        const atSeconds = parseInt(_atSeconds, 10);

        saveAdBreaks(activeProgram.id, null, (prev) => [
          ...prev,
          {
            cuepoint: secondsToHourMinutesSeconds(atSeconds),
            ad_break: secondsToHourMinutesSeconds(122),
          },
        ]);
      },
      [activeProgram, formatTimeForTimezone, saveAdBreaks],
    );

    const removeNearestCuepoint = React.useCallback(
      (atSeconds) => {
        if (!activeProgram || !activeProgram.__gstvMeta) {
          return;
        }

        let nearestIndex = 0;
        let nearestTimeInSeconds = 9999999;
        saveAdBreaks(activeProgram.id, null, (prev) =>
          prev
            .map((ad, index) => {
              const diff = Math.abs(atSeconds - getSecondsFromHis(ad.cuepoint));
              if (diff < nearestTimeInSeconds) {
                nearestTimeInSeconds = diff;
                nearestIndex = index;
              }
              return ad;
            })
            .filter((item, index) => index !== nearestIndex),
        );
      },
      [activeProgram, saveAdBreaks],
    );

    const trimVideo = React.useCallback(
      (_atPlayoutSeconds) => {
        if (!activeProgram) {
          return;
        }
        const atPlayoutSeconds = parseInt(_atPlayoutSeconds, 10);
        const filteredBreaks = activeProgram.__gstvMeta.ad_breaks.filter(
          (cue) => getSecondsFromHis(cue.cuepoint) < atPlayoutSeconds,
        );
        const newTotalDuration = getDurationInSecondsWithAdBreaks(atPlayoutSeconds, filteredBreaks);

        updateProgramDuration(activeProgram.id, newTotalDuration, filteredBreaks);
      },
      [activeProgram, updateProgramDuration],
    );

    const playerOptions = React.useMemo(
      () => ({
        source: playbackData.url ?? channel.url,
        type: playbackData.type ?? "hls",
        controls: {
          selectSource: false,
          changeSource: false,
          editCuepoints: false,
          playoutTrimming: false,
          timers: false,
        },
      }),
      [channel.url, playbackData.type, playbackData.url],
    );

    // this is an awful idea but time is short
    window.__gstv_get_current_epg = () => epg;
    return (
      <ErrorBoundary onError={resetEpg}>
        {() => (
          <DragDropContext onDragEnd={onDragEnd.bind(null, activeDate)}>
            <ResizableLayout ref={layoutRef}>
              {[
                <div className="scheduler-layout__content" key={0}>
                  <DraggableContentList
                    getPrograms={getPrograms}
                    programs={programs}
                    droppableId="content-list"
                    channel={channel}
                    key={channel.channel_id}
                    addProgram={addProgram}
                    setActiveProgram={selectProgram}
                    isLoading={contentListLoading}
                    transcodingTypes={plan.output_types}
                    onContentDrag={() => onEpgDragEvent("on-content-list-drag-start")}
                    setAlternateStreamAssetId={() => true}
                  />
                </div>,
                <div
                  className="scheduler-layout__calendar"
                  style={{ overflowY: !verticalDroppable ? "hidden" : "auto" }}
                  ref={schedulerRef}
                  key={1}
                >
                  <VerticalScheduler
                    epg={epg}
                    droppableId="epg-vertical-timeline"
                    planDate={activeDate}
                    planBreaks={planBreaks}
                    addPlanBreak={addPlanBreak}
                    deletePlanBreak={deletePlanBreak}
                    selectProgram={selectProgram}
                    onPlay={() => true}
                    removeProgram={removeProgram}
                    copySegment={overwriteSegmentWith}
                    updateProgramDuration={updateProgramDuration}
                    customRange={timeRange.length ? timeRange : []}
                    setCustomTimeRange={setTimeRange}
                    dropsDisabled={!verticalDroppable}
                    onProgramDrag={() => onEpgDragEvent("on-vertical-drag-start")}
                  />
                </div>,
                <div className="scheduler-layout__playback" ref={containerRef} key={2}>
                  <HlsOrDashPlayer {...playerOptions} />
                </div>,
                <div className="scheduler-layout__meta" key={3}>
                  <PlannerMeta epg={epg} planBreaks={planBreaks} planDate={activeDate} />
                </div>,
                <div className="scheduler-layout__playback-meta" key={5}>
                  <EpgProgramOverview
                    program={selectedProgram.program}
                    isLoading={selectedProgram.loading}
                    programEpg={activeProgram}
                    onPlay={() => true}
                    onRemoveProgram={removeProgram}
                    saveAdBreaks={saveAdBreaks}
                    viewContentListProgram={selectProgram}
                    transcodingTypes={plan.output_types}
                    addCuepoint={addCuepoint}
                    removeNearestCuepoint={removeNearestCuepoint}
                    trimVideo={trimVideo}
                  />
                </div>,
                <div className="scheduler-layout__playback" key={4}>
                  <EpgProgramPlayer
                    program={selectedProgram.program}
                    isLoading={selectedProgram.loading}
                    programEpg={activeProgram}
                    saveAdBreaks={saveAdBreaks}
                    transcodingTypes={plan.output_types}
                    addCuepoint={addCuepoint}
                    removeNearestCuepoint={removeNearestCuepoint}
                    trimVideo={trimVideo}
                  />
                </div>,
                <div className="scheduler-layout__timeline" ref={timelineRef} key={6}>
                  <SchedulerTimeline
                    epg={epg}
                    date={activeDate}
                    customRange={timeRange.length ? timeRange : []}
                    setActiveProgram={selectProgram}
                    removeProgram={removeProgram}
                    clearRange={clearTimeRange}
                    onPlay={() => true}
                    planBreaks={planBreaks ?? []}
                    dropsDisabled={!horizontalDroppable}
                    onProgramDrag={() => onEpgDragEvent("on-horizontal-drag-start")}
                    updateProgramDuration={updateProgramDuration}
                  />
                </div>,
              ]}
            </ResizableLayout>
          </DragDropContext>
        )}
      </ErrorBoundary>
    );
  },
);

EpgEditor.displayName;

export default EpgEditor;
