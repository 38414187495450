import React from "react";
import ImageWithFallback from "../../../../components/image-with-fallback.jsx";

export default function LibraryItemThumbnail({ item }) {
  return (
    <span className="link-preview-box-container library-list__thumbnail">
      <span className="link-preview-box">
        <span className="link-preview-box__image">
          <ImageWithFallback url={item.content_poster?.large_url} />
        </span>
        <span className="link-preview-box__footer library-list__thumbnail__label">
          {item.formatted_name}
          {item.restricted_at ? " 🔒" : ""}
        </span>
      </span>
    </span>
  );
}
