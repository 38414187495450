import React from "react";
import Select from "../../../common/select.jsx";
function ProviderFilter({ provider, providers, onChange }) {
  return (
    <Select
      defaultValue={{
        label: provider.provider_name,
        value: provider.provider_guid,
      }}
      placeholder={"Provider"}
      name={"provider"}
      options={providers.map((p) => ({
        label: p.provider_name,
        value: p.provider_guid,
      }))}
      onChange={(input) => onChange(input)}
      containerClass="react-select-container--light react-select-container--small"
    />
  );
}

export default ProviderFilter;
