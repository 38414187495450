import React from "react";

export const SCHEDULER_TIMELINE_MARKER_CLASS = "vertical-scheduler-marker__text";

function useTimelineZoom(containerRef, itemsRef) {
  const [startDate, setStartDate] = React.useState();
  const [endDate, setEndDate] = React.useState();
  const first = React.useRef();
  const last = React.useRef();
  const anchor = React.useRef();
  const itemsRefLength = Object.values(itemsRef.current).length;

  first.current = -1;
  last.current = -1;
  anchor.current = -1;

  const highlightMarkers = React.useCallback(
    (from, to) => {
      // HTMLCollection to Array
      const allMarkers = Object.values(itemsRef.current).filter((ref) => !!ref);

      // highlight marker range
      allMarkers.forEach((marker) => {
        const markerIndex = parseInt(marker.dataset.markerNumber);
        if (markerIndex >= from && markerIndex <= to) {
          marker.classList.add(`${SCHEDULER_TIMELINE_MARKER_CLASS}--selected`);
        }

        if (markerIndex === from) {
          marker.classList.add(`${SCHEDULER_TIMELINE_MARKER_CLASS}--selected-first`);
        }

        if (markerIndex === to) {
          marker.classList.add(`${SCHEDULER_TIMELINE_MARKER_CLASS}--selected-last`);
        }
      });
    },
    [itemsRef],
  );

  const clearHighlightedMarkers = React.useCallback(() => {
    const highlightedMarkers = containerRef.current.getElementsByClassName(
      `${SCHEDULER_TIMELINE_MARKER_CLASS}--selected`,
    );
    if (highlightedMarkers.length) {
      // HTMLCollection to Array
      Array.from(highlightedMarkers).forEach((element) => {
        element.classList.remove(`${SCHEDULER_TIMELINE_MARKER_CLASS}--selected`);
        element.classList.remove(`${SCHEDULER_TIMELINE_MARKER_CLASS}--selected-first`);
        element.classList.remove(`${SCHEDULER_TIMELINE_MARKER_CLASS}--selected-last`);
      });
    }
  }, [containerRef]);

  const updateMarkerRange = React.useCallback(
    (event) => {
      if (event.target.dataset.isMarker === "true") {
        clearHighlightedMarkers();

        const nextMarkerIndex = parseInt(event.target.dataset.markerNumber);

        // range is always based around anchor marker
        if (nextMarkerIndex > anchor.current) {
          first.current = anchor.current;
          last.current = nextMarkerIndex;
        } else if (nextMarkerIndex < anchor.current) {
          first.current = nextMarkerIndex;
          last.current = anchor.current;
        } else {
          // if a marker is clicked (no drag range)
          first.current = nextMarkerIndex;
          last.current = nextMarkerIndex + 1;
        }

        highlightMarkers(first.current, last.current);
      }
    },
    [highlightMarkers, clearHighlightedMarkers],
  );

  const onMouseOver = React.useCallback(
    (event) => {
      updateMarkerRange(event);
    },
    [updateMarkerRange],
  );

  const onRelease = React.useCallback(
    (event) => {
      updateMarkerRange(event);

      // HTMLCollection to Array
      const dates = Object.values(itemsRef.current)
        .filter((ref) => !!ref)
        .map((marker) => {
          const markerIndex = parseInt(marker.dataset.markerNumber);
          if (markerIndex === first.current || markerIndex === last.current) {
            return new Date(marker.dataset.markerDate);
          }
          return null;
        })
        .filter((marker) => marker !== null);

      // only set new dates on mouse release
      setStartDate(dates[0]);
      // if last marker clicked, set endDate to 5 minutes after the last marker time
      setEndDate(dates.length > 1 ? dates[1] : new Date(dates[0]).setMinutes(dates[0].getMinutes() + 5));

      // clear first, last and anchor
      anchor.current = -1;
      first.current = -1;
      last.current = -1;

      document.body.removeEventListener("mouseover", onMouseOver);
      document.body.removeEventListener("mouseup", onRelease);
    },
    [itemsRef, onMouseOver, updateMarkerRange],
  );

  const handleMarkerClick = React.useCallback(
    (event) => {
      const startMarker = event.target;
      anchor.current = parseInt(startMarker.dataset.markerNumber);
      first.current = parseInt(startMarker.dataset.markerNumber);
      last.current = parseInt(startMarker.dataset.markerNumber);

      updateMarkerRange(event);

      document.body.addEventListener("mouseover", onMouseOver);
      document.body.addEventListener("mouseup", onRelease);
    },
    [onMouseOver, onRelease, updateMarkerRange],
  );

  React.useEffect(() => {
    let items = [];
    if (itemsRefLength > 0) {
      items = Object.values(itemsRef.current).filter((ref) => !!ref); // remove any nullable values
      items.forEach((marker) => marker.addEventListener("mousedown", handleMarkerClick));
    }

    return () => {
      if (items.length) {
        items.forEach((marker) => marker.removeEventListener("mousedown", handleMarkerClick));
      }
    };
  }, [itemsRefLength, itemsRef, handleMarkerClick]);

  return {
    startDate: startDate,
    endDate: endDate,
  };
}

export default useTimelineZoom;
