import React from "react";
import Loader from "../common/loader.jsx";
import StreamPlayer from "./epg-editor/components/stream-player.jsx";
import ContentCard from "./library/components/content-card.jsx";
import EpgTimeline from "./epg-editor/components/epg-timeline/epg-timeline.jsx";
import { planToEpg } from "./epg-editor/utils/epg-transformers.js";
import { toast } from "react-toastify";
import EpgProgramInfo from "./scheduling/components/epg-program-info.jsx";
import { useLocation, useNavigate } from "react-router-dom";
import ReactDatePicker from "react-datepicker";
import { addMinutes } from "date-fns";
import SchedulerProvider from "../providers/scheduler-context.jsx";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";
import { AppHeading } from "../layout/parts/app-heading.jsx";
import AppBody from "../layout/parts/app-body.jsx";
import axios from "../requests/axios.js";
import EpgChannelInfo from "./scheduling/components/epg-channel-info.jsx";

function HomePage() {
  const videoHeightRef = React.useRef();

  const [activeChannel, setActiveChannel] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(false);
  const [planDate, setPlanDate] = React.useState(new Date());
  const [activeProgram, setActiveProgram] = React.useState();
  const [channelPlans, setChannelPlans] = React.useState({});
  const [channels, setChannels] = React.useState({});
  const [activeContent, setActiveContent] = React.useState("channel");
  const [cardHeight, setCardHeight] = React.useState();
  const navigate = useNavigate();
  const { state } = useLocation();

  React.useEffect(() => {
    setIsLoading(true);
    setChannelPlans({});

    axios
      .get(`/api/channels?plans=all&plan_date=${formatDate(planDate)}`)
      .then((response) => {
        setChannels(response.data.data);
        setActiveChannel(response.data.data.find((channel) => channel.display_name === "MBC") || response.data.data[0]);

        let channels = [];
        let epg = [];
        response.data.data.forEach((channel) => {
          // fix dates
          if (channel.plans[0]?.programs.length) {
            channel.plans[0].programs.forEach((p) => {
              p.program_start = fromUtcDate(p.program_start);
              p.program_end = fromUtcDate(p.program_end);
              return p;
            });
          }

          const channelPlan = planToEpg(channel, channel.plans[0]);

          channels.push({ ...channelPlan.channel, url: channel.url });

          let previousProgram = null;

          for (let index = 0; index < channelPlan.epg.length; index++) {
            const program = channelPlan.epg[index];
            if (program.Type === "promo") {
              continue;
            }

            if (previousProgram) {
              previousProgram.till = program.since;
            }

            previousProgram = program;
            epg.push(program);
          }
        });

        setChannelPlans({
          channels: channels,
          epg: epg,
        });
      })
      .catch((error) => {
        console.error(error);
        toast.error("Error loading channel plans. Please contact support.");
      })
      .finally(() => setIsLoading(false));
  }, [planDate]);

  React.useLayoutEffect(() => {
    const observer = new ResizeObserver(() => {
      if (videoHeightRef.current) {
        setCardHeight(videoHeightRef.current.clientHeight);
      }
    });

    if (videoHeightRef.current) {
      observer.observe(videoHeightRef.current);
    } else {
      // hack to make sure we avoid timing issues
      setTimeout(() => {
        if (videoHeightRef.current) {
          observer.observe(videoHeightRef.current);
        }
      }, 300);
    }

    return () => observer.disconnect();
  }, []);

  function fromUtcDate(utcDate) {
    let offsetDate = new Date(utcDate);
    const modifier = offsetDate < 0 ? -1 : 1;
    offsetDate = addMinutes(offsetDate, offsetDate.getTimezoneOffset() * modifier);
    return offsetDate;
  }

  function updateNavigation({ plan_date }) {
    navigate("/", { state: { plan_date: plan_date || state.plan_date } });
  }

  function updateActiveDate(input) {
    updateNavigation({ plan_date: input });
    setPlanDate(input);
    setActiveProgram(null);
  }

  function formatDate(date) {
    const d = new Date(date);
    let month = "" + (d.getMonth() + 1);
    let day = "" + d.getDate();
    const year = d.getFullYear();

    if (month.length < 2) {
      month = "0" + month;
    }
    if (day.length < 2) {
      day = "0" + day;
    }

    return [year, month, day].join("-");
  }

  function selectProgram(program) {
    setActiveProgram(program.data);
    setActiveContent("program");
  }

  function selectChannel(channelGuid) {
    setActiveChannel(channels.find((channel) => channel.channel_id === channelGuid));
    setActiveContent("channel");
  }

  return (
    <SchedulerProvider>
      <AppHeading
        breadcrumbs={[
          {
            link: "/",
            title: "Home",
          },
        ]}
        leftActions={[
          {
            component: () => (
              <div className="datepicker-container">
                <button
                  className="btn--text-primary channel-heading__datepicker__arrow-button"
                  onClick={(e) => {
                    e.preventDefault();
                    let newDate = new Date(planDate);
                    updateActiveDate(new Date(newDate.setDate(newDate.getDate() - 1)));
                  }}
                >
                  <ChevronLeftRoundedIcon />
                </button>
                <ReactDatePicker
                  selected={new Date(planDate)}
                  onChange={updateActiveDate}
                  clearIcon={null}
                  dateFormat="yyyy-MM-dd"
                />
                <button
                  className="btn--text-primary channel-heading__datepicker__arrow-button"
                  onClick={(e) => {
                    e.preventDefault();
                    let newDate = new Date(planDate);
                    updateActiveDate(new Date(newDate.setDate(newDate.getDate() + 1)));
                  }}
                >
                  <ChevronRightRoundedIcon />
                </button>
              </div>
            ),
          },
        ]}
      />
      <AppBody>
        <div className="u-margin--block-end">
          <div className="gw">
            <div className="g g--1-of-2">
              <ContentCard maxHeight={cardHeight} noPadding>
                {activeContent === "channel" ? (
                  <EpgChannelInfo channel={activeChannel} />
                ) : (
                  <EpgProgramInfo program={activeProgram} />
                )}
              </ContentCard>
            </div>
            <div className="g g--1-of-2">
              <div className="video-player" ref={videoHeightRef}>
                <StreamPlayer playerSize="fill" liveStreamUrl={activeChannel.url} noTitle />
              </div>
            </div>
          </div>
        </div>

        <ContentCard noPadding>
          {isLoading ? (
            <div className="page-loader-wrapper">
              <Loader />
            </div>
          ) : (
            <EpgTimeline
              epg={channelPlans?.epg ?? []}
              channels={channelPlans?.channels ?? []}
              date={planDate}
              setActiveProgram={selectProgram}
              setActiveChannel={selectChannel}
              mode="view"
            />
          )}
        </ContentCard>
      </AppBody>
    </SchedulerProvider>
  );
}

export default HomePage;
