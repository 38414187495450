import React from "react";
import ContentCard from "./components/content-card.jsx";
import { AppHeading } from "../../layout/parts/app-heading.jsx";
import AppBody from "../../layout/parts/app-body.jsx";
import { useParams } from "react-router-dom";
import useApiRequest from "../../hooks/use-api-request.js";
import { secondsToHourMinutesSeconds } from "../../common/duration-formatting.js";
import FilesTab from "./tabs/files-tab.jsx";
import TabbedContent from "../../components/tabbed-content.jsx";
import DeleteForeverRoundedIcon from "@mui/icons-material/DeleteForeverRounded";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import ChangeVideoSourceDialog from "../components/dialogs/change-video-source-dialog.jsx";
import SaveRoundedIcon from "@mui/icons-material/SaveRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import LockRoundedIcon from "@mui/icons-material/LockRounded";
import NoEncryptionGmailerrorredRoundedIcon from "@mui/icons-material/NoEncryptionGmailerrorredRounded";
import useSpreadState from "../../hooks/use-spread-state.js";
import { toast } from "react-toastify";
import GenericDeleteDialog from "../components/dialogs/generic-delete-dialog.jsx";
import { useNavigate } from "react-router-dom";
import QueueTranscodeDialog from "../components/queue-transcode-dialog.jsx";
import TranscodingStatusIcon from "../components/transcoding-status-icon.jsx";
import GenericRestoreDialog from "../components/dialogs/generic-restore-dialog.jsx";
import RestoreFromTrashRoundedIcon from "@mui/icons-material/RestoreFromTrashRounded";
import axios from "../../requests/axios.js";
import Loader from "../../common/loader.jsx";
import ContentPlayer from "../../components/player/content-player.jsx";

function PromoPage() {
  const [isChangeSourceOpen, setChangeSourceOpen] = React.useState(false);
  const [isEditing, setEditing] = React.useState(false);
  const [isTrashing, setTrashing] = React.useState(false);
  const [isRestoring, setRestoring] = React.useState(false);
  const [transcodingDialog, setTranscodingDialog] = React.useState({ isOpen: false, type: "" });

  const [form, updateForm] = useSpreadState({});
  const { promo_guid } = useParams();
  const { isLoading, response } = useApiRequest(`/api/promos/${promo_guid}?additional=sourceVideo`);
  const [promo, setPromo] = React.useState({});
  const [data, setData] = React.useState({});
  const [isSwitchingPlayers, setSwitching] = React.useState(false);
  const navigate = useNavigate();

  const toggleEditMode = React.useCallback(() => {
    setSwitching(true);
    setEditing((prev) => !prev);
    setTimeout(() => {
      setSwitching(false);
    }, 200);
  }, []);

  const trimVideo = React.useCallback(
    (_atPlayoutSeconds) => {
      let atPlayoutSeconds = parseInt(_atPlayoutSeconds, 10);
      // reset trim by snipping at end or start of timeline
      if (atPlayoutSeconds > promo.duration || atPlayoutSeconds === 0) {
        atPlayoutSeconds = promo.duration;
      }

      updateForm({ trimmed_duration: atPlayoutSeconds });
    },
    [promo.duration, updateForm],
  );

  const playerOptions = React.useMemo(
    () => ({
      trimPoint: data.trimmed_duration,
      trimVideo: trimVideo,
      changeSource: changeSource,

      controls: {
        playoutTrimming: isEditing,
        changeSource: isEditing,
        selectSource: true,
        timers: true,
      },

      availableSources: {
        source: !!promo?.source_video?.asset_id,
        normalised: !!promo?.content_video?.asset_id,
        hls: !!promo?.content_video?.hls_path,
        dash: !!promo?.content_video?.dash_path,
      },
    }),
    [promo, data.trimmed_duration, isEditing, trimVideo],
  );

  React.useEffect(() => {
    if (response.data) {
      setPromo(response.data);
    }
  }, [response]);

  React.useEffect(() => {
    if (promo) {
      setData({ ...promo, ...(isEditing ? form : {}) });
    }
  }, [form, promo, isEditing]);

  const breadcrumbs = [
    {
      link: "/content",
      title: "Content",
    },
    {
      link: "/content/library",
      title: "Library",
    },
    {
      title: response?.data
        ? `${response?.data.promo_name} ${response?.data.promo_active === 1 ? "" : "(Inactive)"}`
        : "...",
      link: `/content/library/promo/${promo_guid}`,
    },
  ];

  function onSaveEdits() {
    axios
      .patch(`/api/promos/${promo_guid}?additional=assets`, form)
      .then((resp) => {
        toast.success("Promo successfully updated");
        setEditing(false);
        setPromo(resp.data);
      })
      .catch((error) => {
        toast.error("Error attempting to update Promo");
        console.error(error);
      });
  }

  function onSetRestricted(restrict = false) {
    axios
      .post(`/api/promos/${promo.promo_guid}/${restrict ? "restrict" : "unrestrict"}`)
      .then((resp) => {
        toast.success(`Promo ${restrict ? "restricted" : "unrestricted"} successfully`);
        setPromo((prev) => ({
          ...prev,
          restricted_at: resp.data.restricted_at,
        }));
      })
      .catch((error) => {
        toast.error("Error updating restricted status on Promo");
        console.error(error);
      });
  }

  function changeSource() {
    setChangeSourceOpen(true);
  }

  function changeSourceClosed() {
    setChangeSourceOpen(false);
  }

  function setTranscoding(isOpen, type) {
    setTranscodingDialog({ isOpen, type });
  }

  return (
    <React.Fragment>
      <AppHeading
        breadcrumbs={breadcrumbs}
        leftActions={[
          {
            component: () => (
              <button
                className="btn btn--icon"
                onClick={() => {
                  if (!["processing", "completed"].includes(promo?.content_video?.hls_status)) {
                    setTranscoding(true, "hls");
                  }
                  return false;
                }}
              >
                <TranscodingStatusIcon
                  status={promo?.content_video?.hls_status}
                  appendTextOptions={{ failed: "Click to transcode", initialised: "Click to transcode" }}
                  small={true}
                />
              </button>
            ),
          },
          {
            component: () => (
              <button
                className="btn btn--icon"
                onClick={() => {
                  if (!["processing", "completed"].includes(promo?.content_video?.dash_status)) {
                    setTranscoding(true, "dash");
                  }
                  return false;
                }}
              >
                <TranscodingStatusIcon
                  status={promo?.content_video?.dash_status}
                  appendTextOptions={{ failed: "Click to transcode", initialised: "Click to transcode" }}
                  small={true}
                  type="dash"
                />
              </button>
            ),
          },
        ]}
        rightActions={[
          {
            when: !!promo.restricted_at && !isEditing,
            icon: LockRoundedIcon,
            balloonLabel: "Unrestrict content",
            onClick: onSetRestricted.bind(null, false),
          },
          {
            when: !promo.restricted_at && !isEditing,
            icon: NoEncryptionGmailerrorredRoundedIcon,
            balloonLabel: "Restrict content",
            onClick: onSetRestricted.bind(null, true),
          },
          {
            when: isEditing,
            icon: SaveRoundedIcon,
            balloonLabel: "Save",
            onClick: onSaveEdits,
          },
          {
            when: isEditing,
            icon: CancelRoundedIcon,
            balloonLabel: "Cancel",
            onClick: () => toggleEditMode(),
          },
          {
            when: !isEditing && promo?.promo_active === 0,
            icon: RestoreFromTrashRoundedIcon,
            balloonLabel: "Restore content",
            onClick: () => setRestoring(true),
          },
          {
            when: !isEditing && promo?.promo_active === 1,
            icon: DeleteForeverRoundedIcon,
            balloonLabel: "Mark as inactive",
            onClick: () => setTrashing(true),
          },
          {
            when: !isEditing && promo?.promo_active === 1,
            icon: EditRoundedIcon,
            balloonLabel: "Edit",
            onClick: () => toggleEditMode(),
          },
        ]}
      />
      <AppBody loading={isLoading}>
        {!isLoading && promo && (
          <div className="spread-container">
            <div className="spread-container__middle spread-container__middle--alone">
              <div className="content-page">
                <div className="content-page__media-row">
                  <div className="content-page__media-row__media">
                    <ContentCard noPadding>
                      {!promo || !Object.keys(promo).length || isSwitchingPlayers ? (
                        <Loader />
                      ) : (
                        <ContentPlayer program={promo} playerOptions={playerOptions} />
                      )}
                    </ContentCard>
                  </div>
                  <div className="content-page__media-row__description">
                    <ContentCard>
                      <div className="content-page__section">
                        <div className="content-page__list">
                          <div className="content-page__list__item">
                            <div className="content-page__list__label">Provider</div>
                            <div className="content-page__list__value">{promo?.provider?.provider_name ?? ""}</div>
                          </div>
                          <div className="content-page__list__item">
                            <div className="content-page__list__label">Promo name</div>
                            {isEditing ? (
                              <textarea
                                value={data.promo_name ?? "None"}
                                onChange={(e) => updateForm({ promo_name: e.target.value })}
                              />
                            ) : (
                              <div className="content-page__list__value">{promo.promo_name}</div>
                            )}
                          </div>
                          <div className="content-page__list__item">
                            <div className="content-page__list__label">Run time</div>
                            <div className="content-page__list__value">
                              {promo.duration ? secondsToHourMinutesSeconds(promo.duration) : "N/A"}
                            </div>
                          </div>
                        </div>
                      </div>
                    </ContentCard>
                  </div>
                </div>

                <div className="content-page__body">
                  <TabbedContent
                    tabs={[
                      {
                        label: "Files",
                        name: "files",
                        component: FilesTab,
                        props: {
                          guid: promo_guid,
                          type: "promos",
                          folders: [
                            {
                              label: "",
                              url: `/api/promos/${promo_guid}/files/summary`,
                            },
                          ],
                        },
                      },
                    ]}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </AppBody>
      <ChangeVideoSourceDialog
        contentType="promo"
        contentGuid={promo_guid}
        onClose={changeSourceClosed}
        isOpen={isChangeSourceOpen}
      />
      <GenericDeleteDialog
        isOpen={isTrashing}
        body={
          "This will mark this promo and all of it's related content as inactive, are you sure you wish to continue?"
        }
        onClose={() => {
          setTrashing(false);
        }}
        endpoint={`/api/promos/${promo_guid}`}
        onComplete={(result, error) => {
          if (error) {
            console.error(error);
          } else if (result) {
            setTrashing(false);
            toast.success("Successfully marked this promo as inactive.");
            setTimeout(() => navigate(0), 1500);
          }
        }}
      />
      <GenericRestoreDialog
        isOpen={isRestoring}
        body={"This will restore this promo and some of its related data. Do you wish to continue?"}
        onClose={() => {
          setRestoring(false);
        }}
        endpoint={`/api/promos/${promo_guid}/restore`}
        onComplete={(result, error) => {
          if (error) {
            console.error(error);
          } else if (result) {
            setRestoring(false);
            toast.success("Successfully restored this promo.");
            setTimeout(() => navigate(0), 1500);
          }
        }}
      />
      <QueueTranscodeDialog
        contentId={promo?.promo_guid}
        contentType={"promo"}
        onClose={() => setTranscoding(false, "")}
        onSuccess={() => {
          setTranscoding(false, "");
          toast.success(`${transcodingDialog.type.toUpperCase()} transcoding queued`);
        }}
        isOpen={transcodingDialog.isOpen}
        transcodeType={transcodingDialog.type}
      />
    </React.Fragment>
  );
}

export default PromoPage;
