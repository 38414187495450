import React from "react";
import ReactModal from "react-modal";
import Loader from "../../common/loader.jsx";
import axios from "../../requests/axios.js";
import { useNavigate } from "react-router-dom";
import formatApiErrors from "../../functions/format-api-errors.js";
import { toast } from "react-toastify";

export default function SaveAvodPlaylistDialog({ isOpen, onClose, updatePage, isCreating, setFormErrors, payload }) {
  const [isLoading, setIsLoading] = React.useState(false);
  const [errors, setErrors] = React.useState({});
  const navigate = useNavigate();

  function onSave(immediate_publish = false) {
    setIsLoading(true);
    /*
      Saving cases:
      1 - creating a completely new playlist
      2 - creating a new version of an existing playlist
      3 - updating a playlist version
    */
    const targetUrl = `/api/avod-playlists${isCreating ? "" : `/${payload.avod_playlist_guid}/versions/${payload.version}`}`;
    const method = isCreating ? "post" : "patch";
    axios[method](targetUrl, {
      ...payload,
      immediate_publish,
    })
      .then((response) => {
        // success
        onClose();

        if (isCreating) {
          navigate(`/avod/playlists/${response.data.avod_playlist_guid}/versions/${response.data.version}`);
        } else {
          updatePage(response.data);
        }

        toast.success(`Successfully ${isCreating ? "created" : "updated"}`);

        if (immediate_publish) {
          toast.info("Processing publish request...");
        }
      })
      .catch((error) => {
        if (error.status === 422) {
          setErrors({
            api: "Something went wrong...",
          });
          setFormErrors(formatApiErrors(error.data.errors || error.data.error));
        } else if (error.status === 500 || error.status === 404) {
          setErrors({ api: "There was unexpected error. Please contact support." });
        } else if (error.status === 403) {
          // get custom error message from api
          setErrors({ api: error.data });
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  return (
    <ReactModal isOpen={isOpen} ariaHideApp={false}>
      <div className="v-react-modal__inner modal-width">
        <div className="v-react-modal__heading">{isCreating ? "Create" : "Save"} Playlist</div>
        <div className="v-react-modal__body modal-body">
          You can optionally choose to publish this playlist if it is ready to be distributed.
          {errors.api ? <p className="u-fc--warning">{errors.api}</p> : null}
        </div>
        <div className="v-react-modal__footer">
          {isLoading ? (
            <Loader width={45} height={45} />
          ) : (
            <React.Fragment>
              <button className="btn btn--inverse-primary" onClick={onClose}>
                Cancel
              </button>
              <button className="btn btn--text-primary" onClick={() => onSave(true)}>
                Publish
              </button>
              <button className="btn btn--primary" onClick={() => onSave(false)}>
                Save
              </button>
            </React.Fragment>
          )}
        </div>
      </div>
    </ReactModal>
  );
}
