import React from "react";
import Select from "../../../common/select.jsx";
import SaveRoundedIcon from "@mui/icons-material/SaveRounded";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import DeleteForeverRoundedIcon from "@mui/icons-material/DeleteForeverRounded";

function SidebarContentGenres({ genreList, genres, dirty_genres: dirtyGenres, onClose, onSet, id }) {
  const [nextImdbGenre, setNextImdbGenre] = React.useState(null);
  const [imdbGenres, setImdbGenres] = React.useState(genres.filter((genre) => !!genre.imdb_genre));

  function getSubgenresForGenre(genreValue) {
    const foundGenre = genreList.imdb.find((genreItem) => genreItem.value === genreValue);
    if (!foundGenre) {
      return [];
    }

    return foundGenre.subgenres;
  }

  function getImdbGenreOptions() {
    // first filter out genres that are already selected
    const ignoreList = imdbGenres.map((g) => g.imdb_genre.value);
    return genreList.imdb
      .filter((item) => !ignoreList.includes(item.value))
      .map((item) => ({ label: item.label, value: item.value }));
  }

  function getImdbSubgenreOptions() {
    if (!nextImdbGenre) {
      return [];
    }

    const options = getSubgenresForGenre(nextImdbGenre.value);
    if (!options) {
      return [];
    }

    return options.map((item) => ({ label: item.label, value: item.value }));
  }

  function getSelectedImdb(key) {
    if (!nextImdbGenre) {
      return [];
    }

    switch (key) {
      case "genre":
        return { label: nextImdbGenre.label, value: nextImdbGenre.value };
      case "subgenre":
        if (!nextImdbGenre.subgenre) {
          return [];
        }

        return { label: nextImdbGenre.subgenre.label, value: nextImdbGenre.subgenre.value };
      default:
        return [];
    }
  }

  function handleChange(key, type, value) {
    if (type === "imdb") {
      switch (key) {
        case "genre":
          setNextImdbGenre(value);
          break;
        case "subgenre":
          setNextImdbGenre((current) => ({ ...current, subgenre: value }));
          break;
        default:
          break;
      }
    }
  }

  function pushImdbGenre() {
    if (!nextImdbGenre) {
      return null;
    }

    let [selectedGenre] = genreList.imdb.filter((item) => item.value === nextImdbGenre.value);
    if (!selectedGenre) {
      return;
    }

    let selectedSubgenre = null;
    if (nextImdbGenre && nextImdbGenre.subgenre) {
      [selectedSubgenre] = selectedGenre.subgenres.filter((item) => item.value === nextImdbGenre.subgenre.value);
      if (!selectedSubgenre) {
        selectedSubgenre = null;
      }
    }

    selectedGenre = {
      ...selectedGenre,
      subgenre: null,
    };

    const nextGenreObject = {
      imdb_genre: {
        ...selectedGenre,
      },
      imdb_subgenre: selectedSubgenre,
    };

    setNextImdbGenre(null);
    setImdbGenres((prev) => [...prev, nextGenreObject]);
  }

  function removeGenre(key, genre) {
    if (key === "imdb") {
      setImdbGenres((current) => {
        return current.filter((item) => item.imdb_genre.value !== genre.imdb_genre.value);
      });
    }
  }

  function onSave() {
    // do stuff
    onSet(id, "genres", [...imdbGenres]);

    onClose();
  }

  return (
    <div className="gstv-ag-grid-sidebar-content">
      <div className="gstv-ag-grid-sidebar-content__section">
        <div className="gstv-ag-grid-sidebar-content__label">IMDb Genres</div>
        <div className="gstv-ag-grid-sidebar-content__group">
          {imdbGenres.length
            ? imdbGenres.map((imdbGenre, imdbGenreIndex) => (
                <div className="u-margin--small--block-end" key={imdbGenreIndex}>
                  <div className="gw">
                    <div className="g g--5-of-12">
                      <div className="gstv-ag-grid-sidebar-content__group__label">{imdbGenre.imdb_genre.label}</div>
                    </div>
                    <div className="g g--5-of-12">
                      <div className="gstv-ag-grid-sidebar-content__group__label">
                        {imdbGenre.imdb_subgenre?.label ?? "(no subgenre)"}
                      </div>
                    </div>
                    <div className="g g--2-of-12">
                      <button
                        className="btn--base--svg btn--danger-inverse"
                        onClick={() => removeGenre("imdb", imdbGenre)}
                      >
                        <DeleteForeverRoundedIcon />
                      </button>
                    </div>
                  </div>
                </div>
              ))
            : null}
        </div>
        <div className="gstv-ag-grid-sidebar-content__group">
          <div className="gw">
            <div className="g g--5-of-12">
              <div className="gstv-ag-grid-select">
                <Select
                  placeholder="Select a Genre"
                  name="genre"
                  options={getImdbGenreOptions()}
                  value={getSelectedImdb("genre")}
                  onChange={(input) => handleChange("genre", "imdb", input)}
                  containerClass="react-select-container--light"
                  classNamePrefix="react-select"
                />
              </div>
            </div>
            <div className="g g--5-of-12">
              <div className="gstv-ag-grid-select">
                <Select
                  placeholder="Select a Genre"
                  name="subgenre"
                  options={nextImdbGenre ? getImdbSubgenreOptions() : []}
                  value={getSelectedImdb("subgenre")}
                  onChange={(input) => handleChange("subgenre", "imdb", input)}
                  classNamePrefix="react-select"
                  containerClass="react-select-container--light"
                  disabled={!nextImdbGenre}
                />
              </div>
            </div>
            <div className="g g--2-of-12">
              <div className="u-display--flex-center u-height-100">
                <button onClick={pushImdbGenre} className="btn--base--svg">
                  <AddCircleRoundedIcon />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="gstv-ag-grid-sidebar-content__section">
        <div className="gstv-ag-grid-sidebar-content__label">Ingested Genres</div>
        <div className="gstv-ag-grid-sidebar-content__group">
          {dirtyGenres && dirtyGenres.length
            ? dirtyGenres.map((dirtyGenre, index) => (
                <div className="u-margin--small--block-end" key={index}>
                  <div className="gw">
                    <div className="g g--1-of-2">
                      <div className="gstv-ag-grid-sidebar-content__group__label">{dirtyGenre.genre}</div>
                    </div>
                    <div className="g g--1-of-2">
                      <div className="gstv-ag-grid-sidebar-content__group__label">
                        {dirtyGenre.sub_genre ?? "(no subgenre)"}
                      </div>
                    </div>
                  </div>
                </div>
              ))
            : null}
        </div>
      </div>
      {/* Another section for 'ingested' genres */}
      <div className="gstv-ag-grid-sidebar-content__footer">
        <button onClick={onClose} className="gstv-ag-grid-sidebar-content__footer__button">
          <CancelRoundedIcon /> Close
        </button>
        <button onClick={onSave} className="gstv-ag-grid-sidebar-content__footer__button">
          <SaveRoundedIcon /> Save
        </button>
      </div>
    </div>
  );
}

export default SidebarContentGenres;
