import React, { useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import SchedulerProvider from "../providers/scheduler-context";
import axios from "../requests/axios";
import Loader from "../common/loader";
import SimpleChannelHeading from "./epg-editor/components/simple-channel-heading";
import { format, setHours, setMinutes, addMinutes, startOfDay, differenceInSeconds, compareAsc } from "date-fns";
import SimplifiedVerticalScheduler from "../components/vertical-scheduler/simplified-vertical-scheduler";
import ArrowBackIosRoundedIcon from "@mui/icons-material/ArrowBackIosRounded";
import { InfiniteLoader, Grid } from "react-virtualized";
import debounce from "../common/debounce";

const SCHEDULER_WIDTH = 400;
const BUFFER = 3;
const DAYS_PAST_TO_LOAD = 7;

function SchedulerWeeklyPage() {
  const [plans, setPlans] = React.useState([]);
  const [channel, setChannel] = React.useState({});
  const [timezone, setTimezone] = React.useState(window.localStorage.getItem("__gstv_timezone") ?? "Europe/London");

  const { channelGuid } = useParams();
  const scrollRef = useRef();
  const debounceRef = React.useRef({ track: undefined });
  const navigate = useNavigate();

  const getNewPlanData = React.useCallback((oldPlans, newPlans) => {
    const additionalPlans = newPlans
      .map((plan) => {
        const newBreaks = plan.plan_breaks
          .map((planBreak) => {
            const referenceDate = startOfDay(new Date(plan.plan_date));
            const [hours, minutes] = planBreak.start.split(":");
            const [endHours, endMinutes] = planBreak.end.split(":");
            const start = setHours(setMinutes(referenceDate, minutes), hours);
            const end = setHours(setMinutes(referenceDate, endMinutes), endHours);
            return {
              ...planBreak,
              start,
              end,
              duration: differenceInSeconds(end, start),
            };
          })
          .sort((a, b) => {
            return compareAsc(a.start, b.start);
          });

        plan.plan_breaks = newBreaks;

        plan.programs.forEach((planProgram) => {
          planProgram.program_start = fromUtcDate(planProgram.program_start);
          planProgram.program_end = fromUtcDate(planProgram.program_end);
          return planProgram;
        });

        return plan;
      })
      //  remove plans that are already in the range
      .filter((plan) => {
        if (!oldPlans.some((item) => item.plan_date === plan.plan_date)) {
          return plan;
        }
      });
    //  merge old plans with new
    const nextPlans = [...oldPlans, ...additionalPlans].sort((a, b) => new Date(a.plan_date) < new Date(b.plan_date));
    return nextPlans;
  }, []);

  React.useEffect(() => {
    axios.get(`api/channels/${channelGuid}`).then((resp) => setChannel(resp.data));
  }, [channelGuid]);

  function fromUtcDate(utcDate) {
    let offsetDate = new Date(utcDate);
    const modifier = offsetDate < 0 ? -1 : 1;
    offsetDate = addMinutes(offsetDate, offsetDate.getTimezoneOffset() * modifier);
    return offsetDate;
  }

  function toggleTimezone() {
    const nextTimezone = timezone === "UTC" ? "Europe/London" : "UTC";

    setTimezone(nextTimezone);
    window.localStorage.setItem("__gstv_timezone", nextTimezone);
  }

  function onCalendarDateChange(input) {
    const planDate = format(input, "y-MM-dd");
    goToSchedulingView(planDate);
  }

  function scroll(scrollPx) {
    if (scrollRef.current) {
      let newScrollLeft = scrollRef.current.state.scrollLeft + scrollPx;

      scrollRef.current.scrollToPosition({
        scrollLeft: newScrollLeft >= 0 ? newScrollLeft : 0,
        scrollTop: 0,
      });
    }
  }

  function scrollToPosition(leftPx) {
    if (scrollRef.current) {
      scrollRef.current.scrollToPosition({
        scrollLeft: leftPx,
        scrollTop: 0,
      });
    }
  }

  function goToSchedulingView(planDate) {
    navigate(`/scheduler/${channelGuid}/${planDate}`);
  }

  function onSectionRendered(onRowsRendered, props) {
    onRowsRendered({ startIndex: props.columnStartIndex, stopIndex: props.columnStopIndex });
  }

  function SimpleSchedulerColumn({ columnIndex, style }) {
    const plan = plans[columnIndex];

    return (
      <div style={{ ...style, ...{ height: "100%", maxHeight: "100%" } }}>
        <div className="weekly-scheduler__header">
          <div
            className="weekly-scheduler__header__label"
            onClick={() => {
              if (plan) {
                goToSchedulingView(plan.plan_date);
              }
            }}
          >
            {plan ? plan.plan_date : ""}
          </div>
        </div>
        {plan && plan.plan_breaks ? (
          <div
            className="scheduler-layout__calendar scheduler-layout__calendar--multi"
            onClick={() => goToSchedulingView(plan.plan_date)}
          >
            <SimplifiedVerticalScheduler
              programs={plan?.programs}
              planBreaks={plan?.plan_breaks || []}
              planDate={new Date(plan.plan_date)}
            />
          </div>
        ) : (
          <div className="weekly-scheduler__loader">
            <Loader />
          </div>
        )}
      </div>
    );
  }

  return (
    <SchedulerProvider timezone={timezone}>
      <div className="spread-container">
        <div className="spread-container__top">
          <SimpleChannelHeading
            channelName={channel.display_name || "Loading channel..."}
            planDate={new Date()}
            changePlanDate={onCalendarDateChange}
            toggleTimezone={toggleTimezone}
          />
        </div>
        <div className="spread-container__middle spread-container__middle--columns">
          <InfiniteLoader
            isRowLoaded={({ index }) => {
              return !!plans[index];
            }}
            loadMoreRows={({ startIndex, stopIndex }) =>
              debounce(
                () => {
                  const baseDate = new Date();

                  const newStart = new Date(baseDate);
                  newStart.setDate(newStart.getDate() + plans.length);
                  if (startIndex === 0) {
                    newStart.setDate(newStart.getDate() - DAYS_PAST_TO_LOAD);
                  }

                  const newEnd = new Date(baseDate);
                  newEnd.setDate(newEnd.getDate() + stopIndex);

                  axios
                    .get(
                      `api/channels/${channelGuid}/plans/range?start_date=${format(newStart, "y-MM-dd")}&end_date=${format(newEnd, "y-MM-dd")}`,
                    )
                    .then((resp) => {
                      setPlans((prev) => getNewPlanData(prev, resp.data.plans));
                      if (startIndex === 0) {
                        scrollToPosition(SCHEDULER_WIDTH * DAYS_PAST_TO_LOAD);
                      }
                    });
                },
                1000,
                false,
                debounceRef.current,
              )
            }
            rowCount={9999} // arbitrary large number
            threshold={10}
          >
            {({ onRowsRendered, registerChild }) => (
              <React.Fragment>
                <button className="weekly-scheduler__scroll-button" onClick={() => scroll(-(SCHEDULER_WIDTH * 7))}>
                  <ArrowBackIosRoundedIcon />
                </button>
                <Grid
                  height={9999} // some big number
                  width={9999} // some big number
                  cellRenderer={({ key, style, columnIndex }) => (
                    <SimpleSchedulerColumn style={style} columnIndex={columnIndex} key={key} />
                  )}
                  columnWidth={SCHEDULER_WIDTH}
                  columnCount={plans.length + BUFFER * 2 + DAYS_PAST_TO_LOAD}
                  rowHeight={1000}
                  rowCount={1}
                  ref={(ref) => {
                    scrollRef.current = ref;
                    registerChild(ref);
                  }}
                  onSectionRendered={onSectionRendered.bind(null, onRowsRendered)}
                  style={{ height: "100%", width: "100%", scrollBehavior: "smooth" }}
                  containerStyle={{ height: "100%", maxHeight: "100%" }}
                />
                <button
                  className="weekly-scheduler__scroll-button weekly-scheduler__scroll-button--flip-y"
                  onClick={() => scroll(SCHEDULER_WIDTH * 7)}
                >
                  <ArrowBackIosRoundedIcon />
                </button>
              </React.Fragment>
            )}
          </InfiniteLoader>
        </div>
      </div>
    </SchedulerProvider>
  );
}

export default SchedulerWeeklyPage;
