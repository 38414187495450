import React from "react";

export default function useSpreadState(initialState = {}) {
  const [state, updateState] = React.useState(initialState);

  const update = React.useCallback((value) => {
    if (typeof value === "function") {
      updateState(value);
    } else {
      updateState((prev) => {
        return { ...prev, ...value };
      });
    }
  }, []);

  const hardUpdate = React.useCallback((value) => updateState(value), []);

  return [state, update, hardUpdate];
}
