import React from "react";
import ReactModal from "react-modal";
import Loader from "../../common/loader.jsx";
import { scheduleProgramsForChannel } from "../../requests/api-requests.js";
import { toast } from "react-toastify";

function PlayoutScheduleAllDialog({ playoutId, onClose, onSuccess, isOpen }) {
  const [isLoading, setIsLoading] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState({});

  function onSubmit() {
    setIsLoading(true);

    scheduleProgramsForChannel(playoutId)
      .then(() => {
        setTimeout(() => {
          onSuccess();
          onClose();
          toast.success("Program scheduling queued");
          setIsLoading(false);
        }, 5000);
      })
      .catch((error) => {
        console.error(error);
        if (error.status === 422) {
          formatApiErrors(error.data.errors);
        } else if (error.status === 500 || error.status === 404) {
          setErrorMessage({ api: "There was an error scheduling programs. Please contact support." });
        }
        setIsLoading(false);
      });
  }

  function formatApiErrors(errorObj) {
    let apiErrors = {};

    for (const key in errorObj) {
      if (errorObj.hasOwnProperty(key)) {
        apiErrors[key] = errorObj[key].join(". ");
      }
    }

    setErrorMessage(apiErrors);
  }

  return (
    <ReactModal isOpen={isOpen} ariaHideApp={false}>
      <div className="v-react-modal__inner modal-width">
        <div className="v-react-modal__heading">Run Schedule</div>
        <div className="v-react-modal__body modal-body">
          Are you sure you wish to schedule all unscheduled programs for this channel?
          {errorMessage.api ? <p className="u-fc--warning">{errorMessage.api}</p> : null}
        </div>
        <div className="v-react-modal__footer">
          {isLoading ? (
            <Loader width={45} height={45} />
          ) : (
            <React.Fragment>
              <button className="btn btn--inverse-primary" onClick={onClose}>
                Cancel
              </button>
              <button className="btn btn--primary" onClick={onSubmit}>
                Confirm
              </button>
            </React.Fragment>
          )}
        </div>
      </div>
    </ReactModal>
  );
}

export default PlayoutScheduleAllDialog;
