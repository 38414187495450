import React from "react";
import Select from "../../../common/select.jsx";

function ChannelPlaybackDropdown({ channel, activePlayback, onChange }) {
  const [playouts, setPlayouts] = React.useState([]);

  React.useEffect(() => {
    setPlayouts([
      { label: "Default", value: { url: channel.url, type: "hls" } },
      ...channel.playouts.map((playout) => ({
        label: `${playout.destination_reference}${!playout.playback_url ? " (No Url)" : ""}`,
        value: { url: playout.playback_url ?? "", type: playout.destination_output },
      })),
    ]);
  }, [channel.channel_id, channel.playouts, channel.url]); // or something

  return (
    <Select
      value={playouts.filter((playout) => playout.value.url === activePlayback.url)}
      placeholder={"Program Video"}
      name={"playback-video"}
      options={playouts}
      onChange={(input) => onChange(input.value)}
      containerClass="react-select-container--small react-select-container--light"
    />
  );
}

export default ChannelPlaybackDropdown;
