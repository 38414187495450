import { getKeyForUnknownContent, getTypeForContent } from "./accessors.js";
import { v4 } from "uuid";
import { getDurationInSecondsWithAdBreaks, getDurationWithAdBreaks } from "./create-ad-breaks.js";
import { isDate } from "date-fns";

export function toEpgChannel(channel) {
  return {
    uuid: channel.guid,
    type: "channel",
    title: channel.display_name,
    country: channel.regions,
    provider: channel.provider_guid,
    logo: channel.art?.square,
    year: 2002,
  };
}

export function toEpgProgram(program, channelGuid) {
  // example of an epg entry
  return {
    id: program[getKeyForUnknownContent(program, "guid")],
    since: "2023-04-24T00:00:00", // @todo
    till: "2022-10-17T23:50:00", // @todo

    // rest is meta data
    description: "Not availabe",
    title: program[getKeyForUnknownContent(program, "name")],
    isYesterday: false,
    channelUuid: channelGuid,
    image: "todo",
    country: "todo",
    Year: "todo",
    Rated: program.rating ? program.rating.rating : null,
    Released: "todo",
    Runtime: program.duration,
    Genre: "todo",
    Director: "unavailable",
    Writer: "unavailable",
    Actors: "unavailable",
    Language: "todo",
    Country: "todo",
    Awards: "todo",
    Metascore: "todo",
    imdbRating: "todo",
    imdbVotes: "todo",
    imdbID: "todo",
    Type: program.video.link_type,
    totalSeasons: "todo",
    Response: "True",
    // "Ratings": [{"Source": "Internet Movie Database", "Value": "8.0/10"}],
    // "rating": 3
  };
}

export function featureToEpg(feature) {
  const adBreaks = feature.cuepoints.map((contentCuepoint) => ({
    ad_break: contentCuepoint.ad_break,
    cuepoint: contentCuepoint.cuepoint,
  }));
  const runtime = getDurationWithAdBreaks(feature.trimmed_duration ?? feature.duration, adBreaks);
  return {
    id: v4(),
    title: feature.formatted_name,
    content_video: feature.content_video,
    isYesterday: false,
    Rated: feature.rating ? feature.rating.rating : "-",
    Runtime: runtime,
    Type: "feature",
    Response: "True",
    isActive: feature.feature_active ? true : false,
    description: feature.summary?.short_summary ?? "",
    Year: feature.release_year,
    Genre: feature.genres.map((genre) => genre.genre),
    __gstvMeta: {
      video_asset: feature.video,
      ad_breaks: adBreaks,
      duration_seconds: Math.floor(feature.trimmed_duration ?? feature.duration),
      total_duration_seconds: getDurationInSecondsWithAdBreaks(feature.trimmed_duration ?? feature.duration, adBreaks),
      original_duration_seconds: Math.floor(feature.duration),
      original_total_duration_seconds: getDurationInSecondsWithAdBreaks(feature.duration, adBreaks),
      original_ad_breaks: adBreaks,
      link_type: "feature",
      link_id: feature.feature_id,
      link_guid: feature.feature_guid,
      program_id: null,
    },
    // cannot be known
    since: null,
    till: null,
    channelUuid: null,
  };
}

export function episodeToEpg(episode) {
  const adBreaks = episode.cuepoints.map((contentCuepoint) => ({
    ad_break: contentCuepoint.ad_break,
    cuepoint: contentCuepoint.cuepoint,
  }));
  const runtime = getDurationWithAdBreaks(episode.trimmed_duration ?? episode.duration, adBreaks);
  return {
    id: v4(),
    title: episode.formatted_name,
    content_video: episode.content_video,
    isYesterday: false,
    Rated: episode.rating ? episode.rating.rating : "-",
    Runtime: runtime,
    Type: "episode",
    isActive: episode.episode_active ? true : false,
    Response: "True",
    description: episode.summary?.short_summary ?? "",
    Year: episode.release_year || "",
    Genre: episode.genres.map((genre) => genre.genre),
    __gstvMeta: {
      video_asset: episode.video,
      ad_breaks: adBreaks,
      duration_seconds: Math.floor(episode.trimmed_duration ?? episode.duration),
      total_duration_seconds: getDurationInSecondsWithAdBreaks(episode.trimmed_duration ?? episode.duration, adBreaks),
      original_duration_seconds: Math.floor(episode.duration),
      original_total_duration_seconds: getDurationInSecondsWithAdBreaks(episode.duration, adBreaks),
      original_ad_breaks: adBreaks,
      link_type: "episode",
      link_id: episode.episode_id,
      link_guid: episode.episode_guid,
      program_id: null,
    },
    // cannot be known
    since: null,
    till: null,
    channelUuid: null,
  };
}

export function promoToEpg(promo) {
  const adBreaks = [];
  const runtime = getDurationWithAdBreaks(promo.duration, adBreaks);
  return {
    id: v4(),
    title: promo.formatted_name,
    content_video: promo.content_video,
    isYesterday: false,
    Rated: "",
    Runtime: runtime,
    Type: "promo",
    isActive: promo.promo_active ? true : false,
    Response: "True",
    description: "",
    Year: "",
    Genre: "",
    __gstvMeta: {
      video_asset: promo.video,
      ad_breaks: adBreaks,
      duration_seconds: Math.floor(promo.duration),
      total_duration_seconds: getDurationInSecondsWithAdBreaks(promo.duration, adBreaks),
      original_duration_seconds: Math.floor(promo.duration),
      original_total_duration_seconds: getDurationInSecondsWithAdBreaks(promo.duration, adBreaks),
      original_ad_breaks: adBreaks,
      link_type: "promo",
      link_id: promo.promo_id,
      link_guid: promo.promo_guid,
      program_id: null,
    },
    // cannot be known
    since: null,
    till: null,
    channelUuid: null,
  };
}

export function programToSimpleEpg(planProgram) {
  let since = planProgram.program_start;
  if (!isDate(since)) {
    since = new Date(planProgram.program_start);
  }

  let till = planProgram.program_end;
  if (!isDate(till)) {
    till = new Date(planProgram.program_end);
  }

  return {
    since,
    till,
    title: planProgram.formatted_name ?? "Inactive Program",
    Type: planProgram.content_type,

    isActive: !!planProgram.formatted_name,
    total_duration_seconds: planProgram.total_duration_seconds,
    __gstvMeta: {
      video_asset: null,
      ad_breaks: planProgram.ad_breaks,
      duration_seconds: planProgram.duration_seconds,
      total_duration_seconds: planProgram.total_duration_seconds,
      original_duration_seconds: planProgram.original_duration_seconds,
      original_total_duration_seconds: planProgram.original_total_duration_seconds,
      original_ad_breaks: planProgram.original_ad_breaks,
      link_type: planProgram.content_type,
      link_id: planProgram.content_id,
      link_guid: planProgram.content_guid,
      program_id: planProgram.content_guid,
    },
  };
}

function programToEpg(channelId, planProgram) {
  let since = planProgram.program_start;
  if (!isDate(since)) {
    since = new Date(planProgram.program_start);
  }

  let till = planProgram.program_end;
  if (!isDate(till)) {
    till = new Date(planProgram.program_end);
  }

  // if program is inactive
  if (!planProgram.program) {
    return {
      id: planProgram.program_id,
      since,
      till,
      title: "Inactive Program",
      channelUuid: channelId,
      isYesterday: false,
      image: planProgram.program?.contentPoster?.large_url,
      isActive: false,
      Rated: "N/A",
      Runtime: planProgram.duration_seconds,
      Type: planProgram.link_type,
      Response: "True",
      description: "",
      Year: "",
      Genre: "",
      __gstvMeta: {
        video_asset: null,
        ad_breaks: planProgram.ad_breaks,
        duration_seconds: planProgram.duration_seconds,
        total_duration_seconds: planProgram.total_duration_seconds,
        original_duration_seconds: planProgram.original_duration_seconds,
        original_total_duration_seconds: planProgram.original_total_duration_seconds,
        original_ad_breaks: planProgram.original_ad_breaks,
        link_type: planProgram.link_type,
        link_id: planProgram.link_id,
        link_guid: planProgram.link_id,
        program_id: planProgram.program_id,
      },
    };
  }

  return {
    // example of an epg entry
    id: planProgram.program_id,
    since,
    till,
    title: planProgram.program.title?.title_name || planProgram.program.title,
    image: planProgram.program.contentPoster?.large_url,
    channelUuid: channelId,
    isYesterday: false,

    isActive: true,
    Rated: planProgram.program.rating.rating,
    Runtime: planProgram.duration_seconds,
    Type: planProgram.link_type,
    Response: "True",
    description: planProgram.program.summary ?? "",
    Year: planProgram.program.release_year,
    Genre: planProgram.program.genres ? planProgram.program.genres.map((genre) => genre.genre) : "",
    __gstvMeta: {
      video_asset: planProgram.program.video,
      ad_breaks: planProgram.ad_breaks,
      duration_seconds: planProgram.duration_seconds,
      total_duration_seconds: planProgram.total_duration_seconds,
      original_duration_seconds: planProgram.original_duration_seconds,
      original_total_duration_seconds: planProgram.original_total_duration_seconds,
      original_ad_breaks: planProgram.original_ad_breaks,
      link_type: planProgram.link_type,
      link_id: planProgram.link_id,
      link_guid: planProgram.link_id,
      program_id: planProgram.program_id,
    },
  };
}

export function planToEpg(channel, plan) {
  if (!channel && !plan) return { channel: {}, epg: [] };
  const conversion = programToEpg.bind(null, channel.channel_id);
  return {
    channel: {
      uuid: channel.channel_id,
      type: "channel",
      title: channel.display_name,
      country: channel.regions,
      provider: channel.provider_guid,
      logo: channel.art?.square,
    },
    epg: plan?.programs.map(conversion) ?? [],
  };
}

export function contentToEpg(content) {
  const type = getTypeForContent(content);
  switch (type) {
    case "feature":
      return featureToEpg(content);
    case "episode":
      return episodeToEpg(content);
    case "promo":
      return promoToEpg(content);
    default:
      throw new Error("Unknown content encountered");
  }
}
