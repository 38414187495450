import React from "react";

const editableTabs = ["feature", "episode", "series"];

export default function TabbedContent({ tabs }) {
  const [selected, setSelected] = React.useState(tabs[0].name);
  const renderActiveTab = React.useCallback(() => {
    let active = tabs.filter((tab) => tab.name === selected);
    if (!active || !active[0]) {
      active = tabs;
    }

    const props = active[0].props ?? {};
    const Component = active[0].component;

    return <Component {...props} />;
  }, [selected, tabs]);

  return (
    <div className="tabbed-content">
      <div className="tabbed-content__tab-list">
        {tabs.map((tab) => (
          <div className="tabbed-content__tab-list__tab" key={tab.name}>
            <button
              className={`tabbed-content__tab ${selected === tab.name ? "tabbed-content__tab--active" : ""}`}
              onClick={() => setSelected(tab.name)}
              type="button"
            >
              <span className="tabbed-content__tab__label">{tab.label}</span>
            </button>
          </div>
        ))}
      </div>
      <div
        className={`tabbed-content__content ${editableTabs.includes(selected) ? "tabbed-content__content--has-actions" : ""}`}
      >
        {renderActiveTab()}
      </div>
    </div>
  );
}
