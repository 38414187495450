import Select from "../common/select.jsx";
import React from "react";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";

function Pagination({ onPageClick, prevUrl, nextUrl, pageSize, onPageSizeClick, isSmall }) {
  return (
    <div className={`pagination-controls ${isSmall ? "pagination-controls--small" : ""}`}>
      <div className="pagination-controls__prev">
        <button onClick={() => onPageClick({ url: prevUrl })} className="btn btn--text-primary" disabled={!prevUrl}>
          <ChevronLeftRoundedIcon />
        </button>
      </div>
      <div className="pagination-controls__size">
        <Select
          placeholder={pageSize}
          name={"page_size"}
          options={[
            { value: "20", label: "20" },
            { value: "50", label: "50" },
            { value: "100", label: "100" },
          ]}
          onChange={(input) => onPageSizeClick(input.value)}
          menuPlacement="auto"
          containerClass="react-select-container--small"
        />
      </div>
      <div className="pagination-controls__next">
        <button onClick={() => onPageClick({ url: nextUrl })} className="btn btn--text-primary" disabled={!nextUrl}>
          <ChevronRightRoundedIcon />
        </button>
      </div>
    </div>
  );
}

export default Pagination;
