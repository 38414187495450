import React from "react";
import ReactModal from "react-modal";
import Loader from "../../../common/loader";
import { toast } from "react-toastify";
import ProviderForm from "./provider-form";

function EditProviderDialog({ isOpen, onClose, onSuccess, provider }) {
  const [isLoading, setIsLoading] = React.useState(false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  function beginSubmit() {
    setIsLoading(true);
    setIsSubmitting(true);
  }

  function endSubmit(success = false) {
    setIsLoading(false);
    setIsSubmitting(false);

    if (success) {
      onClose();
      toast.success("Changes Saved");
      setTimeout(onSuccess, 1000);
    }
  }

  return (
    <ReactModal isOpen={isOpen} ariaHideApp={false}>
      <div className="v-react-modal__inner modal-width">
        <div className="v-react-modal__heading">{`Editing ${provider ? provider.provider_name : ""}`}</div>
        <div className="v-react-modal__body">
          <ProviderForm action="edit" onComplete={endSubmit} submitting={isSubmitting} provider={provider} />
        </div>
        <div className="v-react-modal__footer" style={{ marginTop: "4px" }}>
          {isLoading ? (
            <Loader width={45} height={45} />
          ) : (
            <React.Fragment>
              <button className="btn btn--inverse-primary" onClick={onClose} key="new-provider-dialog-cancel">
                Cancel
              </button>
              <button className="btn btn--primary" onClick={beginSubmit} key="new-provider-dialog-submit">
                Save Changes
              </button>
            </React.Fragment>
          )}
        </div>
      </div>
    </ReactModal>
  );
}

export default EditProviderDialog;
