import React from "react";
import ReactModal from "react-modal";
import Loader from "../../../common/loader.jsx";
import { deleteProvider, restoreProvider } from "../../../requests/providers.js";
import { toast } from "react-toastify";

function RestoreOrRemoveProviderDialog({ id, message, onClose, onSuccess, action, isOpen }) {
  const [isLoading, setIsLoading] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");

  function onSubmit() {
    setIsLoading(true);
    if (action === "delete") {
      deleteProvider(id)
        .then(() => {
          onClose();
          toast.success("Provider deleted successfully.");
          setTimeout(onSuccess, 1000);
        })
        .catch((error) => {
          console.error(error);
          setErrorMessage("There was an error deleting this Provider. Please contact support.");
        })
        .finally(() => setIsLoading(false));
    } else if (action === "restore") {
      restoreProvider(id)
        .then(() => {
          onClose();
          toast.success("Provider restored successfully.");
          setTimeout(onSuccess, 1000);
        })
        .catch((error) => {
          console.error(error);
          setErrorMessage("There was an error restoring this Provider. Please contact support.");
        })
        .finally(() => setIsLoading(false));
    } else {
      setIsLoading(false);
    }
  }

  return (
    <ReactModal isOpen={isOpen} ariaHideApp={false}>
      <div className="v-react-modal__inner modal-width">
        <div className="v-react-modal__heading">{action === "delete" ? "Delete Provider" : "Restore Provider"}</div>
        <div className="v-react-modal__body">
          {message}
          {errorMessage ? <p className="u-fc--warning">{errorMessage}</p> : null}
        </div>
        <div className="v-react-modal__footer">
          {isLoading ? (
            <Loader width={45} height={45} />
          ) : (
            <React.Fragment>
              <button className="btn btn--inverse-primary" onClick={onClose}>
                Cancel
              </button>
              <button className="btn btn--primary" onClick={onSubmit}>
                Confirm
              </button>
            </React.Fragment>
          )}
        </div>
      </div>
    </ReactModal>
  );
}

export default RestoreOrRemoveProviderDialog;
