import axios from "./axios";

export const BASE_URL = import.meta.env.VITE_API_BASE_URL;

export async function addOrganisation(payload) {
  const response = await axios.post(`${BASE_URL}/api/organisations`, payload);
  return response;
}

export async function deleteOrganisation(organisationGuid) {
  const response = await axios.delete(`${BASE_URL}/api/organisations/${organisationGuid}`);
  return response;
}

export async function restoreOrganisation(organisationGuid) {
  const response = await axios.post(`${BASE_URL}/api/organisations/${organisationGuid}/restore`);
  return response;
}

export async function updateOrganisation(organisationGuid, payload) {
  const response = await axios.patch(`${BASE_URL}/api/organisations/${organisationGuid}`, payload);
  return response;
}
