import axios from "./axios";
import fetcher from "../common/fetcher";

export const BASE_URL = import.meta.env.VITE_API_BASE_URL;

export async function getAllProviders(withProviders = "") {
  const data = await fetcher(`${BASE_URL}/api/providers${withProviders === "all" ? "?active=all" : ""}`);
  return data;
}

export async function addProvider(payload) {
  const response = await axios.post(`${BASE_URL}/api/providers`, payload);
  return response;
}

export async function deleteProvider(providerGuid) {
  const response = await axios.delete(`${BASE_URL}/api/providers/${providerGuid}`);
  return response;
}

export async function restoreProvider(providerGuid) {
  const response = await axios.post(`${BASE_URL}/api/providers/${providerGuid}/restore`);
  return response;
}

export async function updateProvider(providerGuid, payload) {
  const response = await axios.patch(`${BASE_URL}/api/providers/${providerGuid}`, payload);
  return response;
}
