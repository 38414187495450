import React from "react";
import { embedDashboard } from "@preset-sdk/embedded";
import axios from "../requests/axios.js";
import { toast } from "react-toastify";
import { AppHeading } from "../layout/parts/app-heading.jsx";
import AppBody from "../layout/parts/app-body.jsx";

function AnalyticsPage() {
  const [dashboard, setDashboard] = React.useState();
  const dashboardRef = React.useRef();

  React.useEffect(() => {
    axios
      .get("/api/analytics/reports")
      .then((resp) => {
        setDashboard({
          dashboard_id: resp.data.dashboard_id,
          token: resp.data.token,
          domain: resp.data.domain,
        });
      })
      .catch((err) => {
        console.error(err);
        toast.warn("Unable to fetch Dashboard");
      });
  }, []);

  React.useEffect(() => {
    const embed = async () => {
      await embedDashboard({
        id: dashboard.dashboard_id,
        supersetDomain: dashboard.domain,
        mountPoint: dashboardRef.current,
        fetchGuestToken: () => dashboard.token,
        dashboardUiConfig: {
          hideTitle: true,
          hideChartControls: false,
          hideTab: true,
        },
      });
    };

    if (dashboardRef.current && dashboard?.token) {
      embed();
    }
  }, [dashboard?.domain, dashboard?.token, dashboard?.dashboard_id]);

  return (
    <React.Fragment>
      <AppHeading
        breadcrumbs={[
          {
            link: "",
            title: "Analytics Reports",
          },
        ]}
      />
      <AppBody>
        <div className="analytics">
          <div className="analytics__dashboard" ref={dashboardRef}></div>
        </div>
      </AppBody>
    </React.Fragment>
  );
}

export default AnalyticsPage;
