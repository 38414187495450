import { addDays, isBefore, setMonth, setYear } from "date-fns";

export default function programTimeCorrection(plan, start, end) {
  const ymd = plan.split("-");
  const year = ymd[0];
  const month = parseInt(ymd[1]) - 1;
  const day = ymd[2];

  const since = start;
  const till = end;
  let program_start = setMonth(setYear(since, year), month);
  program_start.setDate(day);

  let program_end = setMonth(setYear(till, year), month);
  program_end.setDate(day);

  if (isBefore(program_end, program_start)) {
    program_end = addDays(program_end, 1);
  }

  return {
    program_start,
    program_end,
  };
}
