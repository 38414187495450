import React from "react";
import axios from "../../../requests/axios.js";
import { toast } from "react-toastify";
import formatApiErrors from "../../../functions/format-api-errors.js";
import ReactModal from "react-modal";
import Loader from "../../../common/loader.jsx";

export default function ChangeVideoSourceDialog({ contentType, contentGuid, onClose, isOpen }) {
  const [loading, setLoading] = React.useState(false);
  const [errors, setErrors] = React.useState({});
  const [source, setSource] = React.useState("");

  function onSubmit() {
    setLoading(true);

    axios
      .patch(`/api/${contentType}s/${contentGuid}/video`, { source })
      .then(() => {
        toast.success("Video change initiated, please wait for the relevant analysis and transcoding jobs to complete");
        onClose(true);
      })
      .catch((error) => {
        console.error(error);
        if (error.status === 422) {
          setErrors(formatApiErrors(error.data.errors || error.data.error, true));
        } else if (error.status === 500 || error.status === 404) {
          setErrors({ api: "There was unexpected error. Please contact support." });
        }
      })
      .finally(() => setLoading(false));
  }

  return (
    <ReactModal isOpen={isOpen} ariaHideApp={false}>
      <div className="v-react-modal__inner modal-width">
        <div className="v-react-modal__heading">Change Source Video</div>
        <div className="v-react-modal__body modal-body">
          <div className="u-margin--block-end">
            Once the process is completed, the content will be removed from <b>all future schedules</b> it may appear in
            <br />
            This change requires that this content has not been scheduled for the next 48 hours
            <br />
            {errors.api ? <p className="u-fc--warning">{errors.api}</p> : null}
          </div>
          <div className="form-field">
            <label>New Source</label>
            <input
              type="text"
              name="source"
              placeholder="https:// or s3://"
              onChange={(e) => setSource(e.target.value)}
              value={source}
            />
            {errors.hasOwnProperty("source") ? <div className="form-field__error">{errors.source}</div> : null}
          </div>
        </div>
        <div className="v-react-modal__footer">
          {loading ? (
            <Loader width={45} height={45} />
          ) : (
            <React.Fragment>
              <button className="btn btn--inverse-primary" onClick={onClose}>
                Cancel
              </button>
              <button className="btn btn--primary" onClick={onSubmit}>
                Confirm
              </button>
            </React.Fragment>
          )}
        </div>
      </div>
    </ReactModal>
  );
}
