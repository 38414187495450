import React from "react";

export default function useSelectSourceListeners({
  playerId,
  availableSources,
  onSelectSource,
  onSelectNormalised,
  onSelectHls,
  onSelectDash,
}) {
  const initSelectSourceListeners = React.useCallback(() => {
    const control = document.querySelector("[data-plyr='select-source']");
    const sourceSource = document.querySelector("[data-plyr='select-source-dropdown__source']");
    const sourceNormalised = document.querySelector("[data-plyr='select-source-dropdown__normalised']");
    const sourceHls = document.querySelector("[data-plyr='select-source-dropdown__hls']");
    const sourceDash = document.querySelector("[data-plyr='select-source-dropdown__dash']");

    if (control && availableSources.source && sourceSource) {
      sourceSource.classList.add("plyr__select-source-dropdown__item--active");
      sourceSource.addEventListener("click", onSelectSource);
    }

    if (control && availableSources.normalised && sourceNormalised) {
      sourceNormalised.classList.add("plyr__select-source-dropdown__item--active");
      sourceNormalised.addEventListener("click", onSelectNormalised);
    }

    if (control && availableSources.hls && sourceHls) {
      sourceHls.classList.add("plyr__select-source-dropdown__item--active");
      sourceHls.addEventListener("click", onSelectHls);
    }

    if (control && availableSources.dash && sourceDash) {
      sourceDash.classList.add("plyr__select-source-dropdown__item--active");
      sourceDash.addEventListener("click", onSelectDash);
    }
  }, [
    availableSources.dash,
    availableSources.hls,
    availableSources.normalised,
    availableSources.source,
    onSelectDash,
    onSelectHls,
    onSelectNormalised,
    onSelectSource,
  ]);

  const destroySelectSourceListeners = React.useCallback(() => {
    const control = document.querySelector("[data-plyr='select-source']");
    const sourceSource = document.querySelector("[data-plyr='select-source-dropdown__source']");
    const sourceNormalised = document.querySelector("[data-plyr='select-source-dropdown__normalised']");
    const sourceHls = document.querySelector("[data-plyr='select-source-dropdown__hls']");
    const sourceDash = document.querySelector("[data-plyr='select-source-dropdown__dash']");

    if (control && availableSources.source && sourceSource) {
      sourceSource.classList.remove("plyr__select-source-dropdown__item--active");
      sourceSource.removeEventListener("click", onSelectSource);
    }
    if (control && availableSources.normalised && sourceNormalised) {
      sourceNormalised.classList.remove("plyr__select-source-dropdown__item--active");
      sourceNormalised.removeEventListener("click", onSelectNormalised);
    }
    if (control && availableSources.hls && sourceHls) {
      sourceHls.classList.remove("plyr__select-source-dropdown__item--active");
      sourceHls.removeEventListener("click", onSelectHls);
    }
    if (control && availableSources.dash && sourceDash) {
      sourceDash.classList.remove("plyr__select-source-dropdown__item--active");
      sourceDash.removeEventListener("click", onSelectDash);
    }
  }, [
    availableSources.dash,
    availableSources.hls,
    availableSources.normalised,
    availableSources.source,
    onSelectDash,
    onSelectHls,
    onSelectNormalised,
    onSelectSource,
  ]);

  React.useEffect(() => {
    initSelectSourceListeners();

    return () => {
      destroySelectSourceListeners();
    };
  }, [playerId, destroySelectSourceListeners, initSelectSourceListeners]);
}
