import React from "react";
import { DashPlayer } from "../components/player/player";
import ContentCard from "././library/components/content-card";
import { getDashVideos } from "../requests/api-requests";
import { toast } from "react-toastify";
import Loader from "../common/loader.jsx";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import { AppHeading } from "../layout/parts/app-heading.jsx";
import AppBody from "../layout/parts/app-body.jsx";

function DashQcPage() {
  const [videos, setVideos] = React.useState([]);
  const [activeVideo, setActiveVideo] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(true);
  const [searchQ, setSearchQ] = React.useState("");

  React.useEffect(() => {
    setIsLoading(true);

    getDashVideos()
      .then((response) => {
        setVideos(response.data);
      })
      .catch((error) => {
        toast.error("There was an error loading the dash videos.");
        console.error(error);
      })
      .finally(() => setIsLoading(false));
  }, []);

  function searchFilter(list, q) {
    return list.filter((item) => item.name.toLowerCase().indexOf(q.toLowerCase()) > -1);
  }

  return (
    <React.Fragment>
      <AppHeading
        breadcrumbs={[
          {
            link: "/admin/",
            title: "Admin",
          },
          {
            link: "/admin/dash",
            title: "Dash Quality Control",
          },
        ]}
      />
      <AppBody>
        <div className="gw gw--full-height">
          <div className="g g--full-height g--1-of-3">
            <ContentCard>
              <div className="content-list-filter__group content-list-filter__group--fixed">
                <div className="input-group input-group--with-icon content-list-filter__search">
                  <input
                    type="text"
                    className="app-text-input"
                    onChange={(e) => setSearchQ(e.target.value)}
                    value={searchQ}
                  />
                  <SearchRoundedIcon />
                </div>
              </div>

              {isLoading ? (
                <div className="page-loader-wrapper">
                  <Loader />
                </div>
              ) : (
                <div className="program-field__metadata">
                  {searchFilter(videos, searchQ).map((video, index) => (
                    <div
                      className="program-field__label program-field__value program-field__value--link"
                      onClick={() =>
                        setActiveVideo({
                          name: video.name,
                          stream_url: video.stream_url,
                        })
                      }
                      key={index}
                    >
                      {video.name}
                    </div>
                  ))}
                </div>
              )}
            </ContentCard>
          </div>
          <div className="g g--full-height g--2-of-3">
            {isLoading ? (
              <div className="page-loader-wrapper">
                <Loader />
              </div>
            ) : (
              <div className="program-field__video">
                <DashPlayer
                  source={activeVideo?.stream_url || videos[0]?.stream_url}
                  title={activeVideo?.name || videos[0]?.name}
                  editControls={false}
                />
              </div>
            )}
          </div>
        </div>
      </AppBody>
    </React.Fragment>
  );
}

export default DashQcPage;
