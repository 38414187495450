import React from "react";
import Draggable from "react-draggable";
import Loader from "../common/loader.jsx";
import { getContentByType } from "../requests/api-requests.js";
import ContentPlayer from "./player/content-player.jsx";
import { formatCuepoints } from "../common/cuepoints.js";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import AvTimerIcon from "@mui/icons-material/AvTimer";
import LiveTvIcon from "@mui/icons-material/LiveTv";
import FaceIcon from "@mui/icons-material/Face";
import PaletteIcon from "@mui/icons-material/Palette";
import { secondsToHourMinutesSeconds } from "../common/duration-formatting.js";

function _DraggableContentBox({ visible, onClose, content }) {
  const [loading, setLoading] = React.useState(true);
  const [data, setData] = React.useState({});
  const [playerProgram, setPlayerProgram] = React.useState({});

  React.useEffect(() => {
    if (content?.id && content?.type) {
      setLoading(true);
      setPlayerProgram({});

      // load content
      const additional = content.type === "series" ? ["episodes"] : [];
      getContentByType(content.type, content.id, additional)
        .then((program) => {
          program.error = false; // component error tracking
          setData(program);
          if (content.type !== "series") {
            setPlayerProgram(program);
          }
        })
        .catch((e) => {
          console.error(e);
          setData({ error: true });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [content?.id, content?.type]);

  function getPlayerOptionsForProgram(program) {
    return {
      cuepoints: program.cuepoints ? formatCuepoints(program.cuepoints) : [],
      trimPoint: program.trimmed_duration,
      controls: {
        editCuepoints: false,
        playoutTrimming: false,
        changeSource: false,
        selectSource: true,
        timers: true,
      },

      availableSources: {
        source: !!program.source_video?.asset_id,
        normalised: !!program.content_video?.asset_id,
        hls: !!program.content_video?.hls_path,
        dash: !!program.content_video?.dash_path,
      },
    };
  }

  if (!visible) {
    return null;
  }

  const timeInfo = Object.keys(playerProgram).length
    ? {
        ad_time: playerProgram?.cuepoints ? secondsToHourMinutesSeconds(playerProgram.cuepoints.length * 122) : 0,
        duration: secondsToHourMinutesSeconds(playerProgram.trimmed_duration ?? playerProgram.duration),
      }
    : {};

  return (
    <Draggable handle="#js-draggable-content-box-handle" defaultPosition={{ x: 0, y: 0 }} position={null} scale={1}>
      <div className={`draggable-content-box ${content.type === "series" ? "draggable-content-box--wide" : ""}`}>
        <div className="draggable-content-box__header" id={"js-draggable-content-box-handle"}>
          <div className={"draggable-content-box__header__title"}>
            <a
              href={
                content.type === "episode"
                  ? `content/library/series/${data.season?.series?.series_guid}/episode/${data.episode_guid}`
                  : `content/library/${content.type}/${content.id}`
              }
            >
              {content.title}
            </a>
          </div>
          <div className={"draggable-content-box__header__actions"}>
            <button onClick={() => onClose()} className={"btn btn--icon"}>
              <CancelRoundedIcon />
            </button>
          </div>
        </div>
        <div className="draggable-content-box__body">
          {loading ? (
            <div className="draggable-content-box__loader">
              <Loader />
            </div>
          ) : null}

          {!loading && data.error ? <div className="draggable-content-box__error">Something went wrong...</div> : null}

          {!loading && !data.error && playerProgram !== null ? (
            <div className="draggable-content-box__player">
              <ContentPlayer program={playerProgram} playerOptions={getPlayerOptionsForProgram(playerProgram)} />
            </div>
          ) : null}
        </div>

        {!loading && !data.error && content.type === "series" && data.seasons ? (
          <div className="draggable-content-box__aside">
            <ul className="draggable-content-box__list">
              {data.seasons.map((season) => (
                <li key={season.season_guid} className="draggable-content-box__list__item">
                  Season {season.season_number}
                  <ul className="draggable-content-box__list">
                    {season.episodes.map((episode) => (
                      <li
                        key={episode.episode_guid}
                        className={`draggable-content-box__list__item ${episode.episode_guid === playerProgram?.episode_guid ? "draggable-content-box__list__item--active" : ""}`}
                      >
                        <button className="btn--text-primary" onClick={() => setPlayerProgram(episode)}>
                          Episode {episode.episode_number}
                        </button>
                      </li>
                    ))}
                  </ul>
                </li>
              ))}
            </ul>
          </div>
        ) : null}

        <div className="draggable-content-box__meta">
          <div className="program-information__content-summary">
            <div className="program-information__content-summary__block" aria-label="Duration" data-balloon-pos="up">
              <div className="program-information__content-summary__icon">
                <AvTimerIcon />
              </div>
              <div className="program-information__content-summary__label">{timeInfo.duration ?? "N/A"}</div>
            </div>
            <div className="program-information__content-summary__block" aria-label="Total Ads" data-balloon-pos="up">
              <div className="program-information__content-summary__icon">
                <LiveTvIcon />
              </div>
              <div className="program-information__content-summary__label">{timeInfo.ad_time ?? "N/A"}</div>
            </div>
            <div className="program-information__content-summary__block" aria-label="Rating" data-balloon-pos="up">
              <div className="program-information__content-summary__icon">
                <FaceIcon />
              </div>
              <div className="program-information__content-summary__label">
                {(Object.keys(playerProgram).length ? playerProgram : data).rating?.us_tv_rating ?? "N/A"}
              </div>
            </div>
            <div className="program-information__content-summary__block" aria-label="Genre" data-balloon-pos="up">
              <div className="program-information__content-summary__icon">
                <PaletteIcon />
              </div>
              <div className="program-information__content-summary__label">
                {(Object.keys(playerProgram).length ? playerProgram : data).genre?.imdb?.imdb_genre?.label ?? "N/A"}
              </div>
            </div>
          </div>

          <h3 className="draggable-content-box__meta__summary-header">Short Summary</h3>
          {(Object.keys(playerProgram).length ? playerProgram : data).summary?.short_summary || "None"}
        </div>
      </div>
    </Draggable>
  );
}

const DraggableContentBox = React.memo(_DraggableContentBox, (prev, next) => {
  return (
    prev.visible === next.visible && prev.content?.type === next.content?.type && prev.content?.id === next.content?.id
  );
});

export default DraggableContentBox;
