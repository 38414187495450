import React from "react";
import { getAsset } from "../../../requests/api-requests.js";
import Hls from "hls.js";

function StreamPlayer({
  liveStreamUrl = null,
  alternateStreamAssetId = null,
  streamTitle = "",
  playerSize = "",
  noTitle = false,
}) {
  const [alternateStreamUrl, setAlternateStreamUrl] = React.useState(null);
  const videoElement = React.useRef();
  const playerDiv = React.useRef(null);
  let hlsInstance = React.useRef(null);

  React.useEffect(() => {
    if (alternateStreamAssetId) {
      getAsset(alternateStreamAssetId)
        .then((r) => {
          setAlternateStreamUrl(r.source_url);
        })
        .catch((e) => new Error(e));
    }
  }, [alternateStreamAssetId]);

  React.useEffect(() => {
    if (videoElement.current) {
      if (liveStreamUrl && videoElement.current) {
        if (hlsInstance.current) {
          hlsInstance.current.destroy();
        }
        hlsInstance.current = new Hls();
        hlsInstance.current.loadSource(liveStreamUrl);
        hlsInstance.current.attachMedia(videoElement.current);
        hlsInstance.current.on(Hls.Events.MANIFEST_PARSED, function () {
          videoElement.current.play();
        });
      } else if (alternateStreamUrl) {
        videoElement.current.src = alternateStreamUrl;
        videoElement.current.play();
      }
    }

    return () => {
      if (hlsInstance.current) {
        hlsInstance.current.destroy();
      }
    };
  }, [alternateStreamUrl, liveStreamUrl]);

  const onResize = () => {
    if (!playerDiv.current) {
      return;
    }
    const { width, height } = playerDiv.current.getBoundingClientRect();
    // always use width, unless there is not enough height
    if (width / height > 16 / 9) {
      playerDiv.current.classList.remove("channel-player__video--constrained-width");
    } else {
      playerDiv.current.classList.add("channel-player__video--constrained-width");
    }
  };

  React.useLayoutEffect(() => {
    onResize();
    window.addEventListener("resize", onResize);
    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);

  return (
    <div className={`channel-player ${playerSize ? `channel-player--${playerSize}` : null}`}>
      {!noTitle ? <div className="channel-player__title">{streamTitle}</div> : null}
      <div className={`channel-player__video ${noTitle ? "channel-player__video--no-title" : ""}`} ref={playerDiv}>
        <video id="js-channel-player" controls muted ref={videoElement}></video>
      </div>
    </div>
  );
}

export default StreamPlayer;
