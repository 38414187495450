import React from "react";
import Select from "../../../common/select";
import { addUser, updateUser, getAllRoles, getAllOrganisations } from "../../../requests/api-requests";

function UserForm({ action, onComplete, submitting = false, user = {} }) {
  const [roles, setRoles] = React.useState([]);
  const [organisations, setOrganisations] = React.useState([]);
  const [form, setForm] = React.useState({});
  const [errors, setErrors] = React.useState({});

  const verifyForm = React.useCallback(() => {
    let formErrors = {};

    if (!form.first_name) {
      formErrors = { ...formErrors, first_name: "First name is required." };
    }

    if (!form.last_name) {
      formErrors = { ...formErrors, last_name: "Last name is required." };
    }

    if (!form.email) {
      formErrors = { ...formErrors, email: "Email is required." };
    }

    // validation checks on create only
    if (action === "create") {
      if (!form.password) {
        formErrors = { ...formErrors, password: "Please enter a new password." };
      }

      if (form.password && !form.confirm_password) {
        formErrors = { ...formErrors, confirm_password: "Please confirm your password." };
      }

      if (form.password && form.confirm_password && form.password !== form.confirm_password) {
        formErrors = { ...formErrors, password_match: "Passwords must match" };
      }
    }

    return formErrors;
  }, [action, form.first_name, form.last_name, form.email, form.password, form.confirm_password]);

  React.useEffect(() => {
    setErrors({});
    getAllRoles()
      .then((response) => {
        setRoles(response);
      })
      .catch((e) => {
        console.error(e);
        formatApiErrors(e.data.errors);
      });
  }, []);

  React.useEffect(() => {
    getAllOrganisations()
      .then((response) => {
        setOrganisations(response.data);
      })
      .catch((e) => {
        console.error(e);
        formatApiErrors(e.data.errors);
      });
  }, []);

  React.useEffect(() => {
    if (action === "edit") {
      setForm({
        first_name: user.first,
        last_name: user.last,
        email: user.email,
        organisation_id: user.organisation_id,
        roles: user.roles.map((role) => role.name),
      });
    }
  }, [action, user]);

  React.useEffect(() => {
    if (submitting) {
      function onSubmit() {
        setErrors({});

        const formErrors = verifyForm();

        if (Object.keys(formErrors).length) {
          setErrors(formErrors);
          onComplete(false);
        } else if (action === "create") {
          addUser(form)
            .then(() => {
              onComplete(true);
            })
            .catch((error) => {
              console.error(error);
              if (error.status === 422) {
                formatApiErrors(error.data.errors);
              } else if (error.status === 500 || error.status === 404) {
                setErrors({ api: "There was an error creating this user. Please contact support." });
              }
              onComplete(false);
            });
        } else if (action === "edit") {
          updateUser(user.user_guid, form)
            .then(() => {
              onComplete(true);
            })
            .catch((error) => {
              console.error(error);
              if (error.status === 422) {
                formatApiErrors(error.data.errors);
              } else if (error.status === 500 || error.status === 404) {
                setErrors({ api: "There was an error editing this user. Please contact support." });
              }
              onComplete(false);
            });
        }
      }

      onSubmit();
    }
  }, [submitting, action, form, onComplete, user.user_guid, verifyForm]);

  function formatApiErrors(errorObj) {
    let apiErrors = {};

    for (const key in errorObj) {
      if (errorObj.hasOwnProperty(key)) {
        apiErrors[key] = errorObj[key].join(". ");
      }
    }

    setErrors(apiErrors);
  }

  function handleChange(key, e) {
    setForm((prev) => ({
      ...prev,
      [key]: e.target.value,
    }));
  }

  function handleRoleChange(options) {
    setForm((prev) => ({
      ...prev,
      roles: options.map((listRole) => listRole.value),
    }));
  }

  function handleOrganisationChange(option) {
    setForm((prev) => ({
      ...prev,
      organisation_id: option.value,
    }));
  }
  return (
    <form>
      <div className="form-field">
        <label>First Name</label>
        <input
          type="text"
          name="first_name"
          onChange={handleChange.bind(null, "first_name")}
          defaultValue={action === "edit" ? form.first_name : ""}
        />
        {errors.hasOwnProperty("first_name") ? <div className="form-field__error">{errors.first_name}</div> : null}
      </div>

      <div className="form-field">
        <label>Last Name</label>
        <input
          type="text"
          name="last_name"
          onChange={handleChange.bind(null, "last_name")}
          defaultValue={action === "edit" ? form.last_name : ""}
        />
        {errors.hasOwnProperty("last_name") ? <div className="form-field__error">{errors.last_name}</div> : null}
      </div>

      <div className="form-field">
        <label>Email</label>
        <input
          type="text"
          name="email"
          onChange={handleChange.bind(null, "email")}
          defaultValue={action === "edit" ? form.email : ""}
        />
        {errors.hasOwnProperty("email") ? <div className="form-field__error">{errors.email}</div> : null}
      </div>

      {action === "create" ? (
        <React.Fragment>
          <div className="form-field">
            <label>Password</label>
            <input type="password" name="password" onChange={handleChange.bind(null, "password")} />
            {errors.hasOwnProperty("password") ? <div className="form-field__error">{errors.password}</div> : null}
          </div>

          <div className="form-field">
            <label>Confirm Password</label>
            <input type="password" name="confirm_password" onChange={handleChange.bind(null, "confirm_password")} />
            {errors.hasOwnProperty("confirm_password") ? (
              <div className="form-field__error">{errors.confirm_password}</div>
            ) : null}
            {errors.hasOwnProperty("password_match") ? (
              <div className="form-field__error">{errors.password_match}</div>
            ) : null}
          </div>
        </React.Fragment>
      ) : null}

      <div className="form-field">
        <label>Organisation</label>
        <Select
          placeholder={"Organisation"}
          name={"organisation"}
          onChange={handleOrganisationChange}
          defaultValue={
            action === "edit" && Object.keys(user).length && user?.organisation
              ? { label: user.organisation.display_name, value: user.organisation.id }
              : null
          }
          options={organisations.map((org) => ({ label: org.display_name, value: org.id }))}
          isSearchable={false}
          classNamePrefix="react-select"
          menuPortalTarget={document.getElementById("ReactSelectPortal")}
        />
        {errors.hasOwnProperty("organisation_id") ? (
          <div className="form-field__error">{errors.organisation_id}</div>
        ) : null}
      </div>

      <div className="form-field">
        <label>Roles</label>
        <Select
          placeholder={"Roles"}
          name={"roles"}
          onChange={handleRoleChange}
          defaultValue={
            action === "edit" && Object.keys(user).length && user?.roles
              ? user.roles.map((role) => ({ label: role.display_name, value: role.name }))
              : null
          }
          isMulti
          options={roles.map((role) => ({ label: role.display_name, value: role.name }))}
          isSearchable={false}
          classNamePrefix="react-select"
          menuPortalTarget={document.getElementById("ReactSelectPortal")}
        />
        {errors.hasOwnProperty("roles") ? <div className="form-field__error">{errors.roles}</div> : null}
      </div>
      {errors.hasOwnProperty("api") ? <p className="u-fc--warning">{errors.api}</p> : null}
    </form>
  );
}

export default UserForm;
