import React from "react";
import debounce from "../../common/debounce.js";
import { getContent, getContentFromPaginationUrl } from "../../requests/api-requests.js";
import { toast } from "react-toastify";

function useContentList({ planDate = null, channelId = null }) {
  const [programs, setPrograms] = React.useState([]);
  const [requestedUrl, setRequestedUrl] = React.useState();
  const [isLoading, setLoading] = React.useState(false);
  const latestRequestId = React.useRef(1);

  const getPrograms = React.useCallback(
    ({
      content,
      search,
      pageSize,
      url,
      provider,
      genres = [],
      subgenres = [],
      ratings = [],
      seriesGuid = null,
      seasonGuid = null,
      minDuration = null,
      maxDuration = null,
      sorting = [],
      displayInactive = false,
      displayRestricted = false,
    }) => {
      setLoading(true);
      latestRequestId.current = latestRequestId.current + 1;

      debounce(async () => {
        let result;
        const updateComparison = latestRequestId.current;

        try {
          result = url
            ? await getContentFromPaginationUrl(url)
            : await getContent({
                content,
                search,
                provider,
                pageSize,
                genres,
                subgenres,
                ratings,
                seriesGuid,
                seasonGuid,
                minDuration,
                maxDuration,
                sorting,
                planDate,
                displayInactive,
                displayRestricted,
                channelId,
              });

          if (latestRequestId.current === updateComparison) {
            const newContent = result.data.map((program) => {
              if (!program) {
                return null;
              }

              program.formatted_name = program.formatted_name ?? program.series_name;

              if (
                !program.feature_active &&
                !program.episode_active &&
                !program.promo_active &&
                !program.series_active
              ) {
                program.formatted_name += " (Inactive)";
              }

              return program;
            });
            result.data = newContent;

            setRequestedUrl(result.uri);
            setPrograms(result);
          }
        } catch (e) {
          // no error handling for now
          console.error(e);
          toast.error("Something went wrong when trying to reach the API");
        } finally {
          setLoading(false);
        }
      }, 300);
    },
    [setPrograms, setLoading, planDate, channelId],
  );

  return { programs, isLoading, getPrograms, requestedUrl };
}

export default useContentList;
