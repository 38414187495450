import React from "react";
import getClassList from "../../functions/get-class-list.js";

function CustomAgGridSidebar({ isExpanded, onClose, children }) {
  const clickToCloseElement = React.useRef();

  React.useEffect(() => {
    const elementRef = clickToCloseElement.current;
    function clickToCloseListener() {
      onClose();
    }

    if (isExpanded && elementRef) {
      elementRef.addEventListener("click", clickToCloseListener);
    }

    return () => {
      if (isExpanded && elementRef) {
        elementRef.removeEventListener("click", clickToCloseListener);
      }
    };
  }, [isExpanded, onClose]);

  const classes = getClassList({
    "gstv-ag-grid__sidebar": true,
    "gstv-ag-grid__sidebar--expanded": isExpanded,
  });

  return (
    <div className={classes}>
      <div className="gstv-ag-grid__sidebar__overlay" ref={clickToCloseElement}></div>
      <div className="gstv-ag-grid__sidebar__inner">{children}</div>
    </div>
  );
}

export default CustomAgGridSidebar;
