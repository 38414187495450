import React from "react";
import ReactModal from "react-modal";

export default function RemoveTemplateDayDialog({ isOpen, onClose, onSubmit, dayNumber = 0 }) {
  return (
    <ReactModal isOpen={isOpen} ariaHideApp={false}>
      <div className="v-react-modal__inner modal-width">
        <div className="v-react-modal__content">
          <div className="v-react-modal__heading">Delete Day {dayNumber} Confirmation</div>
          <div className="v-react-modal__body modal-body">Are you sure you want to delete this template day?</div>
        </div>
        <div className="v-react-modal__footer">
          <button className="btn btn--primary" onClick={onClose}>
            Nevermind
          </button>
          <button className="btn btn--inverse-primary" onClick={() => onSubmit(dayNumber)}>
            Yes I am sure
          </button>
        </div>
      </div>
    </ReactModal>
  );
}
