import React from "react";
import { toast } from "react-toastify";
import Loader from "../../../common/loader";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import useSpreadState from "../../../hooks/use-spread-state";
import SaveRoundedIcon from "@mui/icons-material/SaveRounded";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import axios from "../../../requests/axios";

function ImageWithFallback({ url }) {
  const fallback = "/images/no-image-placeholder.png";
  return <img src={url || fallback} alt="Image not found" />;
}

function SidebarContentImages({ content_guid: linkGuid, content_type: linkType, provider_id, onClose, isVisible }) {
  const [images, setImages] = React.useState([]);
  const [lightboxOpen, setLightBoxOpen] = React.useState(false);
  const [lightboxUrls, setLightboxUrls] = React.useState([]);
  const [lightboxIndex, setIndex] = React.useState(-1);
  const [form, updateForm] = useSpreadState({});

  const onSave = React.useCallback(() => {
    axios
      .put(`/api/providers/${provider_id}/${linkType}/${linkGuid}/poster`, form)
      .then(() => {
        toast.success("Content posters updated");
        onClose();
      })
      .catch((error) => {
        console.error(error);
        toast.error("Error: Could not update posters");
      });
  }, [form, linkGuid, linkType, onClose, provider_id]);

  React.useEffect(() => {
    if (isVisible) {
      axios
        .get(`/api/providers/${provider_id}/${linkType}/${linkGuid}`)
        .then((response) => {
          const defaultPosters = {};
          const data = response.data.map((image) => {
            if (image.content_poster_thumbnail) {
              defaultPosters.thumbnail_asset_id = image.content_poster_thumbnail.thumbnail_asset_id;
            }

            if (image.content_poster_medium) {
              defaultPosters.medium_asset_id = image.content_poster_medium.medium_asset_id;
            }

            if (image.content_poster_large) {
              defaultPosters.large_asset_id = image.content_poster_large.large_asset_id;
            }

            if (image.content_poster_vertical) {
              defaultPosters.vertical_asset_id = image.content_poster_vertical.vertical_asset_id;
            }

            return {
              id: image.asset_id,
              url: image.source_url,
            };
          });

          updateForm(defaultPosters);
          setImages(data);
        })
        .catch((e) => {
          console.error(e);
          toast.error(
            "Error loading assets. Perhaps one or more of this content's assets does not have an S3 location.",
          );
        });
    }
  }, [linkGuid, linkType, isVisible, provider_id, updateForm]);

  React.useEffect(() => {
    if (images.length) {
      setLightboxUrls(images.map((image) => ({ src: image.source_url })));
    }
  }, [images]);

  return (
    <div className="gstv-ag-grid-sidebar-content">
      <div className="gstv-ag-grid-sidebar-content__section">
        <div className="gstv-ag-grid-sidebar-content__label"></div>
        <div className="gstv-ag-grid-sidebar-content__group">
          <div className="gstv-ag-grid-posters">
            {images && lightboxUrls ? (
              images.map((asset, key) => (
                <div className="gstv-ag-grid-posters__poster" key={`ag-sidebar-poster-${key}`}>
                  <div
                    className="gstv-ag-grid-posters__poster__image"
                    onClick={() => {
                      setIndex(key);
                      setLightBoxOpen(true);
                    }}
                  >
                    <ImageWithFallback url={asset.url} />
                  </div>
                  <div className="form-field">
                    <div className="radio-group-container radio-group-container--vertical">
                      <div className="radio-group">
                        <input
                          className="form-radio"
                          type="radio"
                          name="large-poster"
                          id={`ag-sidebar-poster-${key}-lg`}
                          onChange={() => updateForm({ large_asset_id: asset.id })}
                          checked={form?.large_asset_id === asset.id}
                        />
                        <label htmlFor={`ag-sidebar-poster-${key}-lg`}>1920 x 1080</label>
                      </div>
                      <div className="radio-group">
                        <input
                          className="form-radio"
                          type="radio"
                          name="medium-poster"
                          id={`ag-sidebar-poster-${key}-md`}
                          onChange={() => updateForm({ medium_asset_id: asset.id })}
                          checked={form?.medium_asset_id === asset.id}
                        />
                        <label htmlFor={`ag-sidebar-poster-${key}-md`}>1280 x 720</label>
                      </div>
                      <div className="radio-group">
                        <input
                          className="form-radio"
                          type="radio"
                          name="vertical-poster"
                          id={`ag-sidebar-poster-${key}-v`}
                          onChange={() => updateForm({ vertical_asset_id: asset.id })}
                          checked={form?.vertical_asset_id === asset.id}
                        />
                        <label htmlFor={`ag-sidebar-poster-${key}-v`}>720 x 1280</label>
                      </div>
                      <div className="radio-group">
                        <input
                          className="form-radio"
                          type="radio"
                          name="thumbnail-poster"
                          id={`ag-sidebar-poster-${key}-sm`}
                          onChange={() => updateForm({ thumbnail_asset_id: asset.id })}
                          checked={form?.thumbnail_asset_id === asset.id}
                        />
                        <label htmlFor={`ag-sidebar-poster-${key}-sm`}>100 x 100</label>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <Loader />
            )}
          </div>
        </div>
      </div>

      <div className="gstv-ag-grid-sidebar-content__footer">
        <button onClick={onClose} className="gstv-ag-grid-sidebar-content__footer__button">
          <CancelRoundedIcon /> Close
        </button>
        <button onClick={onSave} className="gstv-ag-grid-sidebar-content__footer__button">
          <SaveRoundedIcon /> Save
        </button>
      </div>
      {images && lightboxUrls ? (
        <Lightbox
          open={lightboxOpen}
          close={() => setLightBoxOpen(false)}
          slides={lightboxUrls}
          index={lightboxIndex}
        />
      ) : null}
    </div>
  );
}

export default SidebarContentImages;
