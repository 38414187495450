import React from "react";
import Loader from "../../../common/loader.jsx";
import ReactModal from "react-modal";
import axios from "../../../requests/axios.js";

function GenericRestoreDialog({
  body = "",
  isOpen = false,
  onClose = () => null,
  endpoint = "",
  onComplete = () => null,
}) {
  const [loading, setLoading] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  function submit() {
    setLoading(true);
    axios
      .post(endpoint)
      .then(() => onComplete(true))
      .catch((err) => {
        onComplete(false, err);
        setErrorMessage("Something has gone wrong. Please contact support.");
      })
      .finally(() => {
        setLoading(false);
      });
  }
  return (
    <ReactModal isOpen={isOpen} ariaHideApp={false}>
      <div className="v-react-modal__inner modal-width">
        <div className="v-react-modal__heading">Restore item</div>
        <div className="v-react-modal__body">
          {body}
          <br />
          {errorMessage ? <p className="u-fc--warning">{errorMessage}</p> : null}
        </div>
        <div className="v-react-modal__footer" style={{ marginTop: "4px" }}>
          {loading ? (
            <Loader width={45} height={45} />
          ) : (
            <React.Fragment>
              <button className="btn btn--inverse-primary" onClick={onClose}>
                Cancel
              </button>
              <button className="btn btn--primary" onClick={submit}>
                Restore
              </button>
            </React.Fragment>
          )}
        </div>
      </div>
    </ReactModal>
  );
}

export default GenericRestoreDialog;
