import React from "react";
import { Link } from "react-router-dom";
import getClassList from "../../functions/get-class-list.js";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

function Action({ action }) {
  if (action.when === false) {
    return null;
  }

  if (action.component) {
    return action.component();
  }

  if (action.type === "link") {
    return (
      <Link
        to={action.href}
        state={action.state ?? {}}
        className={action.className ?? "btn btn--icon"}
        aria-label={action.balloonLabel}
        data-balloon-pos={action.balloonLabelPosition ?? "left"}
      >
        <action.icon />
      </Link>
    );
  }

  if (action.type === "anchor") {
    return (
      <a
        className={action.className ?? "btn btn--icon"}
        href={action.href}
        aria-label={action.balloonLabel}
        data-balloon-pos={action.balloonLabelPosition ?? "left"}
      >
        <action.icon />
      </a>
    );
  }

  return (
    <button
      className={action.className ?? "btn btn--icon"}
      onClick={action.onClick}
      aria-label={action.balloonLabel}
      data-balloon-pos={action.balloonLabelPosition ?? "left"}
    >
      <action.icon />
    </button>
  );
}

export function AppHeadingActions({ leftActions = [], rightActions = [], rightLight = false }) {
  return (
    <div className="app-heading__action-container">
      {leftActions.length ? (
        <div className="app-heading__action-list app-heading__action-list--left">
          {leftActions.map((action, index) => (
            <Action action={action} key={index} />
          ))}
        </div>
      ) : null}
      {rightActions.length ? (
        <div
          className={`app-heading__action-list app-heading__action-list--right ${rightLight ? "app-heading__action-list--light" : ""}`}
        >
          {rightActions.map((action, index) => (
            <Action action={action} key={index} />
          ))}
        </div>
      ) : null}
    </div>
  );
}

export function AppHeadingBreadcrumbs({ breadcrumbs }) {
  const classList = (idx) =>
    getClassList({
      "app-heading__breadcrumb-list__item": !breadcrumbs.className,
      "app-heading__breadcrumb-list__item--disabled": !breadcrumbs.className && idx === breadcrumbs.length - 1,
    });

  return (
    <div className="app-heading__breadcrumb-list">
      {breadcrumbs.map((breadcrumb, index) => (
        <React.Fragment key={index}>
          <Link className={classList(index)} to={breadcrumb.link} state={{ breadcrumbs: breadcrumbs.slice(0, index) }}>
            {breadcrumb.title}
          </Link>
          {index !== breadcrumbs.length - 1 ? (
            <div className="app-heading__breadcrumb-list__separator">
              <FiberManualRecordIcon></FiberManualRecordIcon>
            </div>
          ) : null}
        </React.Fragment>
      ))}
    </div>
  );
}

export function AppHeading({ breadcrumbs, leftActions, rightActions, children }) {
  return (
    <div className="app-heading">
      <AppHeadingBreadcrumbs breadcrumbs={breadcrumbs} />
      {children ? children : <AppHeadingActions leftActions={leftActions} rightActions={rightActions} />}
    </div>
  );
}
