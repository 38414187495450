import React from "react";
import ContentCard from "././library/components/content-card";
import AddNewOrganisationDialog from "./components/dialogs/add-new-organisation-dialog";
import RestoreOrRemoveOrganisationDialog from "./components/dialogs/restore-or-remove-organisation-dialog.jsx";
import { toast } from "react-toastify";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import { AppHeading } from "../layout/parts/app-heading.jsx";
import AppBody from "../layout/parts/app-body.jsx";
import DataTable from "../components/data-table/data-table.jsx";
import DeleteForeverRoundedIcon from "@mui/icons-material/DeleteForeverRounded";
import RestoreFromTrashRoundedIcon from "@mui/icons-material/RestoreFromTrashRounded";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import SlidingPane from "react-sliding-pane";
import OrganisationForm from "./components/dialogs/organisation-form.jsx";
import { v4 as uuid } from "uuid";
import Loader from "../common/loader.jsx";

function ManageOrganisationsPage() {
  const [showNewOrganisationDialog, setShowNewOrganisationDialog] = React.useState(false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const tableRef = React.useRef();

  const [editSlidePane, setEditSlidePane] = React.useState({
    open: false,
    isLoading: false,
  });

  const [addRemoveDialog, setAddRemoveDialog] = React.useState({
    open: false,
    id: "",
    action: "",
  });

  const columns = React.useMemo(
    () => [
      {
        label: "Display Name",
        key: "display_name",
        value: "display_name",
        sortable: true,
        width: 50,
      },
      {
        label: "Name",
        key: "name",
        value: "name",
        sortable: true,
        width: 40,
      },
      {
        label: "",
        value: "",
        key: "0",
        width: 10,
        renderValue: (row) => (
          <span className="data-table__action-cell">
            {row.organisation_active ? (
              <React.Fragment>
                <span className="data-table__action-cell__item data-table__icon" onClick={() => openEditSlidePane(row)}>
                  <EditRoundedIcon />
                </span>
                <span
                  className="data-table__action-cell__item data-table__icon"
                  onClick={() => openAddRemoveDialog(row.id, "delete")}
                >
                  <DeleteForeverRoundedIcon />
                </span>
              </React.Fragment>
            ) : (
              <span
                className="data-table__action-cell__item data-table__icon"
                onClick={() => openAddRemoveDialog(row.id, "restore")}
              >
                <RestoreFromTrashRoundedIcon />
              </span>
            )}
          </span>
        ),
      },
    ],
    [],
  );

  function openNewOrganisationDialog() {
    setShowNewOrganisationDialog(true);
  }

  function closeNewOrganisationDialog() {
    setShowNewOrganisationDialog(false);
  }

  function beginSubmit() {
    setEditSlidePane((prev) => ({
      ...prev,
      isLoading: true,
    }));
    setIsSubmitting(true);
  }

  function endSubmit(success = false) {
    setEditSlidePane((prev) => ({
      ...prev,
      isLoading: false,
    }));
    setIsSubmitting(false);

    if (success) {
      closeEditSlidePane();
      toast.success("Changes Saved");
      refreshPage();
    }
  }

  function openAddRemoveDialog(organisationId, action) {
    setAddRemoveDialog({
      id: organisationId,
      open: true,
      action,
    });
  }

  function closeAddRemoveDialog() {
    setAddRemoveDialog({});
  }

  function openEditSlidePane(org) {
    setEditSlidePane({
      open: true,
      organisation: org,
      title: org.display_name,
    });
  }

  function closeEditSlidePane() {
    setEditSlidePane((prev) => ({
      ...prev,
      open: false,
    }));
  }

  function getMessageForAction(action) {
    switch (action) {
      case "delete":
        return "Are you sure you want to delete this organisation?";
      case "restore":
        return "Are you sure you want to restore this organisation?";
      default:
        return "";
    }
  }

  function refreshPage() {
    tableRef.current?.refreshList();
  }

  return (
    <React.Fragment>
      <AppHeading
        breadcrumbs={[
          {
            link: "/admin/",
            title: "Admin",
          },
          {
            link: "/admin/organisations",
            title: "Organisations",
          },
        ]}
        rightActions={[
          {
            icon: AddCircleRoundedIcon,
            onClick: openNewOrganisationDialog,
            balloonLabel: "Add Organisation",
          },
        ]}
      />
      <AppBody>
        <div className="gw gw--full-height">
          <div className="g g--full-height g--1-of-1">
            <ContentCard noPadding>
              <DataTable url="/api/organisations" columns={columns} ref={tableRef} />
            </ContentCard>
          </div>
        </div>
      </AppBody>
      <AddNewOrganisationDialog
        isOpen={showNewOrganisationDialog}
        onClose={closeNewOrganisationDialog}
        onSuccess={refreshPage}
      />
      <RestoreOrRemoveOrganisationDialog
        isOpen={addRemoveDialog.open}
        id={addRemoveDialog.id}
        action={addRemoveDialog.action}
        message={getMessageForAction(addRemoveDialog.action)}
        onClose={closeAddRemoveDialog}
        onSuccess={refreshPage}
        key={`add-remove-organisation_dialog-${uuid()}`}
      />
      <SlidingPane
        closeIcon={<CancelRoundedIcon />}
        overlayClassName="react-sliding-pane--show"
        isOpen={editSlidePane.open}
        title={`Editing ${editSlidePane.title}`}
        from="right"
        onRequestClose={closeEditSlidePane}
        width={"50%"}
        key="organisation-pane"
      >
        <OrganisationForm
          action="edit"
          onComplete={endSubmit}
          submitting={isSubmitting}
          organisation={editSlidePane.organisation}
        />

        {editSlidePane.isLoading ? (
          <Loader width={45} height={45} />
        ) : (
          <React.Fragment>
            <div className="slide-pane__footer">
              <button
                className="btn btn--inverse-primary"
                onClick={closeEditSlidePane}
                key="new-organisation-dialog-cancel"
              >
                Cancel
              </button>
              <button className="btn btn--primary" onClick={beginSubmit} key="new-organisation-dialog-submit">
                Save Changes
              </button>
            </div>
          </React.Fragment>
        )}
      </SlidingPane>
    </React.Fragment>
  );
}

export default ManageOrganisationsPage;
