import React from "react";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import PendingRoundedIcon from "@mui/icons-material/PendingRounded";
import CircleRoundedIcon from "@mui/icons-material/CircleRounded";

function TranscodingStatusText({ status, type = "hls" }) {
  let statusIcon;
  let statusText = "";

  switch (status) {
    case "initialised":
    case null:
    case undefined:
      statusIcon = <CircleRoundedIcon />;
      statusText = ` ${type.toUpperCase()} not started`;
      break;

    case "processing":
      statusIcon = <PendingRoundedIcon />;
      statusText = `${type.toUpperCase()} in progress`;
      break;

    case "completed":
      statusIcon = <CheckCircleRoundedIcon />;
      statusText = `${type.toUpperCase()} available`;
      break;

    case "failed":
      statusIcon = <CancelRoundedIcon />;
      statusText = `${type.toUpperCase()} failed`;
      break;
  }

  return (
    <span
      className={`content-item__transcoding-status__status-item${status === "completed" ? " content-item__transcoding-status__status-item--highlighted" : ""}`}
    >
      {statusIcon}
      {statusText}
    </span>
  );
}

export default TranscodingStatusText;
