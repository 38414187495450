import { isAfter, isBefore, isEqual, setMilliseconds } from "date-fns";

const dateIsBetween = (_date, _from, _to, inclusivity = "[]") => {
  if (!["()", "[]", "(]", "[)"].includes(inclusivity)) {
    throw new Error("Inclusivity parameter must be one of (), [], (], [)");
  }
  // ensure we are not comparing ms
  const date = setMilliseconds(_date, 0);
  const from = setMilliseconds(_from, 0);
  const to = setMilliseconds(_to, 0);

  const isBeforeEqual = inclusivity[0] === "[",
    isAfterEqual = inclusivity[1] === "]";

  return (
    (isBeforeEqual ? isEqual(from, date) || isBefore(from, date) : isBefore(from, date)) &&
    (isAfterEqual ? isEqual(to, date) || isAfter(to, date) : isAfter(to, date))
  );
};
export default dateIsBetween;
