import Loader from "../../common/loader.jsx";
import React from "react";
import getClassList from "../../functions/get-class-list.js";

export function DataTableBody({
  isLoading,
  columns,
  rows,
  noDataComponent = null,
  expandedRowIndex,
  expandedRowRender,
}) {
  function getCellValue(column, row, fallback = "-") {
    let nextValue;

    if (column.value.includes(".")) {
      nextValue = column.value.split(".").reduce((prev, curr) => {
        if (prev[curr]) {
          return prev[curr];
        }
        return prev;
      }, row);
    } else {
      nextValue = row[column.value];
    }

    if (typeof nextValue === "object" || nextValue === null || nextValue === undefined) {
      nextValue = fallback;
    }

    if (column.formatValue) {
      return column.formatValue(nextValue);
    }

    return nextValue;
  }

  if (isLoading) {
    return (
      <div className="data-table__loader">
        <Loader></Loader>
      </div>
    );
  }

  if (!rows || !rows.length) {
    if (noDataComponent) {
      return noDataComponent;
    }

    return <div className="data-table__empty">No Results</div>;
  }

  const cellClasses = (col) =>
    getClassList({
      "data-table__cell": true,
      "data-table__cell--centered": col.align === "center",
      "data-table__cell--right": col.align === "right",
      "data-table__cell--left": col.align === "left",
    });

  return (
    <div className="data-table__body">
      {rows.map((row, key, array) => (
        <React.Fragment key={key}>
          <div className={`data-table__row`} key={key}>
            {columns.map((column) => (
              <div
                className={cellClasses(column)}
                key={`${column.key}-${key}`}
                style={{
                  ...(column.width ? { width: `${column.width}%` } : {}),
                }}
              >
                {column.renderValue ? (
                  column.renderValue(row, key, array, key === expandedRowIndex)
                ) : (
                  <span className="data-table__cell__value">{getCellValue(column, row)}</span>
                )}
              </div>
            ))}
          </div>
          {expandedRowIndex === key && <div className="data-table__sub-row">{expandedRowRender(row)}</div>}
        </React.Fragment>
      ))}
    </div>
  );
}
