import React from "react";
import useApiRequest from "../../../hooks/use-api-request.js";
import Loader from "../../../common/loader.jsx";
import ContentCard from "../components/content-card.jsx";
import { getSecondsFromHis } from "../../epg-editor/utils/create-ad-breaks.js";
import CuepointMeta from "../components/cuepoint-meta.jsx";
import { secondsToHourMinutesSeconds } from "../../../common/duration-formatting.js";
import Select from "../../../common/select.jsx";
import axios from "../../../requests/axios.js";
import DialogForm from "../../../components/dialog-form.jsx";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import DeleteForeverRoundedIcon from "@mui/icons-material/DeleteForeverRounded";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import LockRoundedIcon from "@mui/icons-material/LockRounded";
import NoEncryptionGmailerrorredRoundedIcon from "@mui/icons-material/NoEncryptionGmailerrorredRounded";
import ChangeVideoSourceDialog from "../../components/dialogs/change-video-source-dialog.jsx";
import SaveRoundedIcon from "@mui/icons-material/SaveRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import useSpreadState from "../../../hooks/use-spread-state.js";
import { AppHeadingActions } from "../../../layout/parts/app-heading.jsx";
import { toast } from "react-toastify";
import GenericDeleteDialog from "../../components/dialogs/generic-delete-dialog.jsx";
import { useNavigate } from "react-router-dom";
import QueueTranscodeDialog from "../../components/queue-transcode-dialog.jsx";
import TranscodingStatusIcon from "../../components/transcoding-status-icon.jsx";
import GenericRestoreDialog from "../../components/dialogs/generic-restore-dialog.jsx";
import RestoreFromTrashRoundedIcon from "@mui/icons-material/RestoreFromTrashRounded";
import { v4 as uuid } from "uuid";
import EditablePillList from "../../../components/editable-pill-list.jsx";
import ContentPlayer from "../../../components/player/content-player.jsx";
import { areProgramCuepointsSpaced } from "../../epg-editor/utils/cuepoint-spacing-validator.js";
import { formatCuepoints } from "../../../common/cuepoints.js";

let RATING_OPTIONS = {
  mpaa: [],
  us_tv: [],
  bbfc: [],
};

let GENRE_OPTIONS = {
  imdb: [],
  freeview: [],
};

export default function FeatureTab({ guid }) {
  const [showCastCrewDialog, setShowCastCrew] = React.useState(false);
  const [showKeywordDialog, setShowKeywordDialog] = React.useState(false);
  const [isChangeSourceOpen, setChangeSourceOpen] = React.useState(false);
  const [isEditing, setEditing] = React.useState(false);
  const [isSaving, setSaving] = React.useState(false);
  const [isTrashing, setTrashing] = React.useState(false);
  const [isRestoring, setRestoring] = React.useState(false);
  const [transcodingDialog, setTranscodingDialog] = React.useState({ isOpen: false, type: "" });

  const [form, updateForm, hardUpdateForm] = useSpreadState({});
  const { isLoading, response, invalidateCache } = useApiRequest(`/api/features/${guid}/summary`, { cache: true });

  const [feature, setFeature] = React.useState({});
  const [data, setData] = React.useState({});
  const [isSwitchingPlayers, setSwitching] = React.useState(false);
  const navigate = useNavigate();

  React.useEffect(() => {
    if (response.data) {
      setFeature(response.data);
    }
  }, [response]);

  React.useEffect(() => {
    if (feature) {
      setData({ ...feature, ...(isEditing ? form : {}) });
    }
  }, [form, feature, isEditing]);

  const getActiveRating = React.useCallback(
    (scheme) => {
      if (!data.rating) {
        return null;
      }

      const rating = RATING_OPTIONS[scheme].filter((i) => i.value === data.rating[`${scheme}_rating_id`]);
      if (!rating.length) {
        return null;
      }

      return rating[0];
    },
    [data.rating],
  );

  const getActiveGenre = React.useCallback(
    (scheme) => {
      let genre = null;
      switch (scheme) {
        case "imdb_genre":
          genre = GENRE_OPTIONS["imdb"].find((i) => i.value === data.imdb_genre?.value);
          break;
        case "imdb_subgenre":
          var primaryGenre = getActiveGenre("imdb_genre");
          //  subgenre is linked to a primary genre
          if (!primaryGenre || !data.imdb_subgenre) {
            break;
          }

          genre = getActiveGenre("imdb_genre").subgenres.find((i) => i.value === data.imdb_subgenre?.value);
          break;
        case "freeview_genre":
          genre = GENRE_OPTIONS["freeview"].find((i) => i.value === data.freeview_genre?.value);
          break;
      }

      if (!genre) {
        return null;
      }

      return genre;
    },
    [data.imdb_genre, data.imdb_subgenre, data.freeview_genre],
  );

  const resetForm = React.useCallback(() => {
    hardUpdateForm({
      imdb_genre: feature?.genre?.imdb?.imdb_genre
        ? { label: feature.genre.imdb.imdb_genre.label, value: feature.genre.imdb.imdb_genre.value }
        : null,
      imdb_subgenre: feature?.genre?.imdb?.imdb_subgenre
        ? { label: feature.genre.imdb.imdb_subgenre.label, value: feature.genre.imdb.imdb_subgenre.value }
        : null,
      freeview_genre: feature?.genre?.freeview?.freeview_genre
        ? {
            label: feature.genre.freeview.freeview_genre.label,
            value: feature.genre.freeview.freeview_genre.value,
          }
        : null,
    });
  }, [feature, hardUpdateForm]);

  const toggleEditMode = React.useCallback(() => {
    setSwitching(true);
    setEditing((prev) => !prev);
    setTimeout(() => {
      setSwitching(false);
    }, 200);
  }, []);

  const cancelEditing = React.useCallback(() => {
    toggleEditMode();
    resetForm();
  }, [resetForm, toggleEditMode]);

  const addCuepoint = React.useCallback(
    (_atSeconds) => {
      const atSeconds = parseInt(_atSeconds, 10);

      const oldAdBreaks = form?.cuepoints ? [...form.cuepoints] : [];

      const newAdBreaks = [
        ...oldAdBreaks,
        {
          cuepoint: secondsToHourMinutesSeconds(atSeconds),
          ad_break: secondsToHourMinutesSeconds(122),
        },
      ];

      updateForm({ cuepoints: newAdBreaks });
    },
    [form.cuepoints, updateForm],
  );

  const removeNearestCuepoint = React.useCallback(
    (atSeconds) => {
      if (!form?.cuepoints.length) {
        return;
      }

      let nearestIndex = 0;
      let nearestTimeInSeconds = 9999999;

      const newAdBreaks = form.cuepoints
        .map((ad, index) => {
          const diff = Math.abs(atSeconds - getSecondsFromHis(ad.cuepoint));
          if (diff < nearestTimeInSeconds) {
            nearestTimeInSeconds = diff;
            nearestIndex = index;
          }
          return ad;
        })
        .filter((item, index) => index !== nearestIndex);

      updateForm({ cuepoints: newAdBreaks });
    },
    [form.cuepoints, updateForm],
  );

  const trimVideo = React.useCallback(
    (_atPlayoutSeconds) => {
      let atPlayoutSeconds = parseInt(_atPlayoutSeconds, 10);
      // reset trim by snipping at end or start of timeline
      if (atPlayoutSeconds > feature.duration || atPlayoutSeconds === 0) {
        atPlayoutSeconds = feature.duration;
      }

      updateForm({ trimmed_duration: atPlayoutSeconds });
    },
    [feature.duration, updateForm],
  );

  const playerOptions = React.useMemo(
    () => ({
      cuepoints: data.cuepoints ? formatCuepoints(data.cuepoints) : [],
      trimPoint: data.trimmed_duration,
      addCuepoint: addCuepoint,
      removeNearestCuepoint: removeNearestCuepoint,
      trimVideo: trimVideo,

      changeSource: changeSource,
      controls: {
        editCuepoints: isEditing,
        playoutTrimming: isEditing,
        changeSource: isEditing,
        selectSource: true,
        timers: true,
      },

      availableSources: {
        source: !!feature.source_video?.asset_id,
        normalised: !!feature.content_video?.asset_id,
        hls: !!feature.content_video?.hls_path,
        dash: !!feature.content_video?.dash_path,
      },
    }),
    [feature, addCuepoint, data.cuepoints, data.trimmed_duration, isEditing, removeNearestCuepoint, trimVideo],
  );

  // fill out dropdown list options
  React.useEffect(() => {
    if (!RATING_OPTIONS.length) {
      axios
        .get("/api/ratings")
        .then((resp) => {
          RATING_OPTIONS = resp.data;
        })
        .catch((err) => {
          console.error(err);
        });
    }

    if (!GENRE_OPTIONS.length) {
      axios
        .get("/api/genres")
        .then((resp) => {
          const imdbGenres = resp.data.imdb.map((genre) => ({
            label: genre.label,
            value: genre.value,
            subgenres: genre.subgenres.map((subgenre) => ({ label: subgenre.label, value: subgenre.value })),
          }));

          const freeviewGenres = resp.data.freeview.map((genre) => ({
            label: genre.label,
            value: genre.value,
          }));

          GENRE_OPTIONS = {
            imdb: imdbGenres,
            freeview: freeviewGenres,
          };
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, []);

  //  set default form values
  React.useEffect(() => {
    if (feature) {
      updateForm({
        imdb_genre: feature?.genre?.imdb?.imdb_genre
          ? { label: feature.genre.imdb.imdb_genre.label, value: feature.genre.imdb.imdb_genre.value }
          : null,
        imdb_subgenre: feature?.genre?.imdb?.imdb_subgenre
          ? { label: feature.genre.imdb.imdb_subgenre.label, value: feature.genre.imdb.imdb_subgenre.value }
          : null,
        freeview_genre: feature?.genre?.freeview?.freeview_genre
          ? {
              label: feature.genre.freeview.freeview_genre.label,
              value: feature.genre.freeview.freeview_genre.value,
            }
          : null,
      });
    }
  }, [feature, updateForm]);

  React.useEffect(() => {
    // ensure we are never stacking overlays
    if (isEditing) {
      setTrashing(false);
      setRestoring(false);
      updateForm({
        cuepoints: feature.cuepoints ?? [],
      });
    }
  }, [isEditing, feature?.cuepoints, updateForm]);

  if (isLoading) {
    return (
      <div className="content-page__loader">
        <Loader />
      </div>
    );
  }

  function onSaveEdits() {
    setSaving(true);

    if (!areProgramCuepointsSpaced(form)) {
      setSaving(false);
      return;
    }

    const payload = { ...form };
    // remove subgenre from payload if not set
    if (!payload.imdb_subgenre) {
      delete payload.imdb_subgenre;
    }

    axios
      .patch(`/api/features/${feature.feature_guid}?additional=assets`, payload)
      .then((resp) => {
        toast.success("Feature successfully updated");
        toggleEditMode();
        invalidateCache();
        setFeature(resp.data);
      })
      .catch((error) => {
        toast.error("Error attempting to update Feature");
        console.error(error);
      })
      .finally(() => setSaving(false));
  }

  function onSetRestricted(restrict = false) {
    setSaving(true);

    axios
      .post(`/api/features/${feature.feature_guid}/${restrict ? "restrict" : "unrestrict"}`)
      .then((resp) => {
        toast.success(`Feature ${restrict ? "restricted" : "unrestricted"} successfully`);
        setFeature((prev) => ({
          ...prev,
          restricted_at: resp.data.restricted_at,
        }));
      })
      .catch((error) => {
        toast.error("Error updating restricted status on Feature");
        console.error(error);
      })
      .finally(() => setSaving(false));
  }

  function getSubgenresForGenre(genre) {
    if (!genre) {
      return [];
    }

    const foundGenre = GENRE_OPTIONS.imdb.find((genreItem) => genreItem.value === genre.value);
    if (!foundGenre) {
      return [];
    }

    return foundGenre.subgenres;
  }

  function openCastCrewDialog() {
    setShowCastCrew(true);
  }

  function closeCastCrewDialog() {
    setShowCastCrew(false);
  }

  function changeSource() {
    setChangeSourceOpen(true);
  }

  function changeSourceClosed() {
    setChangeSourceOpen(false);
  }

  function updateGenre(type, input) {
    switch (type) {
      case "imdb_genre":
        updateForm({
          imdb_genre: input,
          imdb_subgenre: null,
        });
        break;

      case "imdb_subgenre":
        updateForm({
          imdb_subgenre: input,
        });
        break;

      case "freeview_genre":
        updateForm({
          freeview_genre: input,
        });
        break;
    }
  }

  function updateCastCrew(key, value, index) {
    const newCastCrew = data.cast_crew.map((member, ccIndex) => {
      if (ccIndex === index) {
        member[key] = value;
      }

      return member;
    });

    updateForm({
      cast_crew: newCastCrew,
    });
  }

  function updateRating(label, event) {
    const newRating = event.value;
    updateForm({
      rating: {
        ...data.rating,
        [`${label}_rating_id`]: newRating,
      },
    });
  }

  function addCastCrew(castCrew) {
    updateForm({ cast_crew: [...data.cast_crew, castCrew] });
  }

  function removeCastCrew(index) {
    updateForm({ cast_crew: [...data.cast_crew.slice(0, index), ...data.cast_crew.slice(index + 1)] });
  }

  function addKeyword(keyword) {
    updateForm({
      keywords: [...data.keywords, keyword],
    });
  }

  function onKeywordRemove(index) {
    updateForm({ keywords: [...data.keywords.slice(0, index), ...data.keywords.slice(index + 1)] });
  }

  function setTranscoding(isOpen, type) {
    setTranscodingDialog({ isOpen, type });
  }

  return (
    !isLoading &&
    feature && (
      <React.Fragment>
        <div className="tabbed-content__actions">
          {isSaving ? (
            <Loader height={35} width={35} />
          ) : (
            <AppHeadingActions
              leftActions={[
                {
                  component: () => (
                    <button
                      className="btn btn--icon"
                      onClick={() => {
                        if (!["processing", "completed"].includes(feature?.content_video?.hls_status)) {
                          setTranscoding(true, "hls");
                        }
                        return false;
                      }}
                    >
                      <TranscodingStatusIcon
                        status={feature?.content_video?.hls_status}
                        appendTextOptions={{ failed: "Click to transcode", initialised: "Click to transcode" }}
                        small={true}
                      />
                    </button>
                  ),
                },
                {
                  component: () => (
                    <button
                      className="btn btn--icon"
                      onClick={() => {
                        if (!["processing", "completed"].includes(feature?.content_video?.dash_status)) {
                          setTranscoding(true, "dash");
                        }
                        return false;
                      }}
                    >
                      <TranscodingStatusIcon
                        status={feature?.content_video?.dash_status}
                        appendTextOptions={{ failed: "Click to transcode", initialised: "Click to transcode" }}
                        small={true}
                        type="dash"
                      />
                    </button>
                  ),
                },
              ]}
              rightActions={[
                {
                  when: !!feature.restricted_at && !isEditing,
                  icon: LockRoundedIcon,
                  balloonLabel: "Unrestrict content",
                  onClick: onSetRestricted.bind(null, false),
                },
                {
                  when: !feature.restricted_at && !isEditing,
                  icon: NoEncryptionGmailerrorredRoundedIcon,
                  balloonLabel: "Restrict content",
                  onClick: onSetRestricted.bind(null, true),
                },
                {
                  when: isEditing,
                  icon: SaveRoundedIcon,
                  balloonLabel: "Save",
                  onClick: onSaveEdits,
                },
                {
                  when: isEditing,
                  icon: CancelRoundedIcon,
                  balloonLabel: "Cancel",
                  onClick: cancelEditing,
                },
                {
                  when: !isEditing && feature.feature_active === 0,
                  icon: RestoreFromTrashRoundedIcon,
                  balloonLabel: "Restore content",
                  onClick: () => setRestoring(true),
                },
                {
                  when: !isEditing && feature.feature_active === 1,
                  icon: DeleteForeverRoundedIcon,
                  balloonLabel: "Mark as inactive",
                  onClick: () => setTrashing(true),
                },
                {
                  when: !isEditing && feature.feature_active === 1,
                  icon: EditRoundedIcon,
                  balloonLabel: "Edit",
                  onClick: () => toggleEditMode(),
                },
              ]}
            />
          )}
        </div>
        <div className="content-page__media-row">
          <div className="content-page__media-row__media">
            <ContentCard noPadding>
              {!feature || !Object.keys(feature).length || isSwitchingPlayers ? (
                <Loader />
              ) : (
                <ContentPlayer program={feature} playerOptions={playerOptions} />
              )}
            </ContentCard>
          </div>
          <div className="content-page__media-row__description">
            <ContentCard>
              <div className="content-page__section">
                <div className="content-page__list">
                  <div className="content-page__list__item">
                    <div className="content-page__list__label">Run time</div>
                    <div className="content-page__list__value">
                      {feature.duration
                        ? secondsToHourMinutesSeconds(feature.duration)
                        : feature.video_with_trashed
                          ? secondsToHourMinutesSeconds(feature.video_with_trashed.duration)
                          : "N/A"}
                    </div>
                  </div>
                  <div className="content-page__list__item">
                    <div className="content-page__list__label">Release year</div>
                    <div className="content-page__list__value">
                      {isEditing ? (
                        <input
                          type="text"
                          name="release_year"
                          value={data.release_year ?? "-"}
                          onChange={(e) => updateForm({ release_year: e.target.value })}
                        ></input>
                      ) : (
                        feature.release_year ?? "-"
                      )}
                    </div>
                  </div>
                  <div className="content-page__list__item">
                    <div className="content-page__list__label">Country of Origin</div>
                    <div className="content-page__list__value">
                      {isEditing ? (
                        <input
                          type="text"
                          name="country"
                          placeholder={data.title?.country_of_origin ?? "-"}
                          value={data.country || ""}
                          onChange={(e) => updateForm({ country: e.target.value })}
                        ></input>
                      ) : (
                        feature.title?.country_of_origin || "None given"
                      )}
                    </div>
                  </div>
                  <div className="content-page__list__item">
                    <div className="content-page__list__label">IMDb Genre</div>
                    {isEditing ? (
                      <Select
                        placeholder="Select a Genre"
                        name="genre"
                        options={GENRE_OPTIONS.imdb}
                        value={getActiveGenre("imdb_genre")}
                        onChange={(input) => updateGenre("imdb_genre", input)}
                        classNamePrefix="react-select"
                        containerClass="react-select-container--small react-select-container--light"
                        menuPlacement="auto"
                      />
                    ) : (
                      <div className="content-page__list__value">{feature.genre?.imdb?.imdb_genre?.label ?? "N/A"}</div>
                    )}
                  </div>
                  <div className="content-page__list__item">
                    <div className="content-page__list__label">IMDb Subgenre</div>
                    {isEditing ? (
                      <Select
                        placeholder="Select a Genre"
                        name="subgenre"
                        options={getSubgenresForGenre(getActiveGenre("imdb_genre"))}
                        value={getActiveGenre("imdb_subgenre")}
                        onChange={(input) => updateGenre("imdb_subgenre", input)}
                        classNamePrefix="react-select"
                        containerClass="react-select-container--small react-select-container--light"
                        menuPlacement="auto"
                      />
                    ) : (
                      <div className="content-page__list__value">
                        {feature.genre?.imdb?.imdb_subgenre?.label ?? "N/A"}
                      </div>
                    )}
                  </div>
                  <div className="content-page__list__item">
                    <div className="content-page__list__label">Freeview Genre</div>
                    {isEditing ? (
                      <Select
                        placeholder="Select a Genre"
                        name="genre"
                        options={GENRE_OPTIONS.freeview}
                        value={getActiveGenre("freeview_genre")}
                        onChange={(input) => updateGenre("freeview_genre", input)}
                        classNamePrefix="react-select"
                        containerClass="react-select-container--small react-select-container--light"
                        menuPlacement="auto"
                      />
                    ) : (
                      <div className="content-page__list__value">
                        {feature.genre?.freeview?.freeview_genre?.label ?? "N/A"}
                      </div>
                    )}
                  </div>
                  <div className="content-page__list__item">
                    <div className="content-page__list__label">Us TV Rating</div>
                    {isEditing ? (
                      <Select
                        options={RATING_OPTIONS.us_tv}
                        placeholder="Select a Rating"
                        name="us_tv_rating"
                        value={getActiveRating("us_tv")}
                        onChange={(input) => updateRating("us_tv", input)}
                        classNamePrefix="react-select"
                        containerClass="react-select-container--small react-select-container--light"
                        menuPlacement="auto"
                      />
                    ) : (
                      <div className="content-page__list__value">{feature.rating?.us_tv_rating ?? "N/A"}</div>
                    )}
                  </div>
                  <div className="content-page__list__item">
                    <div className="content-page__list__label">MPAA Rating</div>
                    {isEditing ? (
                      <Select
                        options={RATING_OPTIONS.mpaa}
                        placeholder="Select a Rating"
                        name="mpaa_rating"
                        value={getActiveRating("mpaa")}
                        onChange={(input) => updateRating("mpaa", input)}
                        classNamePrefix="react-select"
                        containerClass="react-select-container--small react-select-container--light"
                        menuPlacement="auto"
                      />
                    ) : (
                      <div className="content-page__list__value">{feature.rating?.mpaa_rating ?? "N/A"}</div>
                    )}
                  </div>
                  <div className="content-page__list__item">
                    <div className="content-page__list__label">BBFC Rating</div>
                    {isEditing ? (
                      <Select
                        options={RATING_OPTIONS.bbfc}
                        placeholder="Select a Rating"
                        name="bbfc_rating"
                        value={getActiveRating("bbfc")}
                        onChange={(input) => updateRating("bbfc", input)}
                        classNamePrefix="react-select"
                        containerClass="react-select-container--small react-select-container--light"
                        menuPlacement="auto"
                      />
                    ) : (
                      <div className="content-page__list__value">{feature.rating?.bbfc_rating ?? "N/A"}</div>
                    )}
                  </div>
                  <div className="content-page__list__item">
                    <div className="content-page__list__label">Keywords</div>
                    {isEditing ? (
                      <EditablePillList
                        list={data.keywords?.map(({ keyword }) => keyword)}
                        onRemove={onKeywordRemove}
                        onAdd={() => setShowKeywordDialog(true)}
                        editing={isEditing}
                        emptyMessage="No keywords saved"
                      />
                    ) : (
                      <div className="content-page__list__value">
                        {feature.keywords?.map((keyword) => keyword.keyword).join(", ")}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </ContentCard>
          </div>
        </div>
        <div className="gw">
          <div className="g g--1-of-2">
            <ContentCard>
              <CuepointMeta metadata={feature} isEditing={isEditing} updateForm={updateForm} form={form} />
            </ContentCard>
          </div>
          <div className="g g--1-of-2">
            <ContentCard>
              <div className="content-page__sub-title">
                Cast & Crew
                {isEditing ? (
                  <button className="btn btn--icon" onClick={openCastCrewDialog}>
                    <AddCircleRoundedIcon />
                  </button>
                ) : null}
              </div>
              {isEditing ? (
                <div className="cast-crew">
                  {data.cast_crew?.map((person, index) => (
                    <div className="cast-crew__item" key={index}>
                      <div className="cast-crew__role">
                        <input
                          type="text"
                          onChange={(e) => updateCastCrew("role", e.target.value, index)}
                          value={person.role}
                        />
                      </div>
                      <div className="cast-crew__name">
                        <input
                          type="text"
                          onChange={(e) => updateCastCrew("name", e.target.value, index)}
                          value={person.name}
                        />
                      </div>
                      <div className="cast-crew__actions">
                        <button className="cast-crew__action-btn" onClick={() => removeCastCrew(index)}>
                          <CancelRoundedIcon />
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              ) : feature.cast_crew?.length ? (
                <div className="content-page__list">
                  {feature.cast_crew.map((person, index) => (
                    <div className="content-page__list__item" key={index}>
                      <div className="content-page__list__label">{person.role}</div>
                      <div className="content-page__list__value">{person.name}</div>
                    </div>
                  ))}
                </div>
              ) : (
                "N/A"
              )}
            </ContentCard>
          </div>
        </div>
        <DialogForm
          isOpen={showCastCrewDialog}
          onClose={closeCastCrewDialog}
          onCancel={closeCastCrewDialog}
          onSubmit={addCastCrew}
          title="Add Cast / Crew"
          fields={[
            {
              label: "Role",
              type: "text",
              name: "role",
            },
            {
              label: "Name",
              type: "text",
              name: "name",
            },
          ]}
        />
        <ChangeVideoSourceDialog
          contentType="feature"
          contentGuid={feature.feature_guid}
          onClose={changeSourceClosed}
          isOpen={isChangeSourceOpen}
        />
        <GenericDeleteDialog
          isOpen={isTrashing}
          body={
            "This will mark this feature and all of it's related content as inactive, are you sure you wish to continue?"
          }
          onClose={() => {
            setTrashing(false);
          }}
          endpoint={`/api/features/${feature.feature_guid}`}
          onComplete={(result, error) => {
            if (error) {
              console.error(error);
            } else if (result) {
              setTrashing(false);
              toast.success("Successfully marked this Feature as inactive.");
              setTimeout(() => navigate(0), 1500);
            }
          }}
        />
        <GenericRestoreDialog
          isOpen={isRestoring}
          body={"This will restore this feature and some of its related data. Do you wish to continue?"}
          onClose={() => {
            setRestoring(false);
          }}
          endpoint={`/api/features/${feature.feature_guid}/restore`}
          onComplete={(result, error) => {
            if (error) {
              console.error(error);
            } else if (result) {
              setRestoring(false);
              toast.success("Successfully restored this feature.");
              setTimeout(() => navigate(0), 1500);
            }
          }}
        />
        <QueueTranscodeDialog
          contentId={feature.feature_guid}
          contentType={"feature"}
          onClose={() => setTranscoding(false, "")}
          onSuccess={() => {
            setTranscoding(false, "");
            toast.success(`${transcodingDialog.type.toUpperCase()} transcoding queued`);
          }}
          isOpen={transcodingDialog.isOpen}
          transcodeType={transcodingDialog.type}
        />
        <DialogForm
          isOpen={showKeywordDialog}
          onSubmit={addKeyword}
          onClose={() => setShowKeywordDialog(false)}
          onCancel={() => setShowKeywordDialog(false)}
          title="Add new Keyword"
          body="Complete the form below to add a new keyword to this content"
          fields={[{ label: "Keyword", type: "text", name: "keyword", defaultValue: "" }]}
          key={`add-dialog__${uuid()}`}
        />
      </React.Fragment>
    )
  );
}
