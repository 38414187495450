import React from "react";
import { ChannelBox, ChannelLogo } from "planby";

function EpgChannel({ channel, setActiveChannel }) {
  const { position, logo } = channel;
  return (
    <span className="planby-channel">
      <ChannelBox onClick={setActiveChannel.bind(null, channel.uuid)} {...position}>
        {/* Overwrite styles by add eg. style={{ maxHeight: 52, maxWidth: 52,... }} */}
        {/* Or stay with default styles */}
        <span aria-label={channel.title} data-balloon-pos="right">
          <ChannelLogo src={logo} alt="Logo" style={{ maxHeight: 52, maxWidth: 52 }} />
        </span>
      </ChannelBox>
    </span>
  );
}

export default EpgChannel;
