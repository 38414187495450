import React from "react";
import { AppHeading } from "../layout/parts/app-heading";
import AppBody from "../layout/parts/app-body";
import useApiRequest from "../hooks/use-api-request";
import { useParams } from "react-router-dom";
import SaveRoundedIcon from "@mui/icons-material/SaveRounded";
import Loader from "../common/loader";
import axios from "../requests/axios";
import RemoveTemplateDayDialog from "./templates/dialogs/remove-template-day-dialog";
import { v4 as uuid } from "uuid";
import TemplateContentList from "./templates/template-content-list/template-content-list";
import { DragDropContext } from "react-beautiful-dnd";
import TemplateDay from "./templates/template-day";
import { toast } from "react-toastify";
import SchedulerProvider from "../providers/scheduler-context";
import {
  addDays,
  addHours,
  addMinutes,
  compareAsc,
  differenceInSeconds,
  setHours,
  setMinutes,
  setSeconds,
} from "date-fns";
import dateIsBetween from "../common/date-is-between";
import EpgDndHelper from "./epg-editor/utils/dnd-helpers";
import useContentList from "../components/content-list/use-content-list";
import { fromApiToTemplateItem, fromContentListToTemplateItem } from "./templates/helpers/to-template-item";
import PublishTemplateDialog from "./templates/dialogs/publish-template-dialog";
import DraggableContentBox from "../components/draggable-content-box.jsx";
import { PublishRounded } from "@mui/icons-material";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";

const TEMPLATE_COLUMN_WIDTH = 600;
const END_OF_DAY_INSERTION_THRESHOLD = 15 * 60; // in seconds

const unixZero = new Date(0);
const REFERENCE_DATE = addMinutes(unixZero, unixZero.getTimezoneOffset() * (unixZero.getTimezoneOffset() < 0 ? 1 : -1));

export default function TemplatePage() {
  const [isSaving, setSaving] = React.useState(false);
  const [publishTemplateDialog, setPublishDialog] = React.useState({
    isOpen: false,
  });
  const [removeDayDialog, setRemoveDialog] = React.useState({
    isOpen: false,
  });
  const [channel, setChannel] = React.useState();

  const { template_id } = useParams();
  const { isLoading: templateLoading, response: templateResponse } = useApiRequest(`/api/templates/${template_id}`);
  const { isLoading: contentListLoading, programs, getPrograms } = useContentList({});

  const [template, setTemplate] = React.useState();
  const [draggedItemSourceDay, setDraggedItemSourceDay] = React.useState();

  // Content Box
  const [contentBoxContent, _setContentBoxContent] = React.useState(null);
  const setContentBoxContent = React.useCallback((content) => {
    _setContentBoxContent(content);
  }, []);
  const onCloseContentBox = React.useCallback(() => {
    setContentBoxContent(null);
  }, [setContentBoxContent]);

  const getDefaultChannelBreaks = React.useCallback(() => {
    const newBreaks = channel?.channel_breaks // default to channel breaks
      ? channel.channel_breaks
          .map((dayBreak) => {
            const [endHours, endMinutes] = dayBreak.end.split(":");
            let end = addHours(addMinutes(new Date(REFERENCE_DATE), endMinutes), endHours);

            return {
              break_time: end,
            };
          })
          .sort((a, b) => {
            return compareAsc(a.break_time, b.break_time);
          })
      : [];

    return newBreaks;
  }, [channel?.channel_breaks]);

  const openRemoveDialog = React.useCallback((dayNumber) => {
    setRemoveDialog({
      isOpen: true,
      dayNumber,
    });
  }, []);

  const closeRemoveDialog = React.useCallback(() => {
    setRemoveDialog({
      isOpen: false,
    });
  }, []);

  const openPublishDialog = React.useCallback(() => {
    setPublishDialog({
      isOpen: true,
      channelId: channel?.channel_id,
      templateId: template?.template_id,
      daysCount: template?.days?.length ?? 0,
      channelName: channel?.display_name,
      hasUnsavedChanges: template?.hasUnsavedChanges,
    });
  }, [
    channel?.channel_id,
    channel?.display_name,
    template?.days?.length,
    template?.hasUnsavedChanges,
    template?.template_id,
  ]);

  const closePublishDialog = React.useCallback(() => {
    setPublishDialog({
      isOpen: false,
    });
  }, []);

  const createTemplateBreaksFromSourceBreaks = React.useCallback((sourceBreaks, channelBreaks = []) => {
    let breaks = [];
    if (!sourceBreaks || sourceBreaks.length === 0) {
      breaks = channelBreaks.length
        ? channelBreaks
            .map((dayBreak) => {
              const [hours, minutes] = dayBreak.start.split(":");
              const [endHours, endMinutes] = dayBreak.end.split(":");
              let start = addHours(addMinutes(REFERENCE_DATE, minutes), hours);
              let end = addHours(addMinutes(REFERENCE_DATE, endMinutes), endHours);

              return {
                start,
                end,
                duration: differenceInSeconds(end, start),
              };
            })
            .sort((a, b) => {
              return compareAsc(a.start, b.start);
            })
        : [];
    } else {
      let referenceDate = new Date(REFERENCE_DATE);
      breaks = sourceBreaks
        .sort((a, b) => {
          return compareAsc(a.break_time, b.break_time);
        })
        .map((dayBreak) => {
          const start = referenceDate;
          const end = dayBreak.break_time;
          referenceDate = dayBreak.break_time;

          return {
            start,
            end,
            duration: differenceInSeconds(end, start),
          };
        });
    }

    return breaks;
  }, []);

  const addTemplateDay = React.useCallback(() => {
    setTemplate((prev) => ({
      ...prev,
      hasUnsavedChanges: true,
      days: [
        ...prev.days,
        {
          day_number: prev.days.length + 1, // adds empty day to end
          items: [],
          breaks: getDefaultChannelBreaks(),
          templateBreaks: createTemplateBreaksFromSourceBreaks(null, channel?.channel_breaks),
        },
      ],
    }));
  }, [channel?.channel_breaks, createTemplateBreaksFromSourceBreaks, getDefaultChannelBreaks]);

  const removeTemplateDay = React.useCallback(
    (dayNumber) => {
      if (template) {
        const removeIndex = template.days.findIndex((day) => day.day_number === dayNumber);
        if (removeIndex < 0) {
          return;
        }

        let daysCopy = [...template.days];
        daysCopy.splice(removeIndex, 1);

        // recalculate day numbers
        daysCopy = daysCopy.map((day, index) => ({
          ...day,
          day_number: index + 1,
        }));

        setTemplate((prev) => ({
          ...prev,
          days: daysCopy,
          hasUnsavedChanges: true,
        }));

        closeRemoveDialog();
      }
    },
    [closeRemoveDialog, template],
  );

  const updateTemplateDay = React.useCallback(
    (dayNumber, data) => {
      setTemplate((prev) => {
        const dayIndex = prev.days.findIndex((day) => day.day_number === dayNumber);

        // update templateBreaks based on latest breaks
        const updatedDay = {
          ...data,
          templateBreaks: createTemplateBreaksFromSourceBreaks(data.breaks, channel?.channel_breaks),
        };

        const next = {
          ...prev,
          hasUnsavedChanges: true,
          days: [...prev.days.slice(0, dayIndex), updatedDay, ...prev.days.slice(dayIndex + 1)],
        };
        return next;
      });
    },
    [channel?.channel_breaks, createTemplateBreaksFromSourceBreaks],
  );

  const isDropDisabled = React.useCallback(
    (droppableId) => {
      if (!draggedItemSourceDay) {
        return false;
      }

      const templateDayNumber = droppableId.split("-")[1];
      return draggedItemSourceDay !== templateDayNumber;
    },
    [draggedItemSourceDay],
  );

  const addItem = React.useCallback(
    (item) => {
      const targetDay = template?.days.findLast((day) => {
        const referenceItem = day.items[day.items.length - 1];
        console.log({ dayEnd: addDays(new Date(REFERENCE_DATE), 1), lastItemEnd: new Date(referenceItem.till) });

        return (
          differenceInSeconds(addDays(new Date(REFERENCE_DATE), 1), new Date(referenceItem.till)) >=
          END_OF_DAY_INSERTION_THRESHOLD
        );
      });

      if (!targetDay) {
        return;
      }

      const dndHelper = EpgDndHelper(channel?.channel_id, REFERENCE_DATE, targetDay.templateBreaks);

      let templateItem = fromContentListToTemplateItem(item);
      let list = [...targetDay.items];
      list = dndHelper.insert(list, templateItem, list.length, null);

      updateTemplateDay(targetDay.day_number, {
        ...targetDay,
        items: list,
      });
    },
    [channel?.channel_id, template?.days, updateTemplateDay],
  );

  React.useEffect(() => {
    if (templateResponse.data && !template && !channel) {
      const templateData = templateResponse.data;
      templateData.days?.forEach((day) => {
        /// BREAKS ///
        if (!day.breaks || !day.breaks.length) {
          day.breaks = getDefaultChannelBreaks();
        }

        day.breaks.forEach((dBreak) => {
          dBreak.break_time = fromUtcDate(dBreak.break_time);
        });

        /// ITEMS ///
        day.items = day.items.map((item) => {
          return fromApiToTemplateItem(item);
        });

        /// TEMPLATE_BREAKS ///
        day.templateBreaks = createTemplateBreaksFromSourceBreaks(day.breaks, templateData.channel.channel_breaks);
      });

      templateData.hasUnsavedChanges = false;

      setTemplate(templateData);
      setChannel(templateData.channel);
    }
  }, [templateResponse, getDefaultChannelBreaks, template, channel, createTemplateBreaksFromSourceBreaks]);

  const breadcrumbs = [
    {
      link: "/templates",
      title: "Templates",
    },
    {
      link: `/templates/${template_id}`,
      title: !template || !channel ? "Loading..." : `${template?.template_name} (${channel?.display_name})`,
    },
  ];

  // dates are visually in the users timezone, but in reality they are UTC
  // this resolves the call that axios will make to do .toISOString() to keep things UTC-like
  function fromUtcDate(utcDate) {
    let offsetDate = new Date(utcDate);
    const modifier = offsetDate < 0 ? -1 : 1;
    offsetDate = addMinutes(offsetDate, offsetDate.getTimezoneOffset() * modifier);
    return offsetDate;
  }

  function saveTemplate() {
    setSaving(true);

    const payload = { ...template };
    const days = payload.days.map((day) => {
      const breaks = day.breaks.map((dBreak) => ({
        ...dBreak,
        break_time: dBreak.break_time.toLocaleString("en-US"), // MM-dd-YYYY etc
      }));

      // @TODO add seed info
      const items = day.items.map((item) => {
        const apiItem = {
          link_type: item.type,
          link_guid: item.id,
          duration: differenceInSeconds(item.till, item.since), // duration on scheduler
          starts_at: item.since.toLocaleString("en-US"),
          ends_at: item.till.toLocaleString("en-US"),
        };

        if (day.template_day_id) {
          apiItem.template_day_id = day.template_day_id;
        }

        if (item.template_day_item_id) {
          apiItem.template_day_item_id = item.template_day_item_id;
        }

        return apiItem;
      });

      return {
        ...day,
        breaks,
        items,
      };
    });

    payload.days = days;

    axios
      .post(`api/templates/${template_id}`, payload)
      .then(() => {
        toast.success("Template saved successfully!");
        setTemplate((prev) => ({
          ...prev,
          hasUnsavedChanges: false,
        }));
      })
      .catch((e) => {
        console.error(e);
      })
      .finally(() => setSaving(false));
  }

  function getSegmentIndexWithinTime(breaksArray, time) {
    return breaksArray.findIndex((s) => dateIsBetween(time, s.start, s.end, "[)"));
  }

  function onDragStart({ draggableId, source }) {
    if (draggableId.includes("template-content-list")) {
      return;
    }

    requestAnimationFrame(() => {
      setDraggedItemSourceDay(source.droppableId.split("-")[1]);
    });
  }

  function onDragEnd({ source, destination }) {
    setDraggedItemSourceDay(null);

    if (!destination || destination.droppableId === "template-content-list") {
      return;
    }

    const targetDay = template.days.find((day) => day.day_number == destination.droppableId.split("-")[1]);
    if (!targetDay) {
      return;
    }

    let currentBreakStartTime = null;
    let currentBreakEndTime = null;
    let sourceSegment = null;
    // destination id might include a break
    if (destination.droppableId.includes(":")) {
      const breakTime = destination.droppableId.split("-").slice(-1)[0];
      const [hours, minutes] = breakTime.split(":");
      currentBreakStartTime = setHours(setMinutes(setSeconds(REFERENCE_DATE, 0), minutes), hours);
    }

    // source might also include a break
    if (source.droppableId.includes(":")) {
      const breakTime = source.droppableId.split("-").slice(-1)[0];
      const [hours, minutes] = breakTime.split(":");
      sourceSegment = setHours(setMinutes(setSeconds(REFERENCE_DATE, 0), minutes), hours);
    }

    let isInsert = source.droppableId === "template-content-list";
    let list = [...targetDay.items];
    const dndHelper = EpgDndHelper(channel.channel_id, REFERENCE_DATE, targetDay.templateBreaks);

    let absoluteDestinationIndex = destination.index;
    let absoluteSourceIndex = source.index;

    if (currentBreakStartTime) {
      // indices are relative to the segment they are in, we need the absolute index from here on
      const indexedCounter = [];
      for (let i = 0; i < list.length; i++) {
        const currIndex = getSegmentIndexWithinTime(targetDay.templateBreaks, list[i].since);
        if (indexedCounter[currIndex]) {
          indexedCounter[currIndex] += 1;
        } else {
          indexedCounter[currIndex] = 1;
        }
      }
      const toSegmentIndex = getSegmentIndexWithinTime(targetDay.templateBreaks, currentBreakStartTime);
      const fromSegmentIndex = sourceSegment
        ? getSegmentIndexWithinTime(targetDay.templateBreaks, sourceSegment)
        : null;
      absoluteDestinationIndex += indexedCounter.slice(0, toSegmentIndex).reduce((prev, curr) => prev + curr, 0);
      if (fromSegmentIndex !== null && fromSegmentIndex < toSegmentIndex) {
        // we have for sure already counted this element
        absoluteDestinationIndex--;
      }

      currentBreakEndTime = targetDay.templateBreaks[toSegmentIndex].end;

      if (!isInsert) {
        const fromSegmentIndex = getSegmentIndexWithinTime(targetDay.templateBreaks, sourceSegment);
        absoluteSourceIndex += indexedCounter.slice(0, fromSegmentIndex).reduce((prev, curr) => prev + curr, 0);
      }
    }

    let item = {};

    if (isInsert) {
      item = fromContentListToTemplateItem(programs.data[absoluteSourceIndex]);
      list = dndHelper.insert(list, item, absoluteDestinationIndex, currentBreakStartTime, currentBreakEndTime);
    } else {
      list = dndHelper.move(
        list,
        absoluteSourceIndex,
        absoluteDestinationIndex,
        currentBreakStartTime,
        currentBreakEndTime,
      );
    }

    updateTemplateDay(targetDay.day_number, {
      ...targetDay,
      items: list,
    });
  }

  return (
    <React.Fragment>
      <AppHeading
        breadcrumbs={breadcrumbs}
        rightActions={[
          {
            when: !isSaving,
            icon: PublishRounded,
            balloonLabel: "Schedule Template",
            onClick: openPublishDialog,
          },
          {
            when: !isSaving,
            icon: SaveRoundedIcon,
            balloonLabel: "Save",
            onClick: saveTemplate,
          },
          {
            when: isSaving,
            icon: Loader,
          },
        ]}
      />
      <SchedulerProvider timezone="UTC">
        <AppBody loading={templateLoading}>
          <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
            <div className="template-page">
              <div className="template-page__content-list">
                {!template ? (
                  <Loader />
                ) : (
                  <TemplateContentList
                    template={template}
                    programs={programs}
                    getPrograms={getPrograms}
                    isLoading={contentListLoading}
                    addItem={addItem}
                  />
                )}
              </div>
              <div className="template-page__template-days">
                <div
                  className="template-page__template-days__inner"
                  style={template?.days ? { width: `${(template.days.length + 1) * TEMPLATE_COLUMN_WIDTH}px` } : {}}
                >
                  {template?.days
                    ? template.days.map((day, index) => (
                        <TemplateDay
                          day={day}
                          refDate={REFERENCE_DATE}
                          isAddDayColumn={day.day_number === 0}
                          channelBreaks={channel?.channel_breaks ?? []}
                          templateBreaks={day.templateBreaks}
                          channelId={channel?.channel_id}
                          index={index}
                          addTemplateDay={addTemplateDay}
                          removeTemplateDay={openRemoveDialog}
                          updateTemplateDay={updateTemplateDay}
                          isDropDisabled={isDropDisabled}
                          templateColumnWidth={TEMPLATE_COLUMN_WIDTH}
                          key={index}
                          setActiveContent={setContentBoxContent}
                        />
                      ))
                    : null}
                  <div className="template-page__add-day">
                    <button className="btn btn--icon" onClick={addTemplateDay}>
                      <AddCircleRoundedIcon />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </DragDropContext>
        </AppBody>
      </SchedulerProvider>
      <RemoveTemplateDayDialog
        isOpen={removeDayDialog.isOpen}
        onClose={closeRemoveDialog}
        onSubmit={removeTemplateDay}
        dayNumber={removeDayDialog.dayNumber}
        key={`remove_day_dialog-${uuid()}`}
      />
      <PublishTemplateDialog
        isOpen={publishTemplateDialog.isOpen}
        onClose={closePublishDialog}
        channelId={publishTemplateDialog.channelId}
        templateId={publishTemplateDialog.templateId}
        daysCount={publishTemplateDialog.daysCount}
        channelName={publishTemplateDialog.channelName}
        hasUnsavedChanges={publishTemplateDialog.hasUnsavedChanges}
        key={`publish_template_dialog-${uuid()}`}
      />

      <DraggableContentBox
        visible={contentBoxContent !== null}
        onClose={onCloseContentBox}
        content={contentBoxContent}
      />
    </React.Fragment>
  );
}
