import React from "react";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";

function EditablePillList({ list, editing, onRemove, onAdd, emptyMessage }) {
  return (
    <div className="pill-list">
      {list?.map((item, index) => (
        <button
          className={`pill-list__pill ${editing ? "pill-list__pill--clickable" : ""}`}
          onClick={onRemove.bind(null, index)}
          key={index}
        >
          <span className="pill-list__label">{item}</span>
          {editing ? <CancelRoundedIcon /> : null}
        </button>
      ))}
      <React.Fragment>
        {editing ? (
          <button className="pill-list__pill pill-list__pill--clickable" onClick={onAdd}>
            <span className="pill-list__label">Add</span>
            <AddCircleRoundedIcon />
          </button>
        ) : null}
        {!editing && (!list || !list.length) ? <span className="content-page__text">{emptyMessage}</span> : null}
      </React.Fragment>
    </div>
  );
}

export default EditablePillList;
