import React, { useRef } from "react";
import { toast } from "react-toastify";
import CreateIngestFeedWindow from "./ingest-feeds/create-ingest-feed.jsx";
import ContentCard from "././library/components/content-card.jsx";
import DownloadIngestFeedTemplate from "./ingest-feeds/download-ingest-feed-template.jsx";
import LoginRoundedIcon from "@mui/icons-material/LoginRounded";
import SimCardDownloadRoundedIcon from "@mui/icons-material/SimCardDownloadRounded";
import ArrowCircleRightRoundedIcon from "@mui/icons-material/ArrowCircleRightRounded";
import { AppHeading } from "../layout/parts/app-heading.jsx";
import AppBody from "../layout/parts/app-body.jsx";
import { format } from "date-fns";
import DataTable from "../components/data-table/data-table.jsx";
import { Link } from "react-router-dom";
import IngestFeedCollapsableTable from "./ingest-feeds/ingest-feed-collapsable-table.jsx";

function IngestFeedListPage() {
  const [showCreate, setShowCreate] = React.useState(false);
  const [showDownloadTemplate, setShowDownloadTemplate] = React.useState(false);
  const tableRef = useRef();
  const tablePortalRef = React.useRef();
  const columns = React.useMemo(
    () => [
      {
        label: "Source",
        key: "source_name",
        value: "source_name",
        sortable: true,
        width: 17,
      },
      {
        label: "Feed",
        key: "display_name",
        value: "display_name",
        sortable: true,
        width: 15,
      },
      {
        label: "Type (Spec)",
        key: "feed_type",
        value: "feed_type",
        sortable: true,
        width: 15,
        renderValue: (row) => (
          <span className="data-table__cell__value">
            {row.feed_type} ({row.feed_spec})
          </span>
        ),
      },
      {
        label: "Runs",
        key: "runs_count",
        value: "runs_count",
        width: 5,
        renderValue: (row) => (
          <span className="data-table__action-cell data-table__action-cell--centered">
            <IngestFeedCollapsableTable data={row} hideTableRef={tablePortalRef} />
          </span>
        ),
      },
      {
        label: "Provider",
        key: "provider_name",
        value: "provider.provider_name",
        width: 10,
      },
      {
        label: "Next Scheduled Run",
        key: "scheduled_next_run",
        value: "scheduled_next_run",
        width: 15,
        renderValue: (row) => (
          <span className="data-table__cell__value">
            {row?.scheduled_next_run ? format(new Date(row.scheduled_next_run), "Y-MM-dd HH:mm:ss") : "Once-off"}
          </span>
        ),
      },
      {
        label: "Feed Url",
        key: "feed_url",
        value: "feed_url",
        sortable: true,
        width: 15,
        renderValue: (row) => (
          <span className="data-table__cell__value">
            <a href={row.feed_url}>{row.feed_url}</a>
          </span>
        ),
      },
      {
        label: "",
        value: "",
        key: "0",
        width: 8,
        renderValue: (row) => (
          <span className="data-table__action-cell">
            <span className="data-table__action-cell__item data-table__icon">
              <Link to={`${row.ingest_feed_id}`}>
                <ArrowCircleRightRoundedIcon />
              </Link>
            </span>
          </span>
        ),
      },
    ],
    [],
  );

  function toggleCreate() {
    setShowCreate((prev) => !prev);
  }

  function toggleDownloadTemplate() {
    setShowDownloadTemplate((prev) => !prev);
  }

  function refreshPage() {
    tableRef.current?.refreshList();
  }

  function downloadStarted() {
    toast.info("Your download has started");
    setShowDownloadTemplate(false);
  }

  return (
    <React.Fragment>
      <AppHeading
        breadcrumbs={[
          {
            link: "/content",
            title: "Content",
          },
          {
            title: "Import",
            link: "/content/import",
          },
          {
            title: "Feeds",
            link: "/content/import/feeds",
          },
        ]}
        rightActions={[
          {
            onClick: toggleDownloadTemplate,
            icon: SimCardDownloadRoundedIcon,
            balloonLabel: "Download Ingestion Template",
            balloonLabelPosition: "left",
          },
          {
            onClick: toggleCreate,
            icon: LoginRoundedIcon,
            balloonLabel: "Create Ingest Feed",
            balloonLabelPosition: "left",
          },
        ]}
      />
      <AppBody>
        <div className="gw gw--full-height">
          <div className="g g--1-of-1 g--full-height">
            <ContentCard noPadding>
              <DataTable url="/api/feeds" columns={columns} ref={tableRef} />
            </ContentCard>
          </div>
        </div>
      </AppBody>
      <CreateIngestFeedWindow isOpen={showCreate} onDone={toggleCreate} onSuccess={refreshPage} />
      <DownloadIngestFeedTemplate
        isOpen={showDownloadTemplate}
        close={toggleDownloadTemplate}
        onDone={downloadStarted}
      />
      <div className="trigger-area" ref={tablePortalRef}></div>
    </React.Fragment>
  );
}

export default IngestFeedListPage;
