import React from "react";

function ImageWithFallback({ url }) {
  const fallback = "/images/no-image-placeholder.png";
  return <img src={url || fallback} alt="" />;
}

function MissingProgram() {
  return (
    <div className="epg-program-overview">
      <div className="missing-content">
        <p className="missing-content__text">Select a program or channel to see more information</p>
      </div>
    </div>
  );
}

function EpgProgramInfo({ program }) {
  let type = null;
  let guid = null;
  if (program && program?.__gstvMeta) {
    type = program.__gstvMeta.link_type;
    guid = program.__gstvMeta.link_guid;
  }

  if (!program || !type || !guid) {
    return <MissingProgram />;
  }

  function formatTime24Hour(timeString) {
    return new Date(timeString).toLocaleString("en-UK", {
      hour: "numeric",
      minute: "numeric",
      hour12: false,
    });
  }

  return (
    <div className="epg-program-overview">
      <div className="epg-program-overview__inner">
        <div className="program-information">
          <h3 className="program-information__heading program-information__heading--left">{program.title}</h3>
          <div className="program-information__fields">
            <div className="gw">
              <div className="g g--1-of-1">
                <div className="home-page-image">
                  <div className="poster__image">
                    <ImageWithFallback url={program.image} />
                  </div>
                </div>
              </div>
              <div className="g g--1-of-2">
                <div className="program-field">
                  <div className="program-field__label">Runtime</div>
                  <div className="program-field__value">
                    {formatTime24Hour(program.since)} - {formatTime24Hour(program.till)}
                  </div>
                </div>
                <div className="program-field">
                  <div className="program-field__label">Description</div>
                  <div className="program-field__value">{program?.description ? program.description : "None"}</div>
                </div>
              </div>
              <div className="g g--1-of-2">
                <div className="program-field">
                  <div className="program-field__label">Rating</div>
                  <div className="program-field__value">{program.Rated ?? "-"}</div>
                </div>
                <div className="program-field">
                  <div className="program-field__label">Genre</div>
                  <div className="program-field__value">
                    {program?.Genre && program.Genre.length > 0 ? program.Genre.join(", ") : "None Provided"}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EpgProgramInfo;
