import axios from "axios";

function getUrlParamByName(url, name) {
  const replacedName = name.replace(/[[\]]/g, "\\$&");
  const regex = new RegExp(`[?&]${replacedName}(=([^&#]*)|&|#|$)`);
  const results = regex.exec(url);
  if (!results) {
    return null;
  }

  if (!results[2]) {
    return "";
  }

  return decodeURIComponent(results[2].replace(/\+/g, " "));
}

export function downloadResponseAs(response, fileName) {
  // create file link in browser's memory
  const href = URL.createObjectURL(response.data);

  // create "a" HTML element with href to file & click
  const link = document.createElement("a");
  link.href = href;
  link.setAttribute("download", fileName); //or any other extension
  document.body.appendChild(link);
  link.click();

  // clean up "a" element & remove ObjectURL
  document.body.removeChild(link);
  URL.revokeObjectURL(href);
}

function getEncodedUrl(currentUrl) {
  const splitUrl = currentUrl.split("?");
  const baseUrl = splitUrl[0];
  const nextUrlSegments = splitUrl[1].split("&");
  let nextQueryString = splitUrl[1];
  if (nextUrlSegments.length > 0) {
    nextQueryString = nextUrlSegments
      .map((v) => {
        let nextValue = v;

        if (v.includes("=")) {
          const temp = v.split("=");
          nextValue = `${temp[0]}=${encodeURIComponent(temp[1])}`;
        }

        return nextValue;
      })
      .join("&");
  }
  return `${baseUrl}?${nextQueryString}`;
}

const axiosInstance = () => {
  const instance = axios.create({
    validateStatus: (status) => status >= 200 && status < 300,
    baseURL: import.meta.env.VITE_API_BASE_URL ?? null,
  });

  let token = "";
  if (typeof window !== "undefined") {
    if (window.localStorage.getItem("__bearer")) {
      token = window.localStorage.getItem("__bearer");
      instance.defaults.headers.Authorization = token;
    } else if (window.location.href.includes("token=")) {
      token = `Bearer ${getUrlParamByName(window.location.href, "token")}`;
      instance.defaults.headers.Authorization = token;
    }
  }

  instance.defaults.headers["Content-Type"] = "application/json;charset=UTF-8";

  // Add a request interceptor
  instance.interceptors.request.use(
    (request, error) => {
      if (error) {
        return Promise.reject(error.response);
      }

      if (request.url.includes("search") && request.url.includes("?")) {
        // do some uri manipulation for ie
        // https://stackoverflow.com/questions/18220057
        // eslint-disable-next-line no-param-reassign
        request.url = getEncodedUrl(request.url);
      }

      if (!request.headers["Content-Type"]) {
        request.headers["Content-Type"] = "application/json; charset=utf-8";
      }

      if (typeof request.body === "undefined") {
        request.body = {};
      }

      if (typeof window !== "undefined" && window.localStorage.getItem("__bearer")) {
        token = window.localStorage.getItem("__bearer");
        // eslint-disable-next-line no-param-reassign
        request.headers.Authorization = token;
      }
      return request;
    },
    (error) => Promise.reject(error.message),
  );

  // Add a response interceptor
  instance.interceptors.response.use(
    (response, error) => {
      if (error) {
        return Promise.reject(error.response);
      }
      if (response.headers.authorization && typeof window !== "undefined") {
        const newToken = response.headers.authorization;
        window.localStorage.setItem("__bearer", newToken);
      }
      return response;
    },
    (error) => {
      if (error?.response) {
        // network error
        if (
          (error.response.data &&
            error.response.data.error &&
            (error.response.data.error === "token_invalid" || error.response.data.error === "token_expired")) ||
          error.response.status === 401 ||
          error.response.status === 503 ||
          error.response.status === 400
        ) {
          window.localStorage.clear();
          History.navigate("/login", { state: { from: window.location.pathname } });
        }
        return Promise.reject(error.response);
      }
      return Promise.reject(error);
    },
  );

  return instance;
};

export default axiosInstance();
