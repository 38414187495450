import React from "react";
import Loader from "../../common/loader.jsx";

function WithLoader({ loading, children }) {
  return loading ? (
    <div className="scheduler-content__loader">
      <Loader />
    </div>
  ) : (
    children
  );
}

function WithEmptyResults({ results = [], children }) {
  return !results.length ? <li className="content-list__results__empty">No results...</li> : children(results);
}

function ContentListResults({ isLoading, results, children }) {
  return (
    <div className="content-list__results content-list-results">
      <WithLoader loading={isLoading}>
        <WithEmptyResults results={results}>{children}</WithEmptyResults>
      </WithLoader>
    </div>
  );
}

export default ContentListResults;
