import React from "react";
import ReactModal from "react-modal";
import axios from "../../../../requests/axios";
import { toast } from "react-toastify";
import useSpreadState from "../../../../hooks/use-spread-state";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import ContentPosterSelector from "../content-poster-selector";
import Loader from "../../../../common/loader";

function EditPosterDialog({ isOpen, onClose, onSuccess, type = "", guid, provider }) {
  const [posters, setPosters] = React.useState([]);
  const [form, updateForm] = useSpreadState({});
  const [isLoading, setLoading] = React.useState(false);
  const [lightbox, setLightbox] = React.useState({
    open: false,
    urls: [],
    index: 0,
  });

  const onSave = React.useCallback(() => {
    axios
      .put(`/api/providers/${provider.provider_id}/${type}/${guid}/poster`, form)
      .then((resp) => {
        onSuccess(resp.data);
        toast.success("Content posters updated");
        onClose();
      })
      .catch((error) => {
        console.error(error);
        toast.error("Error: Could not update posters");
      });
  }, [guid, type, provider.provider_id, form, onClose, onSuccess]);

  React.useEffect(() => {
    if (isOpen) {
      setLoading(true);

      axios
        .get(`/api/providers/${provider.provider_id}/${type}/${guid}`)
        .then((resp) => {
          const defaultPosters = {};
          const data = resp.data.map((image) => {
            if (image.content_poster_thumbnail) {
              defaultPosters.thumbnail_asset_id = image.content_poster_thumbnail.thumbnail_asset_id;
            }

            if (image.content_poster_medium) {
              defaultPosters.medium_asset_id = image.content_poster_medium.medium_asset_id;
            }

            if (image.content_poster_large) {
              defaultPosters.large_asset_id = image.content_poster_large.large_asset_id;
            }

            if (image.content_poster_vertical) {
              defaultPosters.vertical_asset_id = image.content_poster_vertical.vertical_asset_id;
            }

            return {
              id: image.asset_id,
              url: image.source_url,
            };
          });

          updateForm(defaultPosters);
          setPosters(data);
        })
        .catch((e) => {
          console.error(e);
          toast.error(
            "Error loading assets. Perhaps one or more of this content's assets does not have an S3 location.",
          );
        })
        .finally(() => setLoading(false));
    }
  }, [isOpen, provider.provider_id, updateForm, guid, type]);

  function openLightbox(posterIndex) {
    setLightbox((prev) => ({ ...prev, open: true, posterIndex }));
  }

  return (
    <ReactModal isOpen={isOpen} ariaHideApp={false} style={{ content: { maxWidth: "1280px" } }}>
      <div className="v-react-modal__inner modal-width">
        <div className="v-react-modal__heading">Edit {type.charAt(0).toUpperCase() + type.slice(1)} Posters</div>
        <div className="v-react-modal__body modal-body">
          {isLoading ? (
            <Loader />
          ) : posters.length ? (
            <ContentPosterSelector posters={posters} form={form} updateForm={updateForm} openLightbox={openLightbox} />
          ) : (
            "No images found"
          )}
        </div>
        <div className="v-react-modal__footer">
          <button className="btn btn--inverse-primary" onClick={onClose}>
            Cancel
          </button>
          <button className="btn btn--primary" onClick={onSave} disabled={!posters.length}>
            Save
          </button>
        </div>
      </div>
      {posters && lightbox.urls ? (
        <Lightbox
          open={lightbox.open}
          close={() => setLightbox((prev) => ({ ...prev, open: false }))}
          slides={lightbox.urls}
          index={lightbox.index}
        />
      ) : null}
    </ReactModal>
  );
}

export default EditPosterDialog;
