import { formatSeconds } from "./format-seconds.js";

export function createAdBreaks(programDuration, defaultDuration = "00:02:02") {
  // 1 cuepoint each 10 minutes
  // 00:02:02 ad slate for each cuepoint
  let timeMarker = 600; // start at 10 minutes
  let incrementTimeMarkerBy = 600; // every 10 minutes
  let magicalSlates = [];
  while (programDuration > timeMarker) {
    magicalSlates.push({ ad_break: defaultDuration, cuepoint: formatSeconds(timeMarker) });
    timeMarker += incrementTimeMarkerBy;
  }

  return magicalSlates;
}

export function getCuepointLocation(adBreak) {
  const [hours, minutes, seconds] = adBreak.cuepoint.split(":");
  const total = +seconds + +minutes * 60 + +hours * 3600;
  return total;
}

export function getAdBreakDuration(adBreaks) {
  if (adBreaks.length === 0) {
    return 0;
  }
  return adBreaks.reduce((prev, curr) => {
    const [hours, minutes, seconds] = curr.ad_break.split(":");
    const total = +seconds + +minutes * 60 + +hours * 3600;

    return prev + total;
  }, 0);
}

export function getDurationOfAllCuepoints(adBreaks) {
  adBreaks.reduce((prev, curr) => {
    return prev + curr.cuepoint;
  }, 0);
}

export function getDurationInSecondsWithAdBreaks(duration, adBreaks) {
  return Math.floor(getAdBreakDuration(adBreaks) + duration);
}

export function getDurationWithAdBreaks(duration, adBreaks) {
  return formatSeconds(getDurationInSecondsWithAdBreaks(duration, adBreaks));
}

export function filterAdBreaksAfter(adBreaks, totalSeconds) {
  return adBreaks.filter((curr) => {
    const [hours, minutes, seconds] = curr.cuepoint.split(":");
    const total = +seconds + +minutes * 60 + +hours * 3600;

    return total <= totalSeconds;
  });
}

export function getSecondsFromHis(hisTime) {
  const [hours, minutes, seconds] = hisTime.split(":");
  const total = +seconds + +minutes * 60 + +hours * 3600;

  return total;
}
