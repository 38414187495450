import React from "react";
import { AppHeading } from "../layout/parts/app-heading";
import AppBody from "../layout/parts/app-body";
import { Link } from "react-router-dom";
import ApartmentRoundedIcon from "@mui/icons-material/ApartmentRounded";
import CoPresentRoundedIcon from "@mui/icons-material/CoPresentRounded";
import GroupsRoundedIcon from "@mui/icons-material/GroupsRounded";
import SettingsInputAntennaRoundedIcon from "@mui/icons-material/SettingsInputAntennaRounded";
import PlaylistAddCheckRoundedIcon from "@mui/icons-material/PlaylistAddCheckRounded";

function AdminDashboardPage() {
  return (
    <React.Fragment>
      <AppHeading
        breadcrumbs={[
          {
            link: "/admin",
            title: "Admin",
          },
        ]}
      />
      <AppBody>
        <div className="gw">
          <div className="g g--1-of-1">
            <div className="island--smaller">
              <div className="gw">
                <div className="g g--1-of-5">
                  <Link to="/admin/organisations" className="btn btn--base--svg btn--wide">
                    <ApartmentRoundedIcon /> Organisations
                  </Link>
                </div>
                <div className="g g--1-of-5">
                  <Link to="/admin/providers" className="btn btn--base--svg btn--wide">
                    <CoPresentRoundedIcon /> Providers
                  </Link>
                </div>
                <div className="g g--1-of-5">
                  <Link to="/admin/users" className="btn btn--base--svg btn--wide">
                    <GroupsRoundedIcon /> Users
                  </Link>
                </div>
                <div className="g g--1-of-5">
                  <Link to="/admin/channels" className="btn btn--base--svg btn--wide">
                    <SettingsInputAntennaRoundedIcon /> Channels
                  </Link>
                </div>
                <div className="g g--1-of-5">
                  <Link to="/admin/dash" className="btn btn--base--svg btn--wide">
                    <PlaylistAddCheckRoundedIcon /> Dash QC
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AppBody>
    </React.Fragment>
  );
}

export default AdminDashboardPage;
