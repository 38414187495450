import React from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../../providers/auth-provider.jsx";
import DashboardRoundedIcon from "@mui/icons-material/DashboardRounded";
import LoginRoundedIcon from "@mui/icons-material/LoginRounded";
import AssignmentTurnedInRoundedIcon from "@mui/icons-material/AssignmentTurnedInRounded";
import DvrIcon from "@mui/icons-material/Dvr";
import TheatersIcon from "@mui/icons-material/Theaters";
import SmartDisplayRoundedIcon from "@mui/icons-material/SmartDisplayRounded";
import InsertChartIcon from "@mui/icons-material/InsertChart";
import ArticleRoundedIcon from "@mui/icons-material/ArticleRounded";
import PlaylistAddCheckRoundedIcon from "@mui/icons-material/PlaylistAddCheckRounded";
import SettingsApplicationsRoundedIcon from "@mui/icons-material/SettingsApplicationsRounded";
import ApartmentRoundedIcon from "@mui/icons-material/ApartmentRounded";
import CoPresentRoundedIcon from "@mui/icons-material/CoPresentRounded";
import GroupsRoundedIcon from "@mui/icons-material/GroupsRounded";
import SettingsInputAntennaRoundedIcon from "@mui/icons-material/SettingsInputAntennaRounded";
import PersonRoundedIcon from "@mui/icons-material/PersonRounded";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import ArrowBackIosRoundedIcon from "@mui/icons-material/ArrowBackIosRounded";
import TableChartIcon from "@mui/icons-material/TableChart";
import InsightsIcon from "@mui/icons-material/Insights";

const Sidebar = ({ currentPage }) => {
  const sidebarCollapsedStateKey = "__gstv-ui__sidebar__sidebar-collapsed";
  const [sidebarCollapsed, setSidebarCollapsed] = React.useState(
    window.localStorage.getItem(sidebarCollapsedStateKey)
      ? window.localStorage.getItem(sidebarCollapsedStateKey) === "collapsed"
      : false,
  );
  const [sectionCollapsed, setSectionCollapsed] = React.useState({
    admin: true,
    content: true,
    channelScheduler: true,
    analytics: true,
  });
  const expandedSpacingRef = React.useRef();
  const { user, logout } = useAuth();

  const toggleSectionCollapse = React.useCallback((section) => {
    setSectionCollapsed((prev) => ({
      ...prev,
      [section]: !prev[section],
    }));
  }, []);

  const addClasses = (page) => (page === currentPage ? "active" : "");
  const isPlatformAdmin = user.roles.some((role) => role.role_name === "platform_admin");

  // Set collapse key if it doesn't exist
  React.useEffect(() => {
    if (!window.localStorage.getItem(sidebarCollapsedStateKey)) {
      window.localStorage.setItem(sidebarCollapsedStateKey, "open");
    }
  }, []);

  React.useEffect(() => {
    if (expandedSpacingRef?.current) {
      function outsideClickListener(event) {
        if (event.target === expandedSpacingRef.current) {
          setSectionCollapsed(() => ({
            ...Object.entries(sectionCollapsed).reduce(
              (prev, [key]) => ({
                ...prev,
                [key]: true,
              }),
              {},
            ),
          }));
        }
      }

      const ref = expandedSpacingRef.current;

      const hasOpen = Object.entries(sectionCollapsed).some(([_, value]) => !value);
      if (hasOpen && ref) {
        ref.classList.add("trigger-area--active");
        document.addEventListener("click", outsideClickListener);
      }

      return () => {
        const hasOpen = Object.entries(sectionCollapsed).some(([_, value]) => !value);
        if (hasOpen && ref) {
          ref.classList.remove("trigger-area--active");
          document.removeEventListener("click", outsideClickListener);
        }
      };
    }
  }, [sectionCollapsed, expandedSpacingRef]);

  function toggleSidebarCollapse() {
    const state = !sidebarCollapsed;
    setSidebarCollapsed(state);
    document.activeElement?.blur();
    window.localStorage.setItem(sidebarCollapsedStateKey, state ? "collapsed" : "open");
  }

  function closeSection(name) {
    setSectionCollapsed((prev) => ({
      ...prev,
      [name]: true,
    }));
  }

  return (
    <ul className={`app-sidebar ${sidebarCollapsed ? "app-sidebar--collapsed" : ""}`}>
      <li className="trigger-area trigger-area--full-width" ref={expandedSpacingRef}></li>

      <li className="app-sidebar__item">
        <div className="app-sidebar__logo">
          {sidebarCollapsed ? (
            <img src="/images/gstv-icon.png" width="48px" />
          ) : (
            <img src="/images/gstv-modern-logo.png" height="50px" />
          )}
        </div>
        <button className="app-sidebar__toggler" onClick={toggleSidebarCollapse}>
          <ArrowBackIosRoundedIcon />
        </button>
      </li>

      <li className="app-sidebar__item">
        <Link to="/" className={`app-sidebar__link ${addClasses("home")}`}>
          <DashboardRoundedIcon />
          <span className="app-sidebar__nav-text">Home</span>
        </Link>
      </li>

      <li className="app-sidebar__item">
        <button
          className={`app-sidebar__link app-sidebar__link--dropdown-header ${addClasses(["content", "import", "quality-control", "library"].includes(currentPage) ? currentPage : "")}`}
          onClick={toggleSectionCollapse.bind(null, "content")}
        >
          <ArticleRoundedIcon />
          <span className="app-sidebar__nav-text">Content</span>
        </button>

        <ul className={`app-sidebar ${sectionCollapsed.content ? "app-sidebar--hidden" : "app-sidebar--visible"}`}>
          <li className="app-sidebar__item">
            <Link
              to="/content"
              className={`app-sidebar__link ${addClasses("content")}`}
              onClick={() => closeSection("content")}
            >
              <ArticleRoundedIcon />
              <span className="app-sidebar__nav-text">Content</span>
            </Link>
          </li>
          <li className="app-sidebar__item">
            <Link
              to="/content/import"
              className={`app-sidebar__link ${addClasses("import")}`}
              onClick={() => closeSection("content")}
            >
              <LoginRoundedIcon />
              <span className="app-sidebar__nav-text">Import</span>
            </Link>
          </li>
          <li className="app-sidebar__item">
            <Link
              to="/content/quality-control"
              className={`app-sidebar__link ${addClasses("quality-control")}`}
              onClick={() => closeSection("content")}
            >
              <AssignmentTurnedInRoundedIcon />
              <span className="app-sidebar__nav-text">Quality Control</span>
            </Link>
          </li>
          <li className="app-sidebar__item">
            <Link
              to="/content/library"
              className={`app-sidebar__link ${addClasses("library")}`}
              onClick={() => closeSection("content")}
            >
              <ArticleRoundedIcon />
              <span className="app-sidebar__nav-text">Library</span>
            </Link>
          </li>
        </ul>
      </li>

      <li className="app-sidebar__item">
        <button
          className={`app-sidebar__link app-sidebar__link--dropdown-header ${addClasses(["scheduler", "templates"].includes(currentPage) ? currentPage : "")}`}
          onClick={toggleSectionCollapse.bind(null, "channelScheduler")}
        >
          <DvrIcon />
          <span className="app-sidebar__nav-text">Channel Scheduler</span>
        </button>

        <ul
          className={`app-sidebar ${sectionCollapsed.channelScheduler ? "app-sidebar--hidden" : "app-sidebar--visible"}`}
        >
          <li className="app-sidebar__item">
            <Link
              to="/scheduler"
              className={`app-sidebar__link ${addClasses("scheduler")}`}
              onClick={() => closeSection("channelScheduler")}
            >
              <SettingsInputAntennaRoundedIcon />
              <span className="app-sidebar__nav-text">Channels</span>
            </Link>
          </li>

          <li className="app-sidebar__item">
            <Link
              to="/templates"
              className={`app-sidebar__link ${addClasses("templates")}`}
              onClick={() => closeSection("channelScheduler")}
            >
              <ArticleRoundedIcon />
              <span className="app-sidebar__nav-text">Templates</span>
            </Link>
          </li>
        </ul>
      </li>

      <li className="app-sidebar__item">
        <Link to="/playouts" className={`app-sidebar__link ${addClasses("playouts")}`}>
          <TheatersIcon />
          <span className="app-sidebar__nav-text">Channel Playouts</span>
        </Link>
      </li>

      <li className="app-sidebar__item">
        <Link to="/avod" className={`app-sidebar__link ${addClasses("playlists")}`}>
          <SmartDisplayRoundedIcon />
          <span className="app-sidebar__nav-text">AVOD</span>
        </Link>
      </li>

      <li className="app-sidebar__item">
        <button
          className={`app-sidebar__link app-sidebar__link--dropdown-header ${addClasses(["analytics-dashboards", "analytics-reporting"].includes(currentPage) ? currentPage : "")}`}
          onClick={toggleSectionCollapse.bind(null, "analytics")}
        >
          <InsertChartIcon />
          <span className="app-sidebar__nav-text">Analytics</span>
        </button>
        <ul className={`app-sidebar ${sectionCollapsed.analytics ? "app-sidebar--hidden" : "app-sidebar--visible"}`}>
          <li className="app-sidebar__item">
            <Link
              to="/analytics/dashboards"
              className={`app-sidebar__link ${addClasses("analytics-dashboards")}`}
              onClick={() => closeSection("analytics")}
            >
              <InsightsIcon />
              <span className="app-sidebar__nav-text">Dashboards</span>
            </Link>
          </li>
          <li className="app-sidebar__item">
            <Link
              to="/analytics/reports"
              className={`app-sidebar__link ${addClasses("analytics-reports")}`}
              onClick={() => closeSection("analytics")}
            >
              <TableChartIcon />
              <span className="app-sidebar__nav-text">Reports</span>
            </Link>
          </li>
        </ul>
      </li>

      <li className="app-sidebar__divider"></li>

      {/* admin check */}
      <li className="app-sidebar__item">
        <a
          className={`app-sidebar__link app-sidebar__link--dropdown-header ${addClasses(["admin", "admin-organisations", "admin-providers", "admin-users", "admin-channels", "admin-dash-qc"].includes(currentPage) ? currentPage : "")} `}
          onClick={toggleSectionCollapse.bind(null, "admin")}
        >
          <SettingsApplicationsRoundedIcon />
          <span className="app-sidebar__nav-text">Admin</span>
        </a>

        <ul className={`app-sidebar ${sectionCollapsed.admin ? "app-sidebar--hidden" : "app-sidebar--visible"}`}>
          {/* platform_admin role check for this option*/}

          {isPlatformAdmin ? (
            <li className="app-sidebar__item">
              <Link
                to="/admin/organisations"
                className={`app-sidebar__link ${addClasses("admin-organisations")}`}
                onClick={() => closeSection("admin")}
              >
                <ApartmentRoundedIcon />
                <span className="app-sidebar__nav-text">Organisations</span>
              </Link>
            </li>
          ) : null}

          <li className="app-sidebar__item">
            <Link
              to="/admin/providers"
              className={`app-sidebar__link ${addClasses("admin-providers")}`}
              onClick={() => closeSection("admin")}
            >
              <CoPresentRoundedIcon />
              <span className="app-sidebar__nav-text">Providers</span>
            </Link>
          </li>

          <li className="app-sidebar__item">
            <Link
              to="/admin/users"
              className={`app-sidebar__link ${addClasses("admin-users")}`}
              onClick={() => closeSection("admin")}
            >
              <GroupsRoundedIcon />
              <span className="app-sidebar__nav-text">Users</span>
            </Link>
          </li>

          <li className="app-sidebar__item">
            <Link
              to="/admin/channels"
              className={`app-sidebar__link ${addClasses("admin-channels")}`}
              onClick={() => closeSection("admin")}
            >
              <SettingsInputAntennaRoundedIcon />
              <span className="app-sidebar__nav-text">Channels</span>
            </Link>
          </li>

          <li className="app-sidebar__item">
            <Link
              to="/admin/dash"
              className={`app-sidebar__link ${addClasses("admin-dash-qc")}`}
              onClick={() => closeSection("admin")}
            >
              <PlaylistAddCheckRoundedIcon />
              <span className="app-sidebar__nav-text">Dash QC</span>
            </Link>
          </li>
        </ul>
      </li>

      <li className="app-sidebar__item">
        <Link to="/user" className={`app-sidebar__link ${addClasses("user")}`}>
          <PersonRoundedIcon />
          <span className="app-sidebar__nav-text">User</span>
        </Link>
      </li>
      <li className="app-sidebar__item">
        <a
          onClick={() => {
            logout();
          }}
          className="app-sidebar__link"
        >
          <LogoutRoundedIcon />
          <span className="app-sidebar__nav-text">Logout</span>
        </a>
      </li>
    </ul>
  );
};

export default Sidebar;
