import React from "react";
import Loader from "../../common/loader.jsx";

// TODO: Handle error messages here as well
export default function AppBody({ loading, children }) {
  return (
    <div className="app-body">
      {loading ? (
        <div className="app-body__loader">
          <Loader />
        </div>
      ) : (
        children
      )}
    </div>
  );
}
