import React from "react";
import Select from "../../../common/select.jsx";
import { getSeasonsForSeries } from "../../../requests/api-requests.js";

const defaultOption = {
  label: "All",
  value: "",
};

function SeasonFilter({ providerId, seriesId, season, onChange, disabled = false }) {
  const [isLoading, setLoading] = React.useState(false);
  const [seasons, setSeasons] = React.useState([]);

  React.useEffect(() => {
    setSeasons([]); // reset list

    if (providerId && seriesId) {
      setLoading(true);

      getSeasonsForSeries(seriesId, providerId)
        .then((data) => {
          setSeasons([
            defaultOption,
            ...data.map((s) => ({
              label: `Season ${s.season_number}`,
              value: s.season_guid,
            })),
          ]);
        })
        .catch((e) => {
          console.error(e);
        })
        .finally(() => setLoading(false));
    }
  }, [providerId, seriesId]);

  const selected = season.value ? season : defaultOption;

  return (
    <Select
      name={"season"}
      value={selected}
      options={seasons}
      onChange={(season) => onChange(season)}
      isSearchable={false}
      isDisabled={disabled || !seriesId}
      isLoading={isLoading}
      containerClass="react-select-container--light react-select-container--small"
    />
  );
}

export default SeasonFilter;
