import React from "react";
import { Link } from "react-router-dom";
import LoginRoundedIcon from "@mui/icons-material/LoginRounded";
import ArticleRoundedIcon from "@mui/icons-material/ArticleRounded";
import AssignmentTurnedInRoundedIcon from "@mui/icons-material/AssignmentTurnedInRounded";
import Dashboard from "./dashboards/dashboard.jsx";
import { format } from "date-fns";

export default function ContentDashboardPage() {
  return (
    <Dashboard
      links={[
        {
          label: "Import",
          icon: LoginRoundedIcon,
          url: "/content/import",
        },
        {
          label: "Library",
          icon: ArticleRoundedIcon,
          url: "/content/library",
        },
        {
          label: "Quality Control",
          icon: AssignmentTurnedInRoundedIcon,
          url: "/content/quality-control",
        },
      ]}
      breadcrumbs={[
        {
          link: "/content",
          title: "Content",
        },
      ]}
      filter={{
        queryKey: "provider",
        queryPath: "/api/providers",
        defaultValue: { value: "all", label: "All providers" },
      }}
      statistics={{
        endpoint: "/api/dashboards/content",
      }}
      recentTable={{
        columns: [
          {
            label: "Date Ingested",
            key: "created_at",
            value: "created_at",
            width: 32,
            formatValue: (value) => {
              if (!value) {
                return "-";
              }

              return format(new Date(value), "Y-MM-dd HH:mm:ss");
            },
          },
          {
            label: "Title",
            key: "title",
            value: "label",
            width: 20,
          },
          {
            label: "Provider",
            key: "provider",
            value: "provider",
            width: 20,
          },
          {
            label: "Status",
            key: "status",
            value: "status",
            width: 20,
          },
          {
            label: "",
            key: "",
            value: "",
            align: "right",
            width: 8,
            renderValue: (value) => (
              <span className="data-table__action-cell__item data-table__icon">
                <Link to={`/content/library/${value.link_type}/${value.link_guid}`}>
                  <LoginRoundedIcon />
                </Link>
              </span>
            ),
          },
        ],
        url: "/api/dashboards/content/recent",
      }}
    />
  );
}
