import React from "react";
import {
  ProgramBox,
  ProgramContent,
  ProgramFlex,
  ProgramStack,
  ProgramTitle,
  ProgramText,
  ProgramImage,
  useProgram,
} from "planby";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import PlayArrowRoundedIcon from "@mui/icons-material/PlayArrowRounded";

function EpgProgram({ program, onProgramClick, removeProgram, onPlay, mode = "", ...rest }) {
  const { styles, formatTime, set12HoursTimeFormat, isLive, isMinWidth } = useProgram({
    program,
    ...rest,
  });

  const { data } = program;
  const { image, title, since, till } = data;

  const sinceTime = formatTime(since, set12HoursTimeFormat()).toLowerCase();
  const tillTime = formatTime(till, set12HoursTimeFormat()).toLowerCase();

  return (
    <ProgramBox
      width={styles.width}
      style={{
        ...styles.position,
        // position: 'relative',
        // left: 'unset',
      }}
      className={`program-box program-box--${data.__gstvMeta.link_type}`}
    >
      <ProgramContent width={styles.width} isLive={isLive} onClick={onProgramClick.bind(null, program)}>
        <ProgramFlex>
          {isLive && isMinWidth && <ProgramImage src={image ?? "/images/no-image-placeholder.png"} alt="Preview" />}
          <ProgramStack>
            <ProgramTitle>{title}</ProgramTitle>
            <ProgramText>
              {sinceTime} - {tillTime}
            </ProgramText>
            <div className="program-box__actions">
              {mode !== "view" ? (
                <button
                  className="btn--remove-program"
                  onClick={(e) => {
                    e.stopPropagation();
                    removeProgram(program);
                  }}
                >
                  <CancelRoundedIcon />
                </button>
              ) : null}
            </div>
          </ProgramStack>
          {mode !== "view" ? (
            <button
              className="btn--text-primary program-box__play-button"
              onClick={(e) => {
                e.preventDefault();
                onPlay(program.data.__gstvMeta.video_asset.asset_id);
              }}
            >
              <PlayArrowRoundedIcon />
            </button>
          ) : null}
        </ProgramFlex>
      </ProgramContent>
    </ProgramBox>
  );
}

export default EpgProgram;
