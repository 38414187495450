import React from "react";
import Loader from "../../common/loader";

function PlayerLoader({ isLoading }) {
  if (!isLoading) {
    return false;
  }

  return (
    <div className="plyr__loader">
      <Loader></Loader>
    </div>
  );
}

export default PlayerLoader;
