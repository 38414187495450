import React from "react";
import ReactInputMask from "react-input-mask";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import RemoveCircleRoundedIcon from "@mui/icons-material/RemoveCircleRounded";
import RotateLeftRoundedIcon from "@mui/icons-material/RotateLeftRounded";

function CuepointMeta({ isEditing, form, updateForm, metadata, classes }) {
  const data = { ...metadata, ...(isEditing ? form : {}) };
  const defaultClasses = {
    heading: "planner-meta-cuepoints__heading__text",
  };

  const classLists = {
    ...defaultClasses,
    ...classes,
  };

  function updateCuepoints(list) {
    updateForm({ cuepoints: list });
  }

  function addCuepoint(index) {
    updateCuepoints([
      ...data.cuepoints.slice(0, index + 1),
      { cuepoint: "00:00:00", ad_break: "00:02:02" },
      ...data.cuepoints.slice(index + 1),
    ]);
  }

  function removeCuepoint(index) {
    updateCuepoints([...data.cuepoints.slice(0, index), ...data.cuepoints.slice(index + 1)]);
  }

  function resetCuepoints() {
    updateCuepoints(metadata.cuepoints);
  }

  function updateAdBreak(index, value) {
    updateCuepoints(
      data.cuepoints.map((cuepoint, key) => {
        if (key === index) {
          cuepoint.cuepoint = value;
        }

        return cuepoint;
      }),
    );
  }

  return (
    <div className="program-field">
      <div className="planner-meta-cuepoints__heading">
        <span className={classLists.heading}>Cuepoints</span>
        <span className="planner-meta-cuepoints__actions">
          {isEditing ? (
            <React.Fragment>
              <button className="btn btn--icon" onClick={() => resetCuepoints()}>
                <RotateLeftRoundedIcon />
              </button>
            </React.Fragment>
          ) : null}
        </span>
      </div>
      <div className={`ad-breaks ${!isEditing ? "ad-breaks--view-only" : ""}`}>
        {data.cuepoints?.length || isEditing ? (
          data.cuepoints.map((ad, key) => (
            <div className="ad-breaks__item" key={key}>
              {isEditing ? (
                <div className="ad-breaks__item__actions">
                  <button onClick={() => removeCuepoint(key)} className="ad-breaks__action-btn">
                    <RemoveCircleRoundedIcon />
                  </button>
                  <button onClick={() => addCuepoint(key)} className="ad-breaks__action-btn">
                    <AddCircleRoundedIcon />
                  </button>
                </div>
              ) : null}
              <div className="ad-breaks__item__input">
                <ReactInputMask
                  mask={"99:99:99"}
                  value={ad.cuepoint}
                  onChange={(e) => {
                    updateAdBreak(key, e.target.value);
                  }}
                />
              </div>
            </div>
          ))
        ) : (
          <div className="ad-breaks__empty">No cuepoints for this title.</div>
        )}
      </div>
      <div className="program-field__value" />
    </div>
  );
}

export default CuepointMeta;
