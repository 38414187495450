import React from "react";
import ContentCard from "././library/components/content-card.jsx";
import DataTable from "../components/data-table/data-table.jsx";
import { Link } from "react-router-dom";
import format from "date-fns/format";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import ArrowCircleRightRoundedIcon from "@mui/icons-material/ArrowCircleRightRounded";
import { AppHeading } from "../layout/parts/app-heading.jsx";
import AppBody from "../layout/parts/app-body.jsx";

export default function PlaylistListPage() {
  const columns = React.useMemo(
    () => [
      {
        label: "Playlist Name",
        key: "playlist_name",
        value: "playlist_name",
        sortable: true,
        width: 20,
      },
      {
        label: "Latest Version",
        value: "version",
        key: "version",
        sortable: true,
        width: 15,
      },
      {
        label: "Status",
        value: "status",
        key: "status",
        sortable: true,
        width: 10,
        renderValue: (row) => (
          <span className="data-table__cell__value">{row.status.charAt(0).toUpperCase() + row.status.slice(1)}</span>
        ),
      },
      {
        label: "Playlist URL",
        value: "manifest.source",
        key: "manifest_source",
        width: 30,
        renderValue: (row) => (
          <span className="data-table__cell__value">
            <a href={row.manifest?.source ?? ""}>{row.manifest?.source ?? "-"}</a>
          </span>
        ),
      },
      {
        label: "Last updated",
        value: "updated_at",
        key: "updated_at",
        sortable: true,
        width: 20,
        formatValue: (value) => {
          if (!value) {
            return "-";
          }

          return format(new Date(value), "y-MM-dd");
        },
      },
      {
        label: "",
        value: "",
        key: "0",
        width: 5,
        renderValue: (row) => (
          <span className="data-table__action-cell">
            <span className="data-table__action-cell__item data-table__icon">
              <Link className="link--base u-fs--micro" to={`/avod/playlists/${row.avod_playlist_guid}/versions`}>
                <ArrowCircleRightRoundedIcon />
              </Link>
            </span>
          </span>
        ),
      },
    ],
    [],
  );

  return (
    <React.Fragment>
      <AppHeading
        breadcrumbs={[
          {
            link: "/avod",
            title: "AVOD",
          },
          {
            link: "/avod/Playlists",
            title: "Playlists",
          },
        ]}
        rightActions={[
          {
            type: "anchor",
            href: "/avod/playlists/create",
            balloonLabel: "Create Playlist",
            icon: AddCircleRoundedIcon,
          },
        ]}
      />
      <AppBody>
        <div className="gw gw--full-height">
          <div className="g g--1-of-1 g--full-height">
            <ContentCard noPadding>
              <DataTable url="/api/avod-playlists" withSearch={true} columns={columns} />
            </ContentCard>
          </div>
        </div>
      </AppBody>
    </React.Fragment>
  );
}
