import React from "react";
import { useDropzone } from "react-dropzone";
import Loader from "../../common/loader";
import DeleteForeverRoundedIcon from "@mui/icons-material/DeleteForeverRounded";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";

function Dropzone({ addFileToForm, imageType, imageExists, isLoading, removeImage, imageUrl = "" }) {
  const { getRootProps, getInputProps } = useDropzone({
    noKeyboard: true,
    multiple: false,
    onDrop: (acceptedFiles) => {
      addFileToForm(acceptedFiles[0], imageType);
    },
  });

  return isLoading ? (
    <div className={`form-field__${imageType}`}>
      <div className="page-loader-wrapper">
        <Loader />
      </div>
    </div>
  ) : imageExists ? (
    <div
      className={`form-field__${imageType} form-field__${imageType}--preview`}
      onClick={removeImage.bind(null, imageType)}
    >
      <img src={imageUrl} alt="preview-image" />
      <DeleteForeverRoundedIcon />
    </div>
  ) : (
    <div {...getRootProps()} className={`form-field__${imageType}`}>
      <input {...getInputProps()} />
      <AddCircleRoundedIcon />
    </div>
  );
}

export default Dropzone;
