import React from "react";
import { AssetPlayer, HlsOrDashPlayer } from "./player";

function ContentPlayer({ program, playerOptions }) {
  const [activeSource, setActiveSource] = React.useState({});

  const sources = React.useMemo(
    () => [
      { name: "source", value: program.source_video?.asset_id ?? null },
      { name: "normalised", value: program.content_video?.asset_id ?? null },
      { name: "hls", value: program.content_video?.accessible_hls_url ?? null },
      { name: "dash", value: program.content_video?.accessible_dash_url ?? null },
    ],
    [
      program.source_video?.asset_id,
      program.content_video?.asset_id,
      program.content_video?.accessible_hls_url,
      program.content_video?.accessible_dash_url,
    ],
  );

  const switchActiveSource = React.useCallback(
    (key) => {
      setActiveSource(() => {
        const nextSource = sources.find((source) => source.name === key);
        return { name: key, value: nextSource ? nextSource.value : null };
      });
    },
    [sources],
  );

  React.useEffect(() => {
    switchActiveSource("normalised"); // load default source
  }, [switchActiveSource, program.content_video?.asset_id]);

  if (["source", "normalised"].includes(activeSource.name)) {
    return (
      <AssetPlayer
        assetId={activeSource.value ?? null}
        switchSource={switchActiveSource}
        activeSource={activeSource.name}
        {...playerOptions}
      />
    );
  } else if (["hls", "dash"].includes(activeSource.name)) {
    return (
      <HlsOrDashPlayer
        type={activeSource.name}
        source={activeSource.value ?? ""}
        switchSource={switchActiveSource}
        activeSource={activeSource.name}
        {...playerOptions}
      />
    );
  }

  return null;
}

export default ContentPlayer;
