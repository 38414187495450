import React from "react";
import { secondsToHourMinutesSeconds } from "../../../../common/duration-formatting";
import PlannerMetaCuepoints from "../planner-meta-cuepoints";
import { formatSeconds } from "../../utils/format-seconds";

const FeatureInformation = ({ feature, programInfo, saveAdBreaks, formatTimeForTimezone }) => (
  <div className="program-information__fields">
    {programInfo.__gstvMeta ? (
      <div className="program-field">
        <div className="program-field__label">Runtime</div>
        <div className="program-field__value">
          {formatTimeForTimezone(programInfo.since)} to {formatTimeForTimezone(programInfo.till)} (
          {secondsToHourMinutesSeconds(programInfo.__gstvMeta.total_duration_seconds)})
        </div>
      </div>
    ) : null}
    {programInfo.__gstvMeta &&
    Math.round(programInfo.__gstvMeta.total_duration_seconds) <
      Math.round(programInfo.__gstvMeta.original_total_duration_seconds) ? (
      <div className="program-field">
        <div className="program-field__label">Original Duration</div>
        <div className="program-field__value">
          {formatSeconds(programInfo.__gstvMeta.original_total_duration_seconds)}
        </div>
      </div>
    ) : null}
    <div className="program-field">
      <div className="program-field__label">Summary</div>
      <div className="program-field__value">{feature.summary?.short_summary ?? "-"}</div>
    </div>
    <div className="program-field">
      <div className="program-field__label">Release Year</div>
      <div className="program-field__value">{feature.release_year}</div>
    </div>
    <div className="program-field">
      <div className="program-field__label">Ratings</div>
      <div className="program-field__value-list">
        <div className="program-field__value">
          <b>BBFC</b> - {feature.rating?.bbfc_rating ? feature.rating.bbfc_rating : ""}
        </div>
        <div className="program-field__value">
          <b>MPAA</b> - {feature.rating?.mpaa_rating ? feature.rating.mpaa_rating : ""}
        </div>
        <div className="program-field__value">
          <b>US-TV</b> - {feature.rating?.us_tv_rating ? feature.rating.us_tv_rating : ""}
        </div>
      </div>
    </div>
    <div className="program-field program-field--list">
      <div className="program-field__label">Genres</div>
      <div className="program-field__value-list">
        {feature.genres ? (
          feature.genres.map((genre, idx) => (
            <div className="program-field__value" key={idx}>
              {genre.imdb_genre ? `${genre.imdb_genre.label} (IMDb)` : ""}
              {genre.freeview_genre ? `${genre.freeview_genre.label} (Freeview)` : ""}
            </div>
          ))
        ) : (
          <div className="program-field__value">-</div>
        )}
      </div>
    </div>
    {programInfo.__gstvMeta ? (
      <>
        <div className="program-field">
          <div className="program-field__label">Ad Duration</div>
          <div className="program-field__value">
            {secondsToHourMinutesSeconds(
              programInfo.__gstvMeta.total_duration_seconds - programInfo.__gstvMeta.duration_seconds,
            )}
          </div>
        </div>
        <div className="program-field">
          <PlannerMetaCuepoints program={programInfo} saveAdBreaks={saveAdBreaks} />
        </div>
      </>
    ) : null}
  </div>
);

export default FeatureInformation;
