import React from "react";
import ReactModal from "react-modal";
import { toast } from "react-toastify";
import { updatePassword } from "../requests/api-requests";
import Loader from "../common/loader.jsx";

function ChangePasswordDialog({ isOpen, onClose, onCancel, userGuid }) {
  const [form, setForm] = React.useState({});
  const [errorMessage, setErrorMessage] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);

  function verifyPassword() {
    if (!form.password) {
      setErrorMessage("Please enter a new password.");
      return false;
    }

    if (!form.confirm_password) {
      setErrorMessage("Please confirm your new password.");
      return false;
    }

    if (form.password !== form.confirm_password) {
      setErrorMessage("Passwords must match");
      return false;
    }

    return true;
  }

  function handleChange(key, e) {
    setForm((prev) => ({
      ...prev,
      [key]: e.target.value,
    }));
  }

  function handleSubmit() {
    setIsLoading(true);

    if (verifyPassword()) {
      updatePassword(userGuid, { password: form.password })
        .then(() => {
          onClose();
          toast.success("Password Changed Successfully");
        })
        .catch((error) => {
          console.error(error);
          setErrorMessage("Error updating password. Please contact support.");
        })
        .finally(() => setIsLoading(false));
    }

    setIsLoading(false);
  }

  return (
    <ReactModal isOpen={isOpen} ariaHideApp={false}>
      <div className="v-react-modal__inner modal-width">
        <div className="v-react-modal__content">
          <div className="v-react-modal__heading">Change Password</div>
          <div className="v-react-modal__body">
            <div className="form-field" key="password">
              <label htmlFor="password">New Password</label>
              <input type="Password" defaultValue="" name="password" onChange={handleChange.bind(null, "password")} />
            </div>
            <div className="form-field" key="confirm_password">
              <label htmlFor="confirm_password">Confirm Password</label>
              <input
                type="password"
                defaultValue=""
                name="confirm_password"
                onChange={handleChange.bind(null, "confirm_password")}
              />
            </div>
          </div>
        </div>
        {errorMessage ? <p className="u-fc--warning">{errorMessage}</p> : null}
        <div className="v-react-modal__footer">
          {isLoading ? (
            <Loader width={45} height={45} />
          ) : (
            [
              <button className="btn btn--inverse-primary" onClick={onCancel} key="change-password-dialog-cancel">
                Cancel
              </button>,
              <button className="btn btn--primary" onClick={handleSubmit} key="change-password-dialog-submit">
                Change Password
              </button>,
            ]
          )}
        </div>
      </div>
    </ReactModal>
  );
}

export default ChangePasswordDialog;
