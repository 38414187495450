import React from "react";
import ReactSelect from "react-select";

export default function Select({ noPortal = false, containerClass, ...props }) {
  let attrs = {
    menuPortalTarget: document.querySelector("#ReactSelectPortal"),
  };

  return (
    <div className={`react-select-container ${containerClass}`}>
      <ReactSelect classNamePrefix="react-select" {...props} {...(noPortal ? {} : attrs)} />
    </div>
  );
}
