import React from "react";
import useApiRequest from "../../../hooks/use-api-request.js";
import Loader from "../../../common/loader.jsx";
import ImageWithFallback from "../../../components/image-with-fallback.jsx";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import EditPosterDialog from "../components/dialogs/edit-poster-dialog.jsx";
import EditRoundedIcon from "@mui/icons-material/EditRounded";

export default function ArtworkTab({ guid, type = "episodes" }) {
  const [posters, setPosters] = React.useState({});
  const [images, setImages] = React.useState({});
  const [editVisible, setEditVisible] = React.useState();
  const [guids, setGuids] = React.useState({});
  const [editDialog, setEditDialog] = React.useState({ open: false });
  const [lightbox, setLightbox] = React.useState({
    open: false,
    urls: [],
    index: 0,
  });

  const { isLoading, response } = useApiRequest(`/api/${type}/${guid}/artwork`);

  React.useEffect(() => {
    if (response.data) {
      setGuids(response?.data?.guids);
      setPosters(response?.data?.posters);
      setImages(response?.data?.images);
    }
  }, [response]);

  if (isLoading) {
    return (
      <div className="content-page__loader">
        <Loader />
      </div>
    );
  }

  function onContentPosterUpdate(posterData) {
    setPosters((prev) => ({
      ...prev,
      //  cut plural 's' from non-series types
      [editDialog.type]: [
        { type: "large", url: posterData.large_url },
        { type: "medium", url: posterData.medium_url },
        { type: "thumbnail", url: posterData.thumbnail_url },
        { type: "vertical", url: posterData.vertical_url },
      ],
    }));
  }

  function onPosterHover(key) {
    setEditVisible(key);
  }

  function onPosterExit() {
    setEditVisible("");
  }

  return (
    <React.Fragment>
      <div className="content-page__title content-page__title--separator">
        <span className="content-page__title__text">Posters</span>
      </div>
      <div className="gw">
        {Object.keys(posters).map((posterParent, parentIndex) =>
          posters[posterParent]?.map((image, index) => (
            <div className="g g--tall g--1-of-3" key={`${parentIndex}-${index}`}>
              <div
                className="link-preview-box-container"
                onMouseEnter={() => onPosterHover(`${parentIndex}-${index}`)}
                onMouseLeave={() => onPosterExit()}
              >
                <button
                  className={`link-preview-box__secondary-action-button ${editVisible !== `${parentIndex}-${index}` ? "link-preview-box__secondary-action-button--hidden" : ""}`}
                  onClick={() =>
                    setEditDialog({
                      open: true,
                      type: posterParent,
                      guid: guids[posterParent],
                    })
                  }
                >
                  <EditRoundedIcon />
                </button>
                <button
                  className="link-preview-box"
                  onClick={() =>
                    setLightbox({
                      open: true,
                      urls: posters[posterParent].map((poster) => ({ src: poster.url })),
                      index,
                    })
                  }
                >
                  <span className="link-preview-box__image">
                    <ImageWithFallback url={image.url} />
                  </span>
                  <span className="link-preview-box__footer">
                    <span className="u-display--flex-apart u-display--flex-v-center u-fs--h6 u-width-100">
                      <span className="u-padding--small--inline-end">{posterParent.toUpperCase()}</span>
                      <span className="u-padding--small--inline-end">{image.type.toUpperCase()}</span>
                    </span>
                  </span>
                </button>
              </div>
            </div>
          )),
        )}
      </div>
      <div className="content-page__title content-page__title--separator">
        <span className="content-page__title__text">Artwork</span>
      </div>
      <div className="gw">
        {Object.keys(images).map((imageParent, parentIndex) =>
          images[imageParent]?.map((image, index) => (
            <div className="g g--tall g--1-of-3" key={`${parentIndex}-${index}`}>
              <div className="link-preview-box-container">
                <button
                  className="link-preview-box"
                  onClick={() =>
                    setLightbox({ open: true, urls: images[imageParent].map((url) => ({ src: url })), index })
                  }
                  disabled={!image}
                >
                  <span className="link-preview-box__image">
                    <ImageWithFallback url={image} />
                  </span>
                  <span className="link-preview-box__footer">
                    <span className="u-display--flex-apart u-display--flex-v-center u-fs--h6 u-width-100">
                      <span className="u-padding--small--inline-end">{imageParent.toUpperCase()}</span>
                    </span>
                  </span>
                </button>
              </div>
            </div>
          )),
        )}
      </div>
      {lightbox.urls ? (
        <Lightbox
          open={lightbox.open}
          close={() => setLightbox((prev) => ({ ...prev, open: false }))}
          slides={lightbox.urls}
          index={lightbox.index}
          noScroll={true}
        />
      ) : null}
      <EditPosterDialog
        onClose={() => setEditDialog({ open: false })}
        isOpen={editDialog.open}
        type={editDialog.type}
        guid={editDialog.guid}
        provider={response?.data.provider}
        onSuccess={onContentPosterUpdate}
      />
    </React.Fragment>
  );
}
