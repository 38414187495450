import React from "react";
import ReactModal from "react-modal";
import Loader from "../../../common/loader";
import { toast } from "react-toastify";
import OrganisationForm from "./organisation-form";

function AddNewOrganisationDialog({ isOpen, onClose, onSuccess }) {
  const [isLoading, setIsLoading] = React.useState(false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  function beginSubmit() {
    setIsLoading(true);
    setIsSubmitting(true);
  }

  function endSubmit(success = false) {
    setIsLoading(false);
    setIsSubmitting(false);

    if (success) {
      onClose();
      toast.success("New Organisation created");
      setTimeout(onSuccess, 1000);
    }
  }

  return (
    <ReactModal isOpen={isOpen} ariaHideApp={false}>
      <div className="v-react-modal__inner modal-width">
        <div className="v-react-modal__heading">Add New Organisation</div>
        <div className="v-react-modal__body">
          <OrganisationForm action="create" submitting={isSubmitting} onComplete={endSubmit} />
        </div>
        <div className="v-react-modal__footer" style={{ marginTop: "4px" }}>
          {isLoading ? (
            <Loader width={45} height={45} />
          ) : (
            <React.Fragment>
              <button className="btn btn--inverse-primary" onClick={onClose}>
                Cancel
              </button>
              <button className="btn btn--primary" onClick={beginSubmit}>
                Create
              </button>
            </React.Fragment>
          )}
        </div>
      </div>
    </ReactModal>
  );
}

export default AddNewOrganisationDialog;
