import React from "react";
import {
  TimelineBox,
  TimelineDivider,
  TimelineDividers,
  TimelineTime,
  TimelineWrapper as PlanByTimelineWrapper,
  useTimeline,
} from "planby";

function TimelineWrapper({
  isBaseTimeFormat,
  isSidebar,
  dayWidth,
  hourWidth,
  numberOfHoursInDay,
  offsetStartHoursRange,
  sidebarWidth,
}) {
  const { time, dividers, formatTime } = useTimeline(numberOfHoursInDay, isBaseTimeFormat);

  const renderTime = (index) => (
    <TimelineBox key={index} width={hourWidth}>
      <TimelineTime>{formatTime(index + offsetStartHoursRange).toLowerCase()}</TimelineTime>
      <TimelineDividers>{renderDividers()}</TimelineDividers>
    </TimelineBox>
  );

  const renderDividers = () => dividers.map((_, index) => <TimelineDivider key={index} width={hourWidth} />);

  return (
    <PlanByTimelineWrapper dayWidth={dayWidth} sidebarWidth={sidebarWidth} isSidebar={isSidebar}>
      {time.map((_, index) => renderTime(index))}
    </PlanByTimelineWrapper>
  );
}

export default TimelineWrapper;
