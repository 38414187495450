import React from "react";
import Select from "../../../common/select.jsx";
import { getSeriesForProvider } from "../../../requests/api-requests.js";

const defaultOption = {
  label: "All",
  value: "",
};

function SeriesFilter({ providerId, selectedSeries, onChange }) {
  const [isLoading, setLoading] = React.useState(false);
  const [series, setSeries] = React.useState([defaultOption]);

  React.useEffect(() => {
    if (providerId) {
      setLoading(true);
      getSeriesForProvider(providerId)
        .then((response) => {
          setSeries([
            defaultOption,
            ...response.data
              .sort((a, b) => (a.series_name > b.series_name ? 1 : -1))
              // .sort((a, b) => (a.series_active > b.series_active ? -1 : 1))
              .map((s) => ({
                label: !s.series_active ? s.series_name + " (Inactive)" : s.series_name,
                value: s.series_guid,
              })),
          ]);
        })
        .catch((e) => {
          console.error(e);
        })
        .finally(() => setLoading(false));
    }
  }, [providerId]);

  const selected = selectedSeries.value ? selectedSeries : defaultOption;

  return (
    <Select
      name={"series"}
      value={selected}
      options={series}
      onChange={(nextSeries) => onChange(nextSeries)}
      isSearchable={true}
      isLoading={isLoading}
      containerClass="react-select-container--light react-select-container--small"
    />
  );
}

export default SeriesFilter;
