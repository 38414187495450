import React from "react";
import Pagination from "../pagination.jsx";

function ContentListPagination({ getPrograms, prevUrl = null, nextUrl = null, size = 50, setSize, isLoading }) {
  return (
    <div className="content-list__pagination">
      {!isLoading ? (
        <Pagination
          onPageClick={getPrograms}
          prevUrl={prevUrl}
          nextUrl={nextUrl}
          pageSize={size}
          onPageSizeClick={setSize}
          isSmall
        />
      ) : null}
    </div>
  );
}

export default ContentListPagination;
