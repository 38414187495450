import React from "react";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";

function MaybeRemovableTag({ label, removable, value = null, onRemove = null }) {
  // @todo standardize data type of label
  const renderLabel = typeof label === "function" ? label() : label;

  if (removable && typeof onRemove === "function" && value) {
    return (
      <button className="input-tag-list__item input-tag-list__item--clickable" onClick={() => onRemove(value)}>
        <span className="input-tag-list__item__label">{renderLabel}</span>
        <CancelRoundedIcon />
      </button>
    );
  }

  return (
    <div className="input-tag-list__item">
      <span className="input-tag-list__item__label">{renderLabel}</span>
    </div>
  );
}

function InputTagList({ tags, onRemove }) {
  return (
    <div className="input-tag-list">
      {tags.map((tag, key) => (
        <MaybeRemovableTag
          label={tag.label}
          removable={tag.removable}
          value={tag.value}
          key={key}
          onRemove={onRemove}
        />
      ))}
    </div>
  );
}

export default InputTagList;
