import React from "react";
import ContentList from "../../../components/content-list/content-list";
import ContentListFilters from "../../../components/content-list/content-list-filters";
import ContentListResults from "../../../components/content-list/content-list-results";
import ContentListPagination from "../../../components/content-list/content-list-pagination";
import Loader from "../../../common/loader";
import axios from "../../../requests/axios";
import { Droppable } from "react-beautiful-dnd";
import TemplateContentItem from "./template-content-item";
import TemplateSeriesItem from "./template-series-item";

export default function TemplateContentList({ template, programs, isLoading, getPrograms, addItem }) {
  const [series, setSeries] = React.useState({});
  const [channel, setChannel] = React.useState();
  const [isLoadingChannel, setLoadingChannel] = React.useState(true);
  const [pageSize, setPageSize] = React.useState(50); // 50 items per page by default

  const resetSeries = React.useCallback(() => {
    setSeries({});
  }, []);

  React.useEffect(() => {
    setLoadingChannel(true);

    axios
      .get(`api/channels/${template.channel_id}`)
      .then((resp) => {
        setChannel(resp.data);
      })
      .catch((e) => console.error(e))
      .finally(() => setLoadingChannel(false));
  }, [template.channel_id]);

  return (
    <React.Fragment>
      {isLoadingChannel ? (
        <div className="page-loader-wrapper">
          <Loader />
        </div>
      ) : (
        <ContentList>
          <ContentListFilters
            providers={channel.providers}
            getPrograms={getPrograms}
            overridePagesize={pageSize}
            displayAllFilters={true}
            alignSortRight={true}
            flexible={true}
            // showInactiveContent={true}
            // showRestrictedContent={true}
            cacheOnProvider={true}
            series={series}
            resetSeries={resetSeries}
          />
          <ContentListResults isLoading={isLoading} results={programs?.data}>
            {(results) => (
              <Droppable droppableId="template-content-list" direction="vertical">
                {(provided) => (
                  <ul {...provided.droppableProps} ref={provided.innerRef} className={`scheduler-content__list`}>
                    {results[0].series_id
                      ? results.map((result, index) => (
                          <TemplateSeriesItem
                            item={result}
                            index={index}
                            addItem={addItem}
                            key={`template-list__item__${index}`}
                          />
                        ))
                      : results.map((result, index) => (
                          <TemplateContentItem
                            item={result}
                            index={index}
                            addItem={addItem}
                            key={`template-list__item__${index}`}
                          />
                        ))}
                    {provided.placeholder}
                  </ul>
                )}
              </Droppable>
            )}
          </ContentListResults>
          <ContentListPagination
            getPrograms={getPrograms}
            prevUrl={programs?.prev_page_url}
            nextUrl={programs?.next_page_url}
            size={pageSize}
            setSize={setPageSize}
            isLoading={isLoading}
          />
        </ContentList>
      )}
    </React.Fragment>
  );
}
