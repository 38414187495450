import ReactModal from "react-modal";
import React from "react";
import { BASE_URL } from "../../requests/api-requests.js";
import useApiRequest from "../../hooks/use-api-request.js";
import Select from "../../common/select.jsx";

export default function DownloadIngestFeedTemplate({ isOpen, close, onDone }) {
  const [provider, setProvider] = React.useState(null);
  const [type, setType] = React.useState(null);
  const { response } = useApiRequest("/api/providers");

  let url = `${BASE_URL}/api/feeds/template?token=${window.localStorage
    .getItem("__bearer")
    .substring("Bearer ".length)}`;
  let args = [];
  if (provider) {
    args.push(`provider=${provider}`);
  }

  if (type) {
    args.push(`export_type=${type}`);
  }

  if (args.length) {
    url += `&${args.join("&")}`;
  }

  const defaultProvider = {
    value: null,
    label: "Any Provider",
  };

  const providerOptions = [
    defaultProvider,
    ...(response && response.data?.data
      ? response.data.data.map((provider) => ({
          label: provider.provider_name,
          value: provider.provider_id,
        }))
      : []),
  ];

  const selectedProvider = provider ? providerOptions.filter((po) => po.value === provider) : defaultProvider;

  return (
    <ReactModal isOpen={isOpen} ariaHideApp={false}>
      <div className="v-react-modal__inner modal-width">
        <div className="v-react-modal__heading">Download Template</div>
        <div className="v-react-modal__body modal-body">
          <div className="form-field u-margin--block-end">
            <label htmlFor="">Download for specific content type?</label>
            <div className="radio-group-container radio-group-container--two-columns">
              <div className="radio-group">
                <input
                  className="form-radio"
                  type="radio"
                  name="template-type"
                  id="template-type-kitchen-sink"
                  onChange={() => setType(null)}
                  checked={type === null}
                  value="kitchen-sink"
                />
                <label htmlFor="template-type-kitchen-sink">Everything</label>
              </div>
              <div className="radio-group">
                <input
                  className="form-radio"
                  type="radio"
                  name="template-type"
                  id="template-type-features"
                  onChange={() => setType("features")}
                  checked={type === "features"}
                  value="features"
                />
                <label htmlFor="template-type-features">Features</label>
              </div>
              <div className="radio-group">
                <input
                  className="form-radio"
                  type="radio"
                  name="template-type"
                  id="template-type-episodes"
                  onChange={() => setType("episodes")}
                  checked={type === "episodes"}
                  value="episodes"
                />
                <label htmlFor="template-type-episodes">Series / Seasons / Episodes</label>
              </div>
              <div className="radio-group">
                <input
                  className="form-radio"
                  type="radio"
                  name="template-type"
                  id="template-type-promos"
                  onChange={() => setType("promos")}
                  checked={type === "promos"}
                  value="promos"
                />
                <label htmlFor="template-type-promos">Promos</label>
              </div>
            </div>
          </div>
          <div className="form-field">
            <label>Type</label>
            <Select
              placeholder="Select a Type"
              name="feed_type"
              options={providerOptions}
              value={selectedProvider}
              onChange={(input) => setProvider(input.value)}
              classNamePrefix="react-select"
            />
          </div>
        </div>
        <div className="v-react-modal__footer">
          <React.Fragment>
            <button className="btn btn--inverse-primary" onClick={close}>
              Cancel
            </button>
            <a className="btn btn--primary" href={url} target="_blank" download onClick={onDone} rel="noreferrer">
              Download File
            </a>
          </React.Fragment>
        </div>
      </div>
    </ReactModal>
  );
}
