import React from "react";
import { relativeSecondsToHourMinutes } from "../helpers/time-helpers.js";
import dateIsBetween from "../../../common/date-is-between.js";
import { addYears } from "date-fns";

export default function useSegments(planDate, planBreaks, epg) {
  const [segments, setSegments] = React.useState([]);

  React.useEffect(() => {
    const nextSegments = planBreaks.map((pb) => {
      const next = {
        label: relativeSecondsToHourMinutes(planDate, pb.start, 0),
        type: pb.type,
        end: pb.end,
        start: pb.start,
      };

      next.items = epg.filter((item) => {
        return dateIsBetween(item.since, pb.start, pb.end, "[)");
      });

      return next;
    });

    // attach any out of range items to the last segment
    if (nextSegments.length) {
      const lastSegment = nextSegments[nextSegments.length - 1];
      const outOfRange = epg.filter((item) => {
        return dateIsBetween(item.since, lastSegment.end, addYears(lastSegment.end, 1), "[)");
      });

      if (outOfRange.length) {
        nextSegments[nextSegments.length - 1].items.push(...outOfRange);
      }
    }

    setSegments(nextSegments);
  }, [planDate, planBreaks, epg]);

  return segments;
}
