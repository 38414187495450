import React from "react";
import ContentCard from "././library/components/content-card";
import AddNewProviderDialog from "./components/dialogs/add-new-provider-dialog";
import { AppHeading } from "../layout/parts/app-heading.jsx";
import AppBody from "../layout/parts/app-body.jsx";
import DataTable from "../components/data-table/data-table.jsx";
import RestoreOrRemoveProviderDialog from "./components/dialogs/restore-or-remove-provider-dialog.jsx";
import EditProviderDialog from "./components/dialogs/edit-provider-dialog.jsx";
import { v4 as uuid } from "uuid";
import DeleteForeverRoundedIcon from "@mui/icons-material/DeleteForeverRounded";
import RestoreFromTrashRoundedIcon from "@mui/icons-material/RestoreFromTrashRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";

function ManageProvidersPage() {
  const [showNewProviderDialog, setShowNewProviderDialog] = React.useState(false);
  const [addRemoveDialog, setAddRemoveDialog] = React.useState({
    open: false,
    id: "",
    action: "",
  });
  const [editDialog, setEditDialog] = React.useState({
    open: false,
    id: "",
  });
  const tableRef = React.useRef();

  const columns = React.useMemo(
    () => [
      {
        label: "Provider Name",
        key: "provider_name",
        value: "provider_name",
        sortable: true,
        width: 30,
      },
      {
        label: "Url",
        key: "url",
        value: "distribution.external_url",
        width: 30,
        renderValue: (row) => (
          <span className="data-table__cell__value">
            {row.distribution?.external_url ? (
              <a href={row.distribution.external_url}>{row.distribution.external_url}</a>
            ) : (
              ""
            )}
          </span>
        ),
      },
      {
        label: "Bucket",
        key: "bucket",
        value: "bucket",
        sortable: true,
        width: 25,
      },
      {
        label: "",
        value: "",
        key: "0",
        width: 15,
        renderValue: (row) => (
          <span className="data-table__action-cell">
            {row.provider_active ? (
              <React.Fragment>
                <span className="data-table__action-cell__item data-table__icon" onClick={() => openEditDialog(row)}>
                  <EditRoundedIcon />
                </span>
                <span
                  className="data-table__action-cell__item data-table__icon"
                  onClick={() => openAddRemoveDialog(row.provider_guid, "delete")}
                >
                  <DeleteForeverRoundedIcon />
                </span>
              </React.Fragment>
            ) : (
              <span
                className="data-table__action-cell__item data-table__icon"
                onClick={() => openAddRemoveDialog(row.provider_guid, "restore")}
              >
                <RestoreFromTrashRoundedIcon />
              </span>
            )}
          </span>
        ),
      },
    ],
    [],
  );

  const openNewProviderDialog = React.useCallback(() => {
    setShowNewProviderDialog(true);
  }, []);

  const closeNewProviderDialog = React.useCallback(() => {
    setShowNewProviderDialog(false);
  }, []);

  function openAddRemoveDialog(providerId, action) {
    setAddRemoveDialog({
      id: providerId,
      open: true,
      action,
    });
  }

  function closeAddRemoveDialog() {
    setAddRemoveDialog({});
  }

  function openEditDialog(provider) {
    setEditDialog({
      provider: provider,
      open: true,
    });
  }

  function closeEditDialog() {
    setEditDialog({});
  }

  function refreshPage() {
    tableRef.current?.refreshList();
  }

  function getMessageForAction(action) {
    switch (action) {
      case "delete":
        return "Are you sure you want to delete this provider?";
      case "restore":
        return "Are you sure you want to restore this provider?";
      default:
        return "";
    }
  }

  return (
    <React.Fragment>
      <AppHeading
        breadcrumbs={[
          {
            link: "/admin/",
            title: "Admin",
          },
          {
            link: "/admin/providers",
            title: "Providers",
          },
        ]}
        rightActions={[
          {
            onClick: openNewProviderDialog,
            balloonLabel: "Add provider",
            icon: AddCircleRoundedIcon,
          },
        ]}
      />
      <AppBody>
        <div className="gw gw--full-height">
          <div className="g g--full-height g--1-of-1">
            <ContentCard noPadding>
              <DataTable url="/api/providers" columns={columns} ref={tableRef} />
            </ContentCard>
          </div>
        </div>
      </AppBody>
      <AddNewProviderDialog isOpen={showNewProviderDialog} onClose={closeNewProviderDialog} onSuccess={refreshPage} />
      <RestoreOrRemoveProviderDialog
        isOpen={addRemoveDialog.open}
        id={addRemoveDialog.id}
        action={addRemoveDialog.action}
        message={getMessageForAction(addRemoveDialog.action)}
        onClose={closeAddRemoveDialog}
        onSuccess={refreshPage}
        key={`add-remove-provider_dialog-${uuid()}`}
      />
      <EditProviderDialog
        isOpen={editDialog.open}
        provider={editDialog.provider}
        onClose={closeEditDialog}
        onSuccess={refreshPage}
        key={`edit-provider_dialog-${uuid()}`}
      />
    </React.Fragment>
  );
}

export default ManageProvidersPage;
