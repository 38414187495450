import React from "react";
import VisibilityRoundedIcon from "@mui/icons-material/VisibilityRounded";

export default function CustomImagesComponent({ onInteract, data }) {
  return (
    <div onClick={() => onInteract(data)} className="gstv-ag-grid-cell-content__images">
      <VisibilityRoundedIcon />
    </div>
  );
}
