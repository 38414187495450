import { ColorRing } from "react-loader-spinner";
import React from "react";

function Loader(props) {
  return (
    <div className="loader-container">
      <ColorRing
        height={80}
        width={80}
        colors={["#5d14d3", "#04e0ff", "#fe01f4", "#04e0ff", "#5d14d3"]}
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
        ariaLabel="oval-loading"
        secondaryColor="#fc9d9e"
        strokeWidth={2}
        strokeWidthSecondary={2}
        {...props}
      />
    </div>
  );
}

export default Loader;
