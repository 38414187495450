import React from "react";
import { Epg, Layout, useEpg } from "planby";
import { theme } from "./theme.js";
import EpgProgram from "./epg-program.jsx";
import EpgChannel from "./epg-channel.jsx";
import TimelineWrapper from "./timeline-wrapper.jsx";
import { endOfDay, startOfDay } from "date-fns";
import format from "date-fns/format";
import { useSchedulerContext } from "../../../../providers/scheduler-context.jsx";

function EpgTimeline({
  isLoading,
  epg,
  channels,
  date,
  setActiveProgram = () => null,
  setActiveChannel = () => null,
  removeProgram,
  onPlay,
  mode = "",
  customRange = [],
}) {
  const { timezone, toOffsetDate } = useSchedulerContext();

  const baseDate = toOffsetDate(startOfDay(date));
  let startDate = timezone !== "UTC" ? baseDate : startOfDay(date);
  let endDate = timezone !== "UTC" ? new Date(baseDate).setDate(baseDate.getDate() + 1) : endOfDay(date);

  // override if custom time range is set
  if (customRange.length) {
    startDate = new Date(startOfDay(customRange[0]));
    startDate.setHours(customRange[0].getHours());

    endDate = new Date(startOfDay(customRange[1]));
    endDate.setHours(customRange[1].getHours() + 1);
  }

  // despite the name, only adds an offset if a timezone is being applied
  const offsetEpg = epg.map((epgProgram) => ({
    ...epgProgram,
    since: toOffsetDate(epgProgram.since),
    till: toOffsetDate(epgProgram.till),
  }));

  const { getEpgProps, getLayoutProps } = useEpg({
    channels,
    epg: offsetEpg,
    dayWidth: 19200,
    itemHeight: 80,
    isSidebar: true,
    isTimeline: true,
    isLine: true,
    startDate: format(startDate, "y-MM-dd'T'HH:mm:ss"),
    endDate: format(endDate, timezone !== "UTC" || customRange.length ? "y-MM-dd'T'HH:mm:ss" : "y-MM-dd'T24:00:00'"),
    isBaseTimeFormat: true,
    theme,
  });

  return (
    <Epg isLoading={isLoading} {...getEpgProps()}>
      <Layout
        {...getLayoutProps()}
        isProgramVisible={() => {
          return true;
        }}
        isChannelVisible={() => true}
        renderTimeline={(props) => <TimelineWrapper {...props} numberOfHoursInDay={24} hourWidth={800} />}
        renderProgram={({ program, ...rest }) => {
          return (
            <EpgProgram
              key={program.data.id}
              program={program}
              onProgramClick={setActiveProgram}
              removeProgram={removeProgram}
              onPlay={onPlay}
              mode={mode}
              {...rest}
            />
          );
        }}
        renderChannel={({ channel }) => (
          <div key={channel.uuid}>
            <EpgChannel key={channel.uuid} channel={channel} setActiveChannel={setActiveChannel} />
          </div>
        )}
      />
    </Epg>
  );
}

export default EpgTimeline;
