import React from "react";
import ContentCard from "././library/components/content-card";
import AddNewUserDialog from "./components/dialogs/add-new-user-dialog";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import { AppHeading } from "../layout/parts/app-heading.jsx";
import AppBody from "../layout/parts/app-body.jsx";
import RestoreOrRemoveUserDialog from "./components/dialogs/restore-or-remove-user-dialog.jsx";
import EditUserDialog from "./components/dialogs/edit-user-dialog.jsx";
import ChangePasswordDialog from "./change-password-dialog.jsx";
import { v4 as uuid } from "uuid";
import DeleteForeverRoundedIcon from "@mui/icons-material/DeleteForeverRounded";
import RestoreFromTrashRoundedIcon from "@mui/icons-material/RestoreFromTrashRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import KeyRoundedIcon from "@mui/icons-material/KeyRounded";
import DataTable from "../components/data-table/data-table.jsx";

function ManageUsersPage() {
  const [showNewUserDialog, setShowNewUserDialog] = React.useState(false);
  const [addRemoveDialog, setAddRemoveDialog] = React.useState({
    open: false,
    id: "",
    action: "",
  });
  const [editDialog, setEditDialog] = React.useState({
    open: false,
    id: "",
  });
  const [passwordDialog, setPasswordDialog] = React.useState({
    open: false,
    id: "",
  });
  const tableRef = React.useRef();

  const columns = React.useMemo(
    () => [
      {
        label: "First Name",
        key: "first",
        value: "first",
        sortable: true,
        width: 15,
      },
      {
        label: "Last Name",
        key: "last",
        value: "last",
        sortable: true,
        width: 15,
      },
      {
        label: "Email",
        key: "email",
        value: "email",
        sortable: true,
        width: 20,
      },
      {
        label: "Organisation",
        key: "organisation_name",
        value: "organisation.display_name",
        width: 10,
      },
      {
        label: "Roles",
        key: "roles",
        value: "",
        width: 10,
        renderValue: (row) => (
          <span className="data-table__cell__value">
            {row?.roles.length > 0 ? row.roles.map((role) => role.display_name).join(", ") : ""}
          </span>
        ),
      },
      {
        label: "Last Login",
        key: "last_login",
        value: "last_login",
        sortable: true,
        width: 15,
      },
      {
        label: "",
        value: "",
        key: "0",
        width: 15,
        renderValue: (row) => (
          <span className="data-table__action-cell">
            {row.user_active ? (
              <React.Fragment>
                <span className="data-table__action-cell__item data-table__icon" onClick={() => openEditDialog(row)}>
                  <EditRoundedIcon />
                </span>
                <span
                  className="data-table__action-cell__item data-table__icon"
                  onClick={() => openPasswordDialog(row.user_guid)}
                >
                  <KeyRoundedIcon />
                </span>
                <span
                  className="data-table__action-cell__item data-table__icon"
                  onClick={() => openAddRemoveDialog(row.user_guid, "delete")}
                >
                  <DeleteForeverRoundedIcon />
                </span>
              </React.Fragment>
            ) : (
              <span
                className="data-table__action-cell__item data-table__icon"
                onClick={() => openAddRemoveDialog(row.user_guid, "restore")}
              >
                <RestoreFromTrashRoundedIcon />
              </span>
            )}
          </span>
        ),
      },
    ],
    [],
  );

  function openNewUserDialog() {
    setShowNewUserDialog(true);
  }

  function closeNewUserDialog() {
    setShowNewUserDialog(false);
  }

  function openAddRemoveDialog(userId, action) {
    setAddRemoveDialog({
      id: userId,
      open: true,
      action,
    });
  }

  function closeAddRemoveDialog() {
    setAddRemoveDialog({});
  }

  function openEditDialog(user) {
    setEditDialog({
      user: user,
      open: true,
    });
  }

  function closeEditDialog() {
    setEditDialog({});
  }

  function openPasswordDialog(userId) {
    setPasswordDialog({
      id: userId,
      open: true,
    });
  }

  function closePasswordDialog() {
    setPasswordDialog({});
  }

  function refreshPage() {
    tableRef.current?.refreshList();
  }

  function getMessageForAction(action) {
    switch (action) {
      case "delete":
        return "Are you sure you want to delete this user?";
      case "restore":
        return "Are you sure you want to restore this user?";
      default:
        return "";
    }
  }

  return (
    <React.Fragment>
      <AppHeading
        breadcrumbs={[
          {
            link: "/admin/",
            title: "Admin",
          },
          {
            link: "/admin/users",
            title: "Users",
          },
        ]}
        rightActions={[
          {
            onClick: openNewUserDialog,
            balloonLabel: "Add user",
            icon: AddCircleRoundedIcon,
          },
        ]}
      />
      <AppBody>
        <div className="gw gw--full-height">
          <div className="g g--full-height g--1-of-1">
            <ContentCard noPadding>
              <DataTable url="/api/users" columns={columns} ref={tableRef} />
            </ContentCard>
          </div>
        </div>
      </AppBody>
      <AddNewUserDialog isOpen={showNewUserDialog} onClose={closeNewUserDialog} />
      <RestoreOrRemoveUserDialog
        isOpen={addRemoveDialog.open}
        id={addRemoveDialog.id}
        action={addRemoveDialog.action}
        message={getMessageForAction(addRemoveDialog.action)}
        onClose={closeAddRemoveDialog}
        onSuccess={refreshPage}
        key={`add-remove-user_dialog-${uuid()}`}
      />
      <EditUserDialog
        isOpen={editDialog.open}
        user={editDialog.user}
        onClose={closeEditDialog}
        onSuccess={refreshPage}
        key={`edit-user_dialog-${uuid()}`}
      />
      <ChangePasswordDialog
        isOpen={passwordDialog.open}
        onClose={closePasswordDialog}
        onCancel={closePasswordDialog}
        userGuid={passwordDialog.id}
        key={`password-dialog__${uuid()}`}
      />
    </React.Fragment>
  );
}

export default ManageUsersPage;
