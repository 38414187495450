import React from "react";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import PendingRoundedIcon from "@mui/icons-material/PendingRounded";
import CircleRoundedIcon from "@mui/icons-material/CircleRounded";

function TranscodingStatusIcon({ status, type = "", popupPosition = "down", appendTextOptions = {} }) {
  const defaultAppend = {
    all: "",
    initialised: "",
    processing: "",
    completed: "",
    failed: "",
  };
  const append = { ...defaultAppend, ...appendTextOptions };

  let appended = "";
  switch (status) {
    case "initialised":
    case null:
    case undefined:
      appended = append.all ? ` ${append.all}` : ` ${append.initialised}`;
      return (
        <div
          className="status-icon status-icon--neutral"
          aria-label={`${type.toUpperCase()} not started.\n${appended}`}
          data-balloon-pos={popupPosition}
          data-balloon-length="medium"
        >
          <CircleRoundedIcon />
        </div>
      );
    case "processing":
      appended = append.all ? ` ${append.all}` : ` ${append.processing}`;
      return (
        <div
          className="status-icon status-icon--starting"
          aria-label={`${type.toUpperCase()} In Progress.${appended}`}
          data-balloon-pos={popupPosition}
          data-balloon-length="medium"
        >
          <PendingRoundedIcon />
        </div>
      );
    case "completed":
      appended = append.all ? ` ${append.all}` : ` ${append.completed}`;
      return (
        <div
          className="status-icon status-icon--success"
          aria-label={`${type.toUpperCase()} Completed!${appended}`}
          data-balloon-pos={popupPosition}
          data-balloon-length="medium"
        >
          <CheckCircleRoundedIcon />
        </div>
      );
    case "failed":
      appended = append.all ? ` ${append.all}` : ` ${append.failed}`;
      return (
        <div
          className="status-icon status-icon--failed"
          aria-label={`${type.toUpperCase()} Failed!${appended}`}
          data-balloon-pos={popupPosition}
          data-balloon-length="medium"
        >
          <CancelRoundedIcon />
        </div>
      );
  }
}

export default TranscodingStatusIcon;
