import React from "react";
import WatchLaterIcon from "@mui/icons-material/WatchLater";

function DurationFilter({ onChangeMin, onChangeMax, min, max }) {
  return (
    <div className="content-list-filter__duration__input-group">
      <div className="input-partitioned">
        <div className="input-partitioned__separator">
          <WatchLaterIcon />
        </div>
        <input
          value={min}
          type="number"
          className="input-partitioned__input"
          placeholder="minimum"
          name="duration_min"
          maxLength={3}
          onKeyDown={(evt) => ["e", "E", "+", "-", "v", "V"].includes(evt.key) && evt.preventDefault()}
          onChange={onChangeMin}
        />
      </div>
      <div className="input-partitioned">
        <div className="input-partitioned__separator">
          <WatchLaterIcon />
        </div>
        <input
          value={max}
          type="number"
          className="input-partitioned__input"
          placeholder="maximum"
          name="duration_max"
          maxLength={3}
          onKeyDown={(evt) => ["e", "E", "+", "-", "v", "V"].includes(evt.key) && evt.preventDefault()}
          onChange={onChangeMax}
        />
      </div>
      <div className="content-list-filter__duration__label">Minutes</div>
    </div>
  );
}

export default DurationFilter;
