import React from "react";
import ReactModal from "react-modal";
import Loader from "../../../../common/loader.jsx";
import axios from "../../../../requests/axios.js";
import { BASE_URL } from "../../../../requests/api-requests.js";
import { toast } from "react-toastify";

function ManageAssetDialog({ id, message, onClose, onConfirm, action, isOpen }) {
  const [isLoading, setLoading] = React.useState(false);
  const [error, setError] = React.useState("");

  function onSubmit() {
    setLoading(true);
    let promise =
      action === "delete"
        ? axios.delete(`${BASE_URL}/api/assets/${id}`)
        : axios.post(`${BASE_URL}/api/assets/${id}/restore`);

    promise
      .then(() => {
        onClose();
        toast.success(action === "delete" ? "Asset deleted successfully" : "Asset restored successfully");
        onConfirm();
      })
      .catch((err) => {
        console.error(err);
        setError("Something went wrong while contacting the server");
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return (
    <ReactModal isOpen={isOpen} ariaHideApp={false}>
      <div className="v-react-modal__inner modal-width">
        <div className="v-react-modal__heading">Manage Asset</div>
        <div className="v-react-modal__body modal-body">
          {message}
          {error ? <p className="u-fc--warning">{error}</p> : null}
        </div>
        <div className="v-react-modal__footer">
          {isLoading ? (
            <Loader width={45} height={45} />
          ) : (
            [
              <button className="btn btn--inverse-primary" onClick={onClose} key="cancel">
                Cancel
              </button>,
              <button className="btn btn--primary" onClick={onSubmit} key="confirm">
                Confirm
              </button>,
            ]
          )}
        </div>
      </div>
    </ReactModal>
  );
}

export default ManageAssetDialog;
