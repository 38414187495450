import React from "react";

function ChannelHeadingTimezone({ timezone, toggleTimezone }) {
  return (
    <div className="channel-heading__timezone">
      <div className="channel-heading__timezone-button">
        <button className="btn btn--pill btn--ntrl-min" onClick={toggleTimezone}>
          {timezone}
        </button>
      </div>
    </div>
  );
}

export default ChannelHeadingTimezone;
