import React from "react";
import { differenceInSeconds, setHours, setMinutes, setSeconds, startOfDay } from "date-fns";
import dateIsBetween from "../../../common/date-is-between";
import { useSchedulerContext } from "../../../providers/scheduler-context.jsx";

const POSITION_UPDATE_INTERVAL_MS = 30000; //  currently 30 sec

function setCurrentTimeOn(date) {
  const now = new Date();
  return setHours(setMinutes(setSeconds(date, now.getSeconds()), now.getMinutes()), now.getHours());
}

function CurrentTimeMarker({ timeRange, pixelsPerSecond, date, direction = "vertical", considerRange = true }) {
  const { toUtcDate } = useSchedulerContext();
  const [thisDay, setThisDay] = React.useState(toUtcDate(setCurrentTimeOn(date)));

  React.useEffect(() => {
    let timer = setInterval(() => {
      setThisDay(toUtcDate(setCurrentTimeOn(date)));
    }, POSITION_UPDATE_INTERVAL_MS);
    return () => {
      clearInterval(timer);
    };
  }, [date, toUtcDate]);

  // render nothing if current time is outside zoom range and we are rendering vertically
  if (!dateIsBetween(thisDay, timeRange[0], timeRange[1], "()") && considerRange) {
    return;
  }

  const startComparison = considerRange ? timeRange[0] : startOfDay(thisDay);
  let offsetPixels = differenceInSeconds(thisDay, startComparison) * pixelsPerSecond;

  const styles = {
    ...(direction === "horizontal" ? { top: `${offsetPixels}px` } : {}),
    ...(direction === "vertical" ? { left: `${offsetPixels}px` } : {}),
  };

  return (
    <div
      className={`scheduler-timeline__content__current-time scheduler-timeline__content__current-time--${direction}`}
      style={styles}
    />
  );
}

export default CurrentTimeMarker;
