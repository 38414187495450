import React from "react";
import PowerSettingsCircle from "../../../../assets/power-settings-circle";

function ChannelHeadingAutosaveButton({ autosaveEnabled, toggleAutosave }) {
  return (
    <div className="channel-heading__action-button">
      <div
        className={`channel-heading__autosave-button ${autosaveEnabled ? "channel-heading__autosave-button--active" : ""}`}
      >
        <button
          className={`btn btn--icon `}
          onClick={() => {
            toggleAutosave();
            document.activeElement.blur();
          }}
          aria-label={autosaveEnabled ? "Disable autosave" : "Enable autosave"}
          data-balloon-pos="right"
        >
          <PowerSettingsCircle size={28} />
        </button>
      </div>
    </div>
  );
}

export default ChannelHeadingAutosaveButton;
