import React from "react";
import { useLocation, useParams } from "react-router-dom";
import { BASE_URL, copyPlaylistVersion, getAllProviders } from "../requests/api-requests";
import { toast } from "react-toastify";
import Loader from "../common/loader";
import { useNavigate } from "react-router-dom";
import useApiRequest from "../hooks/use-api-request.js";
import AvodPlaylist from "./avod-playlist/avod-playlist.jsx";
import SaveAvodPlaylistDialog from "./avod-playlist/save-avod-playlist-dialog.jsx";
import CreateAvodFields from "./avod-playlist/create-avod-fields.jsx";
import ShareAvodPlaylistDialog from "./avod-playlist/share-avod-playlist.jsx";
import getToken from "../functions/getToken.js";
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";
import SaveRoundedIcon from "@mui/icons-material/SaveRounded";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import ShareRoundedIcon from "@mui/icons-material/ShareRounded";
import { AppHeading } from "../layout/parts/app-heading.jsx";
import AppBody from "../layout/parts/app-body.jsx";

function validateForm(formFields) {
  let errors = {};

  if (!formFields.playlist_name) {
    errors.playlist_name = "This is a required field";
  }

  if (!formFields.ad_decision_url) {
    errors.ad_decision_url = "This is a required field";
  }

  return errors;
}

export default function PlaylistVersionPage({ create }) {
  const [providers, setProviders] = React.useState([]);
  const [isProvidersLoading, setProvidersLoading] = React.useState(true);
  const [programs, setPrograms] = React.useState([]);
  const [showSaveDialog, setShowSaveDialog] = React.useState(false);
  const [showShareDialog, setShowShareDialog] = React.useState(false);
  const [form, setForm] = React.useState({ ad_decision_url: "" }); // default value to avoid controlled input warning
  const [formErrors, setFormErrors] = React.useState({});
  const [playlist, setPlaylist] = React.useState({});

  const adUrlRef = React.useRef();

  const { id, version } = useParams();
  const navigate = useNavigate();
  const url = create ? "" : `/api/avod-playlists/${id}/versions/${version}`;
  const { isLoading, response, error } = useApiRequest(url);
  const { state } = useLocation();

  const updatePrograms = React.useCallback((list) => {
    setPrograms(list);
  }, []);

  const updateForm = React.useCallback((field, value) => {
    setForm((prev) => ({
      ...prev,
      [field]: value,
    }));
  }, []);

  const updatePlaylist = React.useCallback((newPlaylist) => {
    setPlaylist(newPlaylist);
  }, []);

  const cloneVersion = React.useCallback(() => {
    if (id && version) {
      setProvidersLoading(true);
      setPlaylist({});

      copyPlaylistVersion(id, version)
        .then((r) => {
          navigate(`/avod/playlists/${id}/versions/${r.data.version}`);
        })
        .catch((e) => {
          console.error(e);
          toast.error("There was an error copying this playlist.");
        })
        .finally(() => setProvidersLoading(false));
    }
  }, [id, version, navigate]);

  React.useEffect(() => {
    setProvidersLoading(true);
    getAllProviders()
      .then((response) => {
        const newProviders = response.data
          .sort((a, b) => (a.provider_active > b.provider_active ? -1 : 1))
          .map((provider) => {
            if (provider.provider_active === 0) {
              provider.provider_name = provider.provider_name + " (Inactive)";
            }
            return provider;
          });

        setProviders(newProviders);
      })
      .catch((error) => {
        toast.error("There was an error loading providers");
        console.error(error);
      })
      .finally(() => setProvidersLoading(false));
  }, []);

  React.useEffect(() => {
    if (response.data) {
      setPlaylist(response.data);
    }

    if (response.data?.programs) {
      setPrograms(response.data.programs);
    }

    // set form values from response
    if (response.data?.ad_decision_server) {
      updateForm("ad_decision_url", response.data.ad_decision_server);
    }

    if (response.data?.playlist_name) {
      updateForm("playlist_name", response.data.playlist_name);
    }
  }, [response, updateForm]);

  function onShowSaveDialog() {
    if (playlist && playlist.status === "processing") {
      toast.error("Cannot save a plan while it is busy being processed");
      return;
    }

    if (create) {
      const errors = validateForm(form);

      if (Object.keys(errors).length) {
        // don't show form
        setFormErrors(errors);
        return;
      }

      // clear old form errors if none found
      setFormErrors({});
    }

    setShowSaveDialog(true);
  }

  const loading = isLoading || isProvidersLoading;

  if (error) {
    toast.error("Unable to load playlist");
    navigate(-1);
    return null;
  }

  let breadcrumbs = [
    {
      link: "/avod",
      title: "AVOD",
    },
    {
      link: "/avod/playlists",
      title: "Playlists",
    },
    {
      link: create ? `/avod/playlists/${id}/versions/create` : `/avod/playlists/${id}/versions/${version}`,
      title: create ? (id ? "Create Version" : `Create`) : `Version ${version}`,
    },
  ];

  if (id) {
    breadcrumbs.splice(-1, 0, {
      link: id ? `/avod/playlists/${id}/versions` : "",
      title: playlist?.playlist_name ?? state?.playlist_name ?? "...",
    });
  }

  return (
    <React.Fragment>
      <AppHeading
        breadcrumbs={breadcrumbs}
        rightActions={[
          {
            balloonLabel: "Cancel",
            icon: CancelRoundedIcon,
            onClick: "",
            type: "link",
            href: `/avod/playlists${id ? `/${id}/versions` : ""}`,
          },
          {
            when: playlist?.status === "published",
            balloonLabel: "Share Playlist",
            icon: ShareRoundedIcon,
            onClick: () => setShowShareDialog(true),
          },
          {
            when: !["processing", "published"].includes(playlist?.status),
            balloonLabel: "Save Playlist",
            icon: SaveRoundedIcon,
            onClick: onShowSaveDialog,
          },
          {
            when: !create && id && version,
            balloonLabel: "Copy to new version",
            icon: ContentCopyRoundedIcon,
            onClick: cloneVersion,
          },
        ]}
      />
      <AppBody>
        <div className="avod-playlist">
          <div className="avod-playlist__meta">
            {loading ? <Loader /> : null}
            {!loading && create ? (
              <CreateAvodFields
                updateField={updateForm}
                errors={formErrors}
                playlistName={id ? state.playlist_name : ""}
              />
            ) : null}
            {!loading && !create ? (
              <div className="form-field">
                <label htmlFor="ad_decision_url">Ad Decision Server URL</label>
                <div className="avod-playlist__meta__input-group avod-playlist__meta__input-group--with-button">
                  {playlist?.status && playlist.status === "draft" ? (
                    <input
                      type="text"
                      name="ad_decision_url"
                      id="ad_decision_url"
                      value={form?.ad_decision_url ?? ""}
                      onChange={(e) => updateForm("ad_decision_url", e.target.value)}
                      ref={adUrlRef}
                    />
                  ) : (
                    <input
                      type="text"
                      name="ad_decision_url"
                      id="ad_decision_url"
                      value={form?.ad_decision_url}
                      disabled
                      ref={adUrlRef}
                    />
                  )}
                  <button
                    className="btn btn--icon"
                    onClick={() => navigator.clipboard.writeText(adUrlRef.current.value)}
                    aria-label="Copy Ad Url"
                    data-balloon-pos="left"
                  >
                    <ContentCopyRoundedIcon />
                  </button>
                </div>
                {formErrors.hasOwnProperty("ad_decision_url") ? (
                  <div className="form-field__error">{formErrors.ad_decision_url}</div>
                ) : null}
              </div>
            ) : null}
          </div>
          <div className="avod-playlist__content">
            {!loading ? (
              <AvodPlaylist
                playlist={programs}
                providers={providers}
                updatePrograms={updatePrograms}
                editingDisabled={["processing", "published"].includes(playlist?.status)}
              />
            ) : null}
          </div>
        </div>
      </AppBody>
      <SaveAvodPlaylistDialog
        isOpen={showSaveDialog}
        onClose={() => setShowSaveDialog(false)}
        payload={{
          ...(create ? form : response.data),
          programs,
          ...(id && state?.playlist_name ? { playlist_name: state.playlist_name, avod_playlist_guid: id } : {}),
        }}
        isCreating={create}
        setFormErrors={setFormErrors}
        updatePage={updatePlaylist}
      />
      {playlist?.status === "published" ? (
        <ShareAvodPlaylistDialog
          isOpen={showShareDialog}
          onClose={() => setShowShareDialog(false)}
          url={playlist?.manifest?.distribution_url}
          wordpressExportDownloadUrl={`${BASE_URL}/api/avod-playlists/${id}/versions/${version}/export-wordpress?token=${getToken(false)}`}
        />
      ) : null}
    </React.Fragment>
  );
}
