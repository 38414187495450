import React from "react";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";
import ReactDatePicker from "react-datepicker";

function ChannelHeadingDatepicker({ planDate, updatePlanDate }) {
  return (
    <div className="channel-heading__datepicker">
      <div className="datepicker-container">
        <button
          className="btn--text-primary channel-heading__datepicker__arrow-button"
          onClick={(e) => {
            e.preventDefault();
            let newDate = new Date(planDate.getTime());
            newDate.setDate(newDate.getDate() - 1);
            updatePlanDate(newDate);
          }}
        >
          <ChevronLeftRoundedIcon />
        </button>
        <ReactDatePicker
          selected={new Date(planDate.getTime())}
          onChange={(e) => updatePlanDate(e)}
          clearIcon={null}
          dateFormat="yyyy-MM-dd"
        />
        <button
          className="btn--text-primary channel-heading__datepicker__arrow-button"
          onClick={(e) => {
            e.preventDefault();

            let newDate = new Date(planDate.getTime());
            updatePlanDate(new Date(newDate.setDate(newDate.getDate() + 1)));
          }}
        >
          <ChevronRightRoundedIcon />
        </button>
      </div>
    </div>
  );
}

export default ChannelHeadingDatepicker;
