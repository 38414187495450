import ReactModal from "react-modal";
import Loader from "../../common/loader.jsx";
import React from "react";
import { triggerIngestion } from "../../requests/api-requests.js";
import { toast } from "react-toastify";
import formatApiErrors from "../../functions/format-api-errors.js";

export default function IngestFeedRunIngestDialog({ isOpen, feedId, onDone }) {
  const [isLoading, setIsLoading] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState({});

  function submit() {
    setIsLoading(true);
    triggerIngestion(feedId)
      .then(() => {
        toast.success("Ingestion triggered successfully");
        onDone();
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        if (error.status === 422) {
          formatApiErrors(error.data.errors);
        } else if (error.status === 500 || error.status === 404) {
          setErrorMessage({
            api: `There was an error triggering this ingestion, received code ${error.status}. Please contact support.`,
          });
        }
        setIsLoading(false);
      });
  }

  return (
    <ReactModal isOpen={isOpen} ariaHideApp={false}>
      <div className="v-react-modal__inner modal-width">
        <div className="v-react-modal__heading">Trigger Ingestion</div>
        <div className="v-react-modal__body modal-body">
          Are you sure you wish to trigger an ingestion?
          <br />
          This cannot be cancelled once started
          <br />
          {errorMessage.api ? <p className="u-fc--warning">{errorMessage.api}</p> : null}
        </div>
        <div className="v-react-modal__footer">
          {isLoading ? (
            <Loader width={45} height={45} />
          ) : (
            <React.Fragment>
              <button className="btn btn--inverse-primary" onClick={onDone}>
                Cancel
              </button>
              <button className="btn btn--primary" onClick={submit}>
                Confirm
              </button>
            </React.Fragment>
          )}
        </div>
      </div>
    </ReactModal>
  );
}
