export function getHeightFromSeconds(secondsPerSection, pixelsPerSection, seconds) {
  const sections = seconds / secondsPerSection;
  const pixels = sections * pixelsPerSection;

  return pixels;
}

function findFurthestShortProgram(
  fromIndex,
  list,
  shortHeight,
  secondsPerSection,
  pixelsPerSection,
  direction,
  distance = 0,
) {
  if (!list[fromIndex]) {
    return distance;
  }

  const current = getHeightFromSeconds(secondsPerSection, pixelsPerSection, list[fromIndex].total_duration_seconds);

  if (current > shortHeight) {
    return distance;
  }

  return findFurthestShortProgram(
    fromIndex + direction,
    list,
    shortHeight,
    secondsPerSection,
    pixelsPerSection,
    direction,
    distance + 1,
  );
}

// offset can be [-1, y], [0, y], or [1, y]
export function getSmallOffset(fromIndex, list, shortHeight, secondsPerSection, pixelsPerSection) {
  const shortProgramsBehind =
    findFurthestShortProgram(fromIndex, list, shortHeight, secondsPerSection, pixelsPerSection, -1) - 1;
  const shortProgramsAhead =
    findFurthestShortProgram(fromIndex, list, shortHeight, secondsPerSection, pixelsPerSection, 1) - 1;

  let x = shortProgramsBehind !== 0 ? (shortProgramsBehind % 3) - 1 : 0;
  const y = shortProgramsBehind !== 0 ? Math.floor(shortProgramsBehind / 3) : 0;

  if (shortProgramsAhead > 0 && shortProgramsBehind === 0 && x === 0) {
    x -= 1;
  }

  return [x, y];
}

export function getPrependedMargin(fromIndex, list, shortHeight, secondsPerSection, pixelsPerSection) {
  const indexHeight = getHeightFromSeconds(secondsPerSection, pixelsPerSection, list[fromIndex].total_duration_seconds);
  if (indexHeight <= shortHeight) {
    return 0;
  }

  const shortProgramsBehind = findFurthestShortProgram(
    fromIndex - 1,
    list,
    shortHeight,
    secondsPerSection,
    pixelsPerSection,
    -1,
  );

  let marginAdjustment = 0;

  if (shortProgramsBehind > 0) {
    for (let i = 1; i < shortProgramsBehind + 1; i++) {
      const current = getHeightFromSeconds(
        secondsPerSection,
        pixelsPerSection,
        list[fromIndex - i].total_duration_seconds,
      );

      marginAdjustment += current;
    }
  }

  return marginAdjustment;
}
