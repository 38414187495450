import React from "react";
import SortRoundedIcon from "@mui/icons-material/SortRounded";
import SouthIcon from "@mui/icons-material/South";
import NorthIcon from "@mui/icons-material/North";
import SortByAlphaIcon from "@mui/icons-material/SortByAlpha";

function SortOptions({ onChange, sortOrder }) {
  function getSortOption(optionKey) {
    const optionFound = sortOrder.find((sortOption) => sortOption.value === optionKey);
    return optionFound ? optionFound.order : null;
  }

  function getSortOptionIcon(optionKey) {
    if (optionKey === "title") {
      return (
        <React.Fragment>
          <SortByAlphaIcon />
          {getSortOption(optionKey) === "desc" ? <SouthIcon /> : <NorthIcon />}
        </React.Fragment>
      );
    }
    if (optionKey === "duration") {
      return (
        <React.Fragment>
          <SortRoundedIcon />
          {getSortOption(optionKey) === "desc" ? <SouthIcon /> : <NorthIcon />}
        </React.Fragment>
      );
    }
  }

  function getNextSortCycleStep(orderValue) {
    const cycleOrder = [null, "asc", "desc"];
    let currentStep = cycleOrder.findIndex((option) => option === orderValue);
    return cycleOrder[currentStep] === cycleOrder.length - 1 ? cycleOrder[0] : cycleOrder[currentStep + 1];
  }

  return (
    <div className="content-list-filter__sort__options">
      <div className="content-list-filter__sort__label">Sorting</div>
      <button
        className="btn btn--pill btn--ntrl-min u-display--flex-apart u-display--flex-v-center"
        onClick={() => onChange("title", getNextSortCycleStep(getSortOption("title")))}
        key="title-sort"
      >
        Title
        <span
          className={`content-sort-btn__icon ${
            getSortOption("title") ? "content-sort-btn__icon--primary" : "content-sort-btn__icon--disabled"
          }`}
        >
          {getSortOptionIcon("title")}
        </span>
      </button>
      <button
        className="btn btn--pill btn--ntrl-min u-display--flex-apart u-display--flex-v-center"
        onClick={() => onChange("duration", getNextSortCycleStep(getSortOption("duration")))}
        key="duration-sort"
      >
        Duration
        <span
          className={`content-sort-btn__icon ${
            getSortOption("duration") ? "content-sort-btn__icon--primary" : "content-sort-btn__icon--disabled"
          }`}
        >
          {getSortOptionIcon("duration")}
        </span>
      </button>
    </div>
  );
}

export default SortOptions;
