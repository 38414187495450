import React from "react";
import { Link } from "react-router-dom";
import { useSchedulerContext } from "../../../providers/scheduler-context.jsx";
import ChannelPlaybackDropdown from "./channel-playback-dropdown.jsx";
import ArrowBackIosRoundedIcon from "@mui/icons-material/ArrowBackIosRounded";
import ChannelHeadingChannelDropdown from "./channel-heading/channel-heading-channel-dropdown.jsx";
import ChannelHeadingDatepicker from "./channel-heading/channel-heading-datepicker.jsx";
import ChannelHeadingTimezone from "./channel-heading/channel-heading-timezone.jsx";
import ChannelHeadingAutosaveButton from "./channel-heading/channel-heading-autosave-button.jsx";
import ChannelHeadingResetLayoutButton from "./channel-heading/channel-heading-reset-layout-button.jsx";
import ChannelHeadingRightActions from "./channel-heading/channel-heading-right-actions.jsx";

function ChannelHeading({
  channel,
  planDate,
  changePlanDate,
  clearPlan,
  saveChannelPlan,
  planId,
  planUpdated,
  scheduleUpdated,
  openChangesDialog,
  toggleTimezone,
  playoutUrl,
  setPlayoutUrl,
  isSaving,
  autosaveEnabled,
  toggleAutosave,
  resetLayout,
}) {
  const [updatedAt, setUpdatedAt] = React.useState();
  const { timezone } = useSchedulerContext();

  function updatePlanDate(newDate) {
    if (scheduleUpdated()) {
      openChangesDialog(
        "changeDate",
        "Are you sure you wish to change the plan date? (Unsaved changes will be lost)",
        newDate,
      );
    } else {
      changePlanDate(newDate);
    }
  }

  React.useEffect(() => {
    setUpdatedAt(new Date(planUpdated));
  }, [planUpdated]);

  if (!channel || !planDate) {
    return;
  }

  return [
    <div className="channel-heading" key={"header"}>
      <div className="channel-heading__back">
        <Link className="link--base" to={`/scheduler/${channel.channel_id}`}>
          <ArrowBackIosRoundedIcon />
        </Link>
      </div>

      <ChannelHeadingChannelDropdown label={channel.display_name} />

      <ChannelHeadingDatepicker planDate={planDate} updatePlanDate={updatePlanDate} />

      <ChannelHeadingTimezone timezone={timezone} toggleTimezone={toggleTimezone} />

      {channel.playouts.length > 1 ? (
        <div className="channel-heading__playback-select">
          <ChannelPlaybackDropdown channel={channel} activePlayback={playoutUrl} onChange={setPlayoutUrl} />
        </div>
      ) : null}

      <ChannelHeadingAutosaveButton autosaveEnabled={autosaveEnabled} toggleAutosave={toggleAutosave} />
      <ChannelHeadingResetLayoutButton resetLayout={resetLayout} />

      <ChannelHeadingRightActions
        channel={channel}
        planDate={planDate}
        planId={planId}
        updatedAt={updatedAt}
        saveChannelPlan={saveChannelPlan}
        isSaving={isSaving}
        clearPlan={clearPlan}
        changePlanDate={changePlanDate}
      />
    </div>,
  ];
}

export default ChannelHeading;
