import React from "react";
import SlidingPane from "react-sliding-pane";
import useApiRequest from "../../hooks/use-api-request.js";
import Select from "../../common/select.jsx";
import Loader from "../../common/loader.jsx";
import formatApiErrors from "../../functions/format-api-errors.js";
import { createPromo } from "../../requests/api-requests.js";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";

const defaultFormShape = {
  provider: null,
  promo_name: "",
  source_vod_url: "",
};

export default function CreatePromoWindow({ isOpen, onDone, onSuccess, action = "create" }) {
  const [form, setForm] = React.useState(defaultFormShape);
  const [errors, setErrors] = React.useState({});
  const [submitLoading, setSubmitLoading] = React.useState(false);
  const { isLoading, response } = useApiRequest("/api/providers");

  React.useEffect(() => {
    if (!isOpen) {
      setForm(defaultFormShape);
      setErrors({});
    }
  }, [isOpen]);

  function onFailed(error) {
    if (error.status === 422) {
      setErrors(formatApiErrors(error.data.errors, true));
    } else if (error.status === 500 || error.status === 404) {
      setErrors({
        api: `There was an error creating promo, received code ${error.status}. Please contact support.`,
      });
    } else if (error.status === 403) {
      setErrors({ api: "Insufficient permissions to create a promo." });
    }
  }

  function submit() {
    const formErrors = validate();
    if (Object.keys(formErrors).length) {
      setErrors(formErrors);
      return;
    }

    const payload = {
      ...form,
      provider_id: form.provider.value, // we only want the provider_id in the payload
    };
    delete payload.provider;

    setSubmitLoading(true);

    createPromo(payload)
      .then(() => {
        onSuccess();
        onDone();
      })
      .catch(onFailed)
      .finally(() => setSubmitLoading(false));
  }

  function validate() {
    let formErrors = {};

    if (!form.promo_name) {
      formErrors = { ...formErrors, promo_name: "Promo Name is required." };
    }

    if (!(form.source_vod_url.startsWith("https://") || form.source_vod_url.startsWith("s3://"))) {
      formErrors = { ...formErrors, source_vod_url: "Promo url must start with https:// or s3://" };
    }

    if (!form.provider?.value) {
      formErrors = { ...formErrors, provider: "Please select a Provider." };
    }

    if (Object.keys(formErrors).length) {
      formErrors = { ...formErrors, api: "Errors in fields above. Please fix and retry." };
    }

    return formErrors;
  }

  function handleChange(key, value) {
    setForm((_form) => ({
      ..._form,
      [key]: value,
    }));
  }

  function getProviders() {
    if (!response?.data?.data) {
      return [];
    }

    return response.data.data.map((provider) => ({ label: provider.provider_name, value: provider.provider_id }));
  }

  function getSelectedProvider(provider_name, provider_id) {
    let providers = [
      {
        label: "No provider",
        value: "",
      },
    ];
    if (!provider_name || !provider_id) {
      return providers[0];
    }

    return getProviders().filter((p) => p.value === provider_id)[0] ?? providers[0];
  }

  return (
    <SlidingPane
      closeIcon={<CancelRoundedIcon />}
      overlayClassName="react-sliding-pane--show"
      isOpen={isOpen}
      title={"Create new Promo"}
      from="right"
      onRequestClose={() => onDone()}
      width={"40%"}
      key="create-promo-pane"
    >
      {isLoading ? (
        <Loader />
      ) : (
        <form>
          <div className="form-field">
            <label>Promo Name</label>
            <input
              type="text"
              name="promo_name"
              onChange={(e) => handleChange("promo_name", e.target.value)}
              defaultValue={action === "edit" ? form.promo_name : ""}
            />
            {errors.hasOwnProperty("promo_name") ? <div className="form-field__error">{errors.promo_name}</div> : null}
          </div>

          <div className="form-field">
            <label>For Provider</label>
            <Select
              placeholder="Select a Provider"
              name="provider"
              options={getProviders()}
              value={getSelectedProvider(form.provider?.label, form.provider?.value)}
              onChange={(input) => handleChange("provider", input)}
              classNamePrefix="react-select"
            />
            {errors.hasOwnProperty("provider") ? <div className="form-field__error">{errors.provider}</div> : null}
          </div>

          <div className="form-field">
            <label>Source Vod Url</label>
            <input
              type="text"
              name="source_vod_url"
              onChange={(e) => handleChange("source_vod_url", e.target.value)}
              defaultValue={action === "edit" ? form.source_vod_url : ""}
            />
            {errors.hasOwnProperty("source_vod_url") ? (
              <div className="form-field__error">{errors.source_vod_url}</div>
            ) : null}
          </div>

          {errors.hasOwnProperty("api") ? <p className="u-fc--warning">{errors.api}</p> : null}
        </form>
      )}
      <div className="slide-pane__footer">
        {submitLoading ? (
          <Loader width={45} height={45} />
        ) : (
          <React.Fragment>
            <button className="btn btn--inverse-primary" onClick={() => onDone()}>
              Cancel
            </button>
            <button
              className="btn btn--primary"
              onClick={(e) => {
                e.preventDefault();
                submit();
              }}
            >
              Create Promo
            </button>
          </React.Fragment>
        )}
      </div>
    </SlidingPane>
  );
}
